import requestSender from "./base/requestSender";

export default function(axiosInstance){
    return {
        saveName(payload) {
            return requestSender('post', 'introduction/save-name', payload)
        },
        saveStatement(payload) {
            return requestSender('post', 'introduction/save-statement', payload)
        },
        saveDateIntroduction(payload) {
            return requestSender('post', 'introduction/save-date-introduction', payload)
        },

        uploadToAsutr(payload) {
            return requestSender('post', 'introduction/upload-to-asutr', payload)
        },
    }
}