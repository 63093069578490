<template>
    <span>
        <el-dialog
            append-to-body
            title="Ответственные за компетенцию"
            :close-on-click-modal="false"
            :visible.sync="show"
            width="60%"
            destroy-on-close
        >
            <el-row>
                <div class="hint-div">
                    Если Вы не нашли пользователя в списке, обратитесь к секретарю рабочей группы. Возможно, такого пользователя еще нет в системе
                </div>
            </el-row>
            <el-row v-loading="loading">
                <el-form
                    label-position="left"
                    label-width="140px"
                >
                    <el-form-item label="Ответственные">
                        <users-table
                            add-button-text="Добавить ответственных"
                            :users="profilingTsd.users"
                            :add-users-filter-role="['admin', 'secretary', 'expert']"
                            :add-users-filter-projects="[parseInt($route.params.id)]"
                            negotiation-row
                            :negotiation-main-user="profilingTsd.main_user_id"
                            @new-users="addNewSharedUsers"
                            @user-deleted="deleteUser"
                            @main-changed="changeMainUser"
                        ></users-table>
                    </el-form-item>

                </el-form>
            </el-row>



            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="show = false">Закрыть</el-button>
            </span>
        </el-dialog>

        <span ref="wrapper">
            <slot name="reference"></slot>
        </span>
    </span>
</template>

<script>
import {off, on} from "element-ui/src/utils/dom";
import UsersTable from "../user/UsersTable";


export default {
    name: "tsd-shared-users-dialog",
    components: {UsersTable},

    props: {
        profilingId: {
            type: Number
        },
        tsdId: {
            type: Number
        }
    },

    data() {
        return {
            loading: false,
            show: false,

            profilingTsd: {
                main_user_id: null,
                users: []
            }
        }
    },
    mounted() {
        if (this.$refs.wrapper.children) {
            on(this.$refs.wrapper.children[0], 'click', this.showModal);
        }
    },
    watch: { },
    methods: {
        showModal() {
            this.show = true;
            this.getTsdSharedUsers();
        },

        getTsdSharedUsers(){
            this.loading = true;
            this.$api.profiling.getTsdSharedUsers({
                profiling_id: this.profilingId,
                tsd_id: this.tsdId,
            })
                .then(data => {
                    this.profilingTsd = data.profiling_tsd;
                })
                .finally(()=>{
                    this.loading = false;
                })
        },

        addNewSharedUsers(users){
            let ids = users.map(user=>user.id);
            this.loading = true;
            this.$api.profiling.addNewTsdSharedUsers({
                profiling_id: this.profilingId,
                tsd_id: this.tsdId,
                user_ids: ids
            })
                .then(data => {
                    this.$notify.success({
duration: this.$constants.global.notifyDuration,
                        title: 'Сохранено',
                        message: 'Ответственные успешно добавлены'
                    });
                })
                .finally(()=>{
                    this.getTsdSharedUsers();
                })
        },
        deleteUser(user){
            this.loading = true;
            this.$api.profiling.deleteTsdSharedUser({
                profiling_id: this.profilingId,
                tsd_id: this.tsdId,
                user_id: user.id
            })
                .then(data => {
                    this.$notify.success({
duration: this.$constants.global.notifyDuration,
                        title: 'Удалено',
                        message: 'Ответственный успешно удален'
                    });
                })
                .finally(()=>{
                    this.getTsdSharedUsers();
                })
        },
        changeMainUser(user){
            this.loading = true;
            this.$api.profiling.setTsdMainSharedUser({
                profiling_id: this.profilingId,
                tsd_id: this.tsdId,
                user_id: user.id,
            })
                .then(data => {
                    this.$notify.success({
duration: this.$constants.global.notifyDuration,
                        title: 'Сохранено',
                        message: 'Ответственный успешно сохранен'
                    });
                })
                .finally(()=>{
                    this.getTsdSharedUsers();
                })
        },
    },
    beforeDestroy() {
        if (this.$refs.wrapper.children) {
            off(this.$refs.wrapper.children[0], 'click', this.showModal);
        }
    }
}
</script>

<style>

</style>