<template>
  <el-select
      style="width:100%"
      :value="value"
      :size="size"
      filterable
      remote
      clearable
      reserve-keyword
      placeholder="Компетенции"
      :multiple="multiple"
      :remote-method="remoteMethod"
      :loading="loading"
      @change="selected"
      @clear="remoteMethod"
      @visible-change="$event ? remoteMethod() : null"
  >
    <el-option-custom
        v-for="item in options"
        :key="item.id+item.mpc_id"
        :label="item.name"
        :value="item.id"
        :with-checkbox="multiple"
    >
    </el-option-custom>
  </el-select>

</template>

<script>


import ElOptionCustom from "@/components/custom/ElOptionCustom";
export default {
  name: "tsd-competence-select",
  components: {ElOptionCustom},
  props: {
    value: [],
    size: {
      type: String,
      default: ''
    },
    projectId: {
      type: Number,
      required: true,
    },

    multiple: {
      type: Boolean
    }
  },

  data() {
    return {
      loading: false,
      project_id: null,
      options: [],
    }
  },
  mounted() {
    this.remoteMethod()
  },
  watch: {},
  computed: {},
  methods: {
    remoteMethod(query) {
      this.loading = true;
      this.$api.mpc.searchCompetences({
        search: query,
        page: 1,
        project_id: this.projectId,
        fields: 'id, mpc_id, name',
      })
          .then((data) => {
            this.options = data.competences;
          })
          .finally(() => {
            this.loading = false;
          })
    },

    selected(value) {
      this.$emit('input', value);
      this.$emit('selected', value)
    }
  },

}
</script>

<style scoped>

</style>