import requestSender from "./base/requestSender";

export default function(axiosInstance){
    return {
        search(payload){
            return requestSender('get', 'division/search', payload);
        },
        save(payload){
            return requestSender('post', 'division/save', payload);
        },
        delete(payload){
            return requestSender('post', 'division/delete', payload);
        },

    }
}