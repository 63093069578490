<template>
  <div>
    <el-dialog
        title="Загрузка пользователей"
        :visible.sync="show"
        :close-on-click-modal="false"
        width="30%"
    >
      <el-row v-loading="loading">
        <div v-if="!pilot" class="info">
          Необходимо указывать полное наименование подразделения. <br>
          Пример: Эксплуатационное локомотивное депо Саратов -пассажирское - Приволжской дирекции тяги Дирекции тяги
          -филиала ОАО "РЖД"
        </div>
        <div v-if="pilot" class="info">
          Для загрузки участников пилотной оценки на платформу необходимо скачать загрузочный файл и заполнить его согласно примеру
        </div>

        <div class="file-example">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.6663 26.6668C26.6663 27.374 26.3854 28.0523 25.8853 28.5524C25.3852 29.0525 24.7069 29.3334 23.9997 29.3334H7.99967C7.29243 29.3334 6.61415 29.0525 6.11406 28.5524C5.61396 28.0523 5.33301 27.374 5.33301 26.6668V5.33342C5.33301 4.62617 5.61396 3.94789 6.11406 3.4478C6.61415 2.9477 7.29243 2.66675 7.99967 2.66675H18.6663L26.6663 10.6667" stroke="#767A7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M18.667 2.66675V10.6667H26.667" stroke="#767A7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9.03189 21.4052C8.84522 21.4052 8.68789 21.3572 8.55989 21.2612C8.43722 21.1599 8.36789 21.0319 8.35189 20.8772C8.33589 20.7172 8.38922 20.5572 8.51189 20.3972L9.95989 18.4852L8.55989 16.6372C8.43722 16.4719 8.38389 16.3119 8.39989 16.1572C8.42122 16.0026 8.49322 15.8746 8.61589 15.7732C8.73856 15.6719 8.89322 15.6212 9.07989 15.6212C9.23456 15.6212 9.37056 15.6586 9.48789 15.7332C9.60522 15.8079 9.72256 15.9279 9.83989 16.0932L10.7919 17.4212L11.7519 16.0932C11.8692 15.9226 11.9866 15.8026 12.1039 15.7332C12.2212 15.6586 12.3572 15.6212 12.5119 15.6212C12.7039 15.6212 12.8612 15.6719 12.9839 15.7732C13.1066 15.8692 13.1759 15.9972 13.1919 16.1572C13.2079 16.3119 13.1546 16.4719 13.0319 16.6372L11.6319 18.4852L13.0719 20.3972C13.1999 20.5572 13.2532 20.7172 13.2319 20.8772C13.2159 21.0319 13.1466 21.1599 13.0239 21.2612C12.9012 21.3572 12.7439 21.4052 12.5519 21.4052C12.3972 21.4052 12.2612 21.3706 12.1439 21.3012C12.0319 21.2266 11.9146 21.1039 11.7919 20.9332L10.7919 19.5572L9.79989 20.9332C9.67722 21.1039 9.55722 21.2266 9.43989 21.3012C9.32256 21.3706 9.18656 21.4052 9.03189 21.4052Z" stroke="transparent" fill="#767A7A"/>
            <path d="M14.8418 21.3332C14.6018 21.3332 14.4178 21.2692 14.2898 21.1412C14.1618 21.0079 14.0978 20.8239 14.0978 20.5892V16.4292C14.0978 16.1892 14.1618 16.0052 14.2898 15.8772C14.4178 15.7492 14.5992 15.6852 14.8338 15.6852C15.0685 15.6852 15.2498 15.7492 15.3778 15.8772C15.5058 16.0052 15.5698 16.1892 15.5698 16.4292V20.1092H17.5218C17.7245 20.1092 17.8792 20.1626 17.9858 20.2692C18.0978 20.3759 18.1538 20.5252 18.1538 20.7172C18.1538 20.9146 18.0978 21.0666 17.9858 21.1732C17.8792 21.2799 17.7245 21.3332 17.5218 21.3332H14.8418Z" stroke="transparent" fill="#767A7A"/>
            <path d="M20.7798 21.4212C20.4544 21.4212 20.1184 21.3892 19.7718 21.3252C19.4304 21.2559 19.1371 21.1599 18.8918 21.0372C18.7478 20.9679 18.6464 20.8746 18.5878 20.7572C18.5291 20.6399 18.5078 20.5199 18.5238 20.3972C18.5398 20.2692 18.5851 20.1572 18.6598 20.0612C18.7344 19.9599 18.8331 19.8932 18.9558 19.8612C19.0784 19.8239 19.2171 19.8399 19.3718 19.9092C19.5744 20.0106 19.8038 20.0879 20.0598 20.1412C20.3158 20.1892 20.5558 20.2132 20.7798 20.2132C21.1318 20.2132 21.3771 20.1679 21.5158 20.0772C21.6598 19.9812 21.7318 19.8639 21.7318 19.7252C21.7318 19.6026 21.6811 19.5039 21.5798 19.4292C21.4838 19.3546 21.3104 19.2906 21.0598 19.2372L20.1238 19.0372C19.1104 18.8186 18.6038 18.2879 18.6038 17.4452C18.6038 17.0772 18.7024 16.7546 18.8998 16.4772C19.1024 16.1999 19.3824 15.9866 19.7398 15.8372C20.0971 15.6826 20.5131 15.6052 20.9878 15.6052C21.2651 15.6052 21.5451 15.6372 21.8278 15.7012C22.1158 15.7599 22.3638 15.8506 22.5718 15.9732C22.7318 16.0586 22.8331 16.1759 22.8758 16.3252C22.9184 16.4692 22.9131 16.6106 22.8598 16.7492C22.8064 16.8879 22.7104 16.9892 22.5718 17.0532C22.4384 17.1172 22.2678 17.1066 22.0598 17.0212C21.8998 16.9519 21.7238 16.9012 21.5318 16.8692C21.3398 16.8319 21.1531 16.8132 20.9718 16.8132C20.6731 16.8132 20.4438 16.8666 20.2838 16.9732C20.1238 17.0746 20.0438 17.2079 20.0438 17.3732C20.0438 17.4906 20.0918 17.5866 20.1878 17.6612C20.2838 17.7359 20.4544 17.7999 20.6998 17.8532L21.6278 18.0532C22.6571 18.2719 23.1718 18.7892 23.1718 19.6052C23.1718 19.9732 23.0731 20.2932 22.8758 20.5652C22.6784 20.8372 22.4011 21.0479 22.0438 21.1972C21.6864 21.3466 21.2651 21.4212 20.7798 21.4212Z" stroke="transparent" fill="#767A7A"/>
            <path d="M24.3366 21.4052C24.1499 21.4052 23.9926 21.3572 23.8646 21.2612C23.7419 21.1599 23.6726 21.0319 23.6566 20.8772C23.6406 20.7172 23.6939 20.5572 23.8166 20.3972L25.2646 18.4852L23.8646 16.6372C23.7419 16.4719 23.6886 16.3119 23.7046 16.1572C23.7259 16.0026 23.7979 15.8746 23.9206 15.7732C24.0432 15.6719 24.1979 15.6212 24.3846 15.6212C24.5392 15.6212 24.6752 15.6586 24.7926 15.7332C24.9099 15.8079 25.0272 15.9279 25.1446 16.0932L26.0966 17.4212L27.0566 16.0932C27.1739 15.9226 27.2912 15.8026 27.4086 15.7332C27.5259 15.6586 27.6619 15.6212 27.8166 15.6212C28.0086 15.6212 28.1659 15.6719 28.2886 15.7732C28.4112 15.8692 28.4806 15.9972 28.4966 16.1572C28.5126 16.3119 28.4592 16.4719 28.3366 16.6372L26.9366 18.4852L28.3766 20.3972C28.5046 20.5572 28.5579 20.7172 28.5366 20.8772C28.5206 21.0319 28.4512 21.1599 28.3286 21.2612C28.2059 21.3572 28.0486 21.4052 27.8566 21.4052C27.7019 21.4052 27.5659 21.3706 27.4486 21.3012C27.3366 21.2266 27.2192 21.1039 27.0966 20.9332L26.0966 19.5572L25.1046 20.9332C24.9819 21.1039 24.8619 21.2266 24.7446 21.3012C24.6272 21.3706 24.4912 21.4052 24.3366 21.4052Z" stroke="transparent" fill="#767A7A"/>
          </svg>
          <el-link @click="downloadExample">Скачать шаблон загрузочного файла</el-link>
        </div>
        <div v-if="!pilot">
          В одном файле загрузить можно пользователей только с одинаковой ролью
          <role-select
            v-model="role"
            placeholder="Выберите роль"
          >
          </role-select>
        </div>

        <div style="margin-top: 20px">
          <el-upload
            accept=".xls,.xslx"
            ref="file-uploader"
            class="file-uploader"
            drag
            action=""
            :limit="1"
            :auto-upload="false"
            :http-request="doFileUpload"
            :on-exceed="onExceed"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">Нажмите для загрузки файла</div>
            <div class="file-add__desc">
              Параметры файла для загрузки: XLSX | не более 3 Мб
            </div>
          </el-upload>
        </div>
      </el-row>


      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="show = false" :loading="loading">Отмена</el-button>
        <el-button size="small" type="primary" @click="uploadFile" :disabled="!canUpload" :loading="loading">Загрузить</el-button>
      </span>
    </el-dialog>


    <el-dialog
      title="Результаты загрузки"
      :visible.sync="showResult"
      :close-on-click-modal="false"
      width="30%"
    >
      <el-row>
        <div>
          {{addedCount}} пользователей успешно добавлены!
        </div>

        <div
          v-if="addedErrors && Object.keys(addedErrors).length"
          style="margin-top: 20px"
        >
          <div>Исправьте записи и повторите загрузку пользователей:</div>
          <ul
            style="list-style: unset; margin-left: 25px;"
          >
            <li
              v-for="(errorText, errorFio) in addedErrors"
            >
              {{errorFio}}, {{errorText}}
            </li>
          </ul>
        </div>
      </el-row>


      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="showResult = false">Закрыть</el-button>
      </span>
    </el-dialog>

    <div ref="wrapper" style="margin-right: 24px">
      <slot name="reference"></slot>
    </div>
  </div>
</template>

<script>
import {off, on} from "element-ui/src/utils/dom";
import RoleSelect from "@/components/selects/RoleSelect";
import {downloader} from "@/mixins/downloader";

export default {
  name: "import-users-dialog",
  components: {RoleSelect},
  mixins:[downloader],
  props: {
    pilot: {
      type: Boolean,
      default: false
    },
    projectId: {
      type: Number
    }
  },

  data() {
    return {
      isMounted: false,
      loading: false,

      show: false,
      showResult: false,

      role: null,
      addedCount: 0,
      addedErrors: {},
    }
  },
  mounted() {
    this.isMounted = true;
    if (this.$refs.wrapper.children) {
      on(this.$refs.wrapper.children[0], 'click', this.showModal);
    }
  },
  watch: {},
  computed: {
    canUpload() {
      if( !this.pilot && !this.role ){
        return false;
      }

      return true;
    }
  },
  methods: {

    showModal() {
      this.show = true;
    },

    downloadExample() {
      this.downloadFile(
        false,
        this.$api.user.downloadExample,
        { pilot: this.pilot ? 1 : 0 }
      );
    },

    doFileUpload(params) {
      let form = new FormData();
      form.append('role', this.role);
      form.append('pilot', this.pilot ? 1 : 0);
      if( this.projectId ){
        form.append('project_id', this.projectId);
      }
      form.append('attachment', params.file);

      return this.$api.user.importFromFile(form)
        .then(data => {
          this.$refs["file-uploader"].clearFiles();
          this.addedCount = data.added;
          this.addedErrors = data.errors;

          this.show = false;
          this.showResult = true;

          this.$emit('users-uploaded');
        })
        .finally(()=>{
          this.loading = false;
        })
    },
    onExceed(params) {
      this.$notify.error({
        title: 'Лимит',
        message: 'Не более одного элемента'
      });
    },



    uploadFile() {
      if( !this.canUpload ) {
        return;
      }

      this.loading = true;

      this.$refs["file-uploader"].submit();
    },


  },
  beforeDestroy() {
    if (this.$refs.wrapper.children) {
      off(this.$refs.wrapper.children[0], 'click', this.showModal);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/assets/css/project-variables";

.info {
  background-color: #F2F0F0;
  padding: 15px;
}

.file-example {
  display: flex;
  align-items: center;
  margin: 16px 0;
  cursor: pointer;

  svg {
    width: 32px;
    height: 32px;
    margin-right: 8px;

    path {
      transition: all .4s;
    }
  }

  &:hover {

    svg {

      path {
        stroke: $--color-primary;
      }
    }
  }
}

.file-add {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  background: $--color-white;
  border: 1px solid $--color-divider;
  border-radius: 6px;
  cursor: pointer;
  transition: all .4s;

  &__icon {
    width: 40px;
    height: 40px;
    margin-bottom: 8px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__input {
    display: none;
  }

  &__title {
    margin-bottom: 4px;
  }

  &__desc {
    color: $--color-text-regular-4;
  }

  &:hover {
    border-color: $--color-text-regular;
  }

  &:focus {
    border-color: $--color-primary-light-6;
  }

  @media only screen and (max-width: 1480px) {
    font-size: 12px;

    &__icon {
      width: 24px;
      height: 24px;
    }
  }
}

</style>
