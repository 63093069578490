<template>
  <div>
    <el-dialog
        append-to-body
        title="Ответственные за подразделение"
        :close-on-click-modal="false"
        :visible.sync="show"
        width="60%"
        destroy-on-close
        custom-class="responsible-dialog"
    >
      <div class="responsible-dialog__desc">
        Если Вы не нашли пользователя в списке, обратитесь к секретарю рабочей группы. Возможно, такого пользователя
        еще нет в системе
      </div>

      <el-row v-loading="loading">
        <el-form
            class="responsible-dialog__form"
            label-position="left"
            label-width="140px"
        >
          <el-form-item label="Ответственные"></el-form-item>
          <users-table
              :add-button="canSetUsers"
              add-button-text="Добавить ответственных"
              :delete-button="canSetUsers"
              :negotiation-row-disabled="!canSetMainUser"
              :users="users"
              :add-users-filter-role="['admin', 'expert']"
              :add-users-filter-projects="[parseInt($route.params.id)]"
              negotiation-row
              :negotiation-main-user="mainUserId"
              @new-users="addNewSharedUsers"
              @user-deleted="deleteUser"
              @main-changed="changeMainUser"
          ></users-table>
        </el-form>
      </el-row>


      <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="show = false">Закрыть</el-button>
            </span>
    </el-dialog>

    <span ref="wrapper">
            <slot name="reference"></slot>
        </span>
  </div>
</template>

<script>
import {off, on} from "element-ui/src/utils/dom";
import UsersTable from "../user/UsersTable";


export default {
  name: "tsd-tp-shared-users-dialog",
  components: {UsersTable},

  props: {
    profilingId: {
      type: Number
    },
    tpId: {
      type: Number
    },
    canSetUsers: {
      type: Boolean,
      default: true
    },
    canSetMainUser: {
      type: Boolean,
      default: true
    },
  },

  data() {
    return {
      loading: false,
      show: false,
      users: [],
      mainUserId: null,
    }
  },
  mounted() {
    if (this.$refs.wrapper.children) {
      on(this.$refs.wrapper.children[0], 'click', this.showModal);
    }
  },
  watch: {},
  methods: {
    showModal() {
      this.show = true;
      this.getTsdTpSharedUsers();
    },

    getTsdTpSharedUsers() {
      this.loading = true;
      this.$api.profiling.getTsdTpSharedUsers({
        profiling_id: this.profilingId,
        tp_id: this.tpId,
      })
          .then(data => {
            this.users = data.users;
            this.mainUserId = data.main_user_id;
          })
          .finally(() => {
            this.loading = false;
          })
    },

    addNewSharedUsers(users) {
      let ids = users.map(user => user.id);
      this.loading = true;
      this.$api.profiling.addNewTsdTpSharedUsers({
        profiling_id: this.profilingId,
        tp_id: this.tpId,
        user_ids: ids
      })
          .then(data => {
            this.$notify.success({
duration: this.$constants.global.notifyDuration,
              title: 'Сохранено',
              message: 'Ответственные успешно добавлены'
            });
          })
          .finally(() => {
            this.getTsdTpSharedUsers();
          })
    },
    deleteUser(user) {
      this.loading = true;
      this.$api.profiling.deleteTsdTpSharedUser({
        profiling_id: this.profilingId,
        tp_id: this.tpId,
        user_id: user.id
      })
          .then(data => {
            this.$notify.success({
duration: this.$constants.global.notifyDuration,
              title: 'Удалено',
              message: 'Ответственный успешно удален'
            });
          })
          .finally(() => {
            this.getTsdTpSharedUsers();
          })
    },
    changeMainUser(user) {
      this.loading = true;
      this.$api.profiling.setTsdTpMainSharedUser({
        profiling_id: this.profilingId,
        tp_id: this.tpId,
        user_id: user.id,
      })
          .then(data => {
            this.$notify.success({
duration: this.$constants.global.notifyDuration,
              title: 'Сохранено',
              message: 'Ответственный успешно сохранен'
            });
          })
          .finally(() => {
            this.getTsdTpSharedUsers();
          })
    },
  },
  beforeDestroy() {
    if (this.$refs.wrapper.children) {
      off(this.$refs.wrapper.children[0], 'click', this.showModal);
    }
  }
}
</script>

<style>

</style>