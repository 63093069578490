<template>
  <div class="page">
    <el-table
        :data="filteredPermissions"
        ref=""
        border
        style="width: 100%"
        v-loading="loading"
    >
      <el-table-column fixed prop="description" label="Название" min-width="300">
        <template slot="header" slot-scope="scope">
          <div align="center">
            <div>
              <el-input
                  v-model="permissionsSearch"
                  placeholder="Фильтр по названию"
                  size="medium"
                  type="search"
                  clearable
                  prefix-icon="el-icon-search"
              ></el-input>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          v-for="role in roles"
          min-width="140"
          :key="role.id"
      >
        <template slot="header" slot-scope="scope">
          <div align="center">
            {{ role.description }}
            <div>
              <!--                            <el-button-->
              <!--                                type="primary"-->
              <!--                                @click="manageUsersModalShow(role.name)"-->
              <!--                                plain-->
              <!--                                size="mini"-->
              <!--                                style=""-->
              <!--                                icon="el-icon-user-solid"-->
              <!--                            >-->
              <!--                            </el-button>-->
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <el-switch
              :disabled="!$can('rbac-manage')"
              v-model="role.permissions[scope.row.name]"
              @change="permissionChanged(role, scope.row.name)"
          >
          </el-switch>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
        title="Пользователи группы"
        :close-on-click-modal="false"
        :visible.sync="manageUsersModal.show"
        width="70%"
    >
      <el-row>
        <users-table
            :loading="manageUsersModal.loading"
            :users.sync="manageUsersModal.users"
            :paginator="manageUsersModal.paginator"
            add-users-filter-role=""
            search-field
            @search="manageUsersModalSearchChanged"
            @page-changed="manageUsersModalPageChanged"
            @user-deleted="manageUsersModalUserDeleted"
            @new-users="manageUsersModalUsersAdded"
        ></users-table>
      </el-row>

      <span slot="footer" class="dialog-footer">
              <el-button size="small" @click="manageUsersModal.show = false">Закрыть</el-button>
          </span>
    </el-dialog>

    <el-dialog
        title="Редактирование роли"
        :close-on-click-modal="false"
        width="50%"
    >
      <!--            <el-row style="margin-top:-30px">-->
      <!--                <el-form label-width="170px">-->
      <!--                    <el-form-item label="Название группы">-->
      <!--                        <el-input v-model="addGroupModal.group.name"></el-input>-->
      <!--                    </el-form-item>-->
      <!--                    <el-form-item label="Описание группы">-->
      <!--                        <el-input v-model="addGroupModal.group.description"></el-input>-->
      <!--                    </el-form-item>-->
      <!--                </el-form>-->
      <!--            </el-row>-->

      <!--            <span slot="footer" class="dialog-footer">-->
      <!--                            <el-popconfirm-->
      <!--                                v-show="addGroupModal.group.id"-->
      <!--                                confirm-button-text='Да'-->
      <!--                                cancel-button-text='нет'-->
      <!--                                icon="el-icon-question"-->
      <!--                                title="Вы уверены, что хотите удалить группу?"-->
      <!--                                @confirm="deleteGroup(addGroupModal.group.id)"-->
      <!--                            >-->
      <!--                                <el-button-->
      <!--                                    slot="reference"-->
      <!--                                    type="danger"-->
      <!--                                    plain-->
      <!--                                    icon="el-icon-delete"-->
      <!--                                    :loading="addGroupModal.loading"-->
      <!--                                >-->
      <!--                                    Удалить группу-->
      <!--                                </el-button>-->
      <!--                            </el-popconfirm>-->
      <!--                            <el-button @click="addGroupModal.show = false">Отмена</el-button>-->
      <!--                            <el-button-->
      <!--                                type="primary"-->
      <!--                                :loading="addGroupModal.loading"-->
      <!--                                @click="updateGroup"-->
      <!--                                :disabled="!addGroupModal.group.name || !addGroupModal.group.description"-->
      <!--                            > Сохранить-->
      <!--                            </el-button>-->
      <!--                        </span>-->
    </el-dialog>
  </div>
</template>

<script>
import UsersTable from "../components/user/UsersTable";


export default {
  name: 'Rbac',
  components: {UsersTable},

  data() {
    return {
      input: '',
      loading: false,
      permissionsSearch: null,

      roles: [],
      permissions: [],

      manageUsersModal: {
        show: false,
        loading: false,
        roleName: null,
        search: null,
        page: 1,
        users: [],
        paginator: null,
      },

      // addGroupModal: {
      //     show: false,
      //     loading: false,
      //     group: {
      //         id: null,
      //         name: null,
      //         description:null,
      //     },
      // },
    }
  },
  computed: {
    filteredPermissions() {
      if (!this.permissionsSearch) {
        return this.permissions;
      }
      return this.permissions.filter(permission => {
        return permission.description.toLowerCase().indexOf(this.permissionsSearch.toLowerCase()) > -1
      })
    },
  },

  mounted() {
    this.getTableData();
  },
  methods: {
    getTableData() {
      this.roles = [];
      this.permissions = [];

      this.loading = true;
      this.$api.rbac.getElementsForAclTable()
          .then(data => {
            this.permissions = data.permissions;
            this.roles = data.roles;
          })
          .finally(() => {
            this.loading = false;
          })
    },

    permissionChanged(role, permissionName) {
      this.loading = true;
      this.$api.rbac.saveAclGroupPermission({
        role_name: role.name,
        permission_name: permissionName,
        value: role.permissions[permissionName],
      })
          .then(data => {
            this.$notify.success({
duration: this.$constants.global.notifyDuration,
              title: 'Сохранено',
              message: 'Правило успешно сохранено'
            });
          })
          .finally(() => {
            this.loading = false;
          })
    },


    manageUsersModalShow(roleName) {
      this.manageUsersModal.roleName = roleName;
      this.manageUsersModal.search = null;
      this.manageUsersModal.page = 1;
      this.manageUsersModal.show = true;
      this.manageUsersModal.paginator = null;
      this.manageUsersModal.users = [];

      this.manageUsersModalUsersSearch();
    },

    manageUsersModalSearchChanged(search) {
      this.manageUsersModal.page = 1;
      this.manageUsersModal.search = search;
      this.manageUsersModalUsersSearch();
    },
    manageUsersModalPageChanged(page) {
      this.manageUsersModal.page = page;
      this.manageUsersModalUsersSearch();
    },

    manageUsersModalUsersSearch() {
      this.manageUsersModal.loading = true;
      this.$api.user.search({
        role_name: this.manageUsersModal.roleName,
        search: this.manageUsersModal.search,
        page: this.manageUsersModal.page,
      })
          .then(({data, paginator}) => {
            this.manageUsersModal.users = data.users;
            this.manageUsersModal.paginator = paginator;
          })
          .finally(() => {
            this.manageUsersModal.loading = false;
          })
    },

    manageUsersModalUsersAdded(users) {
      let ids = users.map(user => user.id);
      this.manageUsersModal.loading = true;
      this.$api.rbac.assignUsersToGroup({
        role_name: this.manageUsersModal.roleName,
        ids: ids
      })
          .then(data => {
            this.$notify.success({
duration: this.$constants.global.notifyDuration,
              title: 'Сохранено',
              message: 'Пользователи успешно добавлены'
            });
          })
          .finally(() => {
            this.manageUsersModalUsersSearch();
          })
    },
    manageUsersModalUserDeleted(user) {
      this.manageUsersModal.loading = true;
      this.$api.rbac.revokeUsersFromGroup({
        role_name: this.manageUsersModal.roleName,
        ids: [user.id]
      })
          .then(data => {
            this.$notify.success({
duration: this.$constants.global.notifyDuration,
              title: 'Удалено',
              message: 'Пользователь успешно лишен роли'
            });
          })
          .finally(() => {
            this.manageUsersModal.loading = false;
          })
    }
  }
}

</script>

<style scope>

</style>