<template>
  <div>
    <el-dialog
      title="Загрузка ТОС"
      :visible.sync="show"
      :close-on-click-modal="false"
      width="50%"
      top="2vh"
      append-to-body
      destroy-on-close
    >
      <el-row v-loading="loading">

        <div style="display: flex;flex-direction: row-reverse; z-index: 99999">
          <el-checkbox
            v-model="loadProfilings"
            style="z-index: 99999"
          >
            Загрузить профили
          </el-checkbox>
        </div>
        <el-tree
          style="margin-top: -30px"
          ref="tosTree"
          :load="loadTreeNode"
          node-key="objectid"
          :props="{
            label: 'objectname',
            children: 'children',
            disabled: 'disabled',
          }"
          lazy
          show-checkbox
        >
        </el-tree>


      </el-row>

      <span slot="footer" class="dialog-footer">

        <el-button size="small" @click="show = false">Закрыть</el-button>
        <el-button size="small" type="primary" :loading="loading" @click="uploadTsds">Загрузить</el-button>
      </span>
    </el-dialog>

    <span ref="wrapper">
      <slot name="reference"></slot>
    </span>
  </div>
</template>

<script>

import {off, on} from "element-ui/src/utils/dom";
import Paginator from "./../Paginator";
import EllipsisMenu from "../EllipsisMenu";
import {paginator} from "@/mixins/paginator";

export default {
  name: "upload-tos-modal",
  mixins: [],
  components: {},
  props: {},

  data() {
    return {
      show: false,
      loading: false,

      loadProfilings: false,
      props: {},
    }
  },
  mounted() {
    if (this.$refs.wrapper.children) {
      on(this.$refs.wrapper.children[0], 'click', this.showModal);
    }
  },
  watch: {},
  methods: {
    showModal() {
      this.show = true;

    },

    loadTreeNode(node, resolve) {
      // this.loadingRootFolder = !node.data;
      this.$api.tsd.getTypeOeTree({
        parent_id: node.data ? node.data.objectid : null,
      })
        .then(data => {
          // this.loadingRootFolder = false;
          return resolve(data.typeoes)
        })
    },


    uploadTsds() {
      this.loading = true;
      this.$api.tsd.loadTsdsOfTpsToProject({
        project_id: +this.$route.params.id,
        tds_tps: this.$refs.tosTree.getCheckedKeys(),
        load_profilings: this.loadProfilings ? 1 : null,
      })
        .then(data => {
          this.$notify.success({
            duration: this.$constants.global.notifyDuration,
            title: 'Загружено',
            message: 'ТШД успешно загружены'
          });
          this.loading = false;
          this.show = false;
          this.$emit('tsds-loaded');
        })
    },

  },

  beforeDestroy() {
    if (this.$refs.wrapper.children) {
      off(this.$refs.wrapper.children[0], 'click', this.showModal);
    }
  }

}
</script>

<style scope>

</style>
