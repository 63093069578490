<template>
  <div class="profile">

    <el-row type="flex" :gutter="24">
      <el-col :lg="8" :xl="6" v-loading="loading">
        <h1 class="profile__title" style="margin-bottom: 24px;">Личный кабинет</h1>
        <div class="profile__info">
          <h3 class="profile__info-title">{{ profile.full_name }}</h3>
<!--          <el-avatar-->
<!--              fit="scale-down"-->
<!--              shape="circle"-->
<!--              :size="124"-->
<!--              src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"-->
<!--          >-->
<!--          </el-avatar>-->

<!--          <p class="profile__info-prompt">Размер файла не более 1мб.</p>-->

          <div class="profile__settings">
<!--            <div class="profile__settings-item">-->
<!--              <div class="profile__settings-item__img">-->
<!--                <img src="@/assets/img/icons/profile-key.svg" alt="img">-->
<!--              </div>-->

<!--              <div class="profile__settings-item__inner">-->
<!--                <div class="profile__settings-item__title">Пароль</div>-->
<!--              </div>-->
<!--            </div>-->

            <el-button
                type="primary"
                size="small"
                plain
                icon="el-icon-lock"
                @click="changePassModal.show = true"
            >
              Изменить пароль
            </el-button>

            <div class="profile__settings-item">
              <div class="profile__settings-item__img">
                <img src="@/assets/img/icons/profile-briefcase.svg" alt="img">
              </div>

              <div class="profile__settings-item__inner">
                <div class="profile__settings-item__title">Должность</div>

                <click-to-edit
                  disabled
                    v-model="profile.position"
                    @input="saveProfile"
                >
                </click-to-edit>
              </div>
            </div>

            <div class="profile__settings-item">
              <div class="profile__settings-item__img">
                <img src="@/assets/img/icons/profile-subdivision.svg" alt="img">
              </div>

              <div class="profile__settings-item__inner">
                <div class="profile__settings-item__title">Подразделение</div>

                <click-to-edit
                  disabled
                    v-model="profile.division"
                    @input="saveProfile"
                >
                </click-to-edit>
              </div>
            </div>

            <div class="profile__settings-item">
              <div class="profile__settings-item__img">
                <img src="@/assets/img/icons/profile-mail.svg" alt="img">
              </div>

              <div class="profile__settings-item__inner">
                <div class="profile__settings-item__title">Корпоративная электронная почта</div>

                <click-to-edit
                  disabled
                    v-model="profile.email"
                    @input="saveProfile"
                >
                </click-to-edit>
              </div>
            </div>

            <div class="profile__settings-item">
              <div class="profile__settings-item__img">
                <i class="far fa-user"></i>
              </div>

              <div class="profile__settings-item__inner">
                <div class="profile__settings-item__title">Логин</div>

                <click-to-edit
                  disabled
                    v-model="profile.login"
                    @input="saveProfile"
                >
                </click-to-edit>
              </div>
            </div>

            <div class="profile__settings-item">
              <div class="profile__settings-item__img">
                <i class="fas fa-user-cog"></i>
              </div>

              <div class="profile__settings-item__inner">
                <div class="profile__settings-item__title">Роль</div>
                {{ profile.role ? profile.role.description : '' }}
              </div>
            </div>
          </div>
        </div>
      </el-col>

      <el-col :lg="16" :xl="18">
        <h3 class="profile__subtitle" style="margin-bottom: 24px;">Таблица проектов</h3>
        <el-row :gutter="24" style="margin-bottom: 24px;" type="flex">
          <el-col :span="6">
            <label class="form-label">
              <span class="form-label__title">Статус проекта СПК</span>

              <el-select
                  style="width: 100%"
                  v-model="statusesFilter"
                  :multiple="true"
                  size="mini"
                  clearable
                  placeholder="Выберите из списка"
                  @change="getProjects"
              >
                <el-option-custom with-checkbox :label="$constants.project.statusesNames[1]"
                                  :value="1"></el-option-custom>
                <el-option-custom with-checkbox :label="$constants.project.statusesNames[2]"
                                  :value="2"></el-option-custom>
                <el-option-custom with-checkbox :label="$constants.project.statusesNames[3]"
                                  :value="3"></el-option-custom>
                <el-option-custom with-checkbox :label="$constants.project.statusesNames[4]"
                                  :value="4"></el-option-custom>
              </el-select>
            </label>
          </el-col>

          <el-col :span="20" style="align-self: end; text-align: right">
            <el-button
                icon="el-icon-refresh"
                @click="resetFilter"
            >
              Сбросить
            </el-button>
          </el-col>
        </el-row>


        <el-table
            style="width: 100%"
            :data="projects"
            v-loading="loading"
        >
          <el-table-column prop="name" label="Проект">
            <template slot-scope="scope">
              <span
                  class="el-link"
                  @click="$router.push({ name: 'Project', params: { id: scope.row.id } })"
              >
                {{ scope.row.name }}
              </span>
            </template>
          </el-table-column>

        </el-table>

        <paginator
            :paginator.sync="paginator"
            @page-changed="getProjects(false)"
        ></paginator>
      </el-col>
    </el-row>

    <el-dialog
      title="Смена пароля"
      :close-on-click-modal="false"
      :visible.sync="changePassModal.show"
      width="40%"
      top="2vh"
    >
      <el-row>
        <el-form
            size=""
            ref="passForm"
            :model="changePassModal"
            :rules="changePassModal.rules"
        >
          <el-form-item label="Текущий пароль" prop="currentPassword">
            <el-input type="password" v-model="changePassModal.currentPassword"></el-input>
          </el-form-item>
          <el-form-item label="Новый пароль" prop="newPassword">
            <el-tooltip placement="bottom-start" popper-class="password-info" effect="light">
              <div slot="content">
                <p>
                  Пароль должен содержать не менее 8 <br>
                  символов и включать:
                </p>
                <ul>
                  <li>Строчные латинские буквы: abcd...xyz;</li>
                  <li>Прописные латинские буквы: ABCD...XYZ;</li>
                  <li>Цифры: 123...90;</li>
                  <li>Специальные символы: !%()_+ и т.д.</li>
                </ul>
              </div>
              <svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z" stroke="#767a7a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.1201 11.9998C12.4336 11.1087 13.0523 10.3573 13.8667 9.87863C14.6811 9.39999 15.6386 9.22503 16.5697 9.38473C17.5007 9.54443 18.3452 10.0285 18.9536 10.7512C19.5619 11.4738 19.8949 12.3885 19.8934 13.3331C19.8934 15.9998 15.8935 17.3331 15.8935 17.3331" stroke="#767a7a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16 22.667H16.0129" stroke="#767a7a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </el-tooltip>
            <el-input type="password" v-model="changePassModal.newPassword"></el-input>
          </el-form-item>

        </el-form>
      </el-row>

      <span slot="footer" class="dialog-footer">
          <el-button size="small" @click="changePassModal.show = false">Закрыть</el-button>
          <el-button
            type="primary"
            size="small"
            :loading="changePassModal.loading"
            @click="saveNewPass"
          >
            Изменить
          </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import DivisionSelect from "../../components/selects/DivisionSelect";
import ClickToEdit from "../../components/ClickToEdit";
import Paginator from "@/components/Paginator";
import {sorter} from "@/mixins/sorter";
import {paginator} from "@/mixins/paginator";
import ElOptionCustom from "@/components/custom/ElOptionCustom";

export default {
  name: 'Profile',
  mixins: [paginator],
  components: {ElOptionCustom, Paginator, DivisionSelect, ClickToEdit},
  data() {
    const validateCurrentPass = (rule, value, callback) => {
      this.changePassModal.loading = true;
      this.$api.user.login({
        username: this.profile.login,
        password: value,
      }, {notificationError: false})
        .then(data => {
          callback();
        })
        .catch(()=>{
          callback(new Error('Старый пароль не совпал'));
        })
        .finally(() => {
          this.changePassModal.loading = false;
        })
    };
    const validateNewPass = (rule, value, callback) => {
      let successCount = 0;
      [/^(?=.*\d)/, /^(?=.*[a-z])/, /^(?=.*[A-Z])/, /^(?=.*[$&+,:;=?@#|'<>.^*()%!-])/].forEach(rule => {
        if( value.match(rule) ) {
          successCount++;
          if( successCount >= 3 ){
            callback();
          }
        }
      });
      callback(new Error('Пароль не соответствует политике безопасности'));
    };

    return {
      loading: false,
      profile: {},
      changePassModal: {
        show: false,
        loading: false,
        currentPassword: null,
        newPassword: null,
        rules: {
          currentPassword: [
            {required: true, message: 'Поле обязательно для заполнения', trigger: 'change'},
            {validator: validateCurrentPass, trigger: 'validate' },
          ],
          newPassword: [
            {required: true, message: 'Поле обязательно для заполнения', trigger: 'change'},
            { min: 8, message: 'Длина пароля должна быть не менее 8 символов', trigger: 'change' },
            { validator: validateNewPass, trigger: 'change' }
          ],
        },
      },

      loadingProjects: false,
      projects: [],
      statusesFilter: [],
    }
  },
  computed: {},
  mounted() {
    this.getProfile();
  },
  methods: {

    getProfile() {
      this.loading = true;
      this.$api.user.getProfile({
        id: this.$route.params.id,
      })
          .then(data => {
            this.profile = data.profile;
            this.getProjects();
          })
          .finally(() => {
            this.loading = false;
          })
    },
    saveProfile() {
      this.loading = true;
      this.$api.user.saveProfile(this.profile)
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDuration,
              title: 'Сохранено',
              message: 'Профиль успешно сохранен'
            });
          })
          .finally(() => {
            this.getProfile();
          })
    },
    saveNewPass() {
      this.$refs.passForm.validate((result) => {
        if (!result) {
          return;
        }

        this.changePassModal.loading = true;

        this.$api.user.saveNewPassword({
          password: this.changePassModal.newPassword
        })
            .then(data => {
              this.$notify.success({
                duration: this.$constants.global.notifyDuration,
                title: 'Сохранено',
                message: 'Пароль успешно изменен'
              });
            })
            .finally(() => {
              this.changePassModal.loading = false;
              this.changePassModal.show = false;
            })
      });
    },


    resetFilter() {
      this.statuses = [];
      this.getProjects();
    },

    getProjects(resetPage=true) {
      if( this.profile.role.name === 'pilot' ){
        return;
      }
      if( resetPage ){
        this.paginator.page = 1;
      }
      this.projects = [];
      this.loadingProjects = true;
      this.$api.project.search({
        statuses: this.statusesFilter,
        my: 1,

        [this.paginator.param]: this.paginator.page,
        [this.paginator.paramPerPage]: this.paginator.perPage,
      })
          .then(({data, paginator, sorter}) => {
            this.paginatorFill(paginator);
            this.projects = data.projects;
          })
          .finally(() => {
            this.loading = false;
          })
    },
  }
}

</script>

<style lang="scss">
@import "./src/assets/css/project-variables";

.el-link {
  white-space: normal;
  color: $--color-primary;
  transition: all .4s;
}

.password-info {

  p {
    margin-bottom: 12px;
    font-size: 12px;
  }

  ul {
    padding-left: 18px;
    margin-bottom: 0;
    font-size: 12px;
    list-style: circle;

    li:not(:last-child) {
      margin-bottom: 4px;
    }
  }
}

.profile {

  &__title {
    margin-bottom: 0;
  }

  &__subtitle {
    font-weight: 400;
    margin-bottom: 0;
  }

  &__info {
    display: flex;
    flex-direction: column;
    padding: 32px;
    background: $--color-divider-4;
    border-radius: 6px;

    &-title {
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 24px;
      text-align: center;
    }

    .el-avatar {
      margin: 0 auto 24px;
    }

    &-prompt {
      font-size: 14px;
      color: $--color-text-regular-4;
      margin-bottom: 32px;
      text-align: center;
    }
  }

  &__settings {

    &-item {
      display: flex;
      padding: 0 0 16px;
      margin: 0 0 24px;
      border-bottom: 1px solid $--color-divider;

      &__img {
        width: 48px;
        height: 48px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 16px 0 0;
        background: $--color-white;
        box-shadow: 0px 0px 30px 7px rgba(20, 33, 64, 0.02);
        border-radius: 8px;

        img, i {
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        i {
          color: $--color-primary;
        }
      }

      &__inner {
        flex: 1;
      }

      &__title {
        margin-bottom: 4px;
        font-size: 14px;
        color: $--color-text-regular-4;
      }

      .edit-block {

        .edit-block__value {

          > span {
            width: 280px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          > svg {
            width: 24px;
            height: 24px;
            margin: 0;
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
      }
    }

    .el-button {
      width: 100%;
      margin-bottom: 40px;
      font-weight: 400;
      text-transform: none;
    }
  }

  .el-form {

    &-item {

      &__label {
        margin-bottom: 8px;
      }
    }
  }

  @media only screen and (max-width: 1480px) {

    &__info {
      padding: 16px;

      &-title {
        margin-bottom: 16px;
        font-size: 20px;
      }

      .el-avatar {
        width: 80px !important;
        height: 80px !important;
        margin-bottom: 16px;
        line-height: 80px !important;
      }

      &-prompt {
        margin-bottom: 16px;
        font-size: 12px;
      }
    }

    &__settings {

      &-item {
        padding-bottom: 8px;
        margin-bottom: 16px;

        &__img {
          width: 32px;
          height: 32px;
          margin-right: 8px;

          img {
            width: 16px;
            height: 16px;
          }
        }

        &__inner {
          font-size: 12px;
        }

        &__title {
          margin-bottom: 2px;
          font-size: 12px;
        }

        .edit-block .edit-block__value > span {
          width: 220px;
          font-size: 12px;
        }

        .edit-block .edit-block__value > svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}

</style>
