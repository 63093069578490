<template>
  <div>
    <el-dialog
        :title="title"
        :visible.sync="show"
        :close-on-click-modal="false"
        :width="width"
        top="2vh"
        append-to-body
    >
      <el-row class="competence-mpcs-modal-body" v-loading="loading">
        <el-row>
          <el-col :span="4">Подразделения</el-col>
          <el-col :span="20">
            <division-select
                v-model="divisionId"
            >
            </division-select>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">Год утверждения</el-col>
          <el-col :span="20">
            <el-select
              v-model="projectYear"
              clearable
            >
              <el-option
                v-for="index in 5"
                :key="(new Date().getFullYear())-index+1"
                :value="(new Date().getFullYear())-index+1"
                :label="(new Date().getFullYear())-index+1"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">Компетенции из проекта</el-col>
          <el-col :span="20">
            <project-select
              v-model="projectId"
              :filter-divisions="divisionId"
              :filter-introduction-year="+projectYear"
              @change="getMpcCompetencesByProject"
            ></project-select>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">Компетенции</el-col>
          <el-col :span="20">
            <el-select
                v-model="selectedCompetences"
                :disabled="!projectId"
                multiple
                placeholder="Выберите компетенции"
            >
              <el-option-custom
                  v-for="competence in competences"
                  :key="competence.id"
                  :value="competence.id"
                  :label="competence.name"
                  with-checkbox
              ></el-option-custom>
            </el-select>
          </el-col>
        </el-row>
      </el-row>

      <el-row slot="footer" class="dialog-footer" type="flex" justify="center">
        <el-button type="primary" size="small" @click="copyCompetencesFromAnotherMpc"
                   :disabled="!selectedCompetences.length">Подтвердить
        </el-button>
        <el-button size="small" @click="show = false">Отменить</el-button>
      </el-row>
    </el-dialog>

    <span ref="wrapper">
      <slot name="reference"></slot>
    </span>
  </div>
</template>

<script>
import {off, on} from "element-ui/src/utils/dom";
import DivisionSelect from "../selects/DivisionSelect";
import ProjectSelect from "@/components/selects/ProjectSelect";
import ElOptionCustom from "@/components/custom/ElOptionCustom";

export default {
  name: "copy-mpcs-modal",
  components: {ElOptionCustom, ProjectSelect, DivisionSelect},
  props: {
    params: Object,
    mpc: {},
    title: {
      type: String,
      default: 'Копировать из другой МПК'
    },
    width: {
      type: String,
      default: '70%'
    }
  },
  data() {
    return {
      show: false,
      divisionId: null,
      projectYear: null,
      projectId: null,
      competences: [],
      selectedCompetences: [],
      loading: false,
    }
  },
  computed: {},
  mounted() {
    if (this.$refs.wrapper.children) {
      on(this.$refs.wrapper.children[0], 'click', this.showModal);
    }
  },
  methods: {
    showModal() {
      this.show = true;
      this.divisionId = null;
      this.projectYear = null;
      this.projectId = null;
      this.selectedCompetences = [];
    },

    getMpcCompetencesByProject() {
      if( !this.projectId ) {
        return;
      }

      this.selectedCompetences = [];
      this.loading = true;
      this.$api.mpc.getProjectMpc({
        project_id: this.projectId,
      })
          .then(data => {
            this.competences =  data.mpc.competences;

            data.mpc.submodels.forEach( submodel => {
              submodel.competences.forEach(competence => {
                competence.name = submodel.name + ". " + competence.name;
                this.competences.push(competence)
              })
            })
          })
          .finally(() => {
            this.loading = false;
          })
    },

    copyCompetencesFromAnotherMpc() {
      this.loading = true;
      this.$api.mpc.copyCompetencesFromAnotherMpc({
        mpc_id: this.mpc.id,
        competence_ids: this.selectedCompetences,
      })
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDuration,
              title: 'Сохранено',
              message: 'Компетенция(и) и индикаторы добавлены'
            });
            this.$emit('new-competencies-added');
          })
          .finally(() => {
            this.loading = false;
            this.show = false;
            this.$store.dispatch('updateMenu');
          })
    },
  },
  beforeDestroy() {
    if (this.$refs.wrapper.children) {
      off(this.$refs.wrapper.children[0], 'click', this.showModal);
    }
  }
}
</script>

<style scoped lang="scss">
.competence-mpcs-modal-body {
  & .el-row {
    margin-bottom: 20px;
  }
}
</style>