<template>
  <div>
    <el-row type="flex" class="row-bg" justify="space-between" :style="toolRowStyle" style="margin-bottom: 12px;">
      <el-col :span="12">
        <el-input
            v-if="searchField"
            v-model="search"
            prefix-icon="el-icon-search"
            placeholder="Поиск"
            size="mini"
            @clear="searchUsers"
            clearable
            v-debounce:500="searchUsers"
        >
        </el-input>
      </el-col>
      <el-col :span="12" align="right">
        <add-users-dialog
            v-if="addButton"
            :filter-role="addUsersFilterRole"
            :filter-projects="addUsersFilterProjects"
            @users-selected="addUsers"
        >
          <el-button
              slot="reference"
              size="mini"
              type="text"
              icon="fas fa-plus"
          >
            {{ addButtonText }}
          </el-button>
        </add-users-dialog>
      </el-col>
    </el-row>

    <el-table
        v-loading="loading"
        size="mini"
        empty-text="Нет пользователей"
        :data="users"
    >
      <el-table-column prop="full_name" label="ФИО"></el-table-column>
      <el-table-column prop="division" label="Подразделение"></el-table-column>
      <el-table-column prop="position" label="Должность"></el-table-column>
      <el-table-column prop="role.description" label="Роль"></el-table-column>
      <el-table-column v-if="negotiationRow" label="Согласование">
        <template slot-scope="scope">
          <el-radio
            v-if="!negotiationRowMultiple"
            :value="negotiationMainUser"
            :label="scope.row.id"
            :disabled="negotiationRowDisabled"
            @change="$emit('main-changed', scope.row )"
          >&nbsp;
          </el-radio>
          <el-checkbox
            v-else
            :value="!!scope.row.is_main"
            :label="scope.row.id"
            :disabled="negotiationRowDisabled"
            @change="$emit('main-changed', scope.row, $event )"
          >&nbsp;
          </el-checkbox>
        </template>
      </el-table-column>
      <el-table-column align="right" width="40">
        <template slot-scope="scope">
          <ellipsis-menu>
            <el-popconfirm
                v-if="deleteButton"
                confirm-button-text='Да'
                cancel-button-text='нет'
                icon="el-icon-question"
                :title="deleteUserText"
                @confirm="deleteUser(scope.row)"
            >
              <el-button
                  slot="reference"
                  type="text"
                  size="small"
                  icon="el-icon-delete"
                  :loading="loading"
              >
                Удалить пользователя
              </el-button>
            </el-popconfirm>
          </ellipsis-menu>
        </template>
      </el-table-column>
    </el-table>

<!--    <paginator-->
<!--        :paginator.sync="paginator"-->
<!--        @page-changed="$emit('page-changed', $event)"-->
<!--    ></paginator>-->

  </div>
</template>

<script>
import AddUsersDialog from "./AddUsersDialog";
import Paginator from "../Paginator";
import EllipsisMenu from "../EllipsisMenu";


export default {
  name: "users-table",
  components: {EllipsisMenu, Paginator, AddUsersDialog},

  props: {
    searchField: {
      type: Boolean,
      default: false
    },
    users: {
      type: Array,
      default: function () {
        return []
      }
    },
    loading: {
      type: Boolean,
      default: false
    },

    addUsersFilterRole: {},
    addUsersFilterProjects: {},

    addButton: {
      type: Boolean,
      default: true
    },
    addButtonText: {
      type: String,
      default: 'Добавить пользователей'
    },

    deleteButton: {
      type: Boolean,
      default: true
    },
    deleteUserText: {
      type: String,
      default: 'Вы уверены, что хотите удалить пользователя?'
    },
    toolRowStyle: {},

    negotiationRow: {
      type: Boolean,
      default: false
    },
    negotiationRowMultiple: {
      type: Boolean,
      default: false
    },
    negotiationRowDisabled: {
      type: Boolean,
      default: false
    },
    negotiationMainUser: {
      type: Number
    },
  },

  data() {
    return {
      search: null,
      paginator: {page: 1},
    }
  },
  mounted() {

  },
  watch: {},
  methods: {
    addUsers(users) {
      let newUsers = [];
      users.forEach(user => {
        if (this.users.find(val => val.id == user.id)) {
          return;
        }
        this.users.push(user);
        newUsers.push(user);
      })
      this.$emit("new-users", newUsers);
    },
    deleteUser(user) {
      this.$emit('update:users', this.users.filter(val => val.id != user.id))
      this.$emit('user-deleted', user)
    },

    searchUsers() {
      this.$emit("search", this.search);
    }
  }
}
</script>

<style>

</style>
