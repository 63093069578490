<template>
  <span>
    <el-dialog
      title="Журнал удаленных проектов"
      :visible.sync="show"
      :close-on-click-modal="false"
      width="95%"
      top="2vh"
      append-to-body
    >
      <el-row>

        <el-table
          v-loading="loading"
          style="width: 100%"
          :data="projects"
        >
        <el-table-column
          prop="name"
          label="Проект"
        >
          <template slot-scope="scope">
            {{ scope.row.name }}
          </template>
        </el-table-column>

        <el-table-column
          prop="division.name"
          label="Подразделение"
          show-overflow-tooltip
        >
        </el-table-column>

        <el-table-column
          width="200"
          prop="date_start"
          label="Дата начала"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.date_start ? new Date(scope.row.date_start * 1000).toLocaleDateString() : '' }}
          </template>
        </el-table-column>

        <el-table-column
          width="200"
          prop="date_end"
          label="Дата завершения"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.date_end ? new Date(scope.row.date_end * 1000).toLocaleDateString() : '' }}
          </template>
        </el-table-column>

        <el-table-column
          width="200"
          prop="type"
          label="Тип работ"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.type ? $constants.project.typesNames[scope.row.type] : '' }}
          </template>
        </el-table-column>

        <el-table-column
          width="200"
          prop="status"
          label="Статус СПК"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.status ? $constants.project.statusesNames[scope.row.status] : '' }}
          </template>
        </el-table-column>

        <el-table-column
          label="Методолог"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.admins ? scope.row.admins.map(a => a.full_name).join() : '' }}
          </template>
        </el-table-column>
        <el-table-column width="40">
          <template slot-scope="scope">
            <ellipsis-menu
              v-if="$can('project-delete', scope.row.permissions)"
            >
              <el-button
                type="text"
                size="small"
                @click="restoreProject(scope.row)"
              >
                Восстановить проект
              </el-button>
            </ellipsis-menu>
          </template>
        </el-table-column>
      </el-table>

        <paginator
          :paginator.sync="paginator"
          @page-changed="showDeleted"
        ></paginator>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="show = false">Закрыть</el-button>
      </span>
    </el-dialog>

    <span ref="wrapper">
      <slot name="reference"></slot>
    </span>
  </span>
</template>

<script>

import {off, on} from "element-ui/src/utils/dom";
import Paginator from "./../Paginator";
import EllipsisMenu from "../EllipsisMenu";
import {paginator} from "@/mixins/paginator";

export default {
  name: "deleted-projects-modal",
  mixins: [paginator],
  components: {EllipsisMenu, Paginator},
  props: {},

  data() {
    return {
      show: false,
      loading: false,
      projects: [],
    }
  },
  mounted() {
    if (this.$refs.wrapper.children) {
      on(this.$refs.wrapper.children[0], 'click', this.showDeleted);
    }
  },
  watch: {},
  methods: {
    showDeleted() {
      this.loading = true;
      this.projects = [];
      this.show = true;
      this.$api.project.getDeleted({
        expand: 'permissions',
        [this.paginator.param]: this.paginator.page,
        [this.paginator.paramPerPage]: this.paginator.perPage,
      })
        .then(({data, paginator}) => {
          this.projects = data.projects;
          this.paginatorFill(paginator);
        })
        .finally(() => {
          this.loading = false;
        })
    },
    restoreProject(project) {
      this.loading = true;
      this.$api.project.restore({
        id: project.id,
      })
        .then(data => {
          this.$notify.success({
            duration: this.$constants.global.notifyDuration,
            title: 'Восстановлено',
            message: 'Проект успешно восстановлен'
          });
          this.show = false;
          this.$emit('project-restored');
        })
    }
  },

  beforeDestroy() {
    if (this.$refs.wrapper.children) {
      off(this.$refs.wrapper.children[0], 'click', this.showDeleted);
    }
  }

}
</script>