<template>
  <el-select
      :value="value"
      :size="size"
      :multiple="multiple"
      filterable
      clearable
      reserve-keyword
      :placeholder="placeholder"
      :loading="loading"
      @change="valueChanged"
  >
    <el-option-custom
        v-for="item in options"
        v-if="!hideRoles.includes(item.name)"
        :key="item.name"
        :label="item.description"
        :value="item.name"
        :with-checkbox="multiple"
    >
    </el-option-custom>
  </el-select>
</template>

<script>
import ElOptionCustom from "@/components/custom/ElOptionCustom";
export default {
  name: "role-select",
  components: {ElOptionCustom},
  props: {
    value: {},
    size: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Роль в системе'
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    hideRoles: {
      type: Array,
      default: ()=>[],
    },
  },

  data() {
    return {
      loading: false,
      localValue: this.value,
      options: [],
    }
  },
  mounted() {
    this.loadRoles()
  },
  // watch: {
  //     localValue: function(newVal, oldVal) {
  //         this.valueChanged(newVal)
  //     },
  //     defaultUsers: function(newVal, oldVal) {
  //         this.setDefaultOptions(newVal);
  //         this.remoteMethod()
  //     },
  // },
  methods: {
    loadRoles(query) {
      this.loading = true;
      this.$api.rbac.getRoles()
          .then(data => {
            this.options = data.roles;
          })
          .finally(() => {
            this.loading = false;
          })
    },
    valueChanged(value) {
      this.$emit('input', value);
      this.$emit('change', value);
      // this.$emit('selected', value)
    },
  },

}
</script>

<style scoped>

</style>