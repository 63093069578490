<template>
  <div class="library">
    <el-tree
        v-loading="loadingRootFolder"
        empty-text="Нет папок"
        :load="loadTreeNode"
        lazy
        highlight-current
        ref="foldersTree"
        icon-class="el-icon-arrow-right"
        node-key="id"
        :expand-on-click-node="true"
        @node-click="folderClicked"
    >
        <span class="folders-tree-node" slot-scope="{ node, data }">
          <span class="icon-folder">
              <i v-if="!node.expanded" class="fas fa-folder" aria-hidden="true"></i>
              <i v-if="node.expanded" class="el-icon-folder" aria-hidden="true"></i>
              <span class="icon-folder_text" style="padding-left:5px">
                <el-tooltip placement="bottom-start" :offset="100">
                  <div slot="content" style="width: auto;">{{ data.name }}</div>
                  <span style="max-width: 60%;">{{ data.name }}</span>
                </el-tooltip>
              </span>
          </span>

          <span class="manageFolderButtonsSpan">
            <el-button
                v-if="$can('library-manage-folders')"
                style="background-color: unset; padding:0"
                class="table-action-button"
                type="text"
                @click="editFolderModalShow(node)"
                icon="fas fa-pen"
            >
            </el-button>
            <el-tooltip
                v-if="$can('library-manage-folders')"
                content="Добавить подпапку"
                placement="top"
                :open-delay="500"
            >
                <el-button
                    style="margin-left: 0px;background-color: unset"
                    class="table-action-button"
                    type="text"
                    @click="editFolderModalShow(node, true)"
                    icon="fas fa-plus-circle"
                >
                </el-button>
            </el-tooltip>
          </span>
        </span>
    </el-tree>

    <el-button
        v-if="$can('library-manage-folders')"
        type="text"
        size="mini"
        @click="editFolderModalShow(null,true)"
        icon="el-icon-circle-plus"
    >
      Добавить новую папку
    </el-button>

    <el-dialog
        v-if="$can('library-manage-folders')"
        :title="editFolderModal.folder.id ? 'Редактирование папки' : 'Создание папки'"
        :visible.sync="editFolderModal.show"
        width="50%"
    >
      <el-row>
        <el-form
            size="small"
            label-position="top"
        >
          <el-form-item label="Название папки">
            <el-input maxlength="255" show-word-limit v-model="editFolderModal.folder.name"></el-input>
          </el-form-item>
        </el-form>
      </el-row>

      <span slot="footer" class="dialog-footer">
          <el-popconfirm
              style="float:left"
              v-show="editFolderModal.folder.id"
              confirm-button-text='Да'
              cancel-button-text='нет'
              icon="el-icon-question"
              title="Вы уверены, что хотите удалить папку?"
              @confirm="deleteFolder"
          >
              <el-button
                  slot="reference"
                  type="danger"
                  size="small"
                  plain
                  icon="fas fa-trash"
                  :loading="editFolderModal.loading"
              >
                  Удалить папку
              </el-button>
          </el-popconfirm>
          <el-button size="small" @click="editFolderModal.show = false">Отмена</el-button>
          <el-button
              type="primary"
              size="small"
              :loading="editFolderModal.loading"
              @click="saveFolder"
              :disabled="!editFolderModal.folder.name"
          > Сохранить
          </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import UsersTable from "../user/UsersTable";

export default {
  name: "library-folders",
  mixins: [],
  components: {UsersTable},

  props: {},

  data() {
    return {

      loadingRootFolder: false,

      editFolderModal: {
        show: false,
        loading: false,
        folder: {
          id: null,
          name: null,
          parent_id: null
        },
        node: null,
      }
    }
  },
  mounted() {

  },
  methods: {
    loadTreeNode(node, resolve) {
      this.loadingRootFolder = !node.data;
      this.$api.library.getFoldersByParent({
        parent_id: node.data ? node.data.id : null,
      })
          .then(data => {
            this.loadingRootFolder = false;
            return resolve(data.folders)
          })
    },
    folderClicked(node, nodeOfTree, treeNode) {
      this.$emit('folder-selected', node);
    },

    editFolderModalShow(node, isNew = false) {
      if (!node) {
        node = this.$refs.foldersTree.root;
      }
      this.editFolderModal.node = node;
      this.editFolderModal.show = true;
      if (isNew) {
        this.editFolderModal.folder.id = null;
        this.editFolderModal.folder.name = null;
        this.editFolderModal.folder.parent_id = node.data ? node.data.id : null;
      } else {
        this.editFolderModal.folder = Object.assign({}, node.data);
      }
    },

    deleteFolder() {
      if (!this.editFolderModal.folder.id) {
        return;
      }
      this.editFolderModal.loading = true;
      this.$api.library.deleteFolder({
        id: this.editFolderModal.folder.id,
      })
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDuration,
              title: 'Сохранено',
              message: 'Папка успешно удалена'
            });
          })
          .finally(() => {
            this.editFolderModal.show = false;
            this.editFolderModal.loading = false;
            this.editFolderModal.node.parent.loaded = false;
            this.editFolderModal.node.parent.expand();
          })
    },
    saveFolder() {
      this.editFolderModal.loading = true;
      this.$api.library.saveFolder({
        id: this.editFolderModal.folder.id,
        parent_id: this.editFolderModal.folder.parent_id,
        name: this.editFolderModal.folder.name,
      })
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDuration,
              title: 'Сохранено',
              message: 'Папка успешно сохранена'
            });
          })
          .finally(() => {
            this.editFolderModal.loading = false;
            this.editFolderModal.show = false;
            if (this.editFolderModal.folder.id) { // Значит, что это не новая папка, нужно обновлять родителя
              this.editFolderModal.node.parent.loaded = false;
              this.editFolderModal.node.parent.expand();
            } else { // Новая папка внутри папки. Значит нужно обновить саму ноду
              this.editFolderModal.node.loaded = false;
              this.editFolderModal.node.expand();
            }
          })
    },
  }
}
</script>

<style lang="scss">
@import "@/assets/css/project-variables.scss";

.library {
  padding: 24px 0 24px 0;
  border-top: 1px solid $--color-divider-2;
  border-right: 1px solid $--color-divider-2;
  border-bottom: 1px solid $--color-divider-2;
  border-radius: 0 6px 6px 0;

  .el-tree {
    max-height: 60vh;
    margin: 0 0 16px;
    padding-right: 24px;
    overflow: auto;

    .el-tree-node {
      margin: 0 0 16px;

      &__content {
        height: auto;
      }

      &__expand-icon {
        margin-right: 8px;
        font-weight: 700;
      }

      &__children {

        .icon-folder_text {
          max-width: 170px;

          @media only screen and (max-width: 1360px) {
            max-width: 140px;
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .folders-tree-node {
      position: relative;

      .icon-folder {
        flex: 1;
        display: flex;
        align-items: center;

        i {
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $--color-primary;
        }

        .icon-folder_text {
          width: 100%;
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;

          .el-tooltip {
            display: block;
            max-width: 220px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .manageFolderButtonsSpan {
        position: absolute;
        top: 50%;
        right: 0;
        flex-shrink: 0;
        transform: translateY(-50%);

        .el-button {
          width: 18px;
          height: 18px;
          margin-right: 8px;

          i {
            margin: 0;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1480px) {
    padding: 16px 0 16px 0;

    .el-tree {

      .el-tree-node {
        margin-bottom: 8px;

        &__expand-icon {
          margin-right: 0;
        }
      }

      .folders-tree-node {

        .icon-folder {

          i {
            width: 16px;
            height: 16px;
            font-size: 16px;
          }

          .icon-folder_text {
            font-size: 12px;

            .el-tooltip {
              max-width: 180px;
            }
          }
        }
      }
    }
  }
}

.folders-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /*font-size: 14px;*/
  /*padding-right: 8px;*/
}

.folders-tree-node .manageFolderButtonsSpan {
  display: none;
}

.folders-tree-node:hover .manageFolderButtonsSpan {
  display: block;
}
</style>
