<template>
  <el-select
      v-model="localValue"
      :size="size"
      :multiple="multiple"
      filterable
      remote
      clearable
      reserve-keyword
      placeholder="Выберите из списка"
      :remote-method="remoteMethod"

      @visible-change="$event ? remoteMethod() : null"
  >
    <div
      v-loading="loading"
      v-infinite-scroll="nextPage"
      :infinite-scroll-immediate="false"
      :infinite-scroll-delay="100"
    >
      <el-option-custom
          v-if=" savedName && !options.find(o => o.id==value)"
          :key="value"
          :label="savedName"
          :value="value"
          :with-checkbox="multiple"
      >
      </el-option-custom>
      <el-option-custom
          v-for="item in options"
          :key="item.id"
          :label="item.name"
          :value="item.id"
          :with-checkbox="multiple"
      >
      </el-option-custom>
    </div>
  </el-select>

</template>

<script>


import ElOptionCustom from "@/components/custom/ElOptionCustom";
export default {
  name: "division-select",
  components: {ElOptionCustom},
  props: {
    value: {},
    savedName: {},
    size: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
    }
  },

  data() {
    return {
      loading: false,
      query: null,
      page: 1,
      options: [],
    }
  },
  mounted() {
    this.remoteMethod()
  },
  watch: {},
  computed: {
    localValue: {
      get: function () {
        return this.value
      },
      set: function (newValue) {
        this.selected(newValue)
      }
    }
  },
  methods: {
    remoteMethod(query) {
      this.query = query;
      this.page = 1;
      this.options = [];
      this.getData();
    },

    nextPage(){
      this.page++;
      this.getData();
    },

    getData() {
      this.loading = true;
      this.$api.division.search({
        search: this.query,
        page: this.page
      })
          .then(({data}) => {
            this.options = this.options.concat(data.divisions);
          })
          .finally(() => {
            this.loading = false;
          })
    },

    selected(value) {
      this.$emit('input', value);
      this.$emit('selected', value)
    }
  },

}
</script>

<style scoped>

</style>