<template>
    <el-select
        style="width:100%"
        :value="value"
        :size="size"
        :multiple="multiple"
        :disabled="disabled"
        filterable
        remote
        clearable
        reserve-keyword
        placeholder="Полигон жд"
        :remote-method="remoteMethod"
        :loading="loading"
        @change="selected"
        @clear="remoteMethod"
        @visible-change="$event ? remoteMethod() : null"
    >
        <el-option-custom
            v-for="(item,key) in options"
            :key="key"
            :label="item"
            :value="item"
            :with-checkbox="multiple"
        >
        </el-option-custom>
    </el-select>

</template>

<script>


import ElOptionCustom from "@/components/custom/ElOptionCustom";
export default {
    name: "polygon-zhd-select",
  components: {ElOptionCustom},
  props: {
        value: {},
        size:{
            type: String,
            default: ''
        },
        disabled:{
            type: Boolean,
            default: false
        },
        multiple:{
            type: Boolean,
            default: false
        },
    },

    data() {
        return {
            loading: false,
            options: [],
        }
    },
    mounted() {
      this.remoteMethod()
    },
    watch: {},
    computed: {
    },
    methods: {
        remoteMethod(query){
            this.loading = true;
            this.$api.user.getPolygonsZhd()
                .then((data) => {
                  this.options = data.polygons;
                })
                .finally(()=>{
                    this.loading = false;
                })
        },
        selected(value){
            this.$emit('input', value);
            this.$emit('change', value);
        },

    },

}
</script>

<style scoped>

</style>