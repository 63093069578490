<template>
  <div>
    <div class="page__head">
      <el-row type="flex" justify="space-between" align="middle">
        <div class="page__head-left">
          <div class="title-edit">
            <h1 class="page__head-title">Разработка оценочных инструментов</h1>

            <click-to-edit
              v-model="testsDevelopment.name"
              :disabled="!$can('test-development-set-test-user')"
              @input="saveName"
            ></click-to-edit>
          </div>
        </div>
      </el-row>
    </div>

    <div class="settings">
      <div class="settings__right">
        <el-button @click="$router.push({ name: 'ReportMonitoring', query: {project_id:  $route.params.id }})">
          Статистика по пилотной оценке СПК
        </el-button>
      </div>
    </div>

    <el-card class="test-development">
      <el-table
        v-loading="loading || loadingScorm"
        size="mini"
        empty-text="Нет тестов в разработке"
        :data="testsDevelopment.tests"
      >
        <el-table-column
          type="expand"
        >
          <template slot-scope="scope">
            <test-sections
              :testId="scope.row.id"
              :sections="scope.row.sections"
            ></test-sections>
          </template>
        </el-table-column>
        <el-table-column
          label="№ п/п"
          width="70"
        >
          <template slot-scope="scope">
            {{ scope.row.order }}
          </template>

        </el-table-column>

        <el-table-column
          prop="test.name"
          label="Название теста"
        >
          <template slot-scope="scope">

            <el-link
              type="primary"
              target="_blank"
              :href="'/test/'+scope.row.id +'/edit'"
            >
              {{ scope.row.name }}
            </el-link>

            <!--              <span-->
            <!--                  v-if="!scope.row.result"-->
            <!--                  class="el-link"-->
            <!--                  @click="$router.push({ name: 'TestEditor', params: { id: scope.row.id } })"-->
            <!--              >-->
            <!--                {{ scope.row.name }}-->
            <!--              </span>-->

            <!--            <span-->
            <!--                v-else-->
            <!--            >-->
            <!--              {{ scope.row.name }}-->
            <!--            </span>-->

          </template>
        </el-table-column>

        <el-table-column
          label="Количество вопросов (норма от 15 на индикатор)"
          width="150"
        >
          <template slot-scope="scope">
            {{ scope.row.computed.countTestQuestions }}
          </template>

        </el-table-column>
        <el-table-column
          label="Количество согласованных вопросов"
          width="150"
        >
          <template slot-scope="scope">
            <span
              :class="agreedTestClass(scope.row)"
            >
              {{ scope.row.computed.countAgreedTestQuestions }}
            </span>
          </template>

        </el-table-column>
        <el-table-column
          label="Статус"
          width="250"
        >
          <template slot-scope="scope">
            {{ $constants.test.testStatusesNames[scope.row.status] }}
          </template>

        </el-table-column>
        <el-table-column width="40" align="right">
          <template slot-scope="scope">
            <ellipsis-menu>
              <test-shared-users-dialog
                :test-id="scope.row.id"
              >
                <el-button
                  slot="reference"
                  type="text"
                  size="small"
                  icon="fas fa-users"
                  :loading="loading"
                >
                  Ответственные
                </el-button>
              </test-shared-users-dialog>

              <el-button
                v-if="$can('test-development-download-scorm', scope.row)"
                type="text"
                size="small"
                icon="fas fa-download"
                style="margin-left: 0;"
                :loading="loadingScorm"
                @click="downloadScorm(scope.row)"
              >
                Выгрузить SCORM
              </el-button>

              <el-button
                v-if="$can('test-development-preview', scope.row)"
                type="text"
                size="small"
                icon="fas fa-eye"
                style="margin-left: 0;"
                @click="goToPreview(scope.row)"
              >
                Превью
              </el-button>

            </ellipsis-menu>
          </template>
        </el-table-column>
      </el-table>
    </el-card>


  </div>
</template>

<script>

import ClickToEdit from "@/components/ClickToEdit";
import EllipsisMenu from "@/components/EllipsisMenu";
import TestSharedUsersDialog from "@/components/test/TestSharedUsersDialog";
import TestSections from '@/components/test/TestSections'
import {downloader} from "@/mixins/downloader";

export default {
  name: 'TestsDevelopment',
  components: {TestSharedUsersDialog, EllipsisMenu, ClickToEdit, TestSections},
  mixins: [downloader],

  data() {
    return {
      loading: false,
      loadingScorm: false,
      testsDevelopment: {
        id: null,
        name: null,
        in_project_name: null,
        tests: [],
      },

    }
  },
  computed: {},

  mounted() {
    this.getTestsDevelopment();
  },
  methods: {
    getTestsDevelopment() {
      this.loading = true;
      this.$api.test.getTestsDevelopment({
        project_id: this.$route.params.id,
      })
        .then(data => {
          this.testsDevelopment = data.tests_development

        })
        .finally(() => {
          this.loading = false;
        })
    },

    saveName() {
      this.loading = true;
      this.$api.test.saveTestsDevelopmentName({
        id: this.testsDevelopment.id,
        name: this.testsDevelopment.name,
      })
        .then(data => {
          this.$notify.success({
            duration: this.$constants.global.notifyDuration,
            title: 'Сохранено',
            message: 'Имя успешно сохранено'
          });
        })
        .finally(() => {
          this.loading = false;
        })
    },

    downloadScorm(test) {
      this.loadingScorm = true;
      this.downloadFile(
        'scorm.zip',
        this.$api.test.downloadScorm,
        {test_id: test.id},
        () => {
          this.loadingScorm = false;
        }
      )
        .finally(() => {
          this.loadingScorm = false;
        })
    },
    agreedTestClass(test) {
      return (test.computed.countTestQuestions === test.computed.countAgreedTestQuestions && test.computed.countTestQuestions !== 0)
        ? 'all-agreed'
        : 'not-agreed';
    },


    goToPreview(row) {
      const routeData = this.$router.resolve({name: 'TestPreview', params: {id: row.id}})
      window.open(routeData.href, '_blank');
    },
  }
}

</script>

<style>

.questions-timeline {
  padding-left: 10px;
}

.questions-timeline .el-timeline-item {
  padding-bottom: 1px;
}

.questions-timeline .el-timeline-item__timestamp {
  margin-top: 0;
}


.select-question-button {
  padding: 0;
}

.answer-radio {
  display: block;
}

.el-divider.el-divider--horizontal {
  margin: 10px 0;
}

.player-section-collapse .el-collapse-item__header {
  line-height: unset;
}


</style>

<style lang="scss">
.test-development {
  .all-agreed {
    color: green;
  }

  .not-agreed {
    color: red;
  }

  .el-link {
    color: #3D8BF4;
  }

}


</style>
