<template>
  <div>
    <el-dialog
      title="Передача в ЕК АСУТР"
      :visible.sync="show"
      :close-on-click-modal="false"
      append-to-body
    >
      <el-row class="" v-loading="loading">

        <div style="margin-bottom: 20px">
          ! При передаче в ЕК АСУТР МПК/профилей установите будущую дату начала их действия, прибавив 30 дней к текущей дате.
          Внесение изменений и повторная передача МПК/профилей возможны только, если до установленной ранее даты имеется более 10 дней.
        </div>
        <el-form>
          <el-form-item label="Дата начала действия модели и профилей">
            <el-date-picker
              :picker-options="{firstDayOfWeek: 1}"
              style="width: 100%"
              v-model="date"
              align="left"
              placeholder="Выберите дату"
              size="mini"
              type="date"
              format="dd.MM.yyyy"
              value-format="dd.MM.yyyy"
              :editable="false"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
      </el-row>

      <el-row slot="footer" class="dialog-footer" type="flex" justify="center">
        <el-button
          :disabled="!date"
          type="primary"
          size="small"
          @click="doUpload"
        >
          Выгрузить в ЕК АСУТР
        </el-button>
        <el-button size="small" @click="show = false">Отменить</el-button>
      </el-row>
    </el-dialog>

    <span ref="wrapper">
      <slot name="reference"></slot>
    </span>
  </div>
</template>

<script>
import {off, on} from "element-ui/src/utils/dom";

export default {
  name: "upload-to-asutr-modal",
  components: {},
  props: {
    project: {type: Object, required: true},
  },
  data() {
    return {
      show: false,
      loading: false,

      date: null,
    }
  },
  computed: {},
  mounted() {
    if (this.$refs.wrapper.children) {
      on(this.$refs.wrapper.children[0], 'click', this.showModal);
    }
  },
  methods: {
    showModal() {
      this.show = true;
    },

    doUpload() {
      this.loading = true;
      this.$api.introduction.uploadToAsutr({
        project_id: this.project.id,
        date: this.date
      })
        .then(data => {
          this.$notify.success({
            duration: this.$constants.global.notifyDuration,
            title: 'Сохранено',
            message: 'Выгрузка прошла успешно'
          });
        })
        .finally(() => {
          this.loading = false;
        })
    },

  },
  beforeDestroy() {
    if (this.$refs.wrapper.children) {
      off(this.$refs.wrapper.children[0], 'click', this.showModal);
    }
  }
}
</script>

<style scoped lang="scss">

</style>