<template>
  <div class="agree-section">
    <el-tooltip
        effect="light"
        placement="top-start"
    >
      <div slot="content">
        <div>Эксперт: {{ agreedExpert ? 'Завершено' : 'Не завершено' }}</div>
        <div>Эксперт-согласующий: {{ agreedExpertMain ? 'Согласовано' : 'Не согласовано' }}</div>
        <div>Методолог: {{ agreedAdmin ? 'Согласовано' : 'Не согласовано' }}</div>
      </div>
      <div v-show="canShowItems">
        <span class="item" :class="{ agreed: agreedExpert }"></span>
        <span class="item" :class="{ agreed: agreedExpertMain }"></span>
        <span class="item" :class="{ agreed: agreedAdmin }"></span>
      </div>
    </el-tooltip>


    <div
        v-if="canFinish || canAgreeExpertMain || canAgreeAdmin"
    >
      <el-tooltip effect="light" placement="bottom-start">
        <div slot="content">
          {{ canFinish ? 'Отправить на согласование' : 'Согласовать' }}
        </div>
        <el-button
            class="agree-section__btn-check"
            size="mini"
            icon="fas fa-check"
            @click="openAgreePrompt(true)"
        >
        </el-button>
      </el-tooltip>

      <el-tooltip
          v-if="canAgreeExpertMain || canAgreeAdmin"
          effect="light"
          placement="bottom-start"
      >
        <div slot="content">
          Отклонить
        </div>
        <el-button
            class="agree-section__btn-times"
            size="mini"
            icon="fas fa-times"
            @click="openAgreePrompt(false)"
        >
        </el-button>
      </el-tooltip>
    </div>
    <el-tooltip
        v-if="canResetAgree"
        effect="light"
        placement="bottom-start"
    >
      <div slot="content">
        Отправить на доработку
      </div>
      <el-button
          class="agree-section__btn-undo"
          size="mini"
          icon="fas fa-undo-alt"
          @click="openAgreePrompt(false, true)"
      >
      </el-button>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  name: "agree-section",
  components: {},

  props: {
    hideItemsOnAction: {type: Boolean, default: false},

    checkRequiredFields: {type: Boolean, default: false},
    requiredFieldsCheckResult: {type: Boolean, default: false},
    requiredFieldsCheckResultMessage: {type: String},

    agreedExpert: {type: Boolean},
    agreedExpertMain: {type: Boolean},
    agreedAdmin: {type: Boolean},

    canFinish: {type: Boolean},
    canAgreeExpertMain: {type: Boolean},
    canAgreeAdmin: {type: Boolean},
    canResetAgree: {type: Boolean},

    textFinish: {type: String},
    textAgree: {type: String},
    textReject: {type: String},
    textReset: {type: String},

    titleFinish: {type: String},
    titleAgree: {type: String},
    titleReject: {type: String},
    titleReset: {type: String},

    method: {type: Function},
    methodParams: {type: Object},
  },

  data() {
    return {}
  },
  mounted() {

  },
  watch: {},
  computed: {
    canShowItems() {
      if (!this.hideItemsOnAction) {
        return true;
      }
      if (this.canFinish || this.canAgreeExpertMain || this.canAgreeAdmin || this.canResetAgree) {
        return false;
      }

      return true;
    },
  },
  methods: {
    openAgreePrompt(success, reset=false) {

      if( this.checkRequiredFields && !this.requiredFieldsCheckResult ){
        this.$alert(this.requiredFieldsCheckResultMessage, 'Ошибка');
        return;
      }

      const STAGE_EXPERT = 1;
      const STAGE_EXPERT_MAIN = 2;
      const STAGE_ADMIN = 3;

      let stage;
      if (this.canFinish) {
        stage = STAGE_EXPERT;
      } else if (this.canAgreeExpertMain) {
        stage = STAGE_EXPERT_MAIN;
      } else if (this.canAgreeAdmin) {
        stage = STAGE_ADMIN;
      } else if (this.canResetAgree) {
        stage = STAGE_ADMIN;
      }
      if (!stage) {
        return;
      }

      let title;
      let text;
      if (!success) {
        title = this.canResetAgree ? this.titleReset : this.titleReject;
        text = this.canResetAgree ? this.textReset : this.textReject;
      } else if (this.canFinish) {
        title = this.titleFinish;
        text = this.textFinish;
      } else if (this.canAgreeExpertMain) {
        title = this.titleAgree;
        text = this.textAgree;
      } else if (this.canAgreeAdmin) {
        title = this.titleAgree;
        text = this.textAgree;
      }
      if (!title || !text) {
        return;
      }

      let buttonText = (success
          ? (stage === STAGE_EXPERT
              ? 'Отправить на согласование'
              : 'Согласовать')
          : (stage === STAGE_EXPERT
                  ? ''
                  : (stage === STAGE_ADMIN
                      ? 'Подтвердить'
                      : 'Отклонить')
          ));


      this.$prompt(
          text,
          title,
          {
            confirmButtonText: buttonText,
            cancelButtonText: 'Отменить',
            inputType: 'textarea',
            inputValidator: (text) => !success && !text ? 'Обязательно для заполнения' : true,
            customClass: 'agree-window'
          }
      ).then(({value}) => {
        const params = {
          ...this.methodParams,
          stage: stage,
          success: success ? 1 : 0,
          comment: value,
          reset: reset ? 1 : null
        }
        this.method(params)
            .then(data => {
              this.$notify.success({
                duration: this.$constants.global.notifyDuration,
                title: 'Сохранено',
                message: 'Согласование успешно сохранено'
              });
              this.$emit('agreed', data);
            })
      });
    }
  },

}
</script>

<style lang="scss">
@import "@/assets/css/project-variables.scss";

.agree-section {
  width: fit-content;

  .item {
    display: inline-block;
    width: 24px;
    height: 24px;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      width: 14px;
      height: 14px;
      background-color: rgba($--color-danger, 0.5);
      border-radius: 50%;
    }

    &.agreed {

      &::before {
        background-color: $--color-primary;
      }
    }
  }

  &__btns {
    display: inline-flex;
    align-items: center;
    padding-left: 16px;
  }

  .el-button {
    width: 24px;
    height: 24px;
    border: none;

    i {
      margin: 0;
      font-size: 14px;
    }
  }

  &__btn-check {
    color: $--color-white;
    background-color: #3D8BF4;
    border-color: #3D8BF4;
  }

  &__btn-times {
    color: $--color-white;
    background-color: rgba($--color-danger, 0.5);
  }

  &__btn-undo {
    color: $--color-white;
    background-color: $--color-warning;
  }
}
</style>
