<template>
  <div class="page">
    <div class="page__head">
      <el-row type="flex" justify="space-between" align="top">
        <div class="page__head-left">
          <div class="title-edit">
            <h1 class="page__head-title">Профили типовых штатных должностей по профессиональным компетенциям</h1>

            <click-to-edit
                v-model="profiling.name"
                @input="saveProfilingName"
                placeholder="Введите наименование подразделения"
            ></click-to-edit>
          </div>
        </div>
      </el-row>
    </div>

    <div class="settings">
      <div class="settings__left">
        <el-checkbox v-model="myAndSharedCompetencesFilter" @change="getProfiling">Только мои компетенции
        </el-checkbox>
        <el-checkbox v-model="myAndSharedTsdsFilter" @change="getProfiling">Только мои подразделения</el-checkbox>
      </div>

      <div class="settings__right">
        <el-button
          v-if="$can('profiling-export', profiling)"
            :loading="loadingDownloader"
            class="el-button--download"
            type="primary"
            plain
            @click="exportToExcel"
        >
          Выгрузить в excel
        </el-button>

        <upload-tos-modal
          v-if="$can('profiling-import-tos', profiling)"
          style="margin-left: 20px"
          @tsds-loaded="getProfiling"
        >
          <el-button
            slot="reference"
            class="el-button--upload"
            type="primary"
          >
            Загрузить TOС
          </el-button>
        </upload-tos-modal>
      </div>
    </div>

    <el-row :gutter="24" type="flex" align="middle" style="margin-bottom: 24px;">
      <el-col :span="4">
        <label class="form-label">
          <span class="form-label__title">Поиск</span>
          <el-input
              style="width: 100%"
              v-model="search"
              type="number"
              prefix-icon="el-icon-search"
              placeholder="Поиск по ИД типовой штатной должности"
              size="mini"
              clearable
              @clear="getProfiling"
              v-debounce:500="getProfiling"
          >
          </el-input>
        </label>
      </el-col>

<!--      <el-col :span="4">-->
<!--        <label class="form-label">-->
<!--          <span class="form-label__title">Типовая ОЕ</span>-->
<!--          <tsd-type-oe-select-->
<!--              size="mini"-->
<!--              :multiple="true"-->
<!--              v-model="typeoesFilter"-->
<!--              @selected="getProfiling"-->
<!--          ></tsd-type-oe-select>-->
<!--        </label>-->
<!--      </el-col>-->

      <el-col :span="4">
        <label class="form-label">
          <span class="form-label__title">Типовое подразделение</span>
          <tsd-tp-name-select
              size="mini"
              v-model="tsdTpNamesFilter"
              :project-id="parseInt($route.params.id)"
              :multiple="true"
              @change="getProfiling"
          ></tsd-tp-name-select>
        </label>
      </el-col>

      <el-col :span="4">
        <label class="form-label">
          <span class="form-label__title">Типовая штатная должность</span>
          <tsd-select
              size="mini"
              :multiple="true"
              v-model="tsdsFilter"
              @change="getProfiling"
          ></tsd-select>
        </label>
      </el-col>

      <el-col :span="4">
        <label class="form-label">
          <span class="form-label__title">Компетенции</span>
          <tsd-competence-select
              size="mini"
              :multiple="true"
              v-model="tsdCompetenceFilter"
              :project-id="parseInt($route.params.id)"
              @selected="getProfiling"
          >
          </tsd-competence-select>
        </label>
      </el-col>

      <el-col :span="4" style="align-self: flex-end; text-align: right;">
        <el-button
            icon="el-icon-refresh"
            :disabled="isDisabled"
            @click="reset"
        >
          Сбросить
        </el-button>
      </el-col>
    </el-row>

    <div class="content">
      <div class="table-profiling">
        <el-row type="flex" justify="space-between" class="table-settings">
          <div class="table-settings__btns">
            <el-button
                type="text"
                size="mini"
                icon="el-icon-view"
                @click="columnsVisibleModalShow = true"
            >
              Видимость столбцов
            </el-button>
            <deleted-tsds-modal
              v-if="$can('profiling-see-deleted-tsds', profiling)"
              :profiling-id="profiling.id"
              @profiling-tsd-restored="getProfiling"
            >
              <el-button
                slot="reference"
                type="text"
                size="mini"
                icon="fas fa-trash"
              >
                Удаленные строки
              </el-button>
            </deleted-tsds-modal>
          </div>
          <div>
            <el-button
              :disabled="!$can('profiling-start', profiling)"
              :loading="loading"
              type="primary"
              size="mini"
              plain
              icon="fas fa-play-circle"
              @click="startProfiling"
            >
              <span v-if="profiling.status < $constants.profiling.statuses.STATUS_PROFILING">Начать профилирование</span>
              <span v-else>Идет профилирование</span>
            </el-button>
          </div>
        </el-row>

        <el-table
            v-loading="loading"
            class="el-table--profiling"
            style="width: 100%;"
            ref="profilingTable"
            :data="profiling.profiling_tsds"
            tooltip-effect="light"
            :row-class-name="getRowClass"
        >

          <el-table-column
            fixed
              width="16"
              align="right"
              class-name="el-table__cell--ellipsis"
          >
            <template slot-scope="scope">
              <ellipsis-menu>
                <tsd-tp-shared-users-dialog
                  v-if="scope.row.tsd.tp"
                    :can-set-users="$can('profiling-set-tsd-user', scope.row)"
                    :can-set-main-user="$can('profiling-set-tsd-main-user', scope.row)"
                    :profiling-id="profiling.id"
                    :tp-id="scope.row.tsd.tp.objectid"
                >
                  <el-button
                      slot="reference"
                      type="text"
                      size="small"
                      icon="fas fa-users"
                      :loading="loading"
                  >
                    Ответственные
                  </el-button>
                </tsd-tp-shared-users-dialog>
                <tsd-agree-history-modal
                    v-if="$can('profiling-see-tsd-agree-history', scope.row)"
                    :method="$api.profiling.getAgreeHistory"
                    :params="{
                                    profiling_id: profiling.id,
                                    tsd_id: scope.row.tsd.objectid
                                }"
                >
                  <el-button
                      slot="reference"
                      type="text"
                      size="small"
                      icon="fas fa-check-double"
                  >
                    История согласований
                  </el-button>
                </tsd-agree-history-modal>
                <el-popconfirm
                    v-if="$can('profiling-delete-tsd', scope.row)"
                    confirm-button-text='Да'
                    cancel-button-text='нет'
                    icon="el-icon-question"
                    title="Вы уверены, что хотите удалить профиль?"
                    placement="bottom"
                    @confirm="deleteTsd(scope.row)"
                >
                  <el-button
                      slot="reference"
                      type="text"
                      size="small"
                      icon="el-icon-delete"
                      :loading="loading"
                  >
                    Удалить профиль
                  </el-button>
                </el-popconfirm>
              </ellipsis-menu>
            </template>
          </el-table-column>

          <el-table-column
            fixed
              label="Согласования"
              class-name="el-table__cell--bottom"
              width="105"
          >
            <template slot-scope="scope">
                <agree-section
                  hide-items-on-action

                  :agreed-expert="!!scope.row.agreed_expert"
                  :can-finish="!!profiling.all_competences_finished && !!scope.row.can_finish"

                  :agreed-expert-main="!!scope.row.agreed_expert_main"
                  :can-agree-expert-main="!!profiling.all_competences_finished && !!scope.row.can_agree_main"

                  :agreed-admin="!!scope.row.agreed_admin"
                  :can-agree-admin="!!profiling.all_competences_finished && scope.row.can_agree_admin"

                  :can-reset-agree="!!scope.row.can_reset_agree"

                  text-finish="Если Вы уверены, что завершили работу над формированием типовой штатной должности, отправьте ее на согласование. Можете оставить комментарий:"
                  text-agree="Вы согласовываете профиль типовой штатной должности. Можете оставить комментарий:"
                  text-reject="Вы отклоняете профиль типовой штатной должности. Необходимо оставить комментарий:"
                  text-reset="Вы отправляете профиль типовой штатной должности на доработку. Необходимо оставить комментарий:"
                  title-finish="Направить на согласование"
                  title-agree="Согласование профиля типовой штатной должности"
                  title-reject="Отклонение профиля типовой штатной должности"
                  title-reset="Отправка на доработку"

                  :method="$api.profiling.setTsdAgreeStatus"
                  :method-params="{profiling_id: profiling.id, tsd_id: scope.row.tsd.objectid}"

                  @agreed="rowAgreed(scope.row, $event)"
                ></agree-section>

            </template>
          </el-table-column>

          <el-table-column
            fixed
              v-if="columnsVisible['typeoe']"
              label="Типовая ОЕ"
              class-name="el-table__cell--bottom"
              width="80"
          >
            <template slot-scope="scope">
              <el-tooltip placement="top" effect="light">
                <div slot="content">{{ scope.row.tsd.tp
                  ? scope.row.tsd.tp.path + scope.row.tsd.tp.objectname
                  : '' }}</div>
                <p class="text-ellipsis">{{ scope.row.tsd.tp
                  ? scope.row.tsd.tp.path + scope.row.tsd.tp.objectname
                  : '' }}</p>
              </el-tooltip>

            </template>
          </el-table-column>

          <el-table-column
            fixed
              v-if="columnsVisible['typep']"
              label="Типовое подразделение"
              class-name="el-table__cell--bottom"
              width="160"
          >
            <template slot-scope="scope">
              <el-tooltip placement="top" effect="light">
                <div slot="content">{{ scope.row.tsd.tp ? scope.row.tsd.tp.objectname : '' }}</div>
                <p class="text-ellipsis">{{ scope.row.tsd.tp ? scope.row.tsd.tp.objectname : '' }}</p>
              </el-tooltip>
            </template>
          </el-table-column>

          <el-table-column
            fixed
              v-if="columnsVisible['tsd']"
              label="Типовая ШД (ИД)"
              class-name="el-table__cell--bottom"
              width="160"
          >
            <template slot-scope="scope">
              <el-tooltip placement="top" effect="light">
                <div slot="content">{{ scope.row.tsd.objectid }}</div>
                <p class="text-ellipsis">{{ scope.row.tsd.objectid }}</p>
              </el-tooltip>
            </template>
          </el-table-column>

          <el-table-column
            fixed
              v-if="columnsVisible['tsdname']"
              label="Наименование типовой ШД"
              class-name="el-table__cell--bottom"
              width="160"
          >
            <template slot-scope="scope">
              <el-tooltip placement="top" effect="light">
                <div slot="content">{{ scope.row.tsd.objectname }}</div>
                <p class="text-ellipsis">{{ scope.row.tsd.objectname }}</p>
              </el-tooltip>
            </template>
          </el-table-column>

          <el-table-column
            fixed
              v-if="columnsVisible['t9902dlevel']"
              label="Уровень должности"
              class-name="el-table__cell--bottom"
              width="130"
          >
            <template slot-scope="scope">
              {{ $constants.tsd.dLevelNames[scope.row.tsd.t9902dlevel] }}
            </template>
          </el-table-column>

          <el-table-column
            fixed
              v-if="columnsVisible['compcount']"
              label="Кол-во комп."
              class-name="el-table__cell--bottom"
              width="80"
          >
            <template slot-scope="scope">
              {{ competencesCount(scope.$index) }}
            </template>
          </el-table-column>

          <el-table-column
            fixed
              v-if="columnsVisible['point']"
              label="Ср. балл"
              class-name="el-table__cell--bottom"
              width="60"
          >
            <template slot-scope="scope">
              {{ competencesAvgPoint(scope.$index) }}
            </template>
          </el-table-column>

          <el-table-column
              v-for="competence in competences"
              :key="competence.id"
              class-name="el-table__cell--select"
              width="100"
          >
            <template slot="header" slot-scope="scope">
              <div class="table-head">
                <div class="table-head__btn">
                  <el-tooltip effect="light" placement="bottom-start">
                    <div slot="content">
                      {{ (!!competence.agreed_profiling) ? 'Завершено' : 'Завершить' }}
                    </div>
                    <div>
                      <el-button
                          :disabled="!!competence.agreed_profiling || !competence.can_agree_profiling"
                          type="primary"
                          plain
                          size="small"
                          icon="fas fa-check"
                          @click="openAgreeCompetenceWindow(competence, $constants.mpc.competenceAgreeStages.STAGE_PROFILING, true)"
                      ></el-button>
                    </div>
                  </el-tooltip>
                </div>

                <competence-shared-users-dialog
                    class="table-head__responsible"
                    :competence-id="competence.id"
                >
                  <el-button
                      slot="reference"
                      size="small"
                      icon="fas fa-add-users"
                      :loading="loading"
                  ></el-button>
                </competence-shared-users-dialog>
              </div>

              <el-tooltip placement="bottom-start" effect="light">
                <div slot="content">{{ competence.name }}</div>
                <p class="table-head__title">{{ competence.order }}. {{ competence.name }}</p>
              </el-tooltip>
            </template>

            <template slot-scope="scope">
              <select
                class="profiling-select"
                :disabled="!canEditRate(competence, scope.row)"
                :value="scope.row.rates[competence.id]"
                @change="competenceRateChanged(competence.id, scope.row.tsd.objectid, $event, scope.row)"
              >
                <option value="0"> </option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>
<!--              <el-select-->
<!--                  v-model="scope.row.rates[competence.id]"-->
<!--                  :disabled="!canEditRate(competence, scope.row)"-->
<!--                  default-first-option-->
<!--                  class="el-select-profiling"-->
<!--                  popper-class="el-select-dropdown&#45;&#45;profiling"-->
<!--                  @change="competenceRateChanged(competence.id, scope.row.tsd.objectid, $event)"-->
<!--                  placeholder=""-->
<!--              >-->
<!--                <el-option label=" " :value="0"></el-option>-->
<!--                <el-option :label="1" :value="1"></el-option>-->
<!--                <el-option :label="2" :value="2"></el-option>-->
<!--                <el-option :label="3" :value="3"></el-option>-->
<!--              </el-select>-->
            </template>
          </el-table-column>
        </el-table>
        <paginator
          :paginator.sync="paginator"
          @page-changed="getProfiling"
        ></paginator>
      </div>

<!--      <entity-comments-->
<!--          v-if="profiling.id"-->
<!--          :project-id="parseInt($route.params.id)"-->
<!--          :entity-type="$constants.comment.entityTypes.PROFILING"-->
<!--          :entity-id="profiling.id"-->
<!--          title-description="Комментарии к типовой ШД"-->
<!--      >-->
<!--      </entity-comments>-->

    </div>

    <el-dialog title="Видимость столбцов"
               :visible.sync="columnsVisibleModalShow"
               width="400px"
               destroy-on-close
    >
      <el-row>
        <div>
          <el-checkbox @change="doTableLayout" v-model="columnsVisible['typeoe']">Типовая ОЕ</el-checkbox>
        </div>
        <div>
          <el-checkbox @change="doTableLayout" v-model="columnsVisible['typep']">Типовое подразделение</el-checkbox>
        </div>
        <div>
          <el-checkbox @change="doTableLayout" v-model="columnsVisible['tsd']">Типовая ШД (ИД)</el-checkbox>
        </div>
        <div>
          <el-checkbox @change="doTableLayout" v-model="columnsVisible['tsdname']">Наименование типовой ШД</el-checkbox>
        </div>
        <div>
          <el-checkbox @change="doTableLayout" v-model="columnsVisible['t9902dlevel']">Уровень должности</el-checkbox>
        </div>
        <div>
          <el-checkbox @change="doTableLayout" v-model="columnsVisible['compcount']">Количество компетенций
          </el-checkbox>
        </div>
        <div>
          <el-checkbox @change="doTableLayout" v-model="columnsVisible['point']">Средний целевой балл</el-checkbox>
        </div>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="columnsVisibleModalShow = false">Закрыть</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import PageHeading from "../components/PageHeading";
import ClickToEdit from "../components/ClickToEdit";
import EllipsisMenu from "../components/EllipsisMenu";
import {downloader} from "../mixins/downloader";
import CompetenceSharedUsersDialog from "../components/profiling/CompetenceSharedUsersDialog";
import AgreeHistoryModal from "../components/AgreeHistoryModal";
import TsdSharedUsersDialog from "../components/profiling/TsdSharedUsersDialog";
import TsdAgreeHistoryModal from "../components/profiling/TsdAgreeHistoryModal";
import DeletedTsdsModal from "../components/profiling/DeletedTsdsModal";
import TsdTpSharedUsersDialog from "@/components/profiling/TsdTpSharedUsersDialog";
import TsdTypeOeSelect from "@/components/selects/TsdTypeOeSelect";
import TsdTpNameSelect from "@/components/selects/TsdTpNameSelect";
import TsdSelect from "@/components/selects/TsdSelect";
import RoleSelect from "../components/selects/RoleSelect";
import DivisionSelect from "../components/selects/DivisionSelect";
import TsdCompetenceSelect from "../components/selects/TsdCompetenceSelect";
import EntityComments from "../components/comments/EntityComments";
import {off} from "element-ui/src/utils/dom";
import AgreeSection from "@/components/AgreeSection";
import {paginator} from "@/mixins/paginator";
import Paginator from "@/components/Paginator.vue";
import UploadTosModal from "@/components/profiling/UploadTosModal.vue";

export default {
  name: 'Profiling',
  mixins: [downloader, paginator],
  components: {
    UploadTosModal,
    Paginator,
    AgreeSection,
    DivisionSelect,
    TsdSelect,
    TsdTpNameSelect,
    TsdTypeOeSelect,
    TsdTpSharedUsersDialog,
    DeletedTsdsModal,
    TsdAgreeHistoryModal,
    TsdSharedUsersDialog,
    AgreeHistoryModal,
    CompetenceSharedUsersDialog,
    EllipsisMenu,
    ClickToEdit,
    PageHeading,
    EntityComments,
    TsdCompetenceSelect
  },
  data() {
    return {
      loading: false,
      search: null,
      profiling: {
        id: null,
        name: null,
        status: null,
        all_competences_finished: null,
        profiling_tsds: [],
        statement_date: null,
      },
      competences: [],
      myAndSharedCompetencesFilter: false,
      myAndSharedTsdsFilter: false,
      tsdsFilter: [],
      typeoesFilter: [],
      tsdCompetenceFilter: [],
      tsdTpNamesFilter: [],
      columnsVisibleModalShow: false,
      columnsVisible: {
        typeoe: false, typep: true, tsd: false,
        tsdname: true, t9902dlevel: false, compcount: true, point: true,
      },
      width: null,

      paginator: {perPage:25},
    }
  },
  computed: {
    competencesCount() {
      return index => {
        return Object.values(this.profiling.profiling_tsds[index].rates).filter(rate => rate > 0).length;
      }
    },
    competencesAvgPoint() {
      return index => {
        const array = Object.values(this.profiling.profiling_tsds[index].rates).filter(rate => rate > 0);
        const sum = array.reduce((a, b) => a + b, 0);
        const avg = (sum / array.length) || 0;
        // return avg.toFixed(1);
        return Math.round((avg + Number.EPSILON) * 100) / 100;
      };
    },
    isDisabled() {
      return !this.search &&
             !this.tsdsFilter.length &&
             !this.tsdTpNamesFilter.length &&
             !this.typeoesFilter.length &&
             !this.tsdCompetenceFilter.length &&
             !this.myAndSharedCompetencesFilter &&
             !this.myAndSharedTsdsFilter
    }
  },
  mounted() {
    this.getProfiling();
  },
  methods: {
    doTableLayout() {
      // setTimeout(() => {
      //   if(this.$refs.profilingTable) this.$refs.profilingTable.doLayout();
      // }, 300);
      // setTimeout(() => {
      //   if(this.$refs.profilingTable) this.$refs.profilingTable.doLayout();
      // }, 800);
    },
    getProfiling() {
      this.loading = true;
      this.$api.profiling.getProjectProfiling({
        [this.paginator.param]: this.paginator.page,
        [this.paginator.paramPerPage]: this.paginator.perPage ? this.paginator.perPage : 50,

        search: this.search,
        project_id: this.$route.params.id,
        my_competences: this.myAndSharedCompetencesFilter ? 1 : 0,
        my_tsds: this.myAndSharedTsdsFilter ? 1 : 0,

        tsds: this.tsdsFilter,
        tsd_names: this.tsdTpNamesFilter,
        type_oes: this.typeoesFilter,
        tsd_competence: this.tsdCompetenceFilter,

        expand: 'tsd.tp.path',

      })
          .then(({data, paginator}) => {
            this.paginatorFill(paginator);
            this.competences = data.competences;
            data.profiling_tsds.forEach(profiling_tsds => {
              profiling_tsds.rates = {};
              data.profiling.tsd_competences.filter(tsdCompetence => tsdCompetence.tsd_id == profiling_tsds.tsd.objectid).forEach(tsdCompetence => {
                profiling_tsds.rates[tsdCompetence.competence_id] = tsdCompetence.point
              })
            })
            this.profiling = data.profiling;
            this.profiling.profiling_tsds = data.profiling_tsds;

            this.doTableLayout();
          })
          .finally(() => {
            this.loading = false;
          })
    },
    saveProfilingName() {
      this.loading = true;
      this.$api.profiling.saveProfilingName({
        profiling_id: this.profiling.id,
        name: this.profiling.name,
      })
        .then(data => {
          this.$notify.success({
            duration: this.$constants.global.notifyDuration,
            title: 'Сохранено',
            message: 'Элемент успешно сохранен'
          });
        })
        .finally(() => {
          this.loading = false;
        })
    },
    deleteTsd(profilingTsd) {
      this.loading = true;
      this.$api.profiling.deleteTsd({
        profiling_id: this.profiling.id,
        tsd_id: profilingTsd.tsd.objectid,
      })
        .then(data => {
          this.$notify.success({
            duration: this.$constants.global.notifyDuration,
            title: 'Удалено',
            message: 'Профиль успешно удален'
          });
        })
        .finally(() => {
          this.getProfiling();
        })
    },
    competenceRateChanged(competenceId, tsdId, event, row) {
      row.rates[competenceId] = +event.target.value;
      this.$api.profiling.saveCompetenceRate({
        profiling_id: this.profiling.id,
        tsd_id: tsdId,
        competence_id: competenceId,
        point: event.target.value,
      })
        .then(data => {
          this.$notify.success({
            duration: this.$constants.global.notifyDuration,
            title: 'Сохранено',
            message: 'Элемент успешно сохранен'
          });
        })
        .finally(() => {

        })
    },
    openAgreeCompetenceWindow(competence, stage, success) {
      this.$prompt(
          success ? 'Вы завершаете компетенцию. Можете оставить комментарий:' : 'Вы отклоняете компетенцию. Необходимо оставить комментарий:',
          success ? 'Завершение компетенции' : 'Отклонение компетенции',
          {
            confirmButtonText: success ? 'Завершить' : 'Отклонить',
            cancelButtonText: 'Отменить',
            inputType: 'textarea',
            inputValidator: (text) => !success && !text ? 'Обязательно для заполнения' : true,
          }
      ).then(({value}) => {
        this.$api.mpc.setCompetenceAgreeStatus({
          competence_id: competence.id,
          stage: stage,
          success: success ? 1 : 0,
          comment: value,
        })
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDuration,
              title: 'Сохранено',
              message: 'Компетенция успешно сохранена'
            });
            competence.agreed_profiling = data.competence.agreed_profiling;
            this.profiling.all_competences_finished = data.profiling_competences_finished;
            this.doTableLayout();
          })
          .finally(() => {
            this.$store.dispatch('updateMenu');
          })
      });
    },

    rowAgreed(profilingTsd, data) {
      profilingTsd.agreed_expert = data.profiling_tsd.agreed_expert;
      profilingTsd.agreed_expert_main = data.profiling_tsd.agreed_expert_main;
      profilingTsd.agreed_admin = data.profiling_tsd.agreed_admin;
      profilingTsd.can_finish = data.profiling_tsd.can_finish;
      profilingTsd.can_agree_main = data.profiling_tsd.can_agree_main;
      profilingTsd.can_agree_admin = data.profiling_tsd.can_agree_admin;
      profilingTsd.can_reset_agree = data.profiling_tsd.can_reset_agree;
      this.$store.dispatch('updateMenu');
      this.doTableLayout();
    },

    startProfiling() {
      this.loading = true;
      this.$api.profiling.startProfiling({
        profiling_id: this.profiling.id
      })
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDuration,
              title: 'Успешно',
              message: 'Профилирование начато'
            });
            this.getProfiling();
          })
          .finally(() => {
            this.$store.dispatch('updateMenu');
          })
    },
    reset() {
      this.search                        = null;
      this.tsdsFilter                    = [];
      this.typeoesFilter                 = [];
      this.tsdTpNamesFilter              = [];
      this.tsdCompetenceFilter           = [];
      this.myAndSharedTsdsFilter         = false;
      this.myAndSharedCompetencesFilter  = false;

      this.getProfiling();
    },

    uploadTos() {
      this.$api.profiling.uploadTOE({
        profiling_id: this.profiling.id,
      })
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDuration,
              title: 'Сохранено',
              message: 'ТОЕ успешно загружены'
            });
            this.getProfiling();
          })
          .finally(() => {
            this.$store.dispatch('updateMenu');
          })
    },

    exportToExcel() {
      this.downloadFile(
        false,
        this.$api.profiling.exportToExcel,
        {
          to_excel: 1,
          search: this.search,
          project_id: this.$route.params.id,
          my_competences: this.myAndSharedCompetencesFilter ? 1 : 0,
          my_tsds: this.myAndSharedTsdsFilter ? 1 : 0,

          tsds: this.tsdsFilter,
          tsd_names: this.tsdTpNamesFilter,
          type_oes: this.typeoesFilter,
          tsd_competence: this.tsdCompetenceFilter,

        }
      );
    },


    canEditRate(competence, row){
      if( this.profiling.status < this.$constants.profiling.statuses.STATUS_PROFILING ){
        return false;
      }

      if( this.profiling.status >= this.$constants.profiling.statuses.STATUS_APPROVED_BY_STATEMENT ){
        return false;
      }

      if( !competence.agreed_profiling ){
        return !!competence.can_agree_profiling;
      }


      if( !this.profiling.all_competences_finished ){
        return false;
      }

      if( row.can_finish || row.can_agree_main || row.can_agree_admin ){
        return true;
      }

      return false;
    },
    updateWidth() {
      this.width = window.innerWidth;
    },

    getRowClass(row) {
      if( row.row.tsd.expired ){
        return 'expired-tsd';
      }
      if( row.row.is_new ){
        return 'new-tsd';
      }

      return '';
    },
  },
  created() {
    window.addEventListener('resize', this.updateWidth);
    this.updateWidth();
  },
}

</script>

<style lang="scss">
@import "./src/assets/css/project-variables";

.library-link {
  display: inline-flex;
  align-items: center;

  svg {
    display: block;
    width: 16px;
    height: 16px;
    margin: 0 8px 0 0;
  }
}

.profiling-checkbox {
  height: 100%;
  display: flex;
  align-items: flex-end;

  .el-checkbox {
    margin-bottom: 12px;
  }
}

.content {
  position: relative;
}

.table-profiling {
  margin: 0 0 56px 0;
}

.table-settings {
  margin-bottom: 16px;

  &__btns {
    display: flex;
    align-items: center;

    .el-button:not(:last-child) {
      margin-right: 24px;
    }
  }
}

.el-table {

  th.el-table__cell--select {
    padding: 24px 0 12px;

    .cell {
      padding: 0;
    }
  }

  td.el-table__cell--select {
    vertical-align: middle;
  }

  th.el-table__cell--bottom {
    vertical-align: bottom;
  }

  &--profiling {
    border-right: 1px solid $--color-divider;

    .el-table__fixed {
      border-right: 1px solid $--color-divider;
    }

    .table-head {
      display: flex;
      flex-direction: column;

      &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 12px 24px;
        border-bottom: 1px solid $--color-divider;

        .el-button {
          padding: 8px;
          font-weight: 400;
          text-transform: none;
        }
      }

      &__responsible {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px 12px 16px;
      }

      &__status {
        padding: 0 12px 16px;
        color: $--color-primary;
      }

      &__title {
        padding: 0 12px 0;
        margin-bottom: 0;
        font-size: 14px;
        color: $--color-primary;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .cell.el-tooltip {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: unset;
      display: -moz-box;
      -moz-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-clamp: 2;
      box-orient: vertical;
    }

    .el-button {

      i {
        margin-right: 0;
      }
    }
  }
}

.el-select.el-select-profiling {

  .el-input.el-input--suffix .el-input__suffix {
    right: 8px;
    width: 18px;
    height: 18px;
  }

  .el-input.el-input--suffix .el-input__suffix .el-input__icon::before {
    font-size: 16px;
  }

  .el-input__inner {
    padding: 4px 28px 4px 8px;
  }
}

.el-select-dropdown {

  &--profiling {

    .el-select-dropdown__item:first-child {
      height: 48px;
    }
  }
}


.profiling-select {
  width: 100%;
  height: 30px;
  border-radius: 5px;
  border-color: #3D8BF4;
  background-color: white;

  &:disabled{
    border-color: #c0c0c0;

    &:hover {
      cursor: not-allowed;
    }
  }
}
.expired-tsd{
  background-color: #ffd9d9 !important;
}
.new-tsd{
  background-color: #e3ffd9 !important;
}
</style>
