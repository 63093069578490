<template>
    <span>
        <el-dialog
          title="Журнал удаленных компетенций"
          :visible.sync="show"
          :close-on-click-modal="false"
          width="95%"
          top="2vh"
          append-to-body
        >
            <el-row>

<el-table
  class="MPC-table"
  row-class-name="no-hover"
  empty-text="Нет компетенций"
  style="width: 100%"
  border
  :data="competences"

  v-loading="loading"
>


            <el-table-column label="Название компетенции">
                <template slot-scope="scope">
                    <div :key="scope.row.order">

                        <el-row type="flex">
                            <div>
                                <div>{{ scope.row.name }}</div>
                                <div>{{ scope.row.desc }}</div>
                            </div>
                        </el-row>
                    </div>
                </template>
            </el-table-column>

            <el-table-column label="Индикаторы компетенций">
                <template slot-scope="scope">
                    <div v-for="indicator in scope.row.indicators" :key="indicator.id">
                        <div class="indicator-block">
                            <el-row type="flex">
<!--                                <div style="margin-right:10px;">{{ indicator.order }}. </div>-->
                                {{ indicator.name }}
                            </el-row>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="Источники">
                <template slot-scope="scope">
                    <div>
                        <div class="indicator-block" v-for="source in scope.row.sources" :key="source.id">
                            <el-row type="flex">
                                {{ source.name }}
                            </el-row>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="Удалено">
                <template slot-scope="scope">
                    <div>{{ scope.row.deleted_user.full_name }}</div>
                    {{ new Date(scope.row.deleted_at * 1000).toLocaleString() }}
                </template>
            </el-table-column>
            <el-table-column width="40">
                <template slot-scope="scope">
                    <ellipsis-menu
                      v-if="$can('mpc-restore-competence', scope.row)"
                    >
                        <el-popconfirm
                          confirm-button-text='Да'
                          cancel-button-text='нет'
                          icon="el-icon-question"
                          title="Вы уверены, что хотите восстановить компетенцию?"
                          @confirm="restoreCompetence(scope.row)"
                        >
                            <el-button
                              slot="reference"
                              type="text"
                              size="small"
                              icon="fas fa-trash"
                              :loading="loading"
                            >
                                Восстановить компетенцию
                            </el-button>
                        </el-popconfirm>
                    </ellipsis-menu>
                </template>
            </el-table-column>


        </el-table>

                <paginator
                  :paginator.sync="paginator"
                  @page-changed="showDeleted"
                ></paginator>
            </el-row>

            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="show = false">Закрыть</el-button>
            </span>
        </el-dialog>

        <span ref="wrapper" class="btn-wrap">
            <slot name="reference"></slot>
        </span>
    </span>
</template>

<script>

import {off, on} from "element-ui/src/utils/dom";
import Paginator from "./../Paginator";
import EllipsisMenu from "../EllipsisMenu";
import {paginator} from "@/mixins/paginator";

export default {
  name: "deleted-competences-modal",
  mixins: [paginator],
  components: {EllipsisMenu, Paginator},
  props: {
    mpcId: {
      type: Number,
    }
  },

  data() {
    return {
      show: false,
      competences: [],
      loading: false,
    }
  },
  mounted() {
    if (this.$refs.wrapper.children) {
      on(this.$refs.wrapper.children[0], 'click', this.showDeleted);
    }
  },
  watch: {},
  methods: {
    showDeleted() {
      this.loading = true;
      this.competences = [];
      this.show = true;
      this.$api.mpc.getDeletedCompetences({
        mpc_id: this.mpcId,
        [this.paginator.param]: this.paginator.page,
        [this.paginator.paramPerPage]: this.paginator.perPage,
      })
        .then(({data, paginator}) => {
          this.competences = data.competences;
          this.paginatorFill(paginator);
        })
        .finally(() => {
          this.loading = false;
        })
    },
    restoreCompetence(competence) {
      this.loading = true;
      this.$api.mpc.restoreCompetence({
        competence_id: competence.id
      })
        .then(data => {
          this.$notify.success({
            duration: this.$constants.global.notifyDuration,
            title: 'Восстановлено',
            message: 'Элемент успешно восстановлен'
          });
          this.show = false;
          this.$emit('competence-restored');
        })
        .finally(() => {

        })
    }
  },

  beforeDestroy() {
    if (this.$refs.wrapper.children) {
      off(this.$refs.wrapper.children[0], 'click', this.showDeleted);
    }
  }

}
</script>

<style scope>

</style>
