import Vue from 'vue'

import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
// import './assets/css/element-ui-orange.css';
import './assets/css/element-variables.scss';
import 'element-ui/lib/theme-chalk/display.css';

import locale from 'element-ui/lib/locale/lang/ru-RU'
import App from './App.vue'

import vueDebounce from 'vue-debounce'


import ApiPlugin from './plugins/api'
import ConstantsPlugin from './plugins/constants'
import PermissionsCheckerPlugin from './plugins/permissionsChecker'
import router from './router'

import store from './store'

import acl from './acl';
import PermissionsChecker from "@/plugins/permissionsChecker"; // import the instance of the defined ACL

Vue.config.productionTip = false

Vue.use(ApiPlugin);
Vue.use(ConstantsPlugin);
Vue.use(PermissionsCheckerPlugin);
Vue.use(ElementUI, {locale});
Vue.use(vueDebounce);
Vue.use(acl);


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
