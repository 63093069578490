import requestSender from "./base/requestSender";

export default function(axiosInstance){
    return {
        getMonitoringData(payload){
            return requestSender('get', 'report/get-monitoring-data', payload);
        },
        getPilotResult(payload){
            return requestSender('get', 'report/get-pilot-result-data', payload);
        },
        getPilotTests(payload){
            return requestSender('get', 'report/get-pilot-tests', payload);
        },
        getSectionQuestions(payload){
            return requestSender('get', 'report/get-pilot-result-section-questions', payload);
        },
        getPilotResultQuestion(payload){
            return requestSender('get', 'report/get-pilot-result-question', payload);
        },
        getPilotResultQuestionAnswers(payload){
            return requestSender('get', 'report/get-pilot-result-question-answers', payload);
        },


        getQuestionSolutions(payload){
            return requestSender('get', 'report/get-question-solutions', payload);
        },
        addSolution(payload){
            return requestSender('post', 'report/add-solution', payload);
        },
        deleteSolution(payload){
            return requestSender('post', 'report/delete-solution', payload);
        },


        exportTestToExcel(payload, options){
            return requestSender('post', 'report/export-to-excel', payload, options);
        },
    }
}