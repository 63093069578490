<template>
  <div>
    <el-dialog
        title="Журнал удаленных функций"
        :visible.sync="show"
        :close-on-click-modal="false"
        width="95%"
        top="2vh"
        append-to-body
    >
      <el-row>

        <div v-loading="loading">
          <div class="fc-table">

            <el-row class="fc-table-header" :gutter="10">
              <el-col :span="3">Область деятельности</el-col>
              <el-col :span="18">
                <el-row :gutter="10">
                  <el-col :span="4">Обобщенные функции</el-col>
                  <el-col :span="20">
                    <el-row :gutter="10">
                      <el-col :span="6">Функции</el-col>
                      <el-col :span="6">Знания</el-col>
                      <el-col :span="6">Навыки</el-col>
                      <el-col :span="6">Источники</el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="2">Удалено</el-col>
              <el-col :span="1" align="right"></el-col>
            </el-row>

            <el-row
                v-for="(area, areaIndex) in areas"
                class="fc-table-area-row fc-table-element"
                :gutter="10"
                :key="area.id"
            >
              <el-col :span="3" class="">
                {{ area.name }}
              </el-col>
              <el-col :span="18">
                <el-row class="fc-table-element bordered" :gutter="10"
                        v-for="generalFunction in area.general_functions" :key="generalFunction.id">
                  <el-col :span="4" class="">
                    {{ generalFunction.name }}
                  </el-col>
                  <el-col :span="20">
                    <el-row class="fc-table-element bordered" :gutter="10"
                            v-for="func in generalFunction.functions" :key="func.id">
                      <el-col :span="6" class="">
                        {{ func.name }}
                      </el-col>
                      <el-col :span="6">
                        <div class="fc-table-element bordered "
                             v-for="experience in func.experiences" :key="experience.id"
                        >
                          {{ experience.name }}
                        </div>
                      </el-col>
                      <el-col :span="6">
                        <div class="fc-table-element bordered "
                             v-for="skill in func.skills" :key="skill.id">
                          {{ skill.name }}
                        </div>
                      </el-col>
                      <el-col :span="6">
                        <div class="fc-table-element bordered "
                             v-for="source in func.sources" :key="source.id">
                          {{ source.name }}
                        </div>
                      </el-col>
                    </el-row>

                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="1" style="float: right">
                <ellipsis-menu

                >
                  <el-popconfirm
                      v-if="$can('fc-restore-deleted-areas', area)"
                      confirm-button-text='Да'
                      cancel-button-text='нет'
                      icon="el-icon-question"
                      title="Вы уверены, что хотите вернуть область деятельности?"
                      @confirm="restoreArea(area)"
                  >
                    <el-button
                        slot="reference"
                        type="text"
                        size="small"
                        icon="fas fa-trash"
                        :loading="loading"
                    >
                      Восстановить область деятельности
                    </el-button>
                  </el-popconfirm>
                </ellipsis-menu>
              </el-col>
              <el-col :span="2" style="float: right">
                <div>{{ area.deleted_user.full_name }}</div>
                {{ new Date(area.deleted_at * 1000).toLocaleString() }}
              </el-col>
            </el-row>

            <el-divider></el-divider>
          </div>
        </div>

        <paginator
            :paginator.sync="paginator"
            @page-changed="showDeleted"
        ></paginator>
      </el-row>

      <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="show = false">Закрыть</el-button>
            </span>
    </el-dialog>

    <span ref="wrapper" class="btn-wrap">
      <slot name="reference"></slot>
    </span>
  </div>
</template>

<script>

import {off, on} from "element-ui/src/utils/dom";
import Paginator from "./../Paginator";
import EllipsisMenu from "../EllipsisMenu";
import {paginator} from "@/mixins/paginator";

export default {
  name: "deleted-areas-modal",
  mixins: [paginator],
  components: {EllipsisMenu, Paginator},
  props: {
    fcId: {
      type: Number,
    }
  },

  data() {
    return {
      show: false,
      areas: [],
      loading: false,
    }
  },
  mounted() {
    if (this.$refs.wrapper.children) {
      on(this.$refs.wrapper.children[0], 'click', this.showDeleted);
    }
  },
  watch: {},
  methods: {
    showDeleted() {
      this.loading = true;
      this.elements = [];
      this.show = true;
      this.$api.fc.getDeletedAreas({
        fc_id: this.fcId,
        [this.paginator.param]: this.paginator.page,
        [this.paginator.paramPerPage]: this.paginator.perPage,
      })
          .then(({data, paginator}) => {
            this.areas = data.areas;
            this.paginatorFill(paginator);
          })
          .finally(() => {
            this.loading = false;
          })
    },
    restoreArea(area) {
      this.loading = true;
      this.$api.fc.restoreArea({
        area_id: area.id
      })
          // .then(({data, paginator}) => {
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDuration,
              title: 'Восстановлено',
              message: 'Элемент успешно восстановлен'
            });
            this.show = false;
            this.$emit('area-restored');
          })
          .finally(() => {

          })
    }
  },

  beforeDestroy() {
    if (this.$refs.wrapper.children) {
      off(this.$refs.wrapper.children[0], 'click', this.showDeleted);
    }
  }

}
</script>

<style scope>

</style>
