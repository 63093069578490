import Vue from "vue";

// const can = (object, permission) => {
//     if( !object || !object.permissions || !object.permissions[permission] ){
//         return false;
//     }
//     return object.permissions[permission].can;
// };

const objectRulesPassed = (permission, object, logErrors=true) => {
    if( !object || !object.permissions || !object.permissions[permission] ){
        return false;
    }
    // if( logErrors && !object.permissions[permission].rulesPass ){
    //     console.log(this.getObjectRulesErrors(permission, object));
    // }
    return object.permissions[permission].rulesPass;
};

const getObjectRulesErrors = (permission, object) => {
    if( !object || !object.permissions || !object.permissions[permission] ){
        return [];
    }
    return object.permissions[permission].errors;
}

export default {
    install(Vue) {
        Vue.prototype.$objectRulesPassed = objectRulesPassed;
        Vue.prototype.$objectRulesErrors = getObjectRulesErrors;
    }
}
