import requestSender from "./base/requestSender";

export default function(axiosInstance){
    return {

        createCompetencesFromFcAreas(payload){
            return requestSender('post', 'mpc/create-competences-from-fc-areas', payload);
        },

        getProjectMpc(payload){
            return requestSender('get', 'mpc/get-project-mpc', payload);
        },

        getDeletedCompetences(payload){
            return requestSender('get', 'mpc/get-deleted-competences', payload);
        },
        restoreCompetence(payload){
            return requestSender('post', 'mpc/restore-competence', payload);
        },




        exportMpcToExcel(payload, options){
            return requestSender('post', 'mpc/export-to-excel', payload, options);
        },
        exportMpcToWord(payload, options){
            return requestSender('post', 'mpc/export-to-word', payload, options);
        },
        exportAgreeHistoryToExcel(payload, options){
            return requestSender('post', 'mpc/export-agree-history-to-excel', payload, options);
        },




        saveCompetenceOrder(payload){
            return requestSender('post', 'mpc/save-competence-order', payload);
        },
        saveCompetence(payload){
            return requestSender('post', 'mpc/save-competence', payload);
        },
        deleteCompetence(payload){
            return requestSender('post', 'mpc/delete-competence', payload);
        },
        moveCompetenceToSubmodel(payload){
            return requestSender('post', 'mpc/move-competence-to-submodel', payload);
        },


        saveIndicatorOrder(payload){
            return requestSender('post', 'mpc/save-indicator-order', payload);
        },
        saveIndicator(payload){
            return requestSender('post', 'mpc/save-indicator', payload);
        },
        deleteIndicator(payload){
            return requestSender('post', 'mpc/delete-indicator', payload);
        },

        saveSource(payload){
            return requestSender('post', 'mpc/save-source', payload);
        },
        deleteSource(payload){
            return requestSender('post', 'mpc/delete-source', payload);
        },



        saveMpcName(payload){
            return requestSender('post', 'mpc/save-mpc-name', payload);
        },
        saveSubmodel(payload){
            return requestSender('post', 'mpc/save-submodel', payload);
        },
        deleteSubmodel(payload){
            return requestSender('post', 'mpc/delete-submodel', payload);
        },



        getCompetenceSharedUsers(payload){
            return requestSender('get', 'mpc/get-competence-shared-users', payload);
        },
        addNewCompetenceSharedUsers(payload){
            return requestSender('post', 'mpc/add-new-competence-shared-users', payload);
        },
        deleteCompetenceSharedUser(payload){
            return requestSender('post', 'mpc/delete-competence-shared-user', payload);
        },
        setCompetenceMainSharedUser(payload){
            return requestSender('post', 'mpc/set-competence-main-shared-user', payload);
        },



        getCompetenceHistory(payload){
            return requestSender('get', 'mpc/get-competence-history', payload);
        },

        setCompetenceAgreeStatus(payload){
            return requestSender('post', 'mpc/set-competence-agree-status', payload);
        },
        getAgreeHistory(payload){
            return requestSender('get', 'mpc/get-agree-history', payload);
        },


        setStatus(payload){
            return requestSender('post', 'mpc/set-status', payload);
        },

        searchCompetences(payload){
            return requestSender('get', 'mpc/search-competences', payload);
        },

        copyCompetencesFromAnotherMpc(payload) {
            return requestSender('post', 'mpc/copy-competences-from-another-mpc', payload);
        }
    }
}