<template>
  <div class="page">
    <div class="page__head">
      <el-row type="flex" justify="space-between" align="top">
        <div class="page__head-left">
          <div class="title-edit">
            <h1 class="page__head-title">План-график</h1>

            <click-to-edit
              v-model="schedule.name"
              :disabled="!$can('schedule-update')"
              :word-limit="200"
              placeholder="Введите наименование подразделения"
              @input="saveName"
            ></click-to-edit>
          </div>
        </div>
      </el-row>
    </div>

    <el-row type="flex" align="middle" justify="end" style="margin-bottom: 24px;">
      <el-button
          v-if="$can('schedule-export', schedule)"
          :loading="loadingDownloader"
          type="primary"
          plain
          @click="exportToWord"
      >Выгрузить в Word
      </el-button>
      <el-button
          v-if="$can('schedule-set-status', schedule) && schedule.status !== $constants.projectSchedule.statuses.STATUS_FINISHED && schedule.status !== $constants.projectSchedule.statuses.STATUS_PAUSED"
          @click="setStatus($constants.projectSchedule.statuses.STATUS_FINISHED)"
      >
        Завершить работу
      </el-button>
      <el-button
          v-if="$can('schedule-set-status', schedule) && schedule.status === $constants.projectSchedule.statuses.STATUS_FINISHED && schedule.status !== $constants.projectSchedule.statuses.STATUS_PAUSED"
          @click="setStatus($constants.projectSchedule.statuses.STATUS_ACTIVE)"
      >
        Возобновить работу
      </el-button>
      <el-button
          v-if="$can('schedule-update', schedule)"
          type="primary"
          @click="addBlock"
          icon="el-icon-plus"
      >
        Добавить раздел
      </el-button>
    </el-row>

    <div v-loading="loading" class="schedule-table">
      <el-row class="schedule-table__head" type="flex" align="middle">
        <el-col class="schedule-table__col" :span="1">
          <div class="schedule-table__cell">
            <span class="schedule-table__head-title">Номер</span>
          </div>
        </el-col>

        <el-col class="schedule-table__col" :span="5">
          <div class="schedule-table__cell">
            <span class="schedule-table__head-title">Наименование работ</span>
          </div>
        </el-col>

        <el-col class="schedule-table__col" :span="4">
          <div class="schedule-table__cell">
            <span class="schedule-table__head-title">Ответственные</span>
          </div>
        </el-col>

        <el-col class="schedule-table__col" :span="3">
          <div class="schedule-table__cell">
            <span class="schedule-table__head-title">Сроки</span>
          </div>
        </el-col>

        <el-col class="schedule-table__col" :span="5">
          <div class="schedule-table__cell">
            <span class="schedule-table__head-title">Отчетные документы</span>
          </div>
        </el-col>

        <el-col class="schedule-table__col" :span="5">
          <div class="schedule-table__cell">
            <span class="schedule-table__head-title">Комментарий</span>
          </div>
        </el-col>

        <el-col class="schedule-table__col" :span="1"></el-col>
      </el-row>

      <el-scrollbar
        wrap-style="max-height: 40vh;"
      >
        <div v-for="(block, blockIndex) in schedule.blocks" :key="block.id" class="schedule-table__block">
          <el-row class="schedule-table__heading" type="flex" align="middle">
            <el-col class="schedule-table__col" :span="1">
              <div class="schedule-table__cell">
                {{ blockIndex + 1 }}
              </div>
            </el-col>

            <el-col class="schedule-table__col" :span="22" align="center">
              <div class="schedule-table__cell" style="justify-content: center;">
                <click-to-edit
                  :disabled="!$can('schedule-update', schedule)"
                  v-model="block.name"
                  @input="saveBlock(block)"
                ></click-to-edit>
              </div>
            </el-col>

            <el-col class="schedule-table__col" :span="1"></el-col>
          </el-row>

          <el-row
            v-for="(item, itemIndex) in block.items"
            :key="item.id"
            class="schedule-table__row"
            type="flex"
          >
            <el-col class="schedule-table__col" :span="1">
              <div class="schedule-table__cell">
                {{ blockIndex + 1 }}.{{ itemIndex + 1 }}
              </div>
            </el-col>

            <el-col class="schedule-table__col" :span="5">
              <div class="schedule-table__cell">
                <click-to-edit
                  v-model="item.name"
                  :disabled="!$can('schedule-update', schedule)"
                  type="textarea"
                  @input="saveItem(item)"
                ></click-to-edit>
              </div>
            </el-col>

            <el-col class="schedule-table__col" :span="4">
              <div class="schedule-table__cell">
                <click-to-edit
                  v-model="item.responsible"
                  :disabled="!$can('schedule-update', schedule)"
                  type="textarea"
                  @input="saveItem(item)"
                ></click-to-edit>
              </div>
            </el-col>

            <el-col class="schedule-table__col" :span="3">
              <div class="schedule-table__cell">
                <click-to-edit
                  transform-timestamp
                  v-model="item.deadline"
                  :disabled="!$can('schedule-update', schedule)"
                  type="date-picker"
                  @input="saveItem(item)"
                ></click-to-edit>
              </div>
            </el-col>

            <el-col class="schedule-table__col" :span="5">
              <div class="schedule-table__cell">
                <click-to-edit
                  v-model="item.documents"
                  :disabled="!$can('schedule-update', schedule)"
                  type="textarea"
                  @input="saveItem(item)"
                ></click-to-edit>
              </div>
            </el-col>

            <el-col class="schedule-table__col" :span="5">
              <div class="schedule-table__cell">
                <click-to-edit
                  v-model="item.comment"
                  :disabled="!$can('schedule-update', schedule)"
                  type="textarea"
                  @input="saveItem(item)"
                ></click-to-edit>
              </div>
            </el-col>

            <el-col class="schedule-table__col" :span="1">
              <div class="schedule-table__cell" style="justify-content: flex-end; align-items: center;">
                <ellipsis-menu
                  v-if="item.id"
                >
                  <el-popconfirm
                    v-if="$can('schedule-update', schedule)"
                    confirm-button-text='Да'
                    cancel-button-text='нет'
                    icon="el-icon-question"
                    title="Вы уверены, что хотите удалить строку?"
                    @confirm="deleteItem(block, item)"
                  >
                    <el-button
                      slot="reference"
                      type="text"
                      size="small"
                      icon="el-icon-delete"
                      :loading="loading"
                      :disabled="!item.id"
                    >
                      Удалить строку
                    </el-button>
                  </el-popconfirm>
                </ellipsis-menu>
              </div>
            </el-col>
          </el-row>

          <el-row
            v-if="block.id"
            class="schedule-table__bottom"
            type="flex"
            align="middle"
            justify="end"
            style="margin-right: 24px;"
          >
            <el-button
              v-if="$can('schedule-update', schedule)"
              size="mini"
              type="text"
              icon="el-icon-plus"
              :disabled="!block.id"
              @click="addItem(block)"
            >
              Добавить строку
            </el-button>

            <div>
              <el-popconfirm
                v-if="$can('schedule-update', schedule)"
                confirm-button-text='Да'
                cancel-button-text='нет'
                icon="el-icon-question"
                title="Вы уверены, что хотите удалить раздел?"
                @confirm="deleteBlock(block)"
              >
                <el-button
                  class="el-button--delete"
                  slot="reference"
                  type="text"
                  icon="el-icon-delete"
                  :disabled="!block.id"
                  :loading="loading"
                >
                  Удалить раздел
                </el-button>
              </el-popconfirm>
            </div>
          </el-row>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import PageHeading from "../components/PageHeading";
import ClickToEdit from "../components/ClickToEdit";
import EllipsisMenu from "../components/EllipsisMenu";
import {downloader} from "@/mixins/downloader";
import Schedule from "@/api/schedule";

export default {
  name: 'schedule',
  mixins: [downloader],
  components: {EllipsisMenu, ClickToEdit, PageHeading},
  data() {
    return {
      dialogVisible: false,
      loading: false,
      schedule: {
        id: null,
        name: null,
        project_id: null,
        status: null,
        blocks: [],
      },
    }
  },
  computed: {},
  mounted() {
    this.getSchedule();
  },
  methods: {
    getSchedule() {
      this.loading = true;
      this.$api.schedule.getProjectSchedule({
        project_id: this.$route.params.id,
      })
        .then(data => {
          this.schedule = data.schedule;
        })
        .finally(() => {
          this.loading = false;
        })
    },
    saveName() {
      this.loading = true;
      this.$api.schedule.saveName({
        id: this.schedule.id,
        name: this.schedule.name,
      })
        .then(data => {
          this.$notify.success({
            duration: this.$constants.global.notifyDuration,
            title: 'Сохранено',
            message: 'Элемент успешно сохранен',
          });
        })
        .finally(() => {
          this.loading = false;
        })
    },
    addItem(block) {
      block.items.push({
        id: null,
        schedule_block_id: block.id
      })
    },
    saveItem(item) {
      this.loading = true;
      this.$api.schedule.saveItem(item)
        .then(data => {
          this.$notify.success({
            duration: this.$constants.global.notifyDuration,
            title: 'Сохранено',
            message: 'Элемент успешно сохранен',
          });
          item.id = data.item.id;
        })
        .finally(() => {
          this.loading = false;
        })
    },
    deleteItem(block, item) {
      this.loading = true;
      this.$api.schedule.deleteItem({
        id: item.id
      })
        .then(data => {
          this.$notify.success({
            duration: this.$constants.global.notifyDuration,
            title: 'Удалено',
            message: 'Элемент успешно удален',
          });

          block.items = block.items.filter(i => i.id != item.id);
        })
        .finally(() => {
          this.loading = false;
        })
    },
    addBlock() {
      this.schedule.blocks.push({id: null, items: [], schedule_id: this.schedule.id})
    },
    saveBlock(block) {
      this.loading = true;
      this.$api.schedule.saveBlock(block)
        .then(data => {
          this.$notify.success({
            duration: this.$constants.global.notifyDuration,
            title: 'Сохранено',
            message: 'Элемент успешно сохранен',
          });
          if (!block.id) {
            block.id = data.block.id;
            // block.items = [];
          }
        })
        .finally(() => {
          this.loading = false;
          this.$store.dispatch('updateMenu');
        })
    },
    deleteBlock(block) {
      this.loading = true;
      this.$api.schedule.deleteBlock({
        id: block.id
      })
        .then(data => {
          this.$notify.success({
            duration: this.$constants.global.notifyDuration,
            title: 'Удалено',
            message: 'Элемент успешно удален'
          });

          this.schedule.blocks = this.schedule.blocks.filter(b => b.id != block.id);

        })
        .finally(() => {
          this.loading = false;
          this.$store.dispatch('updateMenu');
        })
    },
    setStatus(status) {
      this.loading = true;
      this.$api.schedule.setStatus({
        schedule_id: this.schedule.id,
        status: status,
      })
        .then(data => {
          this.$notify.success({
            duration: this.$constants.global.notifyDuration,
            title: 'Сохранено',
            message: 'Статус успешно сохранен'
          });
          this.schedule.status = status;
        })
        .finally(() => {
          this.loading = false;
          this.$store.dispatch('updateMenu');
        })
    },

    exportToWord() {
      this.downloadFile(
        'План-график ' + (this.schedule.name ? this.schedule.name : '') + '.docx',
        this.$api.schedule.exportToWord,
        {project_id: this.$route.params.id}
      );
    }
  }
}
</script>

<style lang="scss">
@import "./src/assets/css/project-variables";

.schedule-table {
  border-top: 1px solid $--color-divider;

  &__block {
    margin: 0 0 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__head {
    padding: 24px 0 16px;

    &-title {
      margin: 0 4px 0 0;
      color: $--color-text-regular-4;
    }
  }

  &__row {
    padding: 16px 0;
    border-bottom: 1px solid $--color-divider;

    .edit-block {
      flex: 1;
    }
  }

  &__col {
    padding: 0 16px;
    font-size: 14px;
  }

  &__bottom {
    padding: 16px 0;

    > .el-button {
      margin-right: 24px;
    }
  }

  &__cell {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
  }

  &__heading {
    padding: 16px 0;
    background-color: $--color-divider-3;
    border-radius: 6px;

    .el-checkbox {
      margin-right: 24px;
    }

    .el-checkbox__label {
      font-size: 16px;
    }

    .edit-block {
      min-width: 600px;
    }
  }

  @media only screen and (max-width: 1480px) {

    &__head {
      padding-top: 16px;
      padding-bottom: 12px;
    }

    &__heading {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    &__row {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    &__block {
      margin-bottom: 24px;
    }
  }
}

</style>
