<template>
    <el-select
        style="width:100%"
        v-model="localValue"
        :size="size"
        filterable
        remote
        clearable
        multiple
        reserve-keyword
        placeholder="Выберите сотрудника"
        :remote-method="remoteMethod"
        :loading="loading"
    >
        <el-option-custom
            v-for="item in options"
            :key="item.id"
            :label="item.full_name"
            :value="item.id"
            with-checkbox
        >
        </el-option-custom>
    </el-select>

</template>

<script>


import ElOptionCustom from "@/components/custom/ElOptionCustom";
export default {
    name: "users-select",
  components: {ElOptionCustom},
  props: {
        value: {
            type: Array,
            default: function(){return []}
        },
        defaultUsers: {
            type: Array,
            default: function(){return []}
        },
        size:{
            type: String,
            default: ''
        }
    },

    data() {
        return {
            loading: false,

            localValue: this.value,
            options: this.defaultUsers,
        }
    },
    mounted() {
        this.setDefaultOptions(this.defaultUsers);
        this.remoteMethod()
    },
    watch: {
        localValue: function(newVal, oldVal) {
            this.valueChanged(newVal)
        },
        defaultUsers: function(newVal, oldVal) {
            this.setDefaultOptions(newVal);
            this.remoteMethod()
        },
    },
    methods: {
        remoteMethod(query){
            this.loading = true;
            this.$api.user.search({
                search: query,
                page: 1
            })
                .then(({data}) => {
                    this.options = data.users;
                })
                .finally(()=>{
                    this.loading = false;
                })
        },

        setDefaultOptions(newUsers){
            this.options = newUsers;
            this.localValue = newUsers.length ? newUsers.map(a=>a.id) : [];
            this.$emit('input', this.localValue);
        },

        valueChanged(value){
            this.$emit('input', value);
            this.$emit('selected', value)
        }
    },

}
</script>

<style scoped>

</style>