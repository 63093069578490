import requestSender from "./base/requestSender";

export default function(axiosInstance){
    return {
        getPilotData(payload){
            return requestSender('get', 'pilot/get-project-pilot-data', payload);
        },
        changeStatus(payload){
            return requestSender('post', 'pilot/change-status', payload);
        },
        saveName(payload){
            return requestSender('post', 'pilot/save-name', payload);
        },

    }
}