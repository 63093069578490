<template>
    <el-dialog
        :title=" divisionCopy.id ? 'Редактирование подразделения' : 'Создание подразделения'"
        :visible="show"
        :close-on-click-modal="false"
        width="30%"
        top="2vh"
        @close="$emit('update:show', false)"
    >
        <el-row>
            <el-form
                size=""
                ref="divisionForm"
                label-position="left"
                label-width="180px"
                :model="divisionCopy"
                :rules="rules"
            >
                <el-form-item label="Краткое название" prop="short_name">
                    <el-input maxlength="100" show-word-limit v-model="divisionCopy.short_name"></el-input>
                </el-form-item>
                <el-form-item label="Полное название" prop="name">
                    <el-input maxlength="255" show-word-limit v-model="divisionCopy.name"></el-input>
                </el-form-item>
                <el-form-item label="Деактивировать подразделение">
                  <el-switch
                    v-model="divisionCopy.status"
                    :active-value="1"
                    :inactive-value="0"
                  >
                  </el-switch>
                </el-form-item>

            </el-form>
        </el-row>

        <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="$emit('update:show', false)">Отмена</el-button>
                <el-button
                    type="primary"
                    size="small"
                    :loading="loading"
                    @click="saveDivision"
                > Сохранить
                </el-button>
            </span>
    </el-dialog>
</template>

<script>
import Paginator from "../Paginator";

export default {
    name: "edit-division-modal",
    components: {Paginator},

    props: {
        show: {
            type: Boolean,
            default: false
        },
        division: {
            type: Object,
            default: ()=>{return {}}
        }
    },

    data() {
        return {
            loading: false,
            dialogShow: false,
            rules: {
                name: [
                    {required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}
                ],
            },
            divisionCopy: {
                short_name: null,
                name: null,
                status: 0,
            },
        }
    },
    mounted() { },
    watch: {
        show: function(newVal, oldVal) {
            if( newVal ){
                this.prepareData()
            }
        },
    },
    methods: {
        prepareData(){
            if( this.$refs.divisionForm ){
                this.$refs.divisionForm.resetFields();
            }

            this.divisionCopy.id = this.division.id;
            this.divisionCopy.short_name = this.division.short_name;
            this.divisionCopy.name = this.division.name;
            this.divisionCopy.status = this.division.status;
        },
        saveDivision(){
            this.$refs.divisionForm.validate((result)=>{
                if( !result ){
                    return;
                }

                this.loading = true;

                this.$api.division.save(this.divisionCopy)
                    .then(data => {
                        this.$notify.success({
duration: this.$constants.global.notifyDuration,
                            title: 'Сохранено',
                            message: 'Подразделение успешно сохранено'
                        });
                        this.$emit('saved');
                    })
                    .finally(()=>{
                        this.loading = false;
                        this.$emit('update:show', false)
                    })
            });

        },

    }
}
</script>

<style>

</style>