<template>
  <div
    v-loading="loading"
    style="height: 80vh;"
  >
    <iframe
      width="100%"
      height="98%"
      :src="url"
    ></iframe>
  </div>
</template>

<script>
import PageHeading from "../../components/PageHeading";

export default {
  name: 'TestPreview',
  components: {PageHeading},
  data() {
    return {
      loading: false,
      url: null,
    }
  },
  computed: {},
  watch: {},
  mounted() {
    this.getTest();

  },
  methods: {

    getTest() {
      this.loading = true;
      this.$api.test.preview({
        test_id: this.$route.params.id,
      })
        .then(data => {
          this.url = process.env.VUE_APP_API_URL + data.url;
        })
        .finally(() => {
          this.loading = false;
        })
    },

  }
}

</script>

<style lang="scss" scoped>
@import "./src/assets/css/project-variables";


</style>
