<template>
  <el-container id="app">
    <router-view/>
  </el-container>
</template>

<script>
export default {
  name: "login-layout"
}
</script>

<style scoped>

</style>
