<template>
  <el-select
    style="width:100%"
    :value="value"
    :size="size"
    :multiple="multiple"
    :disabled="disabled"
    filterable
    remote
    clearable
    reserve-keyword
    placeholder="Проекты"
    :remote-method="remoteMethod"

    @change="selected"
    @visible-change="$event ? remoteMethod() : null"
  >
    <div
      v-loading="loading"
      v-infinite-scroll="nextPage"
      :infinite-scroll-immediate="false"
      :infinite-scroll-delay="100"
    >
      <el-option-custom
        v-if="multiple"
        with-checkbox
        key="0"
        value="0"
        label="Без проекта"
      ></el-option-custom>

      <el-option-custom
        v-for="item in options"
        :key="item.id"
        :label="item.name"
        :value="item.id"
        :with-checkbox="multiple"
      >
      </el-option-custom>
    </div>
  </el-select>

</template>

<script>


import ElOptionCustom from "@/components/custom/ElOptionCustom";

export default {
  name: "project-select",
  components: {ElOptionCustom},
  props: {
    value: {},
    size: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },

    filterForReport: {
      type: Boolean,
      default: false
    },
    filterDivisions: {type: Number|Array},
    filterIntroductionYear: {type: Number},
  },

  data() {
    return {
      loading: false,
      query: null,
      page: 1,
      options: [],
    }
  },
  mounted() {
    this.$nextTick(() => {
      if( this.value ){
        this.getData(this.multiple ? this.value : [this.value])
      }
    });
  },
  watch: {},
  computed: {
    formattedFilterDivisions() {
      if( !this.filterDivisions ){
        return null;
      }
      if( this.filterDivisions instanceof Array ){
        return this.filterDivisions.length ? this.filterDivisions : null;
      }
      return [this.filterDivisions];
    }
  },
  methods: {
    remoteMethod(query) {
      this.query = query;
      this.page = 1;
      this.options = [];
      this.getData();
    },

    nextPage(){
      this.page++;
      this.getData();
    },

    getData(preloadIds=null){
      this.loading = true;
      this.$api.project.search({
        search: this.query,
        divisions: this.formattedFilterDivisions,
        introduction_year: this.filterIntroductionYear,
        for_report: this.filterForReport ? 1 : null,
        page: this.page,
        ids: preloadIds,
      })
        .then(({data}) => {
          this.options = this.options.concat(data.projects);
        })
        .finally(() => {
          this.loading = false;
        })
    },

    selected(value) {
      this.$emit('input', value);
      this.$emit('change', value);
    }
  },

}
</script>

<style>

</style>