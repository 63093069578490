<template>
  <el-table
      :data="sections"
      :show-header="false"
  >
    <el-table-column width="120">

    </el-table-column>

    <el-table-column>
      <template slot-scope="scope">
        {{scope.row.name}}
      </template>
    </el-table-column>

    <el-table-column width="150">
      <template slot-scope="scope">
        <span
            :class="getRequiredCountQuestions(scope.row.computed.countSectionsQuestions)"
        >
        {{scope.row.computed.countSectionsQuestions}}
        </span>
      </template>
    </el-table-column>

    <el-table-column width="150">
      <template slot-scope="scope">
        <span
            :class="getAgreedTestSectionQuestions(scope.row)"
        >
        {{scope.row.computed.countAgreedSectionsQuestions}}
        </span>
      </template>
    </el-table-column>
    <el-table-column width="250"></el-table-column>
    <el-table-column width="40"></el-table-column>
  </el-table>
</template>

<script>
export default {
  name: "TestSections",
  props: {
    testId: {
      type: Number,
    },
    sections: {}
  },
  data() {
    return {

    }
  },
  mounted() {
  },
  computed:{

  },
  methods: {
    getRequiredCountQuestions(sectionQuestions) {
      return sectionQuestions >= 15 ? 'all-agreed': 'not-agreed'
    },
    getAgreedTestSectionQuestions(section) {
      return (section.computed.countSectionsQuestions === section.computed.countAgreedSectionsQuestions && +section.computed.countSectionsQuestions !== 0)
          ? 'all-agreed'
          : 'not-agreed';
    }
  }
}
</script>

<style lang="scss">
  .test-sections {
    border: none;
    .el-collapse-item__wrap {
      background: transparent;
      border: none;
    }
    .el-collapse-item__header {
      background: transparent;
    }
  }
</style>
