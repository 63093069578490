<template>
    <el-row>
        <el-dialog
            class="solution"
            center
            width="60%"
            append-to-body
            :close-on-click-modal="false"
            title="Добавить решение"
            :visible.sync="show"
            destroy-on-close
        >
          <el-row
              v-loading="loadingChecklist"
              type="flex"
              justify="space-between"
          >
            <el-col :span="12">
              <el-col :span="20">
                <h4>Выберите решение</h4>
                <el-select
                    v-model="currentSolutionChecklists.id"
                    style="width: 100%"
                    size="small"
                    placeholder="Выберите решение"
                    @change="setCurrentSolutionData"
                >
                  <el-option
                      v-for="(solutionName, solutionCode) in $constants.testsDevelopment.checklistResults"
                      :key="solutionCode"
                      :label="solutionName"
                      :value="parseInt(solutionCode)">
                  </el-option>
                </el-select>
                <el-input
                    v-show="showOtherSolution"
                    style="margin-top: 5px"
                    placeholder="Опишите здесь"
                    v-model="currentSolutionChecklists.comment"
                    type="textarea"
                    autosize
                    size="small"
                    @change="setCurrentSolutionData"
                ></el-input>
                <el-button
                    style="margin-top: 10px;"
                    size="mini"
                    type="primary"
                    :disabled="!this.currentSolutionChecklists.comment"
                    @click="addSolution"
                >
                  Добавить решение
                </el-button>
              </el-col>
            </el-col>
            <el-col :span="12">
              <el-col :span="20">
                <h4>Выбранные решения</h4>
                <el-row>
                  <el-row
                      v-for="solution in solutions"
                      :key="solution.id"
                      style="margin-bottom: 10px;"
                  >
                    <el-tag
                        :key="solution.id"
                        closable
                        type="info"
                        @close="deleteSolution(solution)"
                    >
                      {{solution.solution}}
                    </el-tag>
                  </el-row>

                </el-row>
              </el-col>
            </el-col>
          </el-row>

        </el-dialog>

        <span ref="wrapper">
            <slot name="reference"></slot>
        </span>
    </el-row>
</template>

<script>
import {off, on} from "element-ui/src/utils/dom";


export default {
  name: "solution-dialog",
  props: {
    questionId: {},
    solutions: {},
  },

  data() {
    return {
      loading: false,
      show: false,
      solution: null,
      showOtherSolution: false,

      loadingChecklist: false,
      currentSolutionChecklists: {
        id: null,
        comment: null,
      },
    }
  },
  mounted() {
    if (this.$refs.wrapper.children) {
      on(this.$refs.wrapper.children[0], 'click', this.showModal);
    }
  },
  watch: {},
  methods: {
    updateSolutions() {
      this.$emit('update-solutions')
    },
    setCurrentSolutionData() {
      if(this.currentSolutionChecklists.id === 8) {
        this.showOtherSolution = true;
      }else {
        this.currentSolutionChecklists.comment = this.$constants.testsDevelopment.checklistResults[this.currentSolutionChecklists.id]
      }
    },
    addSolution() {

      this.loadingChecklist = true;

      this.$api.report.addSolution({
        question_id: this.questionId,
        solution: this.currentSolutionChecklists.comment

      })
          .then((data) => {
            this.updateSolutions();
            this.$notify.success({
duration: this.$constants.global.notifyDuration,
              title: 'Сохранено',
              message: 'Решение успешно сохранено'
            });
          })
          .finally(() => {
            this.loadingChecklist = false;
            this.currentSolutionChecklists.id = null;
            this.currentSolutionChecklists.comment = null;
            this.showOtherSolution = false;
          })
    },
    deleteSolution(solution) {
      this.loadingChecklist = true;
      this.$api.report.deleteSolution({
        id: solution.id
      })
          .then(data => {
            this.updateSolutions();
            this.$notify.success({
              title: 'Удалено',
              message: 'Решение успешно удалено'
            });
          })
          .finally(() => {
            this.loadingChecklist = false;
          })
    },
    showModal() {
      this.updateSolutions();
      this.show = true;
    },

  },
  beforeDestroy() {
    if (this.$refs.wrapper.children) {
      off(this.$refs.wrapper.children[0], 'click', this.showModal);
    }
  }
}
</script>

<style lang="scss">
.solution {
  .el-dialog__body {
    min-height: 150px;
    max-height: 300px;
    overflow-y: auto;
  }
}

</style>