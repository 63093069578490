<template>
    <span>
        <el-dialog
            append-to-body
            title="Ответственные за компетенцию"
            :visible.sync="show"
            :close-on-click-modal="false"
            width="60%"
            destroy-on-close
        >
            <el-row>
                <div class="hint-div">
                    Если Вы не нашли пользователя в списке, обратитесь к секретарю рабочей группы. Возможно, такого пользователя еще нет в системе
                </div>
            </el-row>
            <el-row v-loading="loading">
                <el-form
                    label-position="left"
                    label-width="140px"
                >
                    <el-form-item label="Ответственные">
                        <users-table
                            :add-button="$can('profiling-set-competence-user', competence)"
                            add-button-text="Добавить ответственных"
                            :delete-button="$can('profiling-set-competence-user', competence)"
                            :users="competence.profiling_users"
                            :add-users-filter-role="['admin', 'expert']"
                            :add-users-filter-projects="[parseInt($route.params.id)]"
                            @new-users="addNewSharedUsers"
                            @user-deleted="deleteUser"
                        ></users-table>
                    </el-form-item>

                </el-form>
            </el-row>



            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="show = false">Закрыть</el-button>
            </span>
        </el-dialog>

        <span ref="wrapper">
            <slot name="reference"></slot>
        </span>
    </span>
</template>

<script>
import {off, on} from "element-ui/src/utils/dom";
import UsersTable from "../user/UsersTable";


export default {
    name: "competence-shared-users-dialog",
    components: {UsersTable},

    props: {
        competenceId: {
            type: Number
        }
    },

    data() {
        return {
            loading: false,
            show: false,

            competence: {
                profiling_users: []
            }
        }
    },
    mounted() {
        if (this.$refs.wrapper.children) {
            on(this.$refs.wrapper.children[0], 'click', this.showModal);
        }
    },
    watch: { },
    methods: {
        showModal() {
            this.show = true;
            this.getCompetenceSharedUsers();
        },

        getCompetenceSharedUsers(){
            this.loading = true;
            this.$api.profiling.getCompetenceSharedUsers({
                competence_id: this.competenceId
            })
                .then(data => {
                    this.competence = data.competence;
                })
                .finally(()=>{
                    this.loading = false;
                })
        },

        addNewSharedUsers(users){
            let ids = users.map(user=>user.id);
            this.loading = true;
            this.$api.profiling.addNewCompetenceSharedUsers({
                competence_id: this.competenceId,
                user_ids: ids
            })
                .then(data => {
                    this.$notify.success({
duration: this.$constants.global.notifyDuration,
                        title: 'Сохранено',
                        message: 'Ответственные успешно добавлены'
                    });
                })
                .finally(()=>{
                    this.getCompetenceSharedUsers();
                })
        },
        deleteUser(user){
            this.loading = true;
            this.$api.profiling.deleteCompetenceSharedUser({
                competence_id: this.competenceId,
                user_id: user.id
            })
                .then(data => {
                    this.$notify.success({
duration: this.$constants.global.notifyDuration,
                        title: 'Удалено',
                        message: 'Ответственный успешно удален'
                    });
                })
                .finally(()=>{
                    this.getCompetenceSharedUsers();
                })
        },
    },
    beforeDestroy() {
        if (this.$refs.wrapper.children) {
            off(this.$refs.wrapper.children[0], 'click', this.showModal);
        }
    }
}
</script>

<style>

</style>