<template>
  <el-table
      class="table-question-answer"
      :data="answers"
      style="width: 100%;"
      :row-class-name="tableRowClassName"
  >
    <el-table-column
        label="Ответ"
        class-name="el-table__cell--bottom"
        width="370"

    >
      <template slot-scope="scope">
        <div :class="{ 'is-right': scope.row.is_right }">{{ scope.row.answer }}</div>
      </template>
    </el-table-column>
    <el-table-column
        label="Ответило"
        class-name="el-table__cell--bottom"
        width="280"

    >
      <template slot-scope="scope">
        <div>{{ scope.row.computed.count_users_answered }}</div>
      </template>
    </el-table-column>
    <el-table-column
        label="%"
        class-name="el-table__cell--bottom"
        width="50"
    >
      <template slot-scope="scope">
        <div>{{ scope.row.computed.count_users_answered_percent }}</div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: "TestQuestionAnswers",
  props: {
    answers: {}
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      if (row.is_right === true) {
        return 'is-right'
      }
      return '';
    }
  }
}
</script>

<style lang="scss">
@import "./src/assets/css/project-variables";

.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

.table-question-answer {

  td:first-child,
  th:first-child {
    padding-left: 24px;
  }

  .el-table__header-wrapper,
  .el-table__body-wrapper {
    background-color: $--color-divider-3;
  }

  th.el-table__cell {
    background-color: $--color-divider-3;
    border: none;
  }

  .el-table__row {
    pointer-events: none;

    td {
      padding: 0;

      .cell {
        padding: 0;

        div {
          padding: 12px;
        }
      }

      &:first-child {
        padding-left: 24px;
      }
    }

    &.is-right {

      td {
        background: #dfffdf;
      }
    }

    &:last-child {

      td {
        border-bottom: none;
      }
    }
  }
}
</style>
