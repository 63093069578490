<template>
  <div class="page__head">
    <el-row type="flex" class="row-bg" justify="space-between" align="middle">
      <el-button
          v-if="back"
          class="back-button"
          size="small"
          icon="fas fa-chevron-left"
          @click="$router.go(-1)"
      >
        Назад
      </el-button>

      <el-skeleton
          class="page__head-left"
          :loading="!heading"
          :rows="1"
          animated
      >
        <template slot="template">
            <el-skeleton-item variant="text" style="height: 25px; width:400px"/>
        </template>

        <h1 class="page__head-title">
          {{ heading }}

<!--          <div class="edit">-->
<!--            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--              <path d="M12 1.5L2.5 11C4.1 11.4 4.83333 12.8333 5 13.5L14.5 4L14 2L12 1.5Z" fill="#A3A6A7"/>-->
<!--              <path-->
<!--                  d="M11.3333 2.00004C11.5083 1.82494 11.7162 1.68605 11.945 1.59129C12.1738 1.49653 12.419 1.44775 12.6666 1.44775C12.9142 1.44775 13.1594 1.49653 13.3882 1.59129C13.617 1.68605 13.8248 1.82494 13.9999 2.00004C14.175 2.17513 14.3139 2.383 14.4087 2.61178C14.5034 2.84055 14.5522 3.08575 14.5522 3.33337C14.5522 3.58099 14.5034 3.82619 14.4087 4.05497C14.3139 4.28374 14.175 4.49161 13.9999 4.66671L4.99992 13.6667L1.33325 14.6667L2.33325 11L11.3333 2.00004Z"-->
<!--                  stroke="#A3A6A7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--            </svg>-->
<!--          </div>-->
        </h1>
      </el-skeleton>

      <el-dialog
          title="Подсказка"
          :visible.sync="showHelp"
          :close-on-click-modal="false"
          width="30%"
      >
        <slot name="help"></slot>
      </el-dialog>

      <slot name="right"></slot>
    </el-row>
  </div>
</template>

<script>


export default {
  name: "page-heading",
  props: {
    heading: {
      type: String,
    },
    help: {
      type: Boolean,
      default: false
    },
    back: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showHelp: false
    }
  },
  mounted() {
  },
  watch: {},
  methods: {},
}
</script>

<style lang="scss">
@import "./src/assets/css/project-variables";

.back-button {
  vertical-align: top;
  margin-right: 15px;
  color: #616161;
  background-color: #F2F2F2;
  border: none
}

.page {

  &__head {
    margin-bottom: 24px;

    &-title {
      display: flex;
      align-items: center;
      margin-bottom: 0;
    }

    .edit {
      width: 24px;
      height: 24px;
      margin-left: 8px;
      cursor: pointer;

      svg {
        display: block;
        width: 100%;
        height: auto;

        path {
          transition: all .4s;
        }
      }

      &:hover {

        svg {

          path:first-of-type {
            fill: $--color-primary;
          }

          path:last-of-type {
            stroke: $--color-primary;
          }
        }
      }
    }

    .edit-block {
      width: 100%;
      min-width: 900px;
    }
  }

  @media only screen and (max-width: 1480px) {

    &__head {
      margin-bottom: 16px;

      &-title {
        font-size: 20px;
      }
    }
  }
}
</style>
