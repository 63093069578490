<template>
  <div>
    <el-upload
        class="upload-demo"
        grab
        ref="upload"
        action=""
        :on-exceed="handleExceed"
        :on-remove="handleDelete"
        :file-list="filesList[0] ? filesList : []"
        :limit="1"
        :http-request="doUpload"
        list-type="picture-card"
    >
      <div slot="file" slot-scope="{file}" class="image-container">
        <img
            class="el-upload-list__item-thumbnail"
            :src="file.url" :alt="file.name"
        >
        <span class="el-upload-list__item-actions image-container__view">
          <span
              class="el-upload-list__item-preview"
              @click="handlePictureCardPreview(file)"
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 3H21V9" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M9 21H3V15" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M21 3L14 10" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M3 21L10 14" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </span>
        </span>

        <span class="image-container__name">{{ file.original_name }}</span>

        <span
          v-if="!disabled"
          class="image-container__delete"
          @click="handleDelete"
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 6H5H21" stroke="#A3A6A7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.00098 6.00098V4.00098C8.00098 3.47054 8.21169 2.96184 8.58676 2.58676C8.96184 2.21169 9.47054 2.00098 10.001 2.00098H14.001C14.5314 2.00098 15.0401 2.21169 15.4152 2.58676C15.7903 2.96184 16.001 3.47054 16.001 4.00098V6.00098M19.001 6.00098V20.001C19.001 20.5314 18.7903 21.0401 18.4152 21.4152C18.0401 21.7903 17.5314 22.001 17.001 22.001H7.00098C6.47054 22.001 5.96184 21.7903 5.58676 21.4152C5.21169 21.0401 5.00098 20.5314 5.00098 20.001V6.00098H19.001Z" stroke="#A3A6A7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10 11V17" stroke="#A3A6A7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14 11V17" stroke="#A3A6A7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </span>
      </div>
      <el-button
          v-if="!disabled && filesList[0] === null"
          size="small"
          type="text"
          icon="el-icon-document-add"
      >
        Добавить изображение
      </el-button>
    </el-upload>

    <el-dialog
        :visible.sync="dialogVisible"
        custom-class="image-preview"
        width="fit-content"
    >
      <div class="image-preview__img">
        <img :src="dialogImageUrl" class="img-responsive" alt="">
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "image-uploader",
  props: {
    value: {},
    method: {
      required: true,
      type: Function,
    },
    filesList: {
      type: Array,
    },
    disabled: {
      type:Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
    }
  },
  mounted() {
  },
  watch: {},
  methods: {
    handleExceed() {
      this.$notify.error({
        title: 'Ошибка',
        message: 'Сначала удалите изображение'
      });
    },
    handleDelete() {
      this.$emit('input', null);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    doUpload(params) {
      let file = params.file,
          fileType = file.type,
          isImage = fileType.indexOf('image') !== -1;

      let isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$notify.error({
          title: 'Ошибка',
          message: 'Размер файла не должен превышать 2 MB'
        });
        this.$refs.upload.uploadFiles = [];
        return;
      }

      if (!isImage) {
        this.$notify.error({
          title: 'Ошибка',
          message: 'Пожалуйста, выберите картинку!'
        });
        this.$refs.upload.uploadFiles = [];
        return;
      }
      let formData = new FormData();
      formData.append('file', file);

      this.$refs.upload.uploadFiles[0].status = 'uploading';

      this.method(
          formData,
          {
            headers: {'Content-Type': 'multipart/form-data'},
            onUploadProgress: this.onProgress.bind(this)
          })
          .then((data) => {
            this.$refs.upload.uploadFiles[0].id = data.id;
            this.$refs.upload.uploadFiles[0].original_name = data.original_name;

            this.$emit('input', this.$refs.upload.uploadFiles[0]);
          })
          .catch((err) => {
            this.$refs.upload.uploadFiles = [];
          });

    },
    onProgress(progressEvent) {
      this.$refs.upload.uploadFiles[0].status = 'uploading';
      this.$refs.upload.uploadFiles[0].percentage = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));

      if (this.$refs.upload.uploadFiles[0].percentage === 100) {
        this.$refs.upload.uploadFiles[0].status = 'success';
      }
    },
  },
}
</script>

<style lang="scss">
@import "./src/assets/css/project-variables";

.image-container {
  display: flex;
  align-items: center;

  &__name {
    margin-right: 12px;
    color: $--color-text-regular-4;
  }

  &__delete {
    width: 24px;
    height: 24px;
    cursor: pointer;

    svg {

      path {
        transition: stroke .4s;
      }
    }

    &:hover {

      svg {

        path {
          stroke: $--color-danger;
        }
      }
    }
  }
}

.el-upload-list--picture-card  {

  .el-upload-list__item {
    width: auto;
  }

  .el-upload-list__item-actions {
    top: 50%;
    left: 2px;
    width: 78px;
    height: 44px;
    border-radius: 4px;
    transform: translateY(-50%);

    .el-upload-list__item-preview {
      width: 100%;
      height: 100%;
    }

    &:hover {

      .el-upload-list__item-preview {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.image-preview {
  max-height: 740px;
  padding: 40px;
  box-shadow: 1px 0 30px 7px rgba(20, 33, 64, 0.02);
  border-radius: 10px;

  .el-dialog__header,
  .el-dialog__body {
    padding: 0;
  }

  .el-dialog__body {
    width: 100%;
    height: 100%;
  }

  &__img {
    width: fit-content;
    height: 480px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
}

</style>
