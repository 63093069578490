<template>
  <el-select
      :value="value"
      :size="size"
      clearable
      placeholder="Выберите из списка"
      @change="valueChanged"
  >
    <!--        <el-option label="Some status" :value="$constants.unit.STATUS_TARGET_SELF"></el-option>-->
    <el-option label="Все пользователи" value=""></el-option>
    <el-option label="Активные пользователи" :value="1"></el-option>
    <el-option label="Неактивные пользователи" :value="0"></el-option>
  </el-select>
</template>

<script>


export default {
  name: "user-status-select",
  props: {
    value: {},
    size: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // value: 1,
    }
  },
  mounted() {
  },
  watch: {},
  methods: {
    valueChanged(value) {
      this.$emit('input', value);
      this.$emit('change', value);
    }
  },

}
</script>

<style scoped>

</style>