<template>
  <div>
    <el-dialog
        append-to-body
        title="Ответственные за область деятельности"
        :close-on-click-modal="false"
        :visible.sync="show"
        width="60%"
        destroy-on-close
        custom-class="responsible-dialog"
    >
      <div class="responsible-dialog__desc">
        Если Вы не нашли пользователя в списке, обратитесь к секретарю рабочей группы. Возможно, такого пользователя
        еще нет в системе
      </div>

      <el-row v-loading="loading">
        <el-form
            class="responsible-dialog__form"
            label-position="left"
            label-width="140px"
        >
          <el-form-item label="Автор записи:">
            {{ area.user ? area.user.full_name : '' }}
          </el-form-item>
          <el-form-item label="Ответственные:"></el-form-item>
          <users-table
              :add-button="$can('fc-set-area-user', area)"
              add-button-text="Добавить ответственных"
              :delete-button="$can('fc-set-area-user', area)"
              :add-users-filter-role="['admin', 'expert']"
              :add-users-filter-projects="[parseInt($route.params.id)]"
              :users="area.users"
              @new-users="addNewSharedUsers"
              @user-deleted="deleteUser"
          ></users-table>
        </el-form>
      </el-row>


      <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="show = false">Закрыть</el-button>
            </span>
    </el-dialog>

    <span ref="wrapper">
            <slot name="reference"></slot>
        </span>
  </div>
</template>

<script>
import {off, on} from "element-ui/src/utils/dom";
import UsersTable from "../user/UsersTable";


export default {
  name: "fc-shared-users-dialog",
  components: {UsersTable},

  props: {
    areaId: {
      type: Number
    }
  },

  data() {
    return {
      loading: false,
      show: false,

      area: {
        user: {},
        users: []
      }
    }
  },
  mounted() {
    if (this.$refs.wrapper.children) {
      on(this.$refs.wrapper.children[0], 'click', this.showModal);
    }
  },
  watch: {},
  methods: {
    showModal() {
      this.show = true;
      this.getAreaSharedUsers();
    },

    getAreaSharedUsers() {
      this.loading = true;
      this.$api.fc.getAreaSharedUsers({
        area_id: this.areaId
      })
          .then(data => {
            this.area = data.area;
          })
          .finally(() => {
            this.loading = false;
          })
    },

    addNewSharedUsers(users) {
      let ids = users.map(user => user.id);
      this.loading = true;
      this.$api.fc.addNewAreaSharedUsers({
        area_id: this.areaId,
        user_ids: ids
      })
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDuration,
              title: 'Сохранено',
              message: 'Ответственные успешно добавлены'
            });
          })
          .finally(() => {
            this.getAreaSharedUsers();
          })
    },
    deleteUser(user) {
      this.loading = true;
      this.$api.fc.deleteAreaSharedUser({
        area_id: this.areaId,
        user_id: user.id
      })
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDuration,
              title: 'Удалено',
              message: 'Ответственный успешно удален'
            });
          })
          .finally(() => {
            this.getAreaSharedUsers();
          })
    },


  },
  beforeDestroy() {
    if (this.$refs.wrapper.children) {
      off(this.$refs.wrapper.children[0], 'click', this.showModal);
    }
  }
}
</script>

<style>

</style>