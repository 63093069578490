import requestSender from "./base/requestSender";

export default function(axiosInstance){
    return {
        getMyTests(payload){
            return requestSender('get', 'test-player/get-my-tests', payload);
        },
        getTestToPlay(payload){
            return requestSender('get', 'test-player/get-test', payload);
        },
        getQuestion(payload){
            return requestSender('get', 'test-player/get-question', payload);
        },
        getNextQuestion(payload){
            return requestSender('get', 'test-player/get-next-question', payload);
        },

        setAnswer(payload){
            return requestSender('post', 'test-player/set-answer', payload);
        },


        getQuestionChecklist(payload){
            return requestSender('get', 'test-player/get-question-checklist', payload);
        },
        saveChecklistElement(payload){
            return requestSender('post', 'test-player/save-checklist-element', payload);
        },
        deleteChecklistElement(payload){
            return requestSender('post', 'test-player/delete-checklist-element', payload);
        },

        saveAssignmentComment(payload){
            return requestSender('post', 'test-player/save-assignment-comment', payload);
        },



        search(payload){
            return requestSender('get', 'test/search', payload);
        },getTestsDevelopment(payload){
            return requestSender('get', 'test/get-tests-development', payload);
        },
        saveTestsDevelopmentName(payload){
            return requestSender('post', 'test/save-tests-development-name', payload);
        },
        downloadScorm(payload, options){
            return requestSender('post', 'test/download-scorm', payload, options);
        },
        preview(payload){
            return requestSender('post', 'test/preview', payload);
        },



        getTestSharedUsers(payload){
            return requestSender('get', 'test/get-test-shared-users', payload);
        },
        addNewTestSharedUsers(payload){
            return requestSender('post', 'test/add-new-test-shared-users', payload);
        },
        deleteTestSharedUser(payload){
            return requestSender('post', 'test/delete-test-shared-user', payload);
        },
        setTestMainSharedUser(payload){
            return requestSender('post', 'test/set-test-main-shared-user', payload);
        },





        getTestToEdit(payload){
            return requestSender('get', 'test-editor/get-test', payload);
        },
        getQuestionToEdit(payload){
            return requestSender('get', 'test-editor/get-question', payload);
        },
        uploadImage(payload, options){
            return requestSender('post', 'test-editor/upload-image', payload, options);
        },
        saveQuestion(payload){
            return requestSender('post', 'test-editor/save-question', payload);
        },
        deleteQuestion(payload){
            return requestSender('post', 'test-editor/delete-question', payload);
        },
        getDeletedQuestions(payload){
            return requestSender('get', 'test-editor/get-deleted-questions', payload);
        },
        restoreQuestion(payload){
            return requestSender('post', 'test-editor/restore-question', payload);
        },
        moveQuestion(payload){
            return requestSender('post', 'test-editor/move-question', payload);
        },
        getQuestionEditHistory(payload){
            return requestSender('get', 'test-editor/get-question-edit-history', payload);
        },
        setQuestionAgreeStatus(payload){
            return requestSender('post', 'test-editor/set-question-agree-status', payload);
        },
        getQuestionAgreeHistory(payload){
            return requestSender('get', 'test-editor/get-question-agree-history', payload);
        },
        setQuestionAuthor(payload){
            return requestSender('post', 'test-editor/set-question-author', payload);
        },



        
        
    }
}