<template>
  <div>
    <el-table
      :data="pilotUsers"
      size="mini"
      style="width: 100%"
      v-loading="loading"
      @sort-change="sortChanged($event, getProjectPilotUsers)"
    >
      <el-table-column prop="full_name" label="ФИО" :sortable="setIsSortable('full_name_asdfas')">
        <template slot-scope="scope">
          {{ scope.row.user ? scope.row.user.full_name : '' }}
        </template>=
      </el-table-column>
      <el-table-column prop="user.division" label="Подразделение"></el-table-column>
      <el-table-column prop="user.position" label="Должность"></el-table-column>
      <el-table-column prop="user.polygon_zhd" label="Полигон ж.д."></el-table-column>
      <el-table-column prop="tsd.tp.objectname" label="Типовое подразделение"></el-table-column>
      <el-table-column prop="tsd.objectname" label="Типовая ШД">
        <template slot-scope="scope">
          {{ scope.row.tsd ? scope.row.tsd.objectname : '' }}
          <el-button
            v-if="$can('project-set-tsd', project.permissions)"
            size="mini"
            type="text"
            icon="far fa-hand-pointer"
            @click="openTsdSelectModal(scope.row.user)"
          ></el-button>
        </template>
      </el-table-column>
      <el-table-column prop="user.role.description" label="Роль"></el-table-column>
      <el-table-column
        v-if="$can('project-delete-from-workgroup', project.permissions)"
        width="60"
        align="right"
      >
        <template slot-scope="scope">
          <ellipsis-menu>
            <el-button
              v-if="$can('users-set-pilot-password')"
              type="text"
              size="mini"
              icon="fas fa-key"
              @click="openSetPasswordModal(scope.row)"
            >
              Задать пароль
            </el-button>
            <el-popconfirm
              confirm-button-text='Да'
              cancel-button-text='нет'
              icon="el-icon-question"
              title="Вы уверены, что хотите удалить пользователя?"
              @confirm="deleteUser(scope.row.user)"
            >
              <el-button
                slot="reference"
                type="text"
                size="mini"
                icon="fas fa-trash"
              >Удалить
              </el-button>
            </el-popconfirm>
          </ellipsis-menu>
        </template>
      </el-table-column>

    </el-table>
    <paginator
      :paginator.sync="paginator"
      @page-changed="getProjectPilotUsers"
    ></paginator>

    <el-dialog
      title="Выбор типовой ШД"
      :visible.sync="tsdSelectModal.show"
      append-to-body
    >
      Выберите подразделение
      <tsd-tp-name-select
        :project-id="project.id"
        style="margin-bottom: 20px"
        size="mini"
        v-model="tsdSelectModal.tsdTp"
      ></tsd-tp-name-select>

      Выберите типовую ШД
      <tsd-select
        :disabled="!tsdSelectModal.tsdTp"
        v-model="tsdSelectModal.tsd"
        :tp_filter="tsdSelectModal.tsdTp ? tsdSelectModal.tsdTp : null"
        size="mini"
      ></tsd-select>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="tsdSelectModal.show = false;">Закрыть</el-button>
        <el-button type="primary" :disabled="!tsdSelectModal.tsd" size="small" @click="setUserTsdObjectid">Сохранить</el-button>
      </span>
    </el-dialog>


    <el-dialog
      title="Задание нового пароля"
      :visible.sync="setPasswordModal.show"
      append-to-body
    >
      <el-row>
        <el-form
          size=""
          ref="passForm"
          :model="setPasswordModal"
          :rules="setPasswordModal.rules"
        >
          <el-form-item label="Новый пароль" prop="newPassword">
            <el-tooltip placement="bottom-start" popper-class="password-info" effect="light">
              <div slot="content">
                <p>
                  Пароль должен содержать не менее 8 <br>
                  символов и включать:
                </p>
                <ul>
                  <li>Строчные латинские буквы: abcd...xyz;</li>
                  <li>Прописные латинские буквы: ABCD...XYZ;</li>
                  <li>Цифры: 123...90;</li>
                  <li>Специальные символы: !%()_+ и т.д.</li>
                </ul>
              </div>
              <svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z" stroke="#767a7a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.1201 11.9998C12.4336 11.1087 13.0523 10.3573 13.8667 9.87863C14.6811 9.39999 15.6386 9.22503 16.5697 9.38473C17.5007 9.54443 18.3452 10.0285 18.9536 10.7512C19.5619 11.4738 19.8949 12.3885 19.8934 13.3331C19.8934 15.9998 15.8935 17.3331 15.8935 17.3331" stroke="#767a7a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16 22.667H16.0129" stroke="#767a7a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </el-tooltip>
            <el-input type="password" v-model="setPasswordModal.newPassword"></el-input>
          </el-form-item>

        </el-form>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="setPasswordModal.show = false;">Закрыть</el-button>
        <el-button type="primary" size="small" @click="setUserPassword">Сохранить</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {off, on} from "element-ui/src/utils/dom";
import AddUsersDialog from "../user/AddUsersDialog";
import {sorter} from "@/mixins/sorter";
import TsdSelect from "@/components/selects/TsdSelect";
import {paginator} from "@/mixins/paginator";
import Paginator from "../Paginator";
import ProjectWorkgroupUsersTable from "@/components/project/ProjectWorkgroupUsersTable";
import EllipsisMenu from "@/components/EllipsisMenu";
import TsdTpNameSelect from "@/components/selects/TsdTpNameSelect.vue";


export default {
  name: "project-pilot-users-table",
  mixins: [sorter, paginator],
  components: {TsdTpNameSelect, EllipsisMenu, ProjectWorkgroupUsersTable, TsdSelect, AddUsersDialog, Paginator},

  props: {
    project: {
      type: Object
    },
  },

  data() {
    const validateNewPass = (rule, value, callback) => {
      let successCount = 0;
      [/^(?=.*\d)/, /^(?=.*[a-z])/, /^(?=.*[A-Z])/, /^(?=.*[$&+,:;=?@#|'<>.^*()%!-])/].forEach(rule => {
        if( value.match(rule) ) {
          successCount++;
          if( successCount >= 3 ){
            callback();
          }
        }
      });
      callback(new Error('Пароль не соответствует политике безопасности'));
    };

    return {
      loading: false,
      show: false,

      pilotUsers: [],

      tsdSelectModal: {
        show: false,
        user: {},
        tsdTp: null,
        tsd: null,
      },
      setPasswordModal: {
        show: false,
        user: {},
        newPassword: null,
        rules: {
          newPassword: [
            {required: true, message: 'Поле обязательно для заполнения', trigger: 'change'},
            { min: 8, message: 'Длина пароля должна быть не менее 8 символов', trigger: 'change' },
            { validator: validateNewPass, trigger: 'change' }
          ],
        },
      },
    }
  },
  mounted() {
    // this.getProjectPilotUsers();
  },
  watch: {
    project(newVal) {
      if(newVal && newVal.id){
        this.getProjectPilotUsers();
      }
    }
  },
  methods: {
    getProjectPilotUsers() {
      this.loading = true;
      this.$api.project.getPilotUsers({
        id: this.project.id,

        [this.sorter.param]: this.sorter.sort,
        [this.paginator.param]: this.paginator.page,
        [this.paginator.paramPerPage]: this.paginator.perPage,

        expand: 'user, tsd, tsd.tp',
      })
        .then(({data, paginator, sorter}) => {
          this.pilotUsers = data.pilot_users
          this.sorterFill(sorter);
          this.paginatorFill(paginator);
        })
        .finally(() => {
          this.loading = false;
        })
    },

    deleteUser(user) {
      this.loading = true;
      this.$api.project.deleteUser({
        project_id: this.project.id,
        user_id: user.id,
      })
        .then(data => {
          this.$notify.success({
            duration: this.$constants.global.notifyDuration,
            title: 'Удалено',
            message: 'Пользователи успешно удален'
          });
        })
        .finally(() => {
          this.getProjectPilotUsers();
        })
    },

    openTsdSelectModal(user){
      this.tsdSelectModal.user = user;
      this.tsdSelectModal.show = true;
    },

    setUserTsdObjectid() {
      this.loading = true;
      this.$api.project.setUserTsdObjectid({
        project_id: this.project.id,
        user_id: this.tsdSelectModal.user.id,
        tsd_objectid: this.tsdSelectModal.tsd,
      })
        .then(data => {
          this.$notify.success({
            duration: this.$constants.global.notifyDuration,
            title: 'Сохранено',
            message: 'Пользователь успешно сохранен'
          });
          this.$emit('update:mainUserId', this.tsdSelectModal.user.id)
          this.tsdSelectModal.show = false;
          this.getProjectPilotUsers();
        })
        .finally(() => {
          this.loading = false;
        })
    },

    openSetPasswordModal(row){
      this.setPasswordModal.user = row.user;
      this.setPasswordModal.show = true;
      this.setPasswordModal.newPassword = null;
      if( this.$refs.passForm ){
        this.$refs.passForm.resetFields();
      }
    },
    setUserPassword() {
      this.$refs.passForm.validate((result) => {
        if (!result) {
          return;
        }
        this.loading = true;
        this.$api.user.savePilotNewPassword({
          user_id: this.setPasswordModal.user.id,
          password: this.setPasswordModal.newPassword,
        })
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDuration,
              title: 'Сохранено',
              message: 'Пароль успешно сохранен'
            });
            this.setPasswordModal.show = false;
          })
          .finally(() => {
            this.loading = false;
          })

      });
    },


  },
}
</script>

<style>

</style>