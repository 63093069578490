<template>
    <el-select
        :value="value"
        :size="size"
        :multiple="multiple"
        :disabled="disabled"
        clearable
        placeholder="Выберите cтатус внедрения СПК"
        @change="selected"
    >
        <el-option
            :label="$constants.division.spk.statusesNames[$constants.division.spk.statuses.IMPLEMENTED]"
            :value="$constants.division.spk.statuses.IMPLEMENTED"
        >
        </el-option>
        <el-option
            :label="$constants.division.spk.statusesNames[$constants.division.spk.statuses.NOT_IMPLEMENTED]"
            :value="$constants.division.spk.statuses.NOT_IMPLEMENTED"
        >
        </el-option>
    </el-select>

</template>

<script>


export default {
    name: "division-spk-status-select",

    props: {
      value: {},
      size: {
        type: String,
        default: 'mini',
      },
      multiple: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      }
    },

    data() {
        return {

        }
    },
    mounted() {  },
    watch: { },
    methods: {
      selected(value){
        this.$emit('input', value);
        this.$emit('change', value);
      },
    },

}
</script>

<style scoped>

</style>
