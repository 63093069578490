import requestSender from "./base/requestSender";

export default function(axiosInstance){
    return {
        getFoldersByParent(payload){
            return requestSender('get', 'library/get-folders-by-parent', payload);
        },
        saveFolder(payload){
            return requestSender('post', 'library/save-folder', payload);
        },
        deleteFolder(payload){
            return requestSender('post', 'library/delete-folder', payload);
        },


        getFilesByFolder(payload){
            return requestSender('get', 'library/get-files-by-folder', payload);
        },
        deleteFile(payload){
            return requestSender('post', 'library/delete-file', payload);
        },


        uploadFile(payload, options){
            return requestSender('post', 'library/upload-file', payload, options);
        },
        downloadFile(payload, options){
            return requestSender('post', 'library/download-file', payload, options);
        },
    }
}