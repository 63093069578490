<template>
  <el-container id="app">
    <el-header class="header" height="auto">
      <el-row type="flex" justify="space-between" align="middle">
        <router-link
          class="header__logo"
          :to="{ name: 'Projects'}"
        >
          <img src="@/assets/img/logo.png" alt="logo" class="img-responsive">
        </router-link>
<!--        <a href="/" class="header__logo">-->
<!--          <img src="@/assets/img/logo.png" alt="logo" class="img-responsive">-->
<!--        </a>-->

        <el-menu
            class="header__menu"
            mode="horizontal"
            router
            :default-active="$route.path"
            menu-trigger="click"
        >
          <el-menu-item v-if="$can('menu-see-rbac')" class="header__menu-item" index="/rbac" :route="{name: 'Rbac'}">RBAC</el-menu-item>
          <el-menu-item v-if="$can('menu-see-divisions')" class="header__menu-item" index="/divisions" :route="{name: 'Divisions'}">Подразделения</el-menu-item>
          <el-menu-item v-if="$can('menu-see-users')" class="header__menu-item" index="/users" :route="{name: 'Users'}">Пользователи</el-menu-item>
          <el-menu-item v-if="$can('menu-see-projects')" class="header__menu-item" index="/projects" :route="{name: 'Projects'}">Проекты</el-menu-item>
          <el-menu-item v-if="$can('menu-see-library')" class="header__menu-item" index="/library" :route="{name: 'Library'}">Библиотека</el-menu-item>
          <el-submenu v-if="$can('menu-see-reports')" index="1">
            <template slot="title">Отчетность</template>
              <el-menu-item class="header__menu-item" index="/report/report_monitoring" :route="{name: 'ReportMonitoring'}">Мониторинг пилота</el-menu-item>
              <el-menu-item class="header__menu-item" index="/report/report_pilot_result" :route="{name: 'ReportPilotResult'}">Результаты пилотной оценки</el-menu-item>
          </el-submenu>

          <el-menu-item v-if="$can('menu-see-assignments')" class="header__menu-item" index="/assignments" :route="{name: 'TestsList'}">Мои назначения</el-menu-item>
        </el-menu>


        <div class="header__account">
          <notifications></notifications>
          <el-avatar
              :src="require('@/assets/img/avatar.png')"
              class="header__account-avatar"
          >
          </el-avatar>

          <div class="header__account-inner">
            <div v-if="user.id" class="header__account-name">{{ user.full_name }}</div>
            <div class="header__account-position">{{ user.position }}</div>
            <router-link
              v-if="user.id"
              :to="{ name: 'Profile', params: { id: user.id }}"
              class="header__account-link"
            >
              Личный кабинет
            </router-link>
          </div>
        </div>

        <div class="header__logout" @click="logout">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 20H6C5.46957 20 4.96086 19.8127 4.58579 19.4793C4.21071 19.1459 4 18.6937 4 18.2222V5.77778C4 5.30628 4.21071 4.8541 4.58579 4.5207C4.96086 4.1873 5.46957 4 6 4H10" stroke="#A3A6A7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16 16L20 12L16 8" stroke="#A3A6A7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M20 12H10" stroke="#A3A6A7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </el-row>
    </el-header>

    <el-main>
      <project-menu></project-menu>
      <router-view></router-view>
    </el-main>
  </el-container>
</template>

<script>
import {mapGetters} from 'vuex';
import AddUsersDialog from "./../components/user/AddUsersDialog";
import ProjectMenu from "@/components/project/ProjectMenu";
import Notifications from "@/components/notifications/Notifications";

export default {
  name: "default-layout",
  components: {ProjectMenu, AddUsersDialog, Notifications},
  computed: {
    ...mapGetters(['user']),
    layout() {
      return this.$route.meta.layout || 'default-layout'
    }
  },
  created() {
    this.$store.dispatch('fetchUser');
  },
  data() {
    return {
      notifications: 2,
    }
  },
  methods: {
    activeUserSelected(selection) {
      localStorage.userId = selection[0].id;
      this.$router.go();
    },
    logout() {
      localStorage.removeItem('jwt');
      this.$router.go();
    },
  }
}
</script>

<style lang="scss">
@import "./src/assets/css/project-variables";

.header {
  z-index: 20;
  position: sticky;
  top: 0;
  background-color: $--color-white;

  &__logo {
    flex-shrink: 0;
    width: 143px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 100px;
  }

  &__menu {
    margin-right: auto;
  }

  &__account {
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin: 0 16px 0 0;

    &-bell {
      position: relative;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;

      .attention {
        position: absolute;
        top: -4px;
        right: 0;
        width: 10px;
        height: 10px;
        flex-shrink: 0;
        background-color: $--color-secondary-red;
        border: 2px solid $--color-white;
        border-radius: 50%;
      }

      &.active {
        cursor: pointer;
      }
    }

    &-avatar {
      width: 56px;
      height: 56px;
      margin: 0 16px 0 0;
      border: 2px solid $--color-text-regular-6;
      border-radius: 50%;

      img {
        width: 100%;
        border-radius: 50%;
      }
    }

    &-name {
      font-size: 14px;
      font-weight: 700;
    }

    &-position {
      font-size: 12px;
      color: $--color-text-regular-4;
    }

    &-link {
      font-size: 14px;
      color: $--color-primary;
    }
  }

  &__logout {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
      display: block;
      height: auto;

      path {
        transition: all .4s;
      }
    }

    &:hover {

      svg {

        path {
          stroke: $--color-primary;
        }
      }
    }
  }

  .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
    border-bottom: none;
  }

  @media only screen and (max-width: 1600px) {

    &__logo {
      width: 120px;
      height: auto;
      margin-right: 60px;
    }
  }

  @media only screen and (max-width: 1480px) {

    &__logo {
      margin-right: 32px;
    }

    &__account {
      margin-right: 12px;

      &-avatar {
        width: 48px;
        height: 48px;
      }

      &-name, &-link {
        font-size: 12px;
      }
    }

    &__logout {
      width: 16px;
      height: 16px;
    }
  }
}

//@media only screen and (max-width: $screen-md-min) {
//
//    .logo-header {
//        padding: 0;
//    }
//
//    #toggle {
//        visibility: visible;
//        opacity: 1;
//        margin-top: 6px;
//    }
//
//    #toggle .span {
//        height: 4px;
//        margin: 5px 0;
//        transition: all .3s ease-out;
//        backface-visibility: visible;
//        visibility: visible;
//        opacity: 1;
//    }
//
//    #menu .el-menu-item {
//        display: none;
//    }
//
//    #menu.active {
//        margin: 70px 0;
//        visibility: visible;
//        opacity: 0.98;
//        transition: all .5s ease-out;
//
//    }
//}
</style>
