<template>
  <div>
    <el-dialog
      append-to-body
      title="Ответственные за компетенцию"
      :visible.sync="show"
      :close-on-click-modal="false"
      width="60%"
      destroy-on-close
      custom-class="responsible-dialog"
    >
      <div class="responsible-dialog__desc">
        Если Вы не нашли пользователя в списке, обратитесь к секретарю рабочей группы. Возможно, такого пользователя
        еще нет в системе
      </div>

      <el-row v-loading="loading">
        <el-form
          class="responsible-dialog__form"
          label-position="left"
          label-width="140px"
        >
          <el-form-item label="Автор записи:">
            {{ competence.user ? competence.user.full_name : '' }}
          </el-form-item>

          <el-form-item label="Ответственные:"></el-form-item>

          <users-table
            :add-button="$can('mpc-set-competence-user', competence)"
            add-button-text="Добавить ответственных"
            :delete-button="$can('mpc-set-competence-user', competence)"
            :users="competence.users"
            :add-users-filter-role="['admin', 'secretary', 'expert']"
            :add-users-filter-projects="[parseInt($route.params.id)]"
            negotiation-row
            :negotiation-row-disabled="!$can('mpc-set-competence-main-user', competence)"
            :negotiation-main-user="competence.main_user_id"
            @new-users="addNewSharedUsers"
            @user-deleted="deleteUser"
            @main-changed="changeMainUser"
          ></users-table>
        </el-form>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="show = false">Закрыть</el-button>
      </span>
    </el-dialog>

    <span ref="wrapper">
      <slot name="reference"></slot>
    </span>
  </div>
</template>

<script>
import {off, on} from "element-ui/src/utils/dom";
import UsersTable from "../user/UsersTable";

export default {
  name: "mpc-shared-users-dialog",
  components: {UsersTable},

  props: {
    competenceId: {
      type: Number
    }
  },

  data() {
    return {
      loading: false,
      show: false,

      competence: {
        main_user_id: null,
        user: {},
        users: []
      }
    }
  },
  mounted() {
    if (this.$refs.wrapper.children) {
      on(this.$refs.wrapper.children[0], 'click', this.showModal);
    }
  },
  watch: {},
  methods: {
    showModal() {
      this.show = true;
      this.getCompetenceSharedUsers();
    },

    getCompetenceSharedUsers() {
      this.loading = true;
      this.$api.mpc.getCompetenceSharedUsers({
        competence_id: this.competenceId
      })
        .then(data => {
          this.competence = data.competence;
        })
        .finally(() => {
          this.loading = false;
        })
    },

    addNewSharedUsers(users) {
      let ids = users.map(user => user.id);
      this.loading = true;
      this.$api.mpc.addNewCompetenceSharedUsers({
        competence_id: this.competenceId,
        user_ids: ids
      })
        .then(data => {
          this.$notify.success({
            duration: this.$constants.global.notifyDuration,
            title: 'Сохранено',
            message: 'Ответственные успешно добавлены'
          });
        })
        .finally(() => {
          this.getCompetenceSharedUsers();
          this.$emit('users-changed');
        })
    },
    deleteUser(user) {
      this.loading = true;
      this.$api.mpc.deleteCompetenceSharedUser({
        competence_id: this.competenceId,
        user_id: user.id
      })
        .then(data => {
          this.$notify.success({
            duration: this.$constants.global.notifyDuration,
            title: 'Удалено',
            message: 'Ответственный успешно удален'
          });
        })
        .finally(() => {
          this.getCompetenceSharedUsers();
          this.$emit('users-changed');
        })
    },
    changeMainUser(user) {
      this.loading = true;
      this.$api.mpc.setCompetenceMainSharedUser({
        competence_id: this.competenceId,
        user_id: user.id,
      })
        .then(data => {
          this.$notify.success({
            duration: this.$constants.global.notifyDuration,
            title: 'Сохранено',
            message: 'Ответственный успешно сохранен'
          });
        })
        .finally(() => {
          this.getCompetenceSharedUsers();
          this.$emit('users-changed');
        })
    },
  },
  beforeDestroy() {
    if (this.$refs.wrapper.children) {
      off(this.$refs.wrapper.children[0], 'click', this.showModal);
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/css/project-variables.scss";

.responsible-dialog {

  &__desc {
    margin-bottom: 24px;
  }

  &__form {

    .el-form-item {
      margin-bottom: 16px;
    }
  }
}
</style>