<template>
  <el-collapse class="test__statistics" v-loading="loading">
    <el-collapse-item name="1">
      <template slot="title">
        Статистика по тестовому вопросу
      </template>

      <div v-if="!stat.count_users_answered">
        Здесь будет отображаться аналитика после прохождения теста
      </div>
      <div v-else>
        <ul class="test__statistics-list test__statistics-list--red">
          <li class="test__statistics-item">
            <span class="test__statistics-item__title">Дискриминативность</span>
            <span class="test__statistics-item__value">{{ stat.discriminativity }}</span>
          </li>

          <li class="test__statistics-item">
            <span class="test__statistics-item__title">Трудность</span>
            <span class="test__statistics-item__value">{{ stat.difficulty }}%</span>
          </li>
        </ul>

        <ul class="test__statistics-list">
          <li class="test__statistics-item">
            <span class="test__statistics-item__title">Всего прошли</span>
            <span class="test__statistics-item__value">{{ stat.count_users_answered }} чел</span>
          </li>

          <li class="test__statistics-item">
            <span class="test__statistics-item__title">Ответили верно</span>
            <span class="test__statistics-item__value">{{ stat.count_right_answers }} чел</span>
          </li>

          <li class="test__statistics-item">
            <span class="test__statistics-item__title">Ответили неверно</span>
            <span class="test__statistics-item__value">{{ stat.count_users_answered - stat.count_right_answers }} чел</span>
          </li>
        </ul>
      </div>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
export default {
  name: "TestQuestionStatistics",
  props: {
    questionId: {
      type: Number,
    },
  },
  watch: {
    questionId: function (newVal, oldVal) {
      this.getQuestionStat(newVal);
    }
  },
  data() {
    return {
      loading: false,
      stat: {
        count_right_answers: null,
        count_users_answered: null,
        count_users_answered_percent: null,
        difficulty: null,
        discriminativity: null,
      }
    }
  },
  methods: {
    getQuestionStat(questionId) {
      if( !questionId ){
        this.stat.count_right_answers = null;
        this.stat.count_users_answered = null;
        this.stat.count_users_answered_percent = null;
        this.stat.difficulty = null;
        this.stat.discriminativity = null;
        return;
      }
      this.loading = true;
      this.$api.report.getPilotResultQuestion({
        question_id: questionId
      })
        .then(data => {
          this.stat.count_right_answers = data.question.computed.count_right_answers;
          this.stat.count_users_answered = data.question.computed.count_users_answered;
          this.stat.count_users_answered_percent = data.question.computed.count_users_answered_percent;
          this.stat.difficulty = data.question.computed.difficulty;
          this.stat.discriminativity = data.question.computed.discriminativity;
        })
        .finally(() => {
          this.loading = false;
        })
    },
  }
}
</script>

<style lang="scss">

</style>
