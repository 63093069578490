<template>
    <span>
        <el-dialog
            top="2vh"
            append-to-body
            :close-on-click-modal="false"
            :title="titleText"
            :visible.sync="show"
            width="70%"
            destroy-on-close
        >
            <el-row>
                <el-row type="flex" class="row-bg" justify="space-between" style="margin-bottom: 32px;">
                    <el-col :span="11">
                        <el-input
                            v-model="search"
                            prefix-icon="el-icon-search"
                            placeholder="Поиск по ФИО и подразделению"
                            size="mini"
                            clearable
                            @clear="searchChanged"
                            v-debounce:500="searchChanged"
                        >
                        </el-input>
                    </el-col>

                    <el-col :span="12" align="right">
                        <el-button
                          v-show="!singleSelect"
                          :disabled="($refs.selectUsersTable && !$refs.selectUsersTable.selection.length)"
                          :loading="loading"
                          style="margin-left:30px"
                          :type=" singleSelect ? 'text' : 'primary'"
                          @click="addSelectedUsers"
                          size="mini"
                          :icon="singleSelect ? '' : 'fas fa-plus'"
                        >
                            {{ addButtonText }}
                        </el-button>
                    </el-col>
                </el-row>
                <el-table
                    v-loading="loading"
                    ref="selectUsersTable"
                    size="mini"
                    empty-text="Нет пользователей"
                    :data="users"
                    :row-key="(row) => row.id"
                    @current-change="handleCurrentChange"
                >
                    <el-table-column type="selection" reserve-selection width="55"
                                     v-if="!singleSelect"></el-table-column>
                    <el-table-column prop="full_name" label="ФИО"></el-table-column>
                    <el-table-column prop="division" label="Подразделение"></el-table-column>
                    <el-table-column prop="position" label="Должность"></el-table-column>
                    <el-table-column prop="role.description" label="Роль"></el-table-column>
                </el-table>
                <paginator
                    :paginator.sync="paginator"
                    @page-changed="searchUsers"
                ></paginator>
            </el-row>

            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="show = false">Отмена</el-button>
            </span>
        </el-dialog>

        <span ref="wrapper">
            <slot name="reference"></slot>
        </span>
    </span>
</template>

<script>
import {off, on} from "element-ui/src/utils/dom";
import Paginator from "../Paginator";
import {paginator} from "@/mixins/paginator";

export default {
  name: "add-users-dialog",
  mixins: [paginator],
  components: {Paginator},

  props: {
    filterRole: {},
    filterProjects: {},
    filterTests: {},
    titleText: {
      type: String,
      default: 'Добавление пользователей'
    },
    addButtonText: {
      type: String,
      default: 'Добавить отмеченных'
    },
    singleSelect: {
      type: Boolean,
      default: false
    },

  },

  data() {
    return {
      loading: false,
      show: false,

      search: null,
      roleName: null,
      users: [],
    }
  },
  mounted() {
    if (this.$refs.wrapper.children) {
      on(this.$refs.wrapper.children[0], 'click', this.showModal);
    }
  },
  watch: {},
  methods: {
    showModal() {
      this.search = null;
      this.paginator.page = 1;
      this.show = true;
      this.users = [];

      this.searchUsers();
    },
    searchChanged(search) {
      this.paginator.page = 1;
      this.searchUsers();
    },


    searchUsers() {
      this.users = [];
      this.loading = true;
      this.$api.user.search({
        role: this.filterRole,
        project_ids: this.filterProjects ? this.filterProjects : null,
        test_ids: this.filterTests ? this.filterTests : null,
        search: this.search,
        [this.paginator.param]: this.paginator.page,
        [this.paginator.paramPerPage]: this.paginator.perPage,
      })
          .then(({data, paginator, sorter}) => {
            this.users = data.users;
            this.paginatorFill(paginator);
          })
          .finally(() => {
            this.loading = false;
          })
    },

    addSelectedUsers(singleUser) {
      this.show = false;
      this.$emit('users-selected', this.singleSelect ? singleUser : this.$refs.selectUsersTable.selection);
    },

    handleCurrentChange(user) {
      if (!this.singleSelect) {
        return;
      }
      this.addSelectedUsers(user);
    }
  },
  beforeDestroy() {
    if (this.$refs.wrapper.children) {
      off(this.$refs.wrapper.children[0], 'click', this.showModal);
    }
  }
}
</script>

<style>

</style>