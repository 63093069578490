<template>
  <el-dialog
      :title="projectCopy.id ? 'Редактирование проекта' : 'Создание проекта'"
      :visible="show"
      :close-on-click-modal="false"
      width="28%"
      top="15vh"
      :center="true"
      custom-class="el-dialog--edit-project"
      destroy-on-close
      @close="$emit('update:show', false)"
  >
    <p class="desc">
      Если в списке нет нужного вам подразделения, вернитесь в раздел
      <span
          class="el-link"
          @click="$router.push({ name: 'Divisions'})"
      >
        Подразделения
      </span>
      для его добавления в список
    </p>

    <el-row>
      <el-form
          size=""
          ref="projectForm"
          label-position="top"
          :model="projectCopy"
          :rules="rules"
      >
        <el-form-item label="Название проекта" prop="name">
          <el-input placeholder="Разработка СПК для работников ЦТ" show-word-limit v-model="projectCopy.name"></el-input>
        </el-form-item>
<!--        <el-form-item label="Краткое название модели" prop="short_name">-->
<!--          <el-input-->
<!--            v-model="projectCopy.short_name"-->
<!--            placeholder="СПК ЦТ"-->
<!--            show-word-limit-->
<!--            maxlength="40"-->
<!--          ></el-input>-->
<!--        </el-form-item>-->

        <el-form-item class="form-two-line-label-item" label="Подразделение-владелец СПК" prop="division_id">
          <division-select
              v-model="projectCopy.division_id"
              :saved-name="projectCopy.division ? projectCopy.division.name : ''"
          ></division-select>
        </el-form-item>

        <el-form-item label="Дата проекта (с)" prop="date_start">
          <el-date-picker
            :picker-options="{firstDayOfWeek: 1}"
              placeholder="Выберите дату"
              v-model="projectCopy.date_start"
              type="date"
              format="dd.MM.yyyy"
              value-format="timestamp"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="Дата проекта (по)" prop="date_end">
          <el-date-picker
            :picker-options="{firstDayOfWeek: 1}"
              placeholder="Выберите дату"
              v-model="projectCopy.date_end"
              type="date"
              format="dd.MM.yyyy"
              value-format="timestamp"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
    </el-row>

    <template slot="footer">
      <el-button @click="$emit('update:show', false)">Отмена</el-button>
      <el-button
          type="primary"
          :loading="loading"
          @click="saveProject"
      >
        Сохранить
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import Paginator from "../Paginator";
import DivisionSelect from "../selects/DivisionSelect";
import UsersSelect from "../selects/UsersSelect";

export default {
  name: "edit-project-modal",
  components: {UsersSelect, DivisionSelect, Paginator},

  props: {
    show: {
      type: Boolean,
      default: false
    },
    project: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  data() {
    return {
      loading: false,
      dialogShow: false,
      rules: {
        name: [
          {required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}
        ],
        // short_name: [
        //   {required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}
        // ],
        division_id: [
          {required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}
        ],
        date_start: [
          {required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}
        ],
      },
      projectCopy: {
        id: null,
        name: null,
        // short_name: null,
        division_id: null,
        type: null,

        author_id: null,
        created_at: null,
        updated_at: null,

        date_range: [],
        date_start: null,
        date_end: null,
      },
    }
  },
  mounted() {
  },
  watch: {
    show: function (newVal, oldVal) {
      if (newVal) {
        this.prepareData()
      }
    },
  },
  methods: {
    prepareData() {
      if (this.$refs.projectForm) {
        this.$refs.projectForm.resetFields();
      }

      Object.keys(this.projectCopy).forEach(key => this.projectCopy[key] = this.project[key]);

      this.projectCopy.date_start = this.project.date_start ? this.project.date_start * 1000 : null;
      this.projectCopy.date_end = this.project.date_end ? this.project.date_end * 1000 : null;
    },
    saveProject() {
      this.$refs.projectForm.validate((result) => {
        if (!result) {
          return;
        }

        this.loading = true;

        this.projectCopy.date_start = this.projectCopy.date_start / 1000;
        this.projectCopy.date_end = this.projectCopy.date_end / 1000;

        this.$api.project.save(this.projectCopy)
            .then(data => {
              this.$notify.success({
duration: this.$constants.global.notifyDuration,
                title: 'Сохранено',
                message: 'Проект успешно сохранен'
              });
              this.$emit('saved');
            })
            .finally(() => {
              this.loading = false;
              this.$emit('update:show', false)
            })
      });

    },

  }
}
</script>

<style lang="scss">
@import "@/assets/css/project-variables.scss";

.el-dialog {

  &--edit-project {
    padding: 32px 40px 40px;
    background: $--color-white;
    box-shadow: 1px 0 30px 7px rgba(20, 33, 64, 0.02);
    border-radius: 10px;

    .el-dialog {

      &__header {
        padding: 0;
        margin-bottom: 4px;
      }

      &__title {
        font-size: 30px;
        font-weight: 400;
        line-height: 1.5;
        color: $--color-text-regular;
      }

      &__body {
        padding: 0;
      }

      &__footer {
        display: flex;
        align-items: center;
        padding: 0;

        .el-button {
          width: calc(50% - 12px);
          margin-right: 24px;

          &:last-child {
            margin-right: 0;
            margin-left: 0;
          }
        }
      }
    }

    .desc {
      margin-bottom: 32px;
      font-size: 14px;
      text-align: center;

      .el-link {
        color: $--color-primary;

        &:hover {
          color: $--color-primary-hover;
        }
      }
    }

    .el-row {
      margin-bottom: 32px;
    }

    .el-form {

      &-item {
        margin: 0 0 24px 0;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .el-select,
    .el-input {
      width: 100%;
    }
  }

  @media only screen and (max-width: 1480px) {

    &--edit-project {

      .el-dialog__title {
        font-size: 20px;
      }

      .desc {
        font-size: 12px;
        margin-bottom: 24px;
      }

      .el-form-item {
        margin-bottom: 16px;
      }
    }
  }
}
</style>
