<template>
  <div v-loading="loading" class="comments">
    <div class="comments__title">{{ titleDescription }}</div>

    <div class="comments__list">
      <div
        v-model="comments"
        v-for="comment in comments"
        :key="comment.id"
        class="comments__item"
      >
        <el-avatar
          :size="56"
          :src="require('@/assets/img/avatar.png')"
          class="comments__item-avatar"
        >
        </el-avatar>

        <div class="comments__item-inner">
          <click-to-edit
            v-model="comment.comment"
            class="comments__item-text fc-table-element-other"
            type="textarea"
            :disabled="!$can('comment-update', comment)"
            @input="saveEntityComment(comment)"
          ></click-to-edit>

          <el-row type="flex" class="comments__item-information">
            <div class="comments__item-name">{{ comment.user.full_name }}</div>
            <span>&nbsp;/&nbsp;</span>
            <div class="comments__item-date" v-model="comment.updated_at">
              {{ new Date(comment.updated_at * 1000).toLocaleDateString() }}
              <span v-if="comment.updated_at !== comment.created_at">(ред)</span>
            </div>
          </el-row>


          <el-popconfirm
            v-if="$can('comment-delete', comment)"
            class="comments__item-delete"
            confirm-button-text='Да'
            cancel-button-text='нет'
            icon="el-icon-question"
            title="Вы уверены, что хотите удалить комментарий?"
            @confirm="deleteEntityComment(comment.id)"
          >
            <el-button
              slot="reference"
              type="text"
            >
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 4H3.33333H14" stroke="#767A7A" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path
                  d="M5.33301 4.00004V2.66671C5.33301 2.31309 5.47348 1.97395 5.72353 1.7239C5.97358 1.47385 6.31272 1.33337 6.66634 1.33337H9.33301C9.68663 1.33337 10.0258 1.47385 10.2758 1.7239C10.5259 1.97395 10.6663 2.31309 10.6663 2.66671V4.00004M12.6663 4.00004V13.3334C12.6663 13.687 12.5259 14.0261 12.2758 14.2762C12.0258 14.5262 11.6866 14.6667 11.333 14.6667H4.66634C4.31272 14.6667 3.97358 14.5262 3.72353 14.2762C3.47348 14.0261 3.33301 13.687 3.33301 13.3334V4.00004H12.6663Z"
                  stroke="#767A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </el-button>
          </el-popconfirm>

        </div>
      </div>
    </div>

    <div
      v-if="canCreate"
      class="comments__add"
    >
      <el-input placeholder="Нажмите для добавления" v-model="newComment"></el-input>
      <el-button
        type="primary"
        :disabled="isDisabled"
        @click="addComment()"
      >
        Сохранить комментарий
      </el-button>
    </div>
  </div>
</template>

<script>

import ClickToEdit from "../../components/ClickToEdit";

export default {
  components: {ClickToEdit},
  name: "entity-comments",
  props: {
    projectId: {
      type: Number,
      required: true,
    },
    entityId: {
      type: Number,
      required: true,
    },
    entityType: {
      type: Number,
      required: true,
    },
    titleDescription: {
      type: String,
      default: 'Комментарии:'
    }
  },

  data() {
    return {
      comments: [],
      loading: false,
      newComment: null,
      canCreate: false,
    }
  },
  watch: {},
  computed: {
    isDisabled() {
      return !this.newComment || this.newComment.trim().length === 0;
    }
  },
  mounted() {
    if (this.entityType && this.entityId) {
      this.getCommentsForEntity()
    }
  },
  methods: {
    getCommentsForEntity() {
      this.loading = true;
      this.$api.comment.getCommentsForEntity({
        project_id: this.projectId,
        entity_type: this.entityType,
        entity_id: this.entityId,
      })
        .then((data) => {
          this.comments = data.comments;
          this.canCreate = data.can_create;
        })
        .finally(() => {
          this.loading = false;
        })
    },
    addComment() {
      this.loading = true;
      this.$api.comment.addNewEntityComment({
        project_id: this.projectId,
        entity_type: this.entityType,
        entity_id: this.entityId,
        comment: this.newComment,
      })
        .then(data => {
          this.comments.unshift(data.comment)
          this.$notify.success({
            duration: this.$constants.global.notifyDuration,
            title: 'Сохранено',
            message: 'Комментарий успешно добавлен'
          });
        })
        .finally(() => {
          this.newComment = null;
          this.loading = false;
        })

    },
    saveEntityComment(comment) {
      this.loading = true;
      this.$api.comment.saveEntityComment({
        id: comment.id,
        comment: comment.comment,
      })
        .then(data => {
          comment.updated_at = Math.floor(Date.now() / 1000);
          this.$notify.success({
            duration: this.$constants.global.notifyDuration,
            title: 'Сохранено',
            message: 'Комментарий успешно сохранен'
          });
        })
        .finally(() => {
          this.loading = false;
        })
    },
    deleteEntityComment(id) {
      this.loading = true;
      this.$api.comment.deleteEntityComment({
        id: id
      })
        .then(data => {
          this.$notify.success({
            duration: this.$constants.global.notifyDuration,
            title: 'Удалено',
            message: 'Комментарий успешно удален'
          });
          this.getCommentsForEntity();
        })
        .finally(() => {
          this.loading = false;
        })
    }
  }
}
</script>

<style lang="scss">
@import "./src/assets/css/project-variables";

.comments {
  padding: 40px 0 0;
  border-top: 1px solid $--color-divider;

  &__title {
    margin: 0 0 32px 0;
    font-size: 36px;
  }

  &__list {
    margin: 0 0 40px;
  }

  &__item {
    display: flex;
    padding: 0 0 32px;
    margin: 0 0 24px;
    border-bottom: 1px solid $--color-divider;

    &-avatar {
      flex-shrink: 0;
      margin: 0 8px 0 0;
    }

    &-inner {
      flex: 1;
      position: relative;
      display: flex;
      flex-direction: column;
    }

    &-text {
      margin: 0 0 8px;

      .edit-block__value > span {
        width: 90%;
        font-size: 16px;
        line-height: 1.5;
        color: $--color-text-regular;
      }
    }

    &-information {
      font-size: 14px;
      color: $--color-text-regular-4;
    }

    &-delete {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 24px;
      height: 24px;
      margin-left: auto;

      button {
        svg {
          width: 100%;
          height: 100%;

          path {
            transition: all .4s;
          }
        }

        &:hover {

          svg {

            path {
              stroke: $--color-danger;
            }
          }
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__add {

    .el-input {
      margin-bottom: 24px;
    }
  }
}

</style>
