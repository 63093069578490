<template>
  <div class="page" v-if="$can('menu-see-reports')">
    <div class="page__head">


      <div class="page__head-left">
        <h1 class="page__head-title">
          {{ $route.meta.title }}
        </h1>
      </div>
    </div>

    <!--  Фильтры  -->
    <el-row
        v-loading="loading"
        class="filters"
        type="flex"
        justify="space-between"
        style="flex-wrap: wrap"
    >
      <el-col class="filters__item" :span="4">
        <label class="form-label">
          <span class="form-label__title">Проект</span>
          <project-select
              size="mini"
              :multiple="false"
              v-model="projectId"
              @change="testId = null"
          >
          </project-select>
        </label>
      </el-col>

      <el-col class="filters__item" :span="4">
        <label class="form-label">
          <span class="form-label__title">Тесты</span>
          <test-select
              size="mini"
              v-model="testId"
              :disabled="!projectId"
              :project_id="projectId"
              @change="getReport"
          >
          </test-select>
        </label>
      </el-col>

      <el-col :span="4" style="align-self: end; text-align: right; margin-left: auto;">
        <el-button
            icon="el-icon-refresh"
            :disabled="resetIsDisabled"
            @click="reset"
        >
          Сбросить
        </el-button>
      </el-col>
    </el-row>

    <div v-if="test">
      <test-with-sections
          :test="test"
          :projectId="projectId"
      >
      </test-with-sections>
    </div>

    <el-row
        v-else
        class="empty"
    >
      Выберите проект и тест
    </el-row>
  </div>
</template>

<script>
import PageHeading from "../../components/PageHeading";
import ClickToEdit from "../../components/ClickToEdit";
import ProjectSelect from "@/components/selects/ProjectSelect";
import TestSelect from "../../components/selects/TestSelect";
import TestWithSections from "../../components/report/reportPilotResult/TestWithSections";

export default {
  name: 'ReportPilotResult',

  components: {
    PageHeading,
    ClickToEdit,
    ProjectSelect,
    TestSelect,
    TestWithSections,
  },
  data() {
    return {
      loading: false,
      testId: null,
      projectId: null,

      test: null,
      newSolution: null,
    }
  },
  computed: {
    resetIsDisabled() {
      return !this.projectId &&
          !this.testId
    },
  },
  mounted() {
    this.getReport()
  },
  methods: {
    getReport() {
      if (this.projectId && this.testId) {
        this.test = null;
        this.loading = true;
        this.$api.report.getPilotResult({
          project_id: this.projectId,
          test_id: this.testId
        })
            .then(data => {
              this.test = data.test;
            })
            .finally(() => {
              this.loading = false;
            })
      }
    },
    reset() {
      this.projectId = null;
      this.testId = null;
      this.test = null;
    }
  }
}

</script>

<style lang="scss" scoped>
@import "./src/assets/css/project-variables";

.empty {
  margin: 30px 0;
}

.filters {
  padding: 0 0 32px;
  margin: 0 0 24px;
  border-bottom: 1px solid $--color-divider;

  &__item {
    margin-right: 24px;
  }
}

</style>