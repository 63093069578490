<template>
  <div>
    <page-heading
      heading="Состав рабочей группы"
    >
      <span slot="help"></span>
      <span slot="right">

        <el-button
          v-show="false"
          style="margin-left:10px;"
          type="primary"
          icon="el-icon-suitcase"
        >
            Актуализировать СПК
        </el-button>
        <el-button
          type="primary"
          plain
          icon="fas fa-arrow-left"
          @click="$router.push({name: 'Project',params: { id: $route.params.id}})"
        >
          Вернуться к проекту
        </el-button>
      </span>
    </page-heading>


    <el-row>
      <el-row type="flex" justify="space-between">
        <h2>Рабочая группа</h2>
        <add-users-dialog
          v-if="$can('project-add-to-workgroup', project.permissions)"
          :filter-role="['admin', 'secretary', 'expert']"
          no-division
          add-button-text="Добавить отмеченных"
          @users-selected="addUsers"
        >
          <el-button
            slot="reference"
            type="primary"
            size="small"
            icon="fas fa-plus"
          >
            Добавить пользователей
          </el-button>
        </add-users-dialog>
      </el-row>

      <project-workgroup-users-table
        :project="project"
        :main-user-id.sync="project.main_user_id"
      ></project-workgroup-users-table>
    </el-row>


    <el-row style="margin-top:30px;">
      <el-row type="flex" justify="space-between">
        <h2>Пилотная группа</h2>
        <import-users-dialog
          v-if="$can('users-import')"
          pilot
          :project-id="project.id"
          @users-uploaded="getProject"
        >
          <el-button
            slot="reference"
            icon="el-icon-document-add"
          >
            Импорт участников пилотной оценки из файла
          </el-button>
        </import-users-dialog>
      </el-row>

      <project-pilot-users-table
        :project="project"
      ></project-pilot-users-table>
    </el-row>

  </div>
</template>

<script>
import PageHeading from "../../components/PageHeading";
import AddUsersDialog from "@/components/user/AddUsersDialog";
import ProjectWorkgroupUsersTable from "@/components/project/ProjectWorkgroupUsersTable";
import ProjectPilotUsersTable from "@/components/project/ProjectPilotUsersTable";
import ImportUsersDialog from "@/components/user/ImportUsersDialog";

export default {
  name: 'ProjectUsers',
  components: {ImportUsersDialog, ProjectPilotUsersTable, ProjectWorkgroupUsersTable, AddUsersDialog, PageHeading},
  data() {
    return {
      loading: false,
      project: {},
    }
  },
  computed: {},
  mounted() {
    this.getProject();
  },
  methods: {
    getProject() {
      this.loading = true;
      this.$api.project.get({
        id: this.$route.params.id,
        expand: 'permissions',
      })
        .then(data => {
          this.project = data.project
        })
        .finally(() => {
          this.loading = false;
        })
    },
    addUsers(users) {
      this.loading = true;
      let user_ids = users.map(u => u.id);
      this.$api.project.addUsers({
        project_id: this.project.id,
        user_ids: user_ids,
      })
        .then(data => {
          this.$notify.success({
            duration: this.$constants.global.notifyDuration,
            title: 'Сохранено',
            message: 'Пользователи успешно добавлены'
          });
          this.project = {};
          this.getProject();
        })
        .finally(() => {
          // this.getProjectUsers();
        })
    },
  }
}

</script>

<style lang="scss" scoped>

</style>