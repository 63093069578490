<template>
  <div>
    <page-heading
      :heading="test.name"
    >
      <span slot="help"></span>
      <span slot="right">

            </span>
    </page-heading>

    <div class="test">
      <div
        v-if="!testFinished"
        type="flex" align="middle" justify="start" class="test__amount"
      >
        <div class="test__amount-item">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="16" cy="16" r="12" stroke="#6DC077" stroke-width="2"/>
            <path d="M10.9997 16.3109L13.948 19.2592C14.3385 19.6497 14.9717 19.6497 15.3622 19.2592L21.966 12.6554" stroke="#6DC077" stroke-width="2" stroke-linecap="round"/>
          </svg>
          <span>{{ rightCount }} правильных</span>
        </div>

        <div class="test__amount-item">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="16" cy="16" r="12" stroke="#D95954" stroke-width="2"/>
            <path d="M20.9998 21.001L10.999 11" stroke="#D95954" stroke-width="2" stroke-linecap="round"/>
            <path d="M10.9992 21.001L21 11" stroke="#D95954" stroke-width="2" stroke-linecap="round"/>
          </svg>
          <span>{{ notRightCount }} неправильных</span>
        </div>

        <div class="test__amount-item">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="16" cy="16" r="12" stroke="#767A7A" stroke-width="2"/>
            <path d="M16 8V16.25L22 19" stroke="#767A7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span>{{ leftCount }} неотвеченных</span>
        </div>
      </div>

      <div class="test__main">
        <el-row
            v-if="!testFinished"
            :gutter="24"
        >
          <el-col
              v-loading="loading"
              :span="4"
              style="padding-top: 24px;"
          >
            <el-collapse
              class="player-section-collapse test__collapse"
              style="margin-bottom: 16px"
              accordion
              @change="showPreInfo=true"
            >
              <el-collapse-item
                title="Памятка"
              >

              </el-collapse-item>
            </el-collapse>
            <el-collapse
                v-model="currentSection"
                class="player-section-collapse test__collapse"
                accordion
                @change=""
            >
              <el-collapse-item
                  v-for="(section, sectionIndex) in test.sections"
                  :key="section.id"
                  :title="section.name"
                  :name="section.id"
              >
                <el-timeline v-if="section.questions.length" class="questions-timeline">
                  <el-scrollbar :class="{ scroll: section.questions.length > 9 }">
                    <el-timeline-item
                        v-for="(question, questionIndex) in section.questions"
                        :icon="getTimelineIcon(question)"
                        :type="getTimelineIconType(question)"
                        size="large"
                        :key="question.id"
                    >
                      <el-button
                          class="select-question-button"
                          :class="{active: question.id===currentQuestion.id}"
                          type="text"
                          size="mini"
                          @click="questionSelected(question)"
                      >
                        Вопрос
                        {{
                          test.sections
                              .slice(0, sectionIndex)
                              .reduce((total, ch) => total += ch.questions.length, 0)
                          + questionIndex
                          + 1
                        }}
                      </el-button>

                    </el-timeline-item>
                  </el-scrollbar>
                </el-timeline>
              </el-collapse-item>
            </el-collapse>
          </el-col>

          <el-col :span="15" v-loading="loading">
            <el-card
              v-if="showPreInfo"
              class="test__content"
              shadow="never"
            >
              <div class="memo">
                <h2 align="center">Добрый день, уважаемый коллега!</h2>
                <p>
                  Вы являетесь участником пилотной оценки системы профессиональных компетенций Вашего подразделения.
                </p>
                <p>
                  Вашей основной задачей будет апробация тестов профессиональных знаний и навыков,
                  которые в дальнейшем будут применяться для оценки профессиональных компетенций работников
                  Вашего подразделения.
                </p>
                <p>
                  Вам необходимо пройти тесты в соответствии с профилем Вашей должности.
                  Каждый вопрос имеет один или несколько вариантов ответов. Перед тем,
                  как отвечать на тестовое задание, внимательно прочитайте текст вопроса и варианты ответов к нему.
                  Количество вопросов зависит от количества компетенций в профиле Вашей должности.
                </p>
                <p>
                  Если в ходе тестирования у Вас будут возникать вопросы,
                  Вы всегда можете вернуться на главную страницу для изучения данной инструкции,
                  а также обратиться к организатору пилотной оценки Вашего подразделения.
                </p>
                <p>
                  Результаты пилотного тестирования будут использоваться для расчета статистических показателей
                  надежности тестов, поскольку в ходе тестирования анализируется качество разработанных заданий,
                  а не уровень развития профессиональных знаний и навыков участников пилотного тестирования.
                  Итоги пилотной оценки не отражаются в результатах оценки работников.
                </p>
                <h3 class="light">При работе с  тестом уделите особое внимание:</h3>
                <ol>
                  <li>формулировке вопросов;</li>
                  <li>формулировке ответов;</li>
                  <li>принадлежности (соответствию) тестовых заданий индикатору профессиональной компетенции;</li>
                  <li>принадлежности (соответствию) тестовых заданий функционалу Вашей должности;</li>
                  <li>
                    видео и изображениям (если они имеются в вопросах): соответствуют ли они вопросу/ответам,
                    четко ли отображаются и др;
                  </li>
                  <li>наличию грамматических и (или) орфографических ошибок.</li>
                </ol>
                <p>
                  Рядом с каждым тестовым заданием расположен чек-лист,
                  в котором Вы можете оставить свой комментарий или выбрать вариант из предложенных.
                </p>
                <h3 class="light">При прохождении тестирования важно соблюдать следующие правила:</h3>
                <ol>
                  <li>
                    не разговаривать по телефону во время тестирования
                    (для этого переведите телефон в бесшумный режим);
                  </li>
                  <li>не перемещаться/выходить из аудитории;</li>
                  <li>не фотографировать экран;</li>
                  <li>
                    не передавать информацию о тестах третьим лицам,
                    за исключением обратной связи, которую Вы можете оставить в чек-листе.
                  </li>
                </ol>
                <h2 align="center">Желаем Вам продуктивной работы!</h2>
              </div>
              <el-button @click="getNextQuestion()">Перейти к тесту</el-button>
            </el-card>
            <el-card
              v-else
                class="test__content"
                shadow="never"
            >
              <h3 class="test__content-title">{{ currentQuestion.question }}</h3>

              <div
                  v-if="currentQuestion.image"
                  class="test__content-img"
              >
                <img
                    :src="currentQuestion.image.url"
                    alt="img"
                >
              </div>

              <div
                v-if="currentQuestion.type==$constants.test.questionTypes.TYPE_SINGLE"
              >
                <div class="question-answers-hint">
                  Выберите один правильный вариант
                </div>
                <el-radio-group
                    v-model="currentQuestionAnswer"
                    :disabled="userAnswers[currentQuestion.id] !== undefined"
                    class="test__content-list"
                >
                  <div
                      v-for="answer in currentQuestion.answers"
                      class="test__content-item"
                  >
                    <el-radio
                        :key="answer.id"
                        :label="parseInt(answer.id)"
                    >
                      <p class="answer">{{ answer.answer }}</p>
                    </el-radio>

                    <el-image
                        v-if="answer.image"
                        :src="answer.image.url"
                        :alt="answer.image.name"
                        :preview-src-list="[answer.image.url]"
                        class="test-img"
                    >
                    </el-image>
                  </div>
                </el-radio-group>
              </div>

              <div
                v-if="currentQuestion.type==$constants.test.questionTypes.TYPE_MULTIPLE"
              >
                <div class="question-answers-hint">
                  Выберите несколько правильных вариантов
                </div>
                <el-checkbox-group
                  v-model="currentQuestionAnswer"
                  :disabled="userAnswers[currentQuestion.id] !== undefined"
                  class="test__content-list"
                >
                  <div
                    v-for="answer in currentQuestion.answers"
                    class="test__content-item"
                  >
                    <el-checkbox
                      :key="answer.id"
                      :label="answer.id"
                    >
                      <p class="answer">{{ answer.answer }}</p>
                    </el-checkbox>

                    <el-image
                      v-if="answer.image"
                      :src="answer.image.url"
                      :alt="answer.image.name"
                      :preview-src-list="[answer.image.url]"
                      class="test-img"
                    >
                    </el-image>
                  </div>
                </el-checkbox-group>
              </div>


              <div class="test__content-btns">
                <el-button
                    v-if="currentQuestion.id && userAnswers[currentQuestion.id] === undefined"
                    :disabled="setAnswerButtonDisabled"
                    type="primary"
                    @click="setAnswer"
                >
                  Принять ответ
                </el-button>
                <el-button
                    v-if="currentQuestion.id && userAnswers[currentQuestion.id] === undefined"
                    @click="getNextQuestion(currentQuestion.id)"
                >
                  Пропустить
                </el-button>
              </div>
            </el-card>
          </el-col>

          <el-col
            v-if="!showPreInfo"
              v-loading="loadingChecklist"
              :span="5"
          >
            <div class="test__check">
              <h4 class="test__check-title">Чек-лист проверки</h4>

              <div
                  v-for="(checklist, checklistIndex) in currentChecklists"
                  class="test__check-item"
              >
                <div class="test__check-item__select">
                  <el-select
                      v-model="checklist.result"
                      style="width: 100%"
                      size="small"
                      placeholder="Выберите результат проверки"
                      @change="saveChecklistElement(checklist)"
                  >
                    <el-option
                        v-for="(resultName, resultCode) in $constants.test.checklistResults"
                        :key="resultCode"
                        :label="resultName"
                        :value="parseInt(resultCode)">
                    </el-option>
                  </el-select>
                  <el-input
                      v-show="checklist.result==6"
                      style="margin-top: 5px"
                      placeholder="Опишите здесь"
                      v-model="checklist.comment"
                      type="textarea"
                      autosize
                      size="small"
                      :element-id="checklist.id"
                      v-debounce:1500="checklistDebounce"
                  ></el-input>
                </div>

                <div class="test__check-item__delete">
                  <el-popconfirm
                      confirm-button-text='Да'
                      cancel-button-text='нет'
                      icon="el-icon-question"
                      title="Вы уверены?"
                      @confirm="deleteChecklistElement(checklist)"
                  >
                    <el-button
                        :disabled="!checklist.id"
                        slot="reference"
                        type="text"
                        size="small"
                        icon="fas fa-trash"
                    >
                    </el-button>
                  </el-popconfirm>
                </div>
              </div>

              <el-button
                  :disabled="userAnswers[currentQuestion.id] !== undefined"
                  type="text"
                  size="mini"
                  icon="fas fa-plus"
                  @click="currentChecklists.push({
                    assignment_id: test.assignment.id,
                    question_id: currentQuestion.id
                  })"
              >
                Добавить проблему
              </el-button>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <el-row v-if="testFinished">
      <el-col :span="8" :offset="8">
        <el-card
            class="test__content test__finished"
            shadow="never"
        >
          <h4 class="test__finished-title" align="center">Пилотное тестирование завершено</h4>
          <div class="test__finished-desc">
            Вы ответили верно на <strong>{{ rightCount }}</strong> вопросов из <strong>{{ questionsCount }}</strong>
          </div>
          <div class="test__finished-desc">Если у вас есть дополнительные комментарии к тесту, Вы можете оставить их!</div>
          <div class="test__finished-desc">Спасибо за участие в тестировании!</div>

          <div class="test__finished-form">
            <el-input
              v-model="test.assignment.comment"
              :disabled="testCommentSaved"
              show-word-limit
              :maxlength="300"
              type="textarea"
              :autosize="{ minRows: 2 }"
            ></el-input>

            <el-button
                type="primary"
                :loading="loading"
                :disabled="testCommentSaved"
                @click="saveAssignmentComment"
            >
              Сохранить комментарий
            </el-button>

            <el-button
                @click="$router.push({name: 'TestsList'})"
            >
              Вернуться к тестам
            </el-button>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import PageHeading from "../../components/PageHeading";

export default {
  name: 'TestPlayer',
  components: {PageHeading},
  data() {
    return {
      showPreInfo: false,
      testFinished: false,
      testCommentSaved: false,

      loading: false,
      loadingChecklist: false,
      currentSection: null,
      test: {
        id: null,
        name: null,
        assignment: null,
        sections: [],
      },
      userAnswers: {},

      currentQuestion: {
        id: null,
        type: null,
        name: null,
        answers: [],
      },

      currentQuestionAnswer: [],
      currentChecklists: [],
    }
  },
  computed: {
    rightCount() {
      return Object.values(this.userAnswers).filter(a => a).length;
    },
    notRightCount() {
      return Object.values(this.userAnswers).filter(a => !a).length;
    },
    questionsCount() {
      return this.test.sections.reduce((accumulator, currentValue, index, array) => {
        return accumulator + currentValue.questions.length;
      }, 0);
    },
    leftCount() {
      return this.questionsCount - Object.values(this.userAnswers).length;
    },
    setAnswerButtonDisabled() {
      return Array.isArray(this.currentQuestionAnswer)
        ? !this.currentQuestionAnswer.length
        : !this.currentQuestionAnswer;
    },

  },
  watch: {
    currentQuestion: function () {
      this.getQuestionChecklist(this.currentQuestion);
    }
  },
  mounted() {
    this.getTest();
  },
  methods: {
    getTest() {
      this.loading = true;
      this.$api.test.getTestToPlay({
        id: this.$route.params.id,
      })
        .then(data => {
          this.test = data.test
          this.userAnswers = data.answers
          // this.getNextQuestion();
          this.showPreInfo = true;
        })
        .finally(() => {
          this.loading = false;
        })
    },
    questionSelected(question) {
      this.loading = true;
      this.currentQuestionAnswer = [];
      this.$api.test.getQuestion({
        id: question.id,
      })
        .then(data => {
          this.currentQuestion = data.question
          if (data.user_answer) {
            this.currentQuestionAnswer = data.user_answer.answer[0];
            this.currentQuestionAnswer = this.currentQuestion.type == this.$constants.test.questionTypes.TYPE_SINGLE ?
              data.user_answer.answer[0] : data.user_answer.answer;
          }
        })
        .finally(() => {
          this.loading = false;
        })
    },
    getQuestionChecklist(question) {
      this.loadingChecklist = true;
      this.currentChecklists = [];
      this.$api.test.getQuestionChecklist({
        question_id: question.id,
      })
        .then(data => {
          this.currentChecklists = data.checklist;
          if( !this.currentChecklists.length ){
            this.currentChecklists.push({
              assignment_id: this.test.assignment.id,
              question_id: this.currentQuestion.id
            })
          }
        })
        .finally(() => {
          this.loadingChecklist = false;
        })
    },
    checklistDebounce(value, event){
      this.saveChecklistElement(this.currentChecklists.find(el=>el.id == event.target.getAttribute('element-id')))
    },
    saveChecklistElement(element) {
      this.loadingChecklist = true;
      this.$api.test.saveChecklistElement(element)
        .then(data => {
          this.$notify.success({
            duration: this.$constants.global.notifyDuration,
            title: 'Сохранено',
            message: 'Элемент успешно сохранен'
          });
          element.id = data.element.id;
        })
        .finally(() => {
          // this.getQuestionChecklist(this.currentQuestion);
          this.loadingChecklist = false;
        })
    },

    deleteChecklistElement(element) {
      this.loadingChecklist = true;
      this.$api.test.deleteChecklistElement({
        id: element.id
      })
        .then(data => {
          this.$notify.success({
            duration: this.$constants.global.notifyDuration,
            title: 'Удалено',
            message: 'Элемент успешно удален'
          });
        })
        .finally(() => {
          this.getQuestionChecklist(this.currentQuestion);
        })
    },
    getNextQuestion(from = null) {
      this.showPreInfo = false;
      this.loading = true;
      this.currentQuestionAnswer = [];
      this.$api.test.getNextQuestion({
        assignment_id: this.test.assignment.id,
        from: from,
      })
        .then(data => {
          if (data.question) {
            this.currentQuestion = data.question;
            this.currentSection = data.question.section_id;
          } else {
            this.showFinish();
          }
        })
        .finally(() => {
          this.loading = false;
        })
    },
    setAnswer() {
      this.loading = true;
      this.$api.test.setAnswer({
        assignment_id: this.test.assignment.id,
        question_id: this.currentQuestion.id,
        answer: this.currentQuestion.type == this.$constants.test.questionTypes.TYPE_SINGLE
          ? [this.currentQuestionAnswer]
          : this.currentQuestionAnswer,
      })
        .then(data => {
          // this.userAnswers[this.currentQuestion.id] = data.result.is_right;
          this.$set(this.userAnswers, this.currentQuestion.id, data.result.is_right)
          this.getNextQuestion(this.currentQuestion.id);
        })
        .finally(() => {
          this.loading = false;
        })
    },
    getTimelineIcon(question) {
      if (this.userAnswers[question.id] !== undefined) {
        if (this.userAnswers[question.id] === 0) {
          return 'el-icon-error';
        } else {
          return 'el-icon-success';
        }
      }
      return 'el-icon-time';
    },
    getTimelineIconType(question) {
      if (this.userAnswers[question.id] !== undefined) {
        if (this.userAnswers[question.id] === 0) {
          return 'danger';
        } else {
          return 'success';
        }
      }
      return 'info';
    },
    showFinish() {
      this.testFinished = true;
    },
    saveAssignmentComment() {
      this.loading = true;
      this.$api.test.saveAssignmentComment({
        assignment_id: this.test.assignment.id,
        comment: this.test.assignment.comment,
      })
        .then(data => {
          this.testCommentSaved = true;
          this.$notify.success({
            duration: this.$constants.global.notifyDuration,
            title: 'Сохранено',
            message: 'Комментарий успешно сохранен'
          });
        })
        .finally(() => {
          this.loading = false;
        })
    },
  }
}

</script>

<style lang="scss" scoped>
@import "./src/assets/css/project-variables";

.test {
  border-top: none;

  &__amount {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 24px;
    border-bottom: none;

    &-item {
      display: flex;
      align-items: center;
      margin-right: 24px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__main {
    border-top: 1px solid $--color-divider;
  }

  &__collapse {

    .questions-timeline {
      border-bottom: none;
    }

    .el-collapse-item.is-active {
      padding-bottom: 0;
    }
  }

  &__content {

    &-title {
      font-weight: 400;
      margin-bottom: 32px;
    }

    &-img {
      margin-bottom: 32px;

      img {
        display: block;
        max-width: 100%;
        height: auto;
      }
    }

    &-list {
      flex-direction: column;
      align-items: flex-start;
    }

    &-item {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 20px 16px;
      margin-bottom: 16px;
      background-color: $--color-white;
      border: 1px solid $--color-divider;
      border-radius: 6px;

      .el-radio__input {
        margin-right: 8px;
      }

      .answer {
        margin-bottom: 0;
        white-space: normal;
      }

      .answer-img {
        width: 120px;
        height: 120px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-btns {
      display: flex;
      align-items: center;
      margin-top: 40px;

      button {
        margin-left: 0;
        margin-right: 24px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &__check {
    padding: 24px 0 0;

    &-title {
      margin-bottom: 16px;
    }

    &-desc {
      margin-bottom: 8px;
      font-size: 14px;
      color: $--color-text-regular-4;
    }

    &-item {
      display: flex;
      align-items: center;
      margin: 0 0 16px;

      &__select {
        flex: 1;
        margin-right: 16px;
      }

      &__delete {
        flex-shrink: 0;
      }
    }
  }

  &__finished {

    &-title {
      margin-bottom: 24px;
    }

    &-desc {
      margin-bottom: 8px;
    }

    &-form {

      .el-textarea {
        margin-bottom: 16px;
      }
    }
  }
}

.select-question-button {
  padding: 0;
}

.select-question-button.active{
  font-weight: bold;
}

.el-divider.el-divider--horizontal {
  margin: 10px 0;
}

.player-section-collapse .el-collapse-item__header {
  line-height: unset;
}

.memo {

  p {
    text-indent: 24px;
  }

  ol {
    list-style-type: none;
    counter-reset: item;

    li {

      &::before {
        content: counter(item) ') ';
        counter-increment: item;
      }
    }
  }
}

.question-answers-hint {
  color: red;
  font-style: italic;
  margin-bottom: 5px;
}

</style>
