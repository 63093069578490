import { render, staticRenderFns } from "./TsdTypeOeSelect.vue?vue&type=template&id=647627cc&scoped=true&"
import script from "./TsdTypeOeSelect.vue?vue&type=script&lang=js&"
export * from "./TsdTypeOeSelect.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "647627cc",
  null
  
)

export default component.exports