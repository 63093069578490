<template>
  <div>
    <el-row type="flex" align="top" justify="space-between" class="page__head">
      <div class="page__head-left">
        <div class="title-edit">
          <h1 class="page__head-title">Пилотная оценка СПК</h1>
          <click-to-edit
            v-model="pilot.name"
            :disabled="!$can('pilot.start', pilot)"
            :word-limit="200"
            @input="savePilotName"
          ></click-to-edit>
          <p>
            Тестирование доступно: {{ pilot.date_start ? new Date(pilot.date_start * 1000).toLocaleDateString() : 'дата начала не задана' }} -
            {{ pilot.date_end ? new Date(pilot.date_end * 1000).toLocaleDateString() : 'дата окончания не задана' }}
          </p>
        </div>
      </div>

      <div class="page__head-right">
        <el-button
          v-if="$can('pilot.start', pilot)"
          type="primary"
          plain
          :loading="loading"
          :disabled="!$objectRulesPassed('pilot.start', pilot)"
          @click="changePilotStatus($constants.pilotAssessment.statuses.STATUS_ACTIVE)"
        >
          Начать пилотную оценку СПК
        </el-button>
        <el-button
          v-if="$can('pilot.start', pilot)"
          type="primary"
          plain
          :loading="loading"
          :disabled="pilot.status !== $constants.pilotAssessment.statuses.STATUS_ACTIVE"
          @click="changePilotStatus($constants.pilotAssessment.statuses.STATUS_FINISHED)"
        >
          Завершить пилотную оценку СПК
        </el-button>
      </div>
    </el-row>

    <div>
<!--      <el-table-->
<!--          :data="tests"-->
<!--          size="mini"-->
<!--          style="width: 100%"-->
<!--          v-loading="loading"-->
<!--      >-->
<!--        <el-table-column prop="name" label="№ п\п" width="60">-->
<!--          <template slot-scope="scope">-->
<!--            {{ scope.$index + 1 }}-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column prop="name" label="Название теста"></el-table-column>-->
<!--        <el-table-column label="Запланировано участников">-->
<!--          <template slot-scope="scope">-->
<!--            {{ testUsersCount[scope.row.id] ? testUsersCount[scope.row.id] : 0 }}-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column label="Завершило тестирование">-->
<!--          <template slot-scope="scope">-->
<!--            {{ testUsersCount[scope.row.id] ? testUsersCount[scope.row.id] : 0 }}-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column>-->
<!--          <template slot-scope="scope">-->
<!--            icon-->
<!--          </template>-->
<!--        </el-table-column>-->

<!--      </el-table>-->
    </div>

    <el-row class="pilot-assessment-spk-table">

      <el-row :gutter="20" class="pilot-assessment-spk-table__header">
        <el-col :span="1">№ п\п</el-col>
        <el-col :span="14">Название теста</el-col>
        <el-col :span="3">Запланировано участников</el-col>
        <el-col :span="3">Завершило тестирование</el-col>
        <el-col :span="2"></el-col>
      </el-row>

      <el-row
          v-for="(test, index) in tests"
          :key="test.id"
          class="pilot-assessment-spk-table__row"
          :gutter="20"
      >
        <el-row :gutter="20" style="margin-bottom: 10px;">
          <el-col :span="1" align="center">{{ index+1 }}. </el-col>
          <el-col :span="14">{{ test.name }}</el-col>
          <el-col :span="3">{{ test.assignments.length }}</el-col>
          <el-col :span="3">
            {{ test.assignments.filter(a => a.result).length }}
          </el-col>
          <el-col
              class="pilot-assessment-spk-table__icon"
              :class="{
                'el-icon-success': test.assignments.filter(a => a.result).length >= 30,
                'el-icon-error': !(test.assignments.filter(a => a.result).length >= 30),
              }"
              :span="2"
              align="center"
          >
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-collapse >
              <el-collapse-item title="Участники">
                <el-row>
                  <el-table
                      :data="test.assignments"
                      style="margin-left: 49px;"
                  >
                    <el-table-column label="ФИО">
                      <template slot-scope="scope">
                        {{ scope.row.user.full_name }}
                      </template>
                    </el-table-column>
                    <el-table-column label="Подразделение">
                      <template slot-scope="scope">
                        {{ scope.row.user.division }}
                      </template>
                    </el-table-column>
                    <el-table-column label="ТШД">
                      <template slot-scope="scope">
                        {{ usersTsds[scope.row.user_id] }}
                      </template>
                    </el-table-column>
                    <el-table-column label="Дата начала">
                      <template slot-scope="scope">
                        {{ scope.row.started_at ? new Date(scope.row.started_at * 1000).toLocaleDateString() : '' }}
                      </template>
                    </el-table-column>
                    <el-table-column label="Дата завершения">
                      <template slot-scope="scope">
                        {{ scope.row.result ? new Date(scope.row.updated_at * 1000).toLocaleDateString() : '' }}
                      </template>
                    </el-table-column>
                    <el-table-column label="Результат">
                      <template slot-scope="scope">
                        {{ scope.row.result }}
                      </template>
                    </el-table-column>
                  </el-table>
                </el-row>
              </el-collapse-item>
            </el-collapse>
          </el-col>
        </el-row>
      </el-row>
    </el-row>
  </div>
</template>

<script>
import PageHeading from "../components/PageHeading";
import EllipsisMenu from "../components/EllipsisMenu";
import ClickToEdit from "@/components/ClickToEdit";

export default {
  name: 'Pilot',
  components: {ClickToEdit, EllipsisMenu, PageHeading},

  data() {
    return {
      loading: false,

      pilot: {
        id: null,
        date_start: null,
        date_end: null,
        status: null,
      },
      tests: [],
      usersTsds: {},

    }
  },
  computed: {},

  mounted() {
    this.getPilotData();
  },
  methods: {
    getPilotData() {
      this.loading = true;
      this.$api.pilot.getPilotData({
        project_id: this.$route.params.id,
        expand: 'permissions',
      })
          .then(data => {
            this.pilot = data.pilot;
            this.tests = data.tests;
            this.usersTsds = data.users_tsds;
          })
          .finally(() => {
            this.loading = false;
          })
    },

    savePilotName() {
      this.loading = true;
      this.$api.pilot.saveName({
        id: this.pilot.id,
        name: this.pilot.name,
      })
        .then(data => {
          this.$notify.success({
            duration: this.$constants.global.notifyDuration,
            title: 'Сохранено',
            message: 'Имя успешно сохранено'
          });
        })
        .finally(() => {
          this.loading = false;
        })
    },

    changePilotStatus(status) {
      this.loading = true;
      this.$api.pilot.changeStatus({
        pilot_id: this.pilot.id,
        status: status,
      })
        .then(data => {
          this.getPilotData();
          this.$notify.success({
            duration: this.$constants.global.notifyDuration,
            title: 'Сохранено',
            message: 'Статус успешно изменен'
          });
        })
        .finally(()=>{
          this.$store.dispatch('updateMenu');
        })

    },


  }
}

</script>

<style lang="scss">

  .pilot-assessment-spk-table {

    &__header {
      margin-bottom: 20px;
      color: #909399;
      font-size: 16px;
    }
    &__row {
      font-size: 16px;
      margin: 40px;
    }
    .el-collapse {
      border: none;
    }

    .el-collapse-item__header {
      background: #f5f5f5;
      margin-left: 49px;
      border: none;
      padding-left: 30px;
    }

    button {
      color: #909399;
      border-color: #909399;
      padding: 7px 15px;
      &:hover {
        color: #909399;
        background-color: #fff;
        border-color: #cecfd1;
      }
    }
    &__icon {
      font-size: 30px;
      &.el-icon-success {
        color: #68b168;
      }
      &.el-icon-error {
        color: #e96666;
      }
    }


  }

</style>
