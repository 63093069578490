import axiosInstance from "./axiosInstance";
import userModule from "../user";
import rbacModule from "../rbac";
import fileModule from "../file";
import divisionModule from "../division";
import projectModule from "../project";
import fcModule from "../fc";
import mpcModule from "../mpc";
import scheduleModule from "../schedule";
import profilingModule from "../profiling";
import testModule from "../test";
import tsdModule from "../tsd";
import pilotModule from "../pilot";
import commentModule from "../comment";
import libraryModule from "../library";
import reportModule from "../report";
import notificationModule from "../notification";
import introductionModule from "../introduction";

export default {
    user: userModule(axiosInstance),
    rbac: rbacModule(axiosInstance),
    file: fileModule(axiosInstance),
    division: divisionModule(axiosInstance),
    project: projectModule(axiosInstance),
    fc: fcModule(axiosInstance),
    mpc: mpcModule(axiosInstance),
    schedule: scheduleModule(axiosInstance),
    profiling: profilingModule(axiosInstance),
    test: testModule(axiosInstance),
    tsd: tsdModule(axiosInstance),
    pilot: pilotModule(axiosInstance),
    comment: commentModule(axiosInstance),
    library: libraryModule(axiosInstance),
    report: reportModule(axiosInstance),
    notification: notificationModule(axiosInstance),
    introduction: introductionModule(axiosInstance),
}