import requestSender from "./base/requestSender";

export default function(axiosInstance){
    return {
        search(payload){
            return requestSender('get', 'project/search', payload);
        },
        get(payload){
            return requestSender('get', 'project/get', payload);
        },
        getWorkgroupUsers(payload){
            return requestSender('get', 'project/get-workgroup-users', payload);
        },
        getPilotUsers(payload){
            return requestSender('get', 'project/get-pilot-users', payload);
        },
        addUsers(payload){
            return requestSender('post', 'project/add-users', payload);
        },
        deleteUser(payload){
            return requestSender('post', 'project/delete-user', payload);
        },
        setMainUser(payload){
            return requestSender('post', 'project/set-main-user', payload);
        },
        setUserTsdObjectid(payload){
            return requestSender('post', 'project/set-user-tsd-objectid', payload);
        },
        save(payload){
            return requestSender('post', 'project/save', payload);
        },
        delete(payload){
            return requestSender('post', 'project/delete', payload);
        },
        restore(payload){
            return requestSender('post', 'project/restore', payload);
        },
        getDeleted(payload){
            return requestSender('get', 'project/get-deleted', payload);
        },

        saveElement(payload){
            return requestSender('post', 'project/save-element', payload);
        },

        setStatus(payload){
            return requestSender('post', 'project/set-status', payload);
        },

    }
}