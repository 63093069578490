import requestSender from "./base/requestSender";

export default function(axiosInstance){
    return {
        getRoles(){
            return requestSender('get', 'rbac/get-roles');
        },
        getElementsForAclTable(){
            return requestSender('get', 'rbac/get-elements-for-table');
        },
        saveAclGroupPermission(payload){
            return requestSender('post', 'rbac/save-group-permission', payload);
        },

        revokeUsersFromGroup(payload){
            return requestSender('post', 'rbac/revoke-users-from-group', payload);
        },
        assignUsersToGroup(payload){
            return requestSender('post', 'rbac/assign-users-to-group', payload);
        },

    }
}