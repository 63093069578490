<template>
  <el-row class="pagination" type="flex" justify="center" align="middle">
    <el-pagination
        v-if="paginator"
        class="pagination__list"
        background
        layout="prev, pager, next"
        :page-count="paginator.pageCount"
        :currentPage="paginator.page"
        @current-change="pageChanged"
    >
    </el-pagination>

    <div
      v-if="!hideAmount"
      class="pagination__amount"
    >
      <el-button
          :class="{ active: pageSize === paginator.perPage }"
          v-for="pageSize in paginator.perPageSizes"
          :key="pageSize"
          @click="setPerPage(pageSize)"
      >
        {{ pageSize }}
      </el-button>
    </div>
  </el-row>
</template>

<script>
export default {
  name: "paginator",
  props: {
    paginator: {
      type: Object,
    },
    hideAmount: {type: Boolean, default: false},
  },
  data() {
    return {}
  },
  methods: {
    pageChanged(page) {
      this.setPaginatorPage(page)
      this.$emit('page-changed', page);
    },

    setPaginatorPage(page) {
      this.paginator.page = page;
      this.$emit('update:paginator', this.paginator);
    },
    setPerPage(perPage) {
      this.paginator.page = 1;
      this.paginator.perPage = perPage;
      this.$emit('update:paginator', this.paginator);
      this.$emit('page-changed');
    },
  },
}
</script>

<style lang="scss">
@import "./src/assets/css/project-variables";

.pagination {
  position: relative;
  margin: 32px 0 0;

  &__amount {
    position: absolute;
    top: 50%;
    right: 0;
    display: flex;
    align-items: center;
    transform: translateY(-50%);

    .el-button.el-button--default {
      padding: 0;
      margin: 0 16px 0 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.5;
      color: $--color-text-regular-4;
      border: none;

      &:last-child {
        margin-right: 0;
      }

      &.active {
        color: $--color-primary;
      }

      &:hover, &:focus {
        background-color: transparent;
        color: $--color-primary;
      }
    }
  }
}

</style>
