<template>
  <div class="page">
    <div class="page__head">
      <el-row type="flex" justify="space-between" align="top">
        <div class="page__head-left">
          <div class="title-edit">
            <h1 class="page__head-title">Функциональная карта</h1>

            <click-to-edit
                v-model="card.name"
                :disabled="!$can('fc-create-area', card)"
                :word-limit="200"
                @input="saveCardName"
            ></click-to-edit>
          </div>
        </div>
      </el-row>
    </div>

    <div class="settings">
      <div class="settings__left">
        <el-checkbox
            v-model="myAndSharedFilter"
            @change="getCard"
            style="margin-right: 24px;"
        >
          Только мои области
        </el-checkbox>

        <deleted-areas-modal
            v-if="$can('fc-see-deleted-areas', card)"
            :fc-id="card.id"
            @area-restored="getCard"
        >
          <el-button
              style="padding: 0"
              slot="reference"
              type="text"
              size="small"
              icon="fas fa-trash"
          >
            Журнал удаленных функций
          </el-button>
        </deleted-areas-modal>
      </div>

      <div class="settings__right">
        <el-button
          v-if="$can('fc-export', card)"
            type="primary"
            plain
            @click="exportToExcel"
        >Выгрузить в Excel
        </el-button>

        <el-button
            v-show="!!selectedAreasArray.length"
            type="primary"
            icon="fas fa-copy"
            :loading="loadingCopies"
            @click="copySelectedToMPC"
        >Скопировать в МПК
        </el-button>
      </div>
    </div>

    <el-row type="flex" justify="space-between" style="margin:15px 0">
      <!--            <div>-->
      <!--                <el-input-->
      <!--                    v-model="search"-->
      <!--                    prefix-icon="el-icon-search"-->
      <!--                    placeholder="Найти..."-->
      <!--                    size="small"-->
      <!--                    clearable-->
      <!--                    @clear="searchChanged"-->
      <!--                    v-debounce:500="searchChanged"-->
      <!--                >-->
      <!--                </el-input>-->
      <!--            </div>-->

    </el-row>

    <div v-loading="loading">
      <div class="fc-table">
        <el-row
            class="fc-table__head"
            type="flex"
            align="middle"
            justify="space-between"
        >
          <el-col
              :span="1"
              class="fc-table__head-col"
          >
            <el-checkbox
                :indeterminate="isCheckAllIndeterminate"
                v-model="checkAll"
                @change="handleCheckAllChange">
              №
            </el-checkbox>
          </el-col>

          <el-col
              :span="4"
              class="fc-table__head-col"
          >
            Область деятельности
          </el-col>

          <el-col :span="18" class="fc-table__head-col">
            <el-row
                type="flex"
                align="middle"
                justify="space-between"
            >
              <el-col :span="4" class="fc-table__head-col">
                Обобщенные функции
              </el-col>

              <el-col :span="20">
                <el-row
                    type="flex"
                    align="middle"
                    justify="space-between"
                >
                  <el-col :span="5" class="fc-table__head-col">
                    Функции
                  </el-col>
                  <el-col :span="7" class="fc-table__head-col">
                    Знания
                  </el-col>
                  <el-col :span="7" class="fc-table__head-col">
                    Навыки
                  </el-col>
                  <el-col :span="5" class="fc-table__head-col">
                    Источники
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-col>

          <el-col :span="1" class="fc-table__head-col"></el-col>
        </el-row>

        <el-row
            v-for="(area, areaIndex) in areas"
            class="fc-table__row"
            :class="{ highlight: isAreaHighlighted(area)} "
            :key="area.id"
            type="flex"
            style="flex-wrap: wrap;"
        >
          <el-col :span="1" class="fc-table__col">
            <el-checkbox
                v-model="selectedAreas[area.id]"
                :disabled="!canSelectAreaToCopy(area)"
                @change="handleCheckedAreaChange"
            >
              {{ area.order }}
            </el-checkbox>
          </el-col>

          <el-col :span="4" class="fc-table__col">
            <click-to-edit
                class="fc-table-element-area"
                v-model="area.name"
                :word-limit="200"
                :disabled="!$can('fc-update-area', area)"
                type="textarea"
                @input="saveElement($constants.fc.elementTypes.TYPE_AREA, area, card)"
            ></click-to-edit>

            <entity-comments
                v-if="area.id"
                :project-id="parseInt($route.params.id)"
                :entity-type="$constants.comment.entityTypes.FC"
                :entity-id="area.id"
            >
            </entity-comments>
          </el-col>

          <el-col :span="18" class="fc-table__head-col">
            <el-row
                v-for="generalFunction in area.general_functions"
                :key="generalFunction.id"
                type="flex"
                class="bordered indent"
            >
              <el-col :span="4" class="fc-table__col has-delete-button has-delete-button--min">
                <click-to-edit
                    class="fc-table-element-function"
                    v-model="generalFunction.name"
                    :word-limit="200"
                    :disabled="!$can('fc-update-area', area)"
                    type="textarea"
                    @input="saveElement($constants.fc.elementTypes.TYPE_GENERAL_FUNCTION, generalFunction, area)"
                ></click-to-edit>
                <el-popconfirm
                  v-if="$can('fc-update-area', area)"
                  confirm-button-text='Да'
                  cancel-button-text='нет'
                  icon="el-icon-question"
                  title="Вы уверены, что хотите удалить элемент?"
                  @confirm="deleteElement($constants.fc.elementTypes.TYPE_GENERAL_FUNCTION, generalFunction)"
                >
                  <el-button
                      slot="reference"
                      class="delete-button"
                      :loading="loading"
                      :disabled="!generalFunction.id"
                      type="text"
                      icon="fas fa-trash"
                  ></el-button>
                </el-popconfirm>
              </el-col>

              <el-col :span="20">
                <el-row
                    v-for="func in generalFunction.functions"
                    :key="func.id"
                    type="flex"
                    class="bordered indent"
                >
                  <el-col :span="5" class="fc-table__head-col has-delete-button has-delete-button--min">
                    <click-to-edit
                        class="fc-table-element-function"
                        v-model="func.name"
                        :word-limit="200"
                        :disabled="!$can('fc-update-area', area)"
                        type="textarea"
                        @input="saveElement($constants.fc.elementTypes.TYPE_FUNCTION, func, generalFunction)"
                    ></click-to-edit>
                    <el-popconfirm
                      v-if="$can('fc-update-area', area)"
                      confirm-button-text='Да'
                      cancel-button-text='нет'
                      icon="el-icon-question"
                      title="Вы уверены, что хотите удалить элемент?"
                      @confirm="deleteElement($constants.fc.elementTypes.TYPE_FUNCTION, func)"
                    >
                      <el-button
                          slot="reference"
                          class="delete-button"
                          :loading="loading"
                          :disabled="!func.id"
                          type="text"
                          icon="fas fa-trash"
                      ></el-button>
                    </el-popconfirm>
                  </el-col>

                  <el-col :span="7" class="fc-table__head-col">
                    <div
                      class="fc-table-element bordered has-delete-button"
                      v-for="experience in func.experiences"
                      :key="experience.id"
                    >
                      <click-to-edit
                          class="fc-table-element-other"
                          v-model="experience.name"
                          :word-limit="250"
                          show-word-limit
                          :disabled="!$can('fc-update-area', area)"
                          type="textarea"
                          @input="saveElement($constants.fc.elementTypes.TYPE_EXPERIENCE, experience, func)"
                      ></click-to-edit>
                      <el-popconfirm
                        v-if="$can('fc-update-area', area)"
                        confirm-button-text='Да'
                        cancel-button-text='нет'
                        icon="el-icon-question"
                        title="Вы уверены, что хотите удалить элемент?"
                        @confirm="deleteElement($constants.fc.elementTypes.TYPE_EXPERIENCE, experience)"
                      >
                        <el-button
                            slot="reference"
                            class="delete-button"
                            :loading="loading"
                            :disabled="!experience.id"
                            type="text"
                            icon="fas fa-trash"
                        ></el-button>
                      </el-popconfirm>
                    </div>
                    <el-button
                        v-if="$can('fc-update-area', area)"
                        type="text"
                        size="mini"
                        icon="fas fa-plus"
                        :disabled="!func.id"
                        @click="func.experiences.push({})"
                    >
                      Добавить знание
                    </el-button>
                  </el-col>

                  <el-col :span="7" class="fc-table__head-col">
                    <div
                      class="fc-table-element bordered has-delete-button"
                      v-for="skill in func.skills"
                      :key="skill.id"
                    >
                      <click-to-edit
                          class="fc-table-element-other"
                          v-model="skill.name"
                          :word-limit="250"
                          show-word-limit
                          :disabled="!$can('fc-update-area', area)"
                          type="textarea"
                          @input="saveElement($constants.fc.elementTypes.TYPE_SKILL, skill, func)"
                      ></click-to-edit>
                      <el-popconfirm
                        v-if="$can('fc-update-area', area)"
                        confirm-button-text='Да'
                        cancel-button-text='нет'
                        icon="el-icon-question"
                        title="Вы уверены, что хотите удалить элемент?"
                        @confirm="deleteElement($constants.fc.elementTypes.TYPE_SKILL, skill)"
                      >
                        <el-button
                            slot="reference"
                            class="delete-button"
                            :loading="loading"
                            :disabled="!skill.id"
                            type="text"
                            icon="fas fa-trash"
                        ></el-button>
                      </el-popconfirm>
                    </div>
                    <el-button
                        v-if="$can('fc-update-area', area)"
                        type="text"
                        size="mini"
                        icon="fas fa-plus"
                        :disabled="!func.id"
                        @click="func.skills.push({})"
                    >Добавить навык
                    </el-button>
                  </el-col>

                  <el-col :span="5" class="fc-table__head-col">
                    <div
                      class="fc-table-element bordered has-delete-button"
                      v-for="source in func.sources"
                      :key="source.id"
                    >
                      <click-to-edit
                          class="fc-table-element-other"
                          v-model="source.name"
                          :word-limit="250"
                          show-word-limit
                          :disabled="!$can('fc-update-area', area)"
                          type="textarea"
                          @input="saveElement($constants.fc.elementTypes.TYPE_SOURCE, source, func)"
                      ></click-to-edit>
                      <el-popconfirm
                        v-if="$can('fc-update-area', area)"
                        confirm-button-text='Да'
                        cancel-button-text='нет'
                        icon="el-icon-question"
                        title="Вы уверены, что хотите удалить элемент?"
                        @confirm="deleteElement($constants.fc.elementTypes.TYPE_SOURCE, source)"
                      >
                        <el-button
                            slot="reference"
                            class="delete-button"
                            :loading="loading"
                            :disabled="!source.id"
                            type="text"
                            icon="fas fa-trash"
                        ></el-button>
                      </el-popconfirm>
                    </div>
                    <el-button
                        v-if="$can('fc-update-area', area)"
                        type="text"
                        size="mini"
                        icon="fas fa-plus"
                        :disabled="!func.id"
                        @click="func.sources.push({})"
                    >Добавить источник
                    </el-button>
                  </el-col>
                </el-row>

                <el-row class="indent">
                  <el-button
                      v-if="$can('fc-update-area', area)"
                      type="text"
                      size="mini"
                      icon="fas fa-plus"
                      :disabled="!generalFunction.id"
                      @click="generalFunction.functions.push({experiences:[], skills:[], sources:[]})"
                  >
                    Добавить функцию
                  </el-button>
                </el-row>
              </el-col>
            </el-row>

            <el-row class="indent">
              <el-button
                  v-if="$can('fc-update-area', area)"
                  type="text"
                  size="mini"
                  icon="fas fa-plus"
                  :disabled="!area.id"
                  @click="area.general_functions.push({functions:[]})"
              >
                Добавить обобщенную функцию
              </el-button>
            </el-row>
          </el-col>

          <el-col :span="1" class="fc-table__col">
            <ellipsis-menu
                v-if="area.id"
            >
              <el-button
                  v-if="area.mpc_competence && area.mpc_competence.id"
                  type="text"
                  size="small"
                  icon="fas fa-link"
                  @click="$router.push({ name: 'Mpc', params: { id: $route.params.id }, query:{ to_mpc: area.mpc_competence.id} })"
              >
                Показать в МПК
              </el-button>
              <edit-history-modal
                v-if="$can('fc-see-area-history', area)"
                title="История изменений области деятельности"
                show-model-full-name
                width="70%"
                :method="$api.fc.getAreaHistory"
                :params="{  area_id: area.id }"
              >
                <el-button
                    slot="reference"
                    type="text"
                    size="small"
                    icon="fas fa-history"
                >
                  История изменений
                </el-button>
              </edit-history-modal>
              <fc-shared-users-dialog
                  :area-id="area.id"
              >
                <el-button
                    slot="reference"
                    type="text"
                    size="small"
                    icon="fas fa-users"
                    :loading="loading"
                >
                  Ответственные
                </el-button>
              </fc-shared-users-dialog>
              <el-popconfirm
                  v-if="$can('fc-update-area', area)"
                  confirm-button-text='Да'
                  cancel-button-text='нет'
                  icon="el-icon-question"
                  title="Вы уверены, что хотите удалить область деятельности?"
                  @confirm="deleteElement($constants.fc.elementTypes.TYPE_AREA, area)"
              >
                <el-button
                    slot="reference"
                    type="text"
                    size="small"
                    icon="el-icon-delete"
                    :loading="loading"
                >
                  Удалить область деятельности
                </el-button>
              </el-popconfirm>
            </ellipsis-menu>
          </el-col>
        </el-row>

        <el-button
            v-if="$can('fc-create-area', card)"
            type="primary"
            plain
            size="small"
            icon="fas fa-plus"
            @click="areas.push({can_update:true,general_functions:[]})"
            style="margin-top: 24px"
        >
          Добавить область деятельности
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>


import PageHeading from "../components/PageHeading";
import ClickToEdit from "../components/ClickToEdit";
import EllipsisMenu from "../components/EllipsisMenu";
import FcSharedUsersDialog from "../components/fc/SharedUsersDialog";
import {downloader} from "../mixins/downloader";
import DeletedAreasModal from "../components/fc/DeletedAreasModal";
import EntityComments from "../components/comments/EntityComments";
import EditHistoryModal from "@/components/EditHistoryModal";

export default {
  name: 'FC',
  mixins: [downloader],
  components: {
    EditHistoryModal,
    DeletedAreasModal,
    FcSharedUsersDialog,
    EllipsisMenu,
    ClickToEdit,
    PageHeading,
    EntityComments
  },

  data() {
    return {
      loading: false,
      rowWasHighlighted: false,

      search: null,
      myAndSharedFilter: false,

      isCheckAllIndeterminate: true,
      checkAll: false,

      loadingCopies: false,
      selectedAreas: {},

      card: {
        id: null,
        name: null,
        status: null,
      },
      areas: [],

    }
  },
  computed: {
    selectedAreasArray() {
      return Object.entries(this.selectedAreas).filter((arr) => arr[1]).map(arr => arr[0]);
    },

  },

  mounted() {
    this.getCard();
  },
  methods: {
    isAreaHighlighted(area) {
      if (this.rowWasHighlighted) {
        return false;
      }
      let areaIdToHighlight = this.$route.query.to_area;
      if (!areaIdToHighlight) {
        return false;
      }
      console.log(area.id, areaIdToHighlight);
      if (area.id == areaIdToHighlight) {
        setTimeout(() => {
          let highlightedElements = document.getElementsByClassName('highlight');
          if (highlightedElements.length) {
            highlightedElements[0].scrollIntoView();

            // fix Menu height
            window.scrollTo(0, window.scrollY - 110);
          }
          setTimeout(() => {
            this.rowWasHighlighted = true;
          }, 4000);
        }, 200);
        return true;
      }

      return false;
    },
    getCard() {
      this.loading = true;
      this.$api.fc.getProjectCard({
        project_id: this.$route.params.id,
        my_and_shared: this.myAndSharedFilter ? 1 : null,
      })
          .then(data => {
            this.card = data.fc;
            this.areas = data.fc.areas;
          })
          .finally(() => {
            this.loading = false;
            this.handleCheckedAreaChange();
          })
    },

    saveCardName() {
      this.loading = true;
      this.$api.fc.saveCardName({
        card_id: this.card.id,
        name: this.card.name,
      })
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDuration,
              title: 'Сохранено',
              message: 'Элемент успешно сохранен'
            });
          })
          .finally(() => {
            this.loading = false;
          })
    },
    saveElement(elementType, element, parent) {
      this.loading = true;
      this.$api.fc.saveElement({
        type: elementType,
        id: element.id,
        name: element.name,
        parent_id: parent.id,
      })
        .then(data => {
          element.id = data.element.id;
          if (data.element.order) { // for areas only
            element.order = data.element.order;
            element.can_copy_to_mpc = data.element.can_copy_to_mpc;
            element.can_see_history = data.element.can_see_history;
            element.can_set_user = data.element.can_set_user;
            element.can_update = data.element.can_update;
          }

          this.$notify.success({
            duration: this.$constants.global.notifyDuration,
            title: 'Сохранено',
            message: 'Элемент успешно сохранен'
          });
        })
        .finally(() => {
          this.loading = false;
          this.$store.dispatch('updateMenu');
        })
    },

    deleteElement(elementType, element) {
      this.loading = true;
      this.$api.fc.deleteElement({
        type: elementType,
        id: element.id
      })
        .then(data => {
          this.$notify.success({
            duration: this.$constants.global.notifyDuration,
            title: 'Удалено',
            message: 'Элемент успешно удален'
          });
        })
        .finally(()=>{
          this.getCard();
        })
    },


    canSelectAreaToCopy(area) {
      return !area.mpc_competence && this.$can('fc-copy-area-to-mpc', area)
    },

    copySelectedToMPC() {
      this.loadingCopies = true;
      this.$api.mpc.createCompetencesFromFcAreas({
        areas_ids: this.selectedAreasArray,
        fc_id: this.card.id
      })
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDuration,
              title: 'Сохранено',
              message: 'Элементы МПК успешно сохранены'
            });
            this.getCard();
          })
          .finally(() => {
            this.loadingCopies = false;
            this.resetAllSelected();
            this.$store.dispatch('updateMenu');
          })
    },


    handleCheckAllChange(val) {
      if (!val) {
        this.resetAllSelected();
      } else {
        this.areas.forEach(area => {
          if (this.canSelectAreaToCopy(area)) {
            this.$set(this.selectedAreas, area.id, true);
          }
        })
      }
      this.isCheckAllIndeterminate = false;
    },

    resetAllSelected() {
      for (let selectedProp in this.selectedAreas) {
        if (this.selectedAreas.hasOwnProperty(selectedProp)) {
          this.$set(this.selectedAreas, selectedProp, false)
          // this.selectedAreas[selectedProp] = false;
        }
      }
    },

    handleCheckedAreaChange(value) {
      let checkedCount = this.selectedAreasArray.length;
      this.checkAll = (checkedCount === this.areas.length);
      this.isCheckAllIndeterminate = checkedCount > 0 && checkedCount < this.areas.length;
    },


    exportToExcel() {
      this.downloadFile(
          false,
          this.$api.fc.exportFcToExcel,
          {
            project_id: this.$route.params.id,
            my_and_shared: this.myAndSharedFilter ? 1 : null,
          }
      );
    }
  }
}

</script>

<style lang="scss">
@import "./src/assets/css/project-variables";

.fc-table {
  font-size: 14px;

  &__head {
    padding: 16px 0;
    background-color: $--color-divider-3;
    border-radius: 6px;

    &-col {
      padding: 0 16px;
    }
  }

  &__row {
    padding: 16px 0;
    margin: 0 0 16px;
    border-bottom: 1px solid $--color-divider;
  }

  &__col {
    padding: 0 16px;
  }

  .comments {
    padding-top: 24px;

    &__title {
      font-size: 16px;
      margin-bottom: 16px;
    }

    &__list {
      margin-bottom: 16px;
    }

    &__item {
      padding-bottom: 16px;
      margin-bottom: 16px;

      &-inner {
        max-width: 100%;
      }

      &-avatar {
        width: 40px !important;
        height: 40px !important;
      }

      &-text {
        margin-bottom: 4px;
      }

      &-delete {
        width: 16px;
        height: 16px;
      }

      &-name,
      &-date {
        font-size: 12px;

        + span {
          font-size: 12px;
        }
      }
    }

    .edit-block__value {

      > span {
        font-size: 14px;
      }
    }

    &__add {

      .el-input {
        margin-bottom: 8px;

        &__inner {
          padding: 8px 12px;
        }
      }

      .el-button {
        padding: 6px 12px;
        font-size: 12px;
        text-transform: none;
      }
    }
  }

  @media only screen and (max-width: 1480px) {
    font-size: 12px;

    &__head {
      padding-top: 8px;
      padding-bottom: 8px;

      &-col {
        padding-right: 8px;
        padding-left: 8px;
      }
    }

    &__row {
      padding-top: 8px;
    }

    &__col {
      padding-right: 8px;
      padding-left: 8px;
    }

    &-element-area {
      font-size: 16px !important;
    }

    .fc-table-element-other {
      font-size: 14px;
    }

    .comments__item-name {
      font-size: 10px;
    }

    .comments {
      padding-top: 16px;

      &__title {
        font-size: 14px;
        margin-bottom: 8px;
      }

      &__list {
        margin-bottom: 8px;
      }

      &__item {
        padding-bottom: 8px;
        margin-bottom: 8px;

        &-avatar {
          width: 24px !important;
          height: 24px !important;
        }
      }
    }
  }
}

.bordered {
  border: 1px solid $--color-divider;
  border-radius: 6px;
}

.indent {
  padding: 4px;
  margin: 4px;
}

.fc-table-header {
  background-color: #F2F2F2;
  color: #797979;
  padding: 10px 10px;
}

.fc-table-area-row {
  margin-top: 10px;
}

.fc-table-element {
  padding: 6px;
  margin-bottom: 6px;
}

.fc-table-element-area {
  font-size: 20px;
}

.fc-table-element-function {
  font-size: 16px;

  @media only screen and (max-width: 1480px) {
    font-size: 12px;
  }
}

.fc-table-element-other {
  font-size: 16px;

  .edit-block__value {

    > span {
      max-width: 80%;
      margin-right: 0;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.fc-table .has-delete-button {
  position: relative;

  .edit-block__value {

    span {
      margin-right: 16px;

      @media only screen and (max-width: 1480px) {
        margin-right: 12px;
      }
    }

    svg {
      margin-top: 4px;
      margin-right: 24px;
    }
  }

  &--min {

    .edit-block__value {

      svg {
        margin-top: 6px;
      }
    }

    .delete-button {
      top: 4px;
      right: 0px;
    }
  }
}

.fc-table .has-delete-button:hover .delete-button {
  display: unset;
}

.fc-table .delete-button {
  position: absolute;
  top: 8px;
  right: 0px;
  padding: 0;
  display: none;
  /*color:black;*/
}

.page {

  &__head {

    .click-to-edit-div {

      .edit-block__value  svg {
        display: none;
      }
    }
  }
}

</style>
