import requestSender from "./base/requestSender";

export default function(axiosInstance){
    return {
        getProjectCard(payload){
            return requestSender('get', 'fc/get-project-card', payload);
        },
        exportFcToExcel(payload, options){
            return requestSender('post', 'fc/export-to-excel', payload, options);
        },
        saveCardName(payload){
            return requestSender('post', 'fc/save-card-name', payload);
        },
        saveElement(payload){
            return requestSender('post', 'fc/save-element', payload);
        },
        deleteElement(payload){
            return requestSender('post', 'fc/delete-element', payload);
        },


        getDeletedAreas(payload){
            return requestSender('get', 'fc/get-deleted-areas', payload);
        },
        restoreArea(payload){
            return requestSender('post', 'fc/restore-area', payload);
        },

        getAreaSharedUsers(payload){
            return requestSender('get', 'fc/get-area-shared-users', payload);
        },
        addNewAreaSharedUsers(payload){
            return requestSender('post', 'fc/add-new-area-shared-users', payload);
        },
        deleteAreaSharedUser(payload){
            return requestSender('post', 'fc/delete-area-shared-user', payload);
        },

        getAreaHistory(payload){
            return requestSender('get', 'fc/get-area-history', payload);
        },
    }
}