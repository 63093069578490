<template>
  <div>
    <el-table
        class="MPC-table"
        row-class-name="no-hover"
        empty-text="Нет компетенций"
        style="width: 100%"
        border
        :data="competences"
        :row-class-name="getRowClass"

        v-loading="loading"
    >


      <!--            <el-table-column width="100" align="right" label="№ п/п">-->
      <!--                <template slot-scope="scope">-->
      <!--                    <Container-->
      <!--                        style="height: 100%"-->
      <!--                        group-name="dfasfsda1"-->
      <!--                        drag-handle-selector=".competence-block-drag-handle"-->
      <!--                        @drop="onCompetenceDrop(scope.row, $event)"-->
      <!--                    >-->
      <!--                    <Draggable :key="scope.row.order">-->
      <!--                        <el-row type="flex" justify="space-between">-->
      <!--                            <span class="competence-block-drag-handle">-->
      <!--                                <i class="fas fa-ellipsis-v" style="padding: 0"></i>-->
      <!--                                <i class="fas fa-ellipsis-v" style="padding: 0"></i>-->
      <!--                            </span>-->
      <!--                            <span>{{ scope.row.order }}.</span>-->
      <!--                        </el-row>-->
      <!--                    </Draggable>-->
      <!--                    </Container>-->
      <!--                </template>-->
      <!--            </el-table-column>-->

      <el-table-column label="">
        <template slot="header">
          <span style="">Название компетенции</span>
        </template>
        <template slot-scope="scope">
          <Container
              style="height: 100%"
              group-name="dfasfsda1"
              drag-handle-selector=".competence-block-drag-handle"
              @drop="onCompetenceDrop(scope.row, $event)"
          >
            <Draggable :key="scope.row.order">

              <el-row type="flex">
                <el-row type="flex" justify="space-between" style="width:60px; min-width:60px; padding-right: 8px;">
                  <span
                      v-if="$can('mpc-move-competence', scope.row)"
                      class="competence-block-drag-handle"
                  >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M22 17L18 21L14 17" stroke="#3D8BF4" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round"/>
                      <path d="M18 4L18 21" stroke="#3D8BF4" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round"/>
                      <path d="M2 7L6 3L10 7" stroke="#3D8BF4" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round"/>
                      <path d="M6 20L6 3" stroke="#3D8BF4" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round"/>
                    </svg>
                  </span>
                  <span style="padding-top: 4px;">{{ scope.row.order }}.</span>
                </el-row>
                <div style="width: 100%">
                  <click-to-edit
                      :disabled="!canUpdateOrCreateCompetence(scope.row)"
                      style="font-size: 18px; margin-bottom: 16px;"
                      v-model="scope.row.name"
                      type="textarea"
                      @input="saveCompetence(scope.row)"
                  ></click-to-edit>
                  <click-to-edit
                      :disabled="!canUpdateOrCreateCompetence(scope.row)"
                      style="width: 100%; margin-bottom: 16px;"
                      placeholder="Внесите сокращенное наименование компетенции"
                      v-model="scope.row.desc"
                      type="textarea"
                      :word-limit="40"
                      show-word-limit
                      @input="saveCompetence(scope.row)"
                  ></click-to-edit>
                  <div class="MPC-table__users" style="display: flex; justify-content: space-between">
                    <agree-section
                      :agreed-expert="!!scope.row.agreed_expert"
                      :can-finish="!scope.row.agreed_expert && scope.row.can_finish"

                      :agreed-expert-main="!!scope.row.agreed_expert_main"
                      :can-agree-expert-main="!!scope.row.agreed_expert && !scope.row.agreed_expert_main  && !!scope.row.can_agree_main"

                      :agreed-admin="!!scope.row.agreed_admin"
                      :can-agree-admin="!!scope.row.agreed_expert_main && !scope.row.agreed_admin && !!scope.row.can_agree_admin"

                      :can-reset-agree="!!scope.row.can_reset_agree"

                      text-finish="Если Вы уверены, что завершили работу над формированием компетенции, отправьте ее на согласование. Можете оставить комментарий:"
                      text-agree="Вы согласовываете компетенцию. Можете оставить комментарий:"
                      text-reject="Вы отклоняете компетенцию. Необходимо оставить комментарий:"
                      text-reset="Вы отправляете компетенцию на доработку. Необходимо оставить комментарий:"
                      title-finish="Направить на согласование"
                      title-agree="Согласование компетенции"
                      title-reject="Отклонение компетенции"
                      title-reset="Отправка на доработку"

                      :method="$api.mpc.setCompetenceAgreeStatus"
                      :method-params="{competence_id: scope.row.id}"

                      check-required-fields
                      :required-fields-check-result="!!scope.row.desc"
                      required-fields-check-result-message="Внесите сокращенное наименование компетенции"

                      @agreed="competenceAgreed(scope.row, $event)"
                    ></agree-section>
                    <div>
                      ID: {{scope.row.id}}
                    </div>
                  </div>
                </div>


              </el-row>



              <el-row>
                <entity-comments
                  v-if="scope.row.id"
                  :project-id="parseInt($route.params.id)"
                  :entity-type="$constants.comment.entityTypes.MPC"
                  :entity-id="scope.row.id"
                >
                </entity-comments>
              </el-row>

            </Draggable>

          </Container>

        </template>
      </el-table-column>

      <el-table-column label="Индикаторы компетенций">
        <template slot-scope="scope">
          <Container
              behaviour="contain"
              style="margin-bottom:20px;"
              :group-name="scope.order"
              drag-handle-selector=".indicator-block-drag-handle"
              @drop="onIndicatorsDrop(scope.row, $event)"
          >
            <Draggable v-for="indicator in scope.row.indicators" :key="indicator.id" class="competence-indicators">
              <div class="indicator-block">
                <span
                    v-if="$can('mpc-move-competence', scope.row)"
                    class="indicator-block-drag-handle"
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M22 17L18 21L14 17" stroke="#3D8BF4" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round"/>
                      <path d="M18 4L18 21" stroke="#3D8BF4" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round"/>
                      <path d="M2 7L6 3L10 7" stroke="#3D8BF4" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round"/>
                      <path d="M6 20L6 3" stroke="#3D8BF4" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round"/>
                    </svg>
                </span>

                <el-row type="flex">
                  <div style="margin-right:10px;">{{ scope.row.order }}.{{ indicator.order }}.</div>
                  <click-to-edit
                      :disabled="!canUpdateOrCreateCompetence(scope.row)"
                      v-model="indicator.name"
                      type="textarea"
                      @input="saveIndicator(indicator)"
                  ></click-to-edit>
                </el-row>

                <el-popconfirm
                    v-if="$can('mpc-update-competence', scope.row)"
                    confirm-button-text='Да'
                    cancel-button-text='нет'
                    icon="el-icon-question"
                    title="Вы уверены, что хотите удалить индикатор?"
                    @confirm="deleteIndicator(scope.row, indicator)"
                >
                  <el-button
                      slot="reference"
                      class="delete-button"
                      :loading="loading"
                      :disabled="!indicator.id"
                      type="text"
                      icon="fas fa-trash"
                  ></el-button>
                </el-popconfirm>
              <span>ID: {{indicator.id}}</span>
              </div>
            </Draggable>
          </Container>
          <el-button
              v-if="$can('mpc-update-competence', scope.row)"
              type="text"
              size="mini"
              icon="fas fa-plus"
              :disabled="!scope.row.id"
              @click="scope.row.indicators.push({order: null, mpc_competence_id: scope.row.id})"
          >
            Добавить индикатор
          </el-button>
        </template>
      </el-table-column>
      <el-table-column :width="hideSourcesColumn?'45px':'400px'">
        <template slot="header" slot-scope="scope">
          <div>
            <el-button
                style="font-size: 1.3rem"
                :icon="hideSourcesColumn ? 'far fa-arrow-alt-circle-left' : 'far fa-arrow-alt-circle-right'"
                type="text"
                @click="hideSourcesColumn = !hideSourcesColumn"
            ></el-button>
            <span v-show="!hideSourcesColumn" style="padding-left:10px">Источники</span>
          </div>
        </template>
        <template slot-scope="scope">
          <div v-show="!hideSourcesColumn">
            <div class="sources indicator-block" v-for="source in scope.row.sources" :key="source.id">
              <el-row type="flex">
                <click-to-edit
                    :disabled="!canUpdateOrCreateCompetence(scope.row)"
                    v-model="source.name"
                    type="textarea"
                    @input="saveSource(source)"
                ></click-to-edit>
              </el-row>

              <el-popconfirm
                  v-if="$can('mpc-update-competence', scope.row)"
                  confirm-button-text='Да'
                  cancel-button-text='нет'
                  icon="el-icon-question"
                  title="Вы уверены, что хотите удалить источник?"
                  @confirm="deleteSource(scope.row, source)"
              >
                <el-button
                    slot="reference"
                    class="delete-button"
                    :loading="loading"
                    :disabled="!source.id"
                    type="text"
                    icon="fas fa-trash"
                ></el-button>
              </el-popconfirm>
            </div>
            <el-button
                v-if="$can('mpc-update-competence', scope.row)"
                type="text"
                size="mini"
                icon="fas fa-plus"
                :disabled="!scope.row.id"
                @click="scope.row.sources.push({mpc_competence_id: scope.row.id})"
            >
              Добавить источник
            </el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column width="50" align="right">
        <template slot-scope="scope">

          <ellipsis-menu v-if="scope.row.id">
            <el-button
                v-if="scope.row.fc_area_id"
                type="text"
                size="small"
                icon="fas fa-link"
                @click="$router.push({ name: 'Fc', params: { id: $route.params.id }, query:{ to_area: scope.row.fc_area_id} })"
            >
              Показать в ФК
            </el-button>
            <edit-history-modal
                v-if="$can('mpc-see-competence-history', scope.row)"
                title="История изменений компетенции"
                show-model-full-name
                width="70%"
                :method="$api.mpc.getCompetenceHistory"
                :params="{  competence_id: scope.row.id }"
            >
              <el-button
                  slot="reference"
                  type="text"
                  size="small"
                  icon="fas fa-history"
              >
                История изменений
              </el-button>
            </edit-history-modal>
            <agree-history-modal
                v-if="scope.row.id && $can('mpc-see-competence-agree-history', scope.row)"
                :method="$api.mpc.getAgreeHistory"
                :params="{  competence_id: scope.row.id }"
            >
              <el-button
                  slot="reference"
                  type="text"
                  size="small"
                  icon="fas fa-check-double"
              >
                История согласований
              </el-button>
            </agree-history-modal>
            <el-button
              v-if="$can('mpc-move-competence', scope.row) && !!submodels.length"
              type="text"
              size="small"
              icon="fas fa-arrows-alt"
              :loading="loading"
              @click="moveToSubmodelModalShow(scope.row)"
            >
              Переместить в подмодель
            </el-button>
            <mpc-shared-users-dialog
                :competence-id="scope.row.id"
                @users-changed="$emit('new-competence-added')"
            >
              <el-button
                  slot="reference"
                  type="text"
                  size="small"
                  icon="fas fa-users"
                  :loading="loading"
              >
                Ответственные
              </el-button>
            </mpc-shared-users-dialog>
            <el-popconfirm
                v-if="$can('mpc-delete-competence', scope.row)"
                confirm-button-text='Да'
                cancel-button-text='нет'
                icon="el-icon-question"
                title="Вы уверены, что хотите удалить компетенцию?"
                @confirm="deleteCompetence(scope.row)"
            >
              <el-button
                  slot="reference"
                  type="text"
                  size="small"
                  icon="el-icon-delete"
                  :loading="loading"
              >
                Удалить компетенцию
              </el-button>
            </el-popconfirm>
          </ellipsis-menu>
        </template>
      </el-table-column>
    </el-table>

    <el-button
        v-if="$can('mpc-create-competence', mpc)"
        :disabled="mpc_submodel_id && competences.length >= $constants.mpc.MAX_SUBMODEL_COMPETENCES"
        style="margin-top:20px;"
        type="primary"
        plain
        size="mini"
        icon="fas fa-plus"
        @click="competences.push({indicators: [], order: null, mpc_id: mpc.id, mpc_submodel_id: mpc_submodel_id})"
    >
      Добавить компетенцию
    </el-button>

    <el-dialog
        title="Выберите подмодель"
        :close-on-click-modal="false"
        :visible.sync="moveToSubmodelModal.show"
        width="20%"
    >
      <el-row>
        <div
            v-for="submodel in submodels"
            style="margin-bottom: 10px;"
        >
          <el-button
              :disabled="submodel.competences.length >= $constants.mpc.MAX_SUBMODEL_COMPETENCES"
              style="width:100%"
              type="primary"
              plain
              :loading="moveToSubmodelModal.loading"
              @click="moveCompetenceToSubmodel(submodel)"
          >
            {{ submodel.name }}
          </el-button>
        </div>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="moveToSubmodelModal.show = false">Закрыть</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ClickToEdit from "../ClickToEdit";
import {Container, Draggable} from 'vue-dndrop';
import {applyDrag} from '../../utils/helpers';
import EllipsisMenu from "../EllipsisMenu";
import MpcSharedUsersDialog from "./SharedUsersDialog";
import AgreeHistoryModal from "../AgreeHistoryModal";
import EntityComments from "../comments/EntityComments";
import EditHistoryModal from "@/components/EditHistoryModal";
import AgreeSection from "@/components/AgreeSection";

export default {
  name: "competences-table",
  components: {
    AgreeSection,
    EditHistoryModal,
    AgreeHistoryModal,
    MpcSharedUsersDialog,
    EllipsisMenu,
    ClickToEdit,
    Container,
    Draggable,
    EntityComments
  },

  props: {
    submodels: { // для того, чтобы перемещать компетенции
      type: Array,
      default: function () {
        return []
      }
    },
    competences: {
      type: Array,
      default: function () {
        return []
      }
    },
    mpc: {
      type: Object
    },
    mpc_submodel_id: {
      type: Number
    },
  },

  data() {
    return {
      loading: false,
      hideSourcesColumn: true,
      rowWasHighlighted: false,

      moveToSubmodelModal: {
        loading: false,
        show: false,
        competenceId: null,
      },

      movedCompetence: {
        competenceId: null,
        removedOrder: null,
        addedOrder: null,
      },

    }
  },
  mounted() {
  },
  watch: {},
  methods: {

    getRowClass(row) {
      if (this.rowWasHighlighted) {
        return '';
      }
      let mpcIdToHighlight = this.$route.query.to_mpc;
      if (!mpcIdToHighlight) {
        return '';
      }
      if (row.row.id == mpcIdToHighlight) {
        setTimeout(() => {
          let highlightedElements = document.getElementsByClassName('highlight');
          if (highlightedElements.length) {
            highlightedElements[0].scrollIntoView();

            // fix Menu height
            window.scrollTo(0, window.scrollY - 110);
          }
          setTimeout(() => {
            this.rowWasHighlighted = true;
          }, 4000);
        }, 500);
        return 'highlight';
      }

      return '';
    },

    canUpdateOrCreateCompetence(competence) {
      if (competence.id) {
        return this.$can('mpc-update-competence', competence);
      }
      return this.$can('mpc-create-competence', this.mpc);
    },


    saveCompetence(competence) {
      this.loading = true;
      let isNewCompetence = !competence.id;
      this.$api.mpc.saveCompetence(competence)
          .then(data => {
            this.$notify.success({
            duration: this.$constants.global.notifyDuration,
              title: 'Сохранено',
              message: 'Компетенция успешно сохранена'
            });
            competence.id = data.competence.id;
            competence.name = data.competence.name;
            competence.desc = data.competence.desc;
            competence.order = data.competence.order;
          })
          .finally(() => {
            this.loading = false;

            // При новых компетенциях у нас пересчитывается порядок. Нужно заново загрузить все
            if (isNewCompetence) {
              this.$emit('new-competence-added')
            }
          })
    },

    deleteCompetence(competence) {
      if (!competence.id) {
        this.$emit('competence-deleted');
        return;
      }
      this.loading = true;
      this.$api.mpc.deleteCompetence({
        id: competence.id
      })
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDuration,
              title: 'Удалено',
              message: 'Компетенция успешно удалена'
            });
          })
          .finally(() => {
            this.loading = false;
            this.$emit('competence-deleted')
          })
    },


    onCompetenceDrop(competence, dragResult) {
      // console.log(competence.order, dragResult);

      if (dragResult.removedIndex !== null && dragResult.addedIndex !== null) {
        return;
      }

      if (dragResult.removedIndex !== null) {
        this.movedCompetence.competenceId = competence.id
        this.movedCompetence.removedOrder = competence.order
      }
      if (dragResult.addedIndex !== null) {
        if (this.movedCompetence.removedOrder) {
          // Значит, что уже был удаленный индекс и не нужно делать увеличение на 1
          this.movedCompetence.addedOrder = dragResult.addedIndex > 0 ? competence.order : competence.order - 1;
        } else {
          this.movedCompetence.addedOrder = dragResult.addedIndex > 0 ? competence.order + 1 : competence.order;
        }
      }
      if (this.movedCompetence.removedOrder && this.movedCompetence.addedOrder) {
        if (this.movedCompetence.removedOrder == this.movedCompetence.addedOrder) {
          return;
        }
        this.loading = true;
        this.$api.mpc.saveCompetenceOrder({
          competence_id: this.movedCompetence.competenceId,
          order: this.movedCompetence.addedOrder,
        })
            .finally(() => {
              this.loading = false;
              this.$emit('new-competence-added');
            })
        this.movedCompetence.competenceId = null;
        this.movedCompetence.removedOrder = null;
        this.movedCompetence.addedOrder = null;
      }


      // competence.indicators = applyDrag(competence.indicators, dragResult)
    },

    onIndicatorsDrop(competence, dragResult) {
      if (dragResult.removedIndex == dragResult.addedIndex) {
        return;
      }
      let movedIndicator = competence.indicators[dragResult.removedIndex];
      let newPosition = dragResult.addedIndex + 1;

      this.loading = true;
      this.$api.mpc.saveIndicatorOrder({
        indicator_id: movedIndicator.id,
        order: newPosition,
      })
          .then(data => {
            competence.indicators = data.indicators;
            if( data.refresh ){
              this.$emit('new-competence-added');
            }
          })
          .finally(() => {
            this.loading = false;
          })


      competence.indicators = applyDrag(competence.indicators, dragResult)
    },

    saveIndicator(indicator) {
      this.loading = true;
      this.$api.mpc.saveIndicator(indicator)
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDuration,
              title: 'Сохранено',
              message: 'Индикатор успешно сохранен'
            });
            indicator.id = data.indicator.id;
            indicator.name = data.indicator.name;
            indicator.order = data.indicator.order;
            if( data.refresh ){
              this.$emit('new-competence-added');
            }
          })
          .finally(() => {
            this.loading = false;
          })
    },

    deleteIndicator(competence, indicator) {
      this.loading = true;
      this.$api.mpc.deleteIndicator(indicator)
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDuration,
              title: 'Удалено',
              message: 'Индикатор успешно удален'
            });
            competence.indicators = data.indicators;
            if( data.refresh ){
              this.$emit('new-competence-added');
            }
          })
          .finally(() => {
            this.loading = false;
          })
    },

    saveSource(source) {
      this.loading = true;
      this.$api.mpc.saveSource(source)
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDuration,
              title: 'Сохранено',
              message: 'Источник успешно сохранен'
            });
            source.id = data.source.id;
            source.name = data.source.name;
          })
          .finally(() => {
            this.loading = false;
          })
    },

    deleteSource(competence, source) {
      this.loading = true;
      this.$api.mpc.deleteSource(source)
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDuration,
              title: 'Удалено',
              message: 'Источник успешно удален'
            });
            competence.sources = data.sources;
          })
          .finally(() => {
            this.loading = false;
          })
    },


    moveToSubmodelModalShow(competence) {
      this.moveToSubmodelModal.competenceId = competence.id;
      this.moveToSubmodelModal.show = true;
    },

    moveCompetenceToSubmodel(submodel) {
      this.moveToSubmodelModal.loading = true;
      this.$api.mpc.moveCompetenceToSubmodel({
        competence_id: this.moveToSubmodelModal.competenceId,
        submodel_id: submodel.id
      })
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDuration,
              title: 'Сохранено',
              message: 'Компетенция успешно перемещена'
            });
          })
          .finally(() => {
            this.moveToSubmodelModal.loading = false;
            this.moveToSubmodelModal.show = false;
            this.$emit('competence-deleted')
          })
    },

    competenceAgreed(competence, data) {
      competence.agreed_admin = data.competence.agreed_admin;
      competence.agreed_expert = data.competence.agreed_expert;
      competence.agreed_expert_main = data.competence.agreed_expert_main;
      competence.can_finish = data.competence.can_finish;
      competence.can_agree_main = data.competence.can_agree_main;
      competence.can_agree_admin = data.competence.can_agree_admin;
      competence.can_reset_agree = data.competence.can_reset_agree;
      competence.can_update = data.competence.can_update;
      competence.can_move = data.competence.can_move;

      this.$store.dispatch('updateMenu');
    }

  }
}
</script>

<style>
.agree-window {
  width: 600px;
}

.highlight {
  animation: blink 4s linear;
}

@keyframes blink {
  0% {
    background-color: rgba(226, 26, 26, 0.16);
  }
  25% {
    background-color: initial;
  }
  50% {
    background-color: rgba(226, 26, 26, 0.16);
  }
  100% {
    background-color: initial;
  }
}
</style>