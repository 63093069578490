import requestSender from "./base/requestSender";

export default function(axiosInstance){
    return {
        getProjectSchedule(payload){
            return requestSender('get', 'schedule/get-project-schedule', payload);
        },
        saveName(payload){
            return requestSender('post', 'schedule/save-name', payload);
        },
        saveItem(payload){
            return requestSender('post', 'schedule/save-item', payload);
        },
        saveBlock(payload){
            return requestSender('post', 'schedule/save-block', payload);
        },
        deleteItem(payload){
            return requestSender('post', 'schedule/delete-item', payload);
        },
        deleteBlock(payload){
            return requestSender('post', 'schedule/delete-block', payload);
        },
        setStatus(payload){
            return requestSender('post', 'schedule/set-status', payload);
        },

        exportToWord(payload, options){
            return requestSender('post', 'schedule/export-to-word', payload, options);
        },

    }
}