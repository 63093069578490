<template>
  <div v-if="isProjectPage" class="project-menu">
    <div class="project-menu__wrap">
      <el-menu
          v-loading="projectMenuLoading"
          class="project-menu__list"
          router
          :default-active="$route.path"
      >
        <el-menu-item
            class="project-menu__item"
            v-for="item in projectMenuItems"
            :key="item.index"
            :index="getIndex(item.index)"
            :route="{
                    name: item.routeName,
                    params: { id: $route.params.id}
                }"
            :disabled="!!item.disabled"
        >
          <div class="project-menu__item-name">
            {{ item.in_project_name }}
          </div>
          <div v-if="item.status" class="project-menu__item-status">
            {{ item.status }}
          </div>
          <div v-if="item.date" class="project-menu__item-date">
            {{ item.date }}
          </div>
        </el-menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "project-menu",
  props: {},
  watch: {
    isProjectPage: function(newVal, oldVal) {
      if( this.isProjectPage ){
        this.getData();
      }
    },
  },
  computed: {
    ...mapGetters(['projectMenuLoading', 'projectMenuItems']),

    isProjectPage() {
      let pathArray = this.$route.path.split('/');
      if (pathArray[1] !== 'project') {
        return false;
      }
      return true;
    },
    projectSubPage() {
      let pathArray = this.$route.path.split('/');
      if (pathArray[1] !== 'project') {
        return null;
      }
      return pathArray[3];
    }
  },
  data() {
    return {}
  },
  mounted() {
    if( this.isProjectPage ){
      this.getData();
    }
  },
  methods: {
    getData() {
      this.$store.dispatch('updateMenu');
    },

    getIndex(page) {
      return '/project/' + this.$route.params.id + '/' + page
    },
  },
}
</script>

<style lang="scss">
@import "./src/assets/css/project-variables";

.project-menu {
  margin: 0 0 32px 0;

  &__wrap {
    width: 100%;
    overflow: auto;
  }

  &__list {
    width: 100%;
    display: flex;
    border: 1px solid $--color-divider-2;
    border-radius: 6px;
  }

  &__item {
    position: relative;
    height: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 12px 32px 12px 16px !important;
    margin-right: 0 !important;
    line-height: 1;

    &-name {
      margin: 0 0 4px;
      font-size: 14px;
      font-weight: 700;
      line-height: 1.5;
      color: $--color-text-regular-3;
    }

    &-status, &-date {
      font-size: 14px;
      line-height: 1.5;
      color: $--color-success;
    }

    &-date {
      margin-top: auto;
      color: $--color-text-regular-4;
    }

    &:hover {
      background-color:$--color-divider-2;
    }

    &.is-active {
      background-color:$--color-divider-2;

      .project-menu__item-name {
        color: $--color-text-regular;
      }
    }
  }

  @media only screen and (max-width: 1680px) {
    margin-bottom: 24px;


    &__list {

      .project-menu__item {
        padding: 8px !important;
        font-size: 12px;

        &-name {
          font-size: 12px;
        }

        &-status, &-date {
          font-size: 10px;
        }
      }
    }
  }
}

</style>
