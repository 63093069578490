<template>
  <div>

    <el-table
      :data="workGroup"
      size="mini"
      style="width: 100%"
      v-loading="loading"
      @sort-change="sortChanged($event, getProjectWorkgroupUsers)"
    >
      <el-table-column prop="full_name" label="ФИО" :sortable="setIsSortable('full_name')"></el-table-column>
      <el-table-column prop="division" label="Подразделение"></el-table-column>
      <el-table-column prop="position" label="Должность"></el-table-column>
      <el-table-column prop="role.description" label="Роль"></el-table-column>
      <el-table-column label="Согласование">
        <template slot-scope="scope">
          <el-radio
            v-if="scope.row.role.name === 'admin'"
            :disabled="!$can('project-add-to-workgroup', project.permissions)"
            :value="mainUserId"
            :label="scope.row.id"
            @change="setMainUser(scope.row)"
          >&nbsp;
          </el-radio>
        </template>
      </el-table-column>
      <el-table-column
        v-if="$can('project-delete-from-workgroup', project.permissions)"
        width="60"
        align="right"
      >
        <template slot-scope="scope">
          <ellipsis-menu>
            <el-popconfirm
              confirm-button-text='Да'
              cancel-button-text='нет'
              icon="el-icon-question"
              title="Вы уверены, что хотите удалить пользователя?"
              @confirm="deleteUser(scope.row)"
            >
              <el-button
                slot="reference"
                type="text"
                size="small"
                icon="el-icon-delete"
              >Удалить
              </el-button>
            </el-popconfirm>
          </ellipsis-menu>
        </template>
      </el-table-column>

    </el-table>
    <paginator
      :paginator.sync="paginator"
      @page-changed="getProjectWorkgroupUsers"
    ></paginator>
  </div>
</template>

<script>
import {off, on} from "element-ui/src/utils/dom";
import AddUsersDialog from "../user/AddUsersDialog";
import {sorter} from "@/mixins/sorter";
import TsdSelect from "@/components/selects/TsdSelect";
import {paginator} from "@/mixins/paginator";
import Paginator from "../Paginator";
import EllipsisMenu from "@/components/EllipsisMenu";


export default {
  name: "project-workgroup-users-table",
  mixins: [sorter, paginator],
  components: {EllipsisMenu, TsdSelect, AddUsersDialog, Paginator},

  props: {
    project: {
      type: Object
    },
    mainUserId: {
      type: Number
    }
  },

  data() {
    return {
      loading: false,
      show: false,

      workGroup: [],
    }
  },
  mounted() {
    // this.getProjectWorkgroupUsers();
  },
  watch: {
    project(newVal) {
      if(newVal && newVal.id){
        this.getProjectWorkgroupUsers();
      }
    }
  },
  methods: {

    getProjectWorkgroupUsers() {
      this.loading = true;
      this.$api.project.getWorkgroupUsers({
        id: this.project.id,

        [this.sorter.param]: this.sorter.sort,
        [this.paginator.param]: this.paginator.page,
        [this.paginator.paramPerPage]: this.paginator.perPage,
      })
        .then(({data, paginator, sorter}) => {
          this.workGroup = data.work_group
          this.sorterFill(sorter);
          this.paginatorFill(paginator);
        })
        .finally(() => {
          this.loading = false;
        })
    },


    deleteUser(user) {
      this.loading = true;
      this.$api.project.deleteUser({
        project_id: this.project.id,
        user_id: user.id,
      })
        .then(data => {
          this.$notify.success({
            duration: this.$constants.global.notifyDuration,
            title: 'Удалено',
            message: 'Пользователи успешно удален'
          });
        })
        .finally(() => {
          this.getProjectWorkgroupUsers();
        })
    },

    setMainUser(user) {
      this.loading = true;
      this.$api.project.setMainUser({
        project_id: this.project.id,
        user_id: user.id,
      })
        .then(data => {
          this.$notify.success({
            duration: this.$constants.global.notifyDuration,
            title: 'Сохранено',
            message: 'Пользователи успешно сохранен'
          });
          this.$emit('update:mainUserId', user.id)
        })
        .finally(() => {
          this.loading = false;
        })
    },

  },
}
</script>

<style>

</style>