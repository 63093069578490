<template>
    <div>
        <page-heading
            heading="Тесты"
        >
            <span slot="help"></span>
            <span slot="right">

            </span>
        </page-heading>



        <el-card>
            <el-table
                v-loading="loading"
                size="mini"
                empty-text="Нет назначенных тестов"
                :data="assignments"
            >
                <el-table-column prop="test.name" label="Тест">
                    <template slot-scope="scope">
                        <span
                            v-if="scope.row.result === null"
                            class="el-link"
                            @click="$router.push({ name: 'TestPlayer', params: { id: scope.row.test_id } })"
                        >{{ scope.row.test.name }}</span>
                        <span
                            v-else
                        >{{ scope.row.test.name }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="" label="Статус">
                    <template slot-scope="scope">
                        {{scope.row.result !== null ? 'Пройден' : 'Не пройден' }}
                    </template>
                </el-table-column>
                <el-table-column prop="result" label="Результат">
                    <template slot-scope="scope">
                        {{scope.row.result !== null ? scope.row.result+'%' : '-'}}
                    </template>
                </el-table-column>

            </el-table>
        </el-card>





    </div>
</template>

<script>

import PageHeading from "../../components/PageHeading";

export default {
    name: 'TestsList',
    components: { PageHeading  },

    data() {
        return {
            loading: false,
            assignments:[],

        }
    },
    computed: { },

    mounted() {
        this.getTests();
    },
    methods: {
        getTests(){
            this.loading = true;
            this.$api.test.getMyTests()
                .then(data => {
                    this.assignments = data.assignments

                })
                .finally(()=>{
                    this.loading = false;
                })
        },



    }
}

</script>

<style>

.questions-timeline {
    padding-left: 10px;
}
.questions-timeline .el-timeline-item {
    padding-bottom: 1px;
}
.questions-timeline .el-timeline-item__timestamp {
    margin-top: 0;
}


.select-question-button {
    padding:0;
}
.answer-radio {
    display: block;
}

.el-divider.el-divider--horizontal{
    margin:10px 0;
}

.player-section-collapse .el-collapse-item__header{
    line-height: unset;
}
</style>

<style scoped>
.el-link{
    color: #3D8BF4;
}
</style>