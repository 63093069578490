<template>
  <div>
    <page-heading
        :heading="project.name"
    >
      <span slot="help"></span>
      <span slot="right">
        <el-button
            v-if="$can('project-see-workgroup')"
            type="primary"
            plain
            icon="el-icon-user-solid"
            @click="$router.push({name: 'ProjectUsers',params: { id: $route.params.id}})"
        >
          Состав рабочей группы
        </el-button>
        <el-button
            v-show="false"
            style="margin-left:10px;"
            type="primary"
            icon="el-icon-suitcase"
        >
            Актуализировать СПК
        </el-button>
      </span>
    </page-heading>

    <el-row>
      <el-col :span="5">
        <p class="project-desc"><b>Дата начала:</b> {{ new Date(project.date_start * 1000).toLocaleDateString() }}</p>
        <p class="project-desc"><b>Дата завершения:</b>
          {{ project.date_end ? new Date(project.date_end * 1000).toLocaleDateString() : '' }}</p>
      </el-col>

      <el-col :span="10">
        <p class="project-desc">
          <b>Статус проекта:</b>
          {{ $constants.project.statusesNames[project.status] }}
          <el-button
              v-if="$can('project-set-status', project.permissions)"
              style="display: inline"
              size="small"
              type="text"
              @click="setStatus(
              project.status !== $constants.project.statuses.STATUS_PAUSED
              ? $constants.project.statuses.STATUS_PAUSED
              : $constants.project.statuses.STATUS_PLANNED
            )"
          >
            <span v-if="project.status !== $constants.project.statuses.STATUS_PAUSED">Приостановить</span>
            <span v-else>Возобновить</span>
          </el-button>
        </p>
        <p class="project-desc">
          <b>Методолог: </b>
          {{ project.admins ? project.admins.map(a => a.full_name).join(', ') : '' }}
        </p>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="24">
        <el-table
            v-loading="loading"
            :data="projectElements"
            style="width: 100%"
        >
          <el-table-column>
            <template slot-scope="scope">
              <el-row type="flex">
                  <span class="link" style="margin-right: 8px;">
                    <i
                        class="fas fa-external-link-alt"
                        @click="$router.push({
                        name: scope.row.charAt(0).toUpperCase() + scope.row.slice(1),
                        params: { id: $route.params.id}
                    })"
                    ></i>
                  </span>

                <click-to-edit
                    v-if="project[scope.row]"
                    v-model="project[scope.row].in_project_name"
                    :disabled="!$can('project-set-element-name')"
                    @input="saveProjectElement(scope.row)"
                ></click-to-edit>
              </el-row>
            </template>
          </el-table-column>

          <el-table-column
              label="Дата начала этапа"
              width="200"
          >
            <template slot-scope="scope">
              <click-to-edit
                  v-if="project[scope.row]"
                  v-model="project[scope.row].date_start"
                  :disabled="!$can('project-set-element-name')"
                  transform-timestamp
                  type="date-picker"
                  @input="saveProjectElement(scope.row)"
              ></click-to-edit>
            </template>
          </el-table-column>
          <el-table-column
              label="Дата завершения"
              width="200"
          >
            <template slot-scope="scope">
              <click-to-edit
                  v-if="project[scope.row]"
                  v-model="project[scope.row].date_end"
                  :disabled="!$can('project-set-element-name')"
                  transform-timestamp
                  type="date-picker"
                  @input="saveProjectElement(scope.row)"
              ></click-to-edit>
            </template>
          </el-table-column>
          <el-table-column label="Статус" width="200">
            <template slot-scope="scope">
              {{
                ($constants.statusesMap[scope.row] ? ($constants.statusesMap[scope.row][project[scope.row].status]) : null)
              }}
            </template>
          </el-table-column>

          <el-table-column
              label=""
              align="left"
              class-name="is-middle"
          >
            <template slot-scope="scope">
              <div v-if="scope.row === 'mpc' || scope.row === 'profiling'">
                <el-button
                    :loading="loadingDownloader"
                    type="primary"
                    size="mini"
                    plain
                    @click="exportAgreeHistoryToExcel(scope.row)"
                >
                  История согласования
                </el-button>
                <el-button
                    :loading="loadingDownloader"
                    type="primary"
                    size="mini"
                    plain
                    @click="exportAgreeHistoryToExcel(scope.row, true)"
                >
                  Итоговый лист согласования
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import PageHeading from "../../components/PageHeading";
import ClickToEdit from "../../components/ClickToEdit";
import {downloader} from "@/mixins/downloader";

export default {
  name: 'Project',
  mixins: [downloader],
  components: {ClickToEdit, PageHeading},
  data() {
    return {
      loading: false,
      project: {
        id: null,
        name: null,
        date_start: null,
        date_end: null,
        main_user_id: null,
        status: null,

        fc: {id: null, in_project_name: null, date_start: null, date_end: null},
        mpc: {id: null, in_project_name: null, date_start: null, date_end: null},
        schedule: {id: null, in_project_name: null, date_start: null, date_end: null},
        profiling: {id: null, in_project_name: null, date_start: null, date_end: null},
        tests_development: {id: null, in_project_name: null, date_start: null, date_end: null},
        pilot: {id: null, in_project_name: null, date_start: null, date_end: null},
        introduction: {id: null, in_project_name: null, date_start: null, date_end: null},
      },
      projectElements: ['schedule', 'fc', 'mpc', 'profiling', 'tests_development', 'pilot', 'introduction'],

    }
  },
  computed: {},
  mounted() {
    this.getProject();
  },
  methods: {
    getProject() {
      this.loading = true;
      this.$api.project.get({
        id: this.$route.params.id,
        expand: 'permissions',
      })
          .then(data => {
            this.project = data.project
          })
          .finally(() => {
            this.loading = false;
          })
    },

    saveProjectElement(elementType) {
      this.loading = true;
      this.$api.project.saveElement({
        element_type: elementType,
        id: this.project[elementType].id,
        in_project_name: this.project[elementType].in_project_name,
        date_start: this.project[elementType].date_start,
        date_end: this.project[elementType].date_end,
      })
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDuration,
              title: 'Сохранено',
              message: 'Элемент успешно сохранен'
            });
          })
          .finally(() => {
            this.loading = false;
            this.$store.dispatch('updateMenu');
          })
    },
    setStatus(status) {
      this.loading = true;
      this.$api.project.setStatus({
        project_id: this.project.id,
        status: status,
      })
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDuration,
              title: 'Сохранено',
              message: 'Cтатус успешно сохранен'
            });
            this.getProject();
          })
          .finally(() => {
            this.loading = false;
            this.$store.dispatch('updateMenu');
          })
    },

    exportAgreeHistoryToExcel(element, short = false) {
      this.downloadFile(
          false,
          this.$api[element].exportAgreeHistoryToExcel,
          {
            id: this.project[element].id,
            short: short ? 1 : 0,
          }
      );
    },
  }
}

</script>

<style lang="scss" scoped>
@import "@/assets/css/project-variables.scss";

.link {
  color: $--color-primary;
  cursor: pointer;
}

.project-desc {
  margin-bottom: 8px;
}

</style>
