import Vue from "vue";
import Vuex from "vuex";

import Api from '../api/base'
import ConstantsPlugin from '../plugins/constants'
import router from '@/router'

Vue.use(Vuex);
Vue.use(ConstantsPlugin);



export default new Vuex.Store({
    state: {
        user: {
            id: null,
            permissions: [],
            chief: {}
        },

        projectMenuLoading: false,
        projectMenuItems: [
            {index: 'schedule', routeName: 'Schedule', in_project_name: 'План-график проекта', date: null, status: null},
            {index: 'fc', routeName: 'Fc', in_project_name: 'Функциональная карта', date: null, status: null},
            {index: 'mpc', routeName: 'Mpc', in_project_name: 'Разработка МПК', date: null, status: null},
            {index: 'profiling', routeName: 'Profiling', in_project_name: 'Профилирование', date: null, status: null},
            {index: 'tests_development', routeName: 'Tests_development', in_project_name: 'Разработка оценочных инструментов', date: null, status: null },
            {index: 'pilot', routeName: 'Pilot', in_project_name: 'Пилотная оценка СПК', date: null, status: null},
            {index: 'introduction', routeName: 'Introduction', in_project_name: 'Внедрение СПК', date: null, status: null},
        ],
    },
    getters: {
        user(state) {
            return state.user
        },

        projectMenuLoading(state) {
            return state.projectMenuLoading
        },
        projectMenuItems(state) {
            return state.projectMenuItems
        },
    },
    actions: {
        fetchUser(ctx) {
            Api.user.getAuthUser()
                .then(data => {
                    ctx.commit('updateUser', data.user);
                })
        },

        updateMenu(ctx, payload) {
            ctx.commit('updateProjectMenuLoading', true);
            Api.project.get({
                id: router.currentRoute.params.id,
            })
                .then(data => {
                    ctx.commit('updateProjectMenuItems', data.project);
                })
                .finally(() => {
                    ctx.commit('updateProjectMenuLoading', false);
                })
        },

    },

    mutations: {
        updateUser(state, user) {
            state.user = user;
        },

        updateProjectMenuLoading(state, result) {
            state.projectMenuLoading = result;
        },
        updateProjectMenuItems(state, project) {
            state.projectMenuItems.forEach(item => {
                state.projectType = project.type;
                item.in_project_name = project[item.index].in_project_name;
                item.date = (project[item.index].date_start ? new Date(project[item.index].date_start * 1000).toLocaleDateString() : '') + (project[item.index].date_end ? ' - ' + new Date(project[item.index].date_end * 1000).toLocaleDateString() : '')
                item.status = (Vue.prototype.$constants.statusesMap[item.index] ? (Vue.prototype.$constants.statusesMap[item.index][project[item.index].status]) : null)
            })
        }
    }
    // modules: {
    //     user
    // }
})