<template>
  <el-dialog
    :title=" userCopy.id ? 'Редактирование пользователя' : 'Создание пользователя'"
    :visible="show"
    :close-on-click-modal="false"
    width="30%"
    top="2vh"
    append-to-body
    @close="$emit('update:show', false)"
  >
    <el-row>
      <el-form
        ref="userForm"
        label-width="180px"
        label-position="left"
        :model="userCopy"
        :rules="rules"
      >
        <el-form-item label="Роль" prop="role">
          <role-select
            size=""
            :hide-roles="['pilot']"
            v-model="userCopy.role.name"
          ></role-select>
        </el-form-item>
        <el-form-item label="Фамилия" prop="name_last">
          <el-input maxlength="100" v-model="userCopy.name_last"></el-input>
        </el-form-item>
        <el-form-item label="Имя" prop="name_first">
          <el-input maxlength="100" v-model="userCopy.name_first"></el-input>
        </el-form-item>
        <el-form-item label="Отчество" prop="name_second">
          <el-input maxlength="100" v-model="userCopy.name_second"></el-input>
        </el-form-item>
        <el-form-item label="Подразделение" prop="division">
          <el-input maxlength="200" v-model="userCopy.division"></el-input>
          <!--                    <division-select-->
          <!--                        size=""-->
          <!--                        v-model="userCopy.division_id"-->
          <!--                        :saved-name="userCopy.division ? userCopy.division.name : ''"-->
          <!--                    ></division-select>-->
        </el-form-item>
        <el-form-item label="Должность" prop="position">
          <el-input maxlength="200" v-model="userCopy.position"></el-input>
        </el-form-item>
        <el-form-item label="Номер заявки в АС ОЗ" prop="application_number">
          <el-input maxlength="500" v-model="userCopy.application_number"></el-input>
        </el-form-item>
        <el-form-item label="Табельный номер" prop="tabnr">
          <el-input maxlength="30" v-model="userCopy.tabnr"></el-input>
        </el-form-item>
        <!--                <el-form-item label="Проект" prop="project">-->
        <!--                    <el-select value="Выбор проекта" style="width:100%"></el-select>-->
        <!--                </el-form-item>-->
        <!--                <el-form-item label="Телефон мобильный" prop="phone_mobile">-->
        <!--                    <el-input maxlength="20" show-word-limit v-model="userCopy.phone_mobile"></el-input>-->
        <!--                </el-form-item>-->
        <!--                <el-form-item label="Телефон рабочий" prop="phone_work">-->
        <!--                    <el-input maxlength="20" show-word-limit v-model="userCopy.phone_work"></el-input>-->
        <!--                </el-form-item>-->
        <!--                <el-form-item label="Телефон внутренний" prop="phone_internal">-->
        <!--                    <el-input maxlength="20" show-word-limit v-model="userCopy.phone_internal"></el-input>-->
        <!--                </el-form-item>-->
        <el-form-item label="Логин" prop="login">
          <el-input maxlength="100" v-model="userCopy.login"></el-input>
        </el-form-item>
        <el-form-item class="form-two-line-label-item" label="Корпоративная электронная почта" prop="email">
          <el-input maxlength="100" v-model="userCopy.email"></el-input>
          <div class="input-helper">
            <el-row type="flex" align="middle">
              <i class="fas fa-info-circle"></i>
              <span>Пароль будет сгенерирован автоматически и выслан на указанную почту</span>
            </el-row>
          </div>
        </el-form-item>
        <!--                <el-form-item label="Задать новый пароль" prop="password">-->
        <!--                    <el-input maxlength="100" show-word-limit v-model="userCopy.password"></el-input>-->
        <!--                </el-form-item>-->
        <!--        <el-form-item label="Сбросить пароль">-->
        <!--          <el-switch-->
        <!--              :active-value="0"-->
        <!--              :inactive-value="1"-->
        <!--              v-model="userCopy.reset_password"-->
        <!--          ></el-switch>-->
        <!--          &lt;!&ndash;                </el-form-item>l="Деактивировать пользователя" prop="active">&ndash;&gt;-->
        <!--          &lt;!&ndash;                    <el-switch&ndash;&gt;-->
        <!--          &lt;!&ndash;                        v-model="userCopy.active"&ndash;&gt;-->
        <!--          &lt;!&ndash;                        :active-value="0"&ndash;&gt;-->
        <!--          &lt;!&ndash;                        :inactive-value="1"&ndash;&gt;-->
        <!--          &lt;!&ndash;                    ></el-switch>&ndash;&gt;-->
        <!--        </el-form-item>-->
      </el-form>
    </el-row>

    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="$emit('update:show', false)">Отмена</el-button>
      <el-button
        type="primary"
        size="small"
        :loading="loading"
        @click="saveUser"
      > Сохранить
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import Paginator from "../Paginator";
import DivisionSelect from "../selects/DivisionSelect";
import RoleSelect from "../selects/RoleSelect";

export default {
  name: "edit-user-modal",
  components: {RoleSelect, DivisionSelect, Paginator},

  props: {
    show: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  data() {
    return {
      loading: false,
      dialogShow: false,
      rules: {
        role: [
          {required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}
        ],
        name_first: [
          {required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}
        ],
        name_last: [
          {required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}
        ],
        division: [
          {required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}
        ],
        login: [
          {required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}
        ],
        position: [
          {required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}
        ],
        application_number: [
          {required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}
        ],
        tabnr: [
          {required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}
        ],
      },
      userCopy: {
        role: {},
      },
    }
  },
  mounted() {
  },
  watch: {
    show: function (newVal, oldVal) {
      if (newVal) {
        this.prepareData()
      }
    },
  },
  methods: {
    prepareData() {
      if (this.$refs.userForm) {
        this.$refs.userForm.resetFields();
      }
      this.userCopy = Object.assign({}, this.user);
    },
    saveUser() {
      this.$refs.userForm.validate((result) => {
        if (!result) {
          return;
        }

        this.loading = true;

        this.$api.user.save(this.userCopy)
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDuration,
              title: 'Сохранено',
              message: 'Пользователь успешно сохранен'
            });
            this.$emit('saved');
            this.$emit('update:show', false);
          })
          .finally(() => {
            this.loading = false;
          })
      });

    },

  }
}
</script>

<style>

.input-helper {
  line-height: 1.5;
  font-size: 12px;
  font-style: italic;
  margin-top: 10px;
}

</style>