<template>
  <el-select
    style="width:100%"
    :value="value"
    :size="size"
    :multiple="multiple"
    :disabled="disabled"
    filterable
    remote
    clearable
    reserve-keyword
    placeholder="Типовая штатная должность"
    :remote-method="remoteMethod"

    @change="selected"
    @clear="remoteMethod"
    @visible-change="$event ? remoteMethod() : null"
  >
    <div
      v-loading="loading"
      v-infinite-scroll="nextPage"
      :infinite-scroll-immediate="false"
      :infinite-scroll-delay="100"
    >

      <el-option-custom
        v-for="item in options"
        :key="item.objectid"
        :label="item.objectname"
        :value="item.objectid"
        :with-checkbox="multiple"
      >
      </el-option-custom>
    </div>
  </el-select>

</template>

<script>


import ElOptionCustom from "@/components/custom/ElOptionCustom";

export default {
  name: "tsd-tp-name-select",
  components: {ElOptionCustom},
  props: {
    value: {},
    size: {
      type: String,
      default: ''
    },
    tp_filter: {
      type: Number,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      loading: false,
      query: null,
      page: 1,
      options: [],
    }
  },
  mounted() {},
  watch: {},
  computed: {
    formattedFilterDivisions() {
      if( !this.filterDivisions ){
        return null;
      }
      if( this.filterDivisions instanceof Array ){
        return this.filterDivisions.length ? this.filterDivisions : null;
      }
      return [this.filterDivisions];
    }
  },
  methods: {
    remoteMethod(query) {
      this.query = query;
      this.page = 1;
      this.options = [];
      this.getData();
    },

    nextPage(){
      this.page++;
      this.getData();
    },

    getData(){
      this.loading = true;
      this.$api.tsd.searchTsds({
        search: this.query,
        tp_objectid: this.tp_filter,
        page: this.page,
        fields: 'objectid, objectname',
      })
        .then(({data}) => {
          this.options = this.options.concat(data.tsds);
        })
        .finally(() => {
          this.loading = false;
        })
    },

    selected(value) {
      this.$emit('input', value);
      this.$emit('change', value);
    }
  },

}
</script>

<style scoped>

</style>