<template>
  <div class="edit-block">
    <div
        class="click-to-edit-div"
        :class="{'click-to-edit-enabled': !disabled}"
        @click="startEdit"
    >
      <el-input
          v-if="edit && type=='input'"
          v-model="valueLocal"
          :size="size"
          ref="inp"
          clearable
          @blur="stopEdit"
          @keyup.enter.native="stopEdit"
          @keyup.esc.native="valueLocal = value; edit = false;"
      ></el-input>

      <el-input
          v-if="edit && type=='textarea'"
          v-model="valueLocal"
          style="z-index: 9"
          type="textarea"
          autosize
          clearable
          :size="size"
          ref="inp"
          :show-word-limit="showWordLimit"
          :maxlength="wordLimit ? wordLimit : null"
          @blur="stopEdit"
          @keyup.enter.native="stopEdit"
          @keyup.esc.native="valueLocal = value; edit = false;"
      ></el-input>

      <el-select
          v-if="edit && type=='select'"
          v-model="valueLocal"
          placeholder="Выберите"
          :size="size"
          ref="inp"
          @change="stopEdit"
          @keyup.esc.native="valueLocal = value; edit = false;"
      >
        <el-option
            v-for="unit in selectOptions"
            :key="unit[selectOptionId]"
            :label="unit[selectOptionName]"
            :value="parseInt(unit[selectOptionId])">
        </el-option>
      </el-select>

      <el-date-picker
          v-if="edit && (type=='date-range-picker' || type=='date-picker')"
          v-model="valueLocal"
          :picker-options="{firstDayOfWeek: 1}"
          :size="size"
          ref="inp"
          :type="type=='date-range-picker' ? 'daterange' : 'date'"
          format="dd.MM.yyyy"
          value-format="timestamp"
          range-separator="-"
          start-placeholder="Дата начала"
          end-placeholder="Дата окончания"
          :default-value="new Date().getTime()"
          @change="stopEdit"
          @blur="valueLocal = value; edit = false;"
          @keyup.esc.native="valueLocal = value; edit = false;"
      >
      </el-date-picker>

      <div v-if="!edit && (type=='input' || type=='textarea')" class="edit-block__value">
        <span v-if="placeholder && valueLocal">{{ valueLocal }}</span>
        <span v-if="placeholder && !valueLocal && !disabled" class="placeholder">{{ placeholder }}</span>
        <svg v-if="!disabled" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 1.5L2.5 11C4.1 11.4 4.83333 12.8333 5 13.5L14.5 4L14 2L12 1.5Z" fill="#A3A6A7"/>
          <path
              d="M11.3333 2.00004C11.5083 1.82494 11.7162 1.68605 11.945 1.59129C12.1738 1.49653 12.419 1.44775 12.6666 1.44775C12.9142 1.44775 13.1594 1.49653 13.3882 1.59129C13.617 1.68605 13.8248 1.82494 13.9999 2.00004C14.175 2.17513 14.3139 2.383 14.4087 2.61178C14.5034 2.84055 14.5522 3.08575 14.5522 3.33337C14.5522 3.58099 14.5034 3.82619 14.4087 4.05497C14.3139 4.28374 14.175 4.49161 13.9999 4.66671L4.99992 13.6667L1.33325 14.6667L2.33325 11L11.3333 2.00004Z"
              stroke="#A3A6A7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>

      <span v-if="!edit && type=='select'">
        {{ getSelectOptionName() }}
      </span>

      <span v-if="!edit && type=='date-range-picker'">
        {{ new Date(valueLocal[0]).toLocaleDateString() }} - {{ new Date(valueLocal[1]).toLocaleDateString() }}
      </span>

      <div v-if="!edit && type=='date-picker'" class="edit-block__value">
        <span class="placeholder" v-if="placeholder && !valueLocal">{{ placeholder }}</span>
        <span v-else>
          {{ new Date(parseInt(valueLocal) * (transformTimestamp ? 1000 : 1)).toLocaleDateString() }}
        </span>
        <svg v-if="!disabled" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 1.5L2.5 11C4.1 11.4 4.83333 12.8333 5 13.5L14.5 4L14 2L12 1.5Z" fill="#A3A6A7"/>
          <path
              d="M11.3333 2.00004C11.5083 1.82494 11.7162 1.68605 11.945 1.59129C12.1738 1.49653 12.419 1.44775 12.6666 1.44775C12.9142 1.44775 13.1594 1.49653 13.3882 1.59129C13.617 1.68605 13.8248 1.82494 13.9999 2.00004C14.175 2.17513 14.3139 2.383 14.4087 2.61178C14.5034 2.84055 14.5522 3.08575 14.5522 3.33337C14.5522 3.58099 14.5034 3.82619 14.4087 4.05497C14.3139 4.28374 14.175 4.49161 13.9999 4.66671L4.99992 13.6667L1.33325 14.6667L2.33325 11L11.3333 2.00004Z"
              stroke="#A3A6A7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>

      <span ref="wrapper" v-if="type=='button-modal'">
        <slot name="reference"></slot>
      </span>
    </div>

    <el-dialog
        v-if="type=='button-modal'"
        :close-on-click-modal="false"
        :title="modalTitle"
        :visible.sync="edit"
        append-to-body
    >
      <el-input
          :disabled="disabled"
          type="textarea"
          :autosize="{ minRows: 4}"
          placeholder="Пишите здесь"
          v-model="valueLocal">
      </el-input>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="edit = false;">Закрыть</el-button>
        <el-button v-if="!disabled" type="primary" size="small" @click="stopEdit">Сохранить</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "click-to-edit",
  components: {},
  props: {
    value: {},
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'input',
      validator: function (value) {
        return ['input', 'textarea', 'select', 'date-picker', 'date-range-picker', 'button-modal'].indexOf(value) !== -1
      }
    },
    size: {
      type: String,
      default: 'small',
      validator: function (value) {
        return ['', 'medium', 'small', 'mini'].indexOf(value) !== -1
      }
    },
    selectOptions: {
      type: Array,
      default: function () {
        return []
      }
    },
    selectOptionId: {
      type: String,
      default: 'id'
    },
    selectOptionName: {
      type: String,
      default: 'name'
    },
    placeholder: {
      type: String,
      default: 'Нажмите для редактирования'
    },
    wordLimit: {
      type: Number,
      default: 0
    },
    showWordLimit: {
      type: Boolean,
      default: false
    },
    transformTimestamp: {
      type: Boolean,
      default: false
    },
    modalTitle: {
      type: String,
      default: 'Редактирование'
    },
  },
  data() {
    return {
      edit: false,
      valueLocal: this.value,
    }
  },
  mounted() {
  },
  watch: {
    value: function () {
      this.valueLocal = this.value;
    }
  },
  methods: {
    startEdit() {
      if( this.edit ){
        return;
      }
      if (this.disabled && this.type != 'button-modal') {
        return;
      }
      if (this.type == 'date-picker' && this.transformTimestamp && this.valueLocal) {
        this.valueLocal *= 1000;
      }
      if (this.type == 'date-picker' && !this.valueLocal) {
        this.valueLocal = new Date().getTime();
      }
      this.edit = true;
      if (this.type != 'button-modal') {
        this.$nextTick(function () {
          this.$refs.inp.focus();
        })
      }
    },
    stopEdit() {
      this.edit = false;

      if (this.valueLocal != this.value) {
        if (this.type == 'date-picker' && this.transformTimestamp) {
          this.valueLocal /= 1000;
        }

        this.$emit('input', this.valueLocal);
      }
    },
    getSelectOptionName() {
      if (!this.valueLocal) {
        return '';
      }
      let option = this.selectOptions.find(option => option[this.selectOptionId] == this.valueLocal);
      if (!option) {
        return '';
      }
      return option[this.selectOptionName];
    }
  },
}
</script>

<style lang="scss" scoped>
@import "./src/assets/css/project-variables";

.edit-block {

  &__value {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    span {
      margin: 0 8px 0 0;
    }

    svg {
      flex-shrink: 0;
      margin: 2px 0 0;

      path {
        transition: all .4s;
      }

      &:hover {

        path:first-of-type {
          fill: $--color-primary;
        }

        path:last-of-type {
          stroke: $--color-primary;
        }
      }
    }

    .placeholder {
      color: $--color-text-regular-4;
    }
  }

  &--no-svg {

    .edit-block__value svg {
      display: none;
    }
  }

  @media only screen and (max-width: 1480px) {
    span {
      font-size: 14px;
    }
  }
}

.click-to-edit-div.click-to-edit-enabled:hover {
  cursor: pointer;
}

.click-to-edit-enabled {
  /*background-color: #f7f9ff;*/
}
</style>
