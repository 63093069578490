<template>
  <div
      v-loading="loading"
      class="test-container"
  >
    <el-row style="margin-bottom: 24px;">
      <el-col
          :span="10"
      >
        <h4 style="margin-bottom: 8px;">{{ test.order }}. {{ test.name }}</h4>

        <el-row type="flex" :gutter="24">
          <el-col :span="8">Надежность: <span :class="getReliabilityClass(test.computed.reliability)">{{ test.computed.reliability }}</span></el-col>
        </el-row>
      </el-col>

      <el-col :span="14" style="display: flex; justify-content: flex-end;">
        <normative-values-modal>
          <el-button
              slot="reference"
              class="el-button--download"
              type="primary"
              plain
          >
            Нормативные значения стат. показателей
          </el-button>
        </normative-values-modal>

        <el-button
          v-if="$can('test-export-pilot-report', test)"
            :loading="loadingDownloader"
            class="el-button--download"
            type="primary"
            plain
            @click="exportToExcel"
        >
          Выгрузка статистики
        </el-button>
<!--        <el-button-->
<!--            class="el-button&#45;&#45;download"-->
<!--            type="primary"-->
<!--            plain-->
<!--        >-->
<!--          Выгрузка исходных данных-->
<!--        </el-button>-->
      </el-col>
    </el-row>

    <el-row
        class="section-container"
        v-for="(section, sectionIndex) in test.sections"
        :key="test.id+'-'+section.id"
    >
<!--      <el-collapse-->
<!--          @change="sectionsCollapseChange($event, section, sectionIndex)"-->
<!--      >-->
      <el-collapse>
        <el-collapse-item
            :title="section.name"
            :name="section.id"
        >

          <el-row class="section-question-container">
            <test-questions
                :test="test"
                :questions="section.questions"
                :startIndex = "getStartIndex(sectionIndex)"
                :sectionIndex = "sectionIndex"
            >
            </test-questions>
          </el-row>
        </el-collapse-item>
      </el-collapse>
    </el-row>

    <el-row>
      <el-row>Комментарии к тесту:</el-row>
      <el-row
          v-for="(comment, index) in test.computed.test_comments"
          :key="index"
      >
        {{ comment }}
      </el-row>
    </el-row>
  </div>
</template>

<script>
import TestQuestions from "../reportPilotResult/TestQuestions";
import {downloader} from "../../../mixins/downloader";
import NormativeValuesModal from "../reportPilotResult/NormativeValuesModal";

export default {
  name: "TestWithSections",
  mixins: [downloader],
  components: {
    TestQuestions,
    NormativeValuesModal,
  },

  props: {
    test: {type: Object},
  },
  data() {
    return {
      startSectionQuestionsIndex:[],
      loading: false,
    }
  },
  computed: {},
  mounted() {
  },
  methods: {
    getStartIndex(sectionIndex){
      return this.test.sections
          .slice(0, sectionIndex)
          .reduce((total, ch) =>  total += ch.questions.length, 0) + 1;
    },
    // getQuestions(section, sectionIndex) {
    //   this.loading = true;
    //   this.$api.report.getSectionQuestions({
    //     'test_id': section.test_id,
    //     'section_id': section.id,
    //   })
    //       .then((data) => {
    //         this.$set(section, 'questions', data.questions)
    //
    //
    //       })
    //       .finally(() => {
    //         this.loading = false;
    //       })
    // },
    // sectionsCollapseChange(e, section, sectionIndex) {
    //   if (!e.length) {
    //     section.questions = [];
    //     return;
    //   }
    //   this.getQuestions(section)
    // },
    exportToExcel() {
      this.downloadFile(
          false,
          this.$api.report.exportTestToExcel,
          {
            test_id: this.test.id
          },
      );
    },
    getReliabilityClass(reliability) {
      if(reliability < 0.7 ) {
        return 'lower-parameter'
      }
      if(reliability >= 0.7 && reliability < 0.8) {
        return 'average-parameter'
      }
      return 'high-parameter'
    },
  }
}
</script>

<style lang="scss">
@import "./src/assets/css/project-variables";

.test-container {
  padding-bottom: 16px;
  border-bottom: 1px solid $--color-divider;

  .section-container {
    margin: 0 0 16px;

    .el-collapse {
      border: 1px solid $--color-divider;
      border-radius: 6px;

      &-item {
        position: relative;
        padding-left: 8px;
        margin: 0;

        &__header {
          height: auto;
          flex-direction: row-reverse;
          justify-content: flex-end;
          padding: 20px;
          font-size: 16px;
          font-weight: 400;
          line-height: 1.5;
          border: none;
        }

        &__arrow {
          position: relative;
          width: 24px;
          height: 24px;
          margin-left: 0;
          transform: none;

          &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background-image: url("../../../assets/img/icons/icon-plus.svg");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            transition: all .4s;
          }

          &.is-active {

            &::before {
              background-image: url("../../../assets/img/icons/icon-minus.svg");
            }
          }
        }

        &__wrap {
          border-bottom: none;
        }

        &__content {
          padding-bottom: 24px;
          font-size: 14px;
          line-height: 1.5;
        }

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 8px;
          height: 100%;
          background-color: $--color-divider-2;
        }
      }
    }
  }
  .lower-parameter{
    color: red;
  }
  .average-parameter {
    color: orange;
  }
  .high-parameter {
    color: green;
  }
}



</style>
