<template>
  <div>
    <page-heading
      heading="Библиотека"
    >
      <span slot="help"></span>
      <span slot="right"></span>
    </page-heading>


    <el-row :gutter="24">
      <el-col :span="6">
        <library-folders
            @folder-selected="folderSelected"
        >
        </library-folders>
      </el-col>

      <el-col :span="18">
        <library-files
          :folder="currentFolder"
          @kpi-saved="folderSelected(currentFolder)"
          @selection-changed="$emit('selection-changed', $event)"
        ></library-files>

      </el-col>
    </el-row>
  </div>
</template>

<script>
import LibraryFolders from "@/components/library/LibraryFolders";
import PageHeading from "@/components/PageHeading";
import LibraryFiles from "@/components/library/LibraryFiles";

export default {
  name: 'library',
  components: {LibraryFiles, PageHeading, LibraryFolders},

  props: {},

  data() {
    return {
      currentFolder: {},
    }
  },
  mounted() {

  },
  methods: {
    folderSelected(folder) {
      this.currentFolder = folder;
    },
  }
}

</script>

<style>

</style>
