<template>
  <div>
    <el-dialog
        append-to-body
        title="Ответственные за тест"
        :visible.sync="show"
        :close-on-click-modal="false"
        width="60%"
        destroy-on-close
    >
      <p>
        Если Вы не нашли пользователя в списке, обратитесь к секретарю рабочей группы. Возможно, такого пользователя
        еще нет в системе
      </p>
      <el-row v-loading="loading">
        <el-form
            label-position="top"
            label-width="140px"
        >
          <el-form-item label="Ответственные">
            <users-table
                :add-button="$can('test-development-set-test-user', test)"
                add-button-text="Добавить ответственных"
                :delete-button="$can('test-development-set-test-user', test)"
                :users="users"
                :add-users-filter-role="['admin', 'expert']"
                :add-users-filter-projects="[parseInt($route.params.id)]"
                negotiation-row
                negotiation-row-multiple
                :negotiation-row-disabled="!$can('test-development-set-test-main-user', test)"
                @new-users="addNewSharedUsers"
                @user-deleted="deleteUser"
                @main-changed="changeMainUser"
            ></users-table>
          </el-form-item>
        </el-form>
      </el-row>


      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="show = false">Закрыть</el-button>
      </span>
    </el-dialog>

    <span ref="wrapper">
      <slot name="reference"></slot>
    </span>
  </div>
</template>

<script>
import {off, on} from "element-ui/src/utils/dom";
import UsersTable from "../user/UsersTable";


export default {
  name: "test-shared-users-dialog",
  components: {UsersTable},

  props: {
    testId: {
      type: Number
    }
  },

  computed: {
    users() {
      let users = [];
      this.test.testUsers.forEach(testUser => {
        let user = testUser.user;
        user.is_main = testUser.is_main
        users.push(user);
      })
      return users;
    }
  },

  data() {
    return {
      loading: false,
      show: false,

      test: {
        testUsers: []
      }
    }
  },
  mounted() {
    if (this.$refs.wrapper.children) {
      on(this.$refs.wrapper.children[0], 'click', this.showModal);
    }
  },
  watch: {},
  methods: {
    showModal() {
      this.show = true;
      this.getTestSharedUsers();
    },

    getTestSharedUsers() {
      this.loading = true;
      this.$api.test.getTestSharedUsers({
        test_id: this.testId
      })
          .then(data => {
            this.test = data.test;
          })
          .finally(() => {
            this.loading = false;
          })
    },

    addNewSharedUsers(users) {
      let ids = users.map(user => user.id);
      if (!ids.length) {
        return;
      }
      this.loading = true;
      this.$api.test.addNewTestSharedUsers({
        test_id: this.testId,
        user_ids: ids
      })
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDuration,
              title: 'Сохранено',
              message: 'Ответственные успешно добавлены'
            });
          })
          .finally(() => {
            this.getTestSharedUsers();
          })
    },
    deleteUser(user) {
      this.loading = true;
      this.$api.test.deleteTestSharedUser({
        test_id: this.testId,
        user_id: user.id
      })
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDuration,
              title: 'Удалено',
              message: 'Ответственный успешно удален'
            });
          })
          .finally(() => {
            this.getTestSharedUsers();
          })
    },
    changeMainUser(user, value) {
      this.loading = true;
      this.$api.test.setTestMainSharedUser({
        test_id: this.testId,
        user_id: user.id,
        value: value ? 1 : 0,
      })
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDuration,
              title: 'Сохранено',
              message: 'Ответственный успешно сохранен'
            });
          })
          .finally(() => {
            this.getTestSharedUsers();
          })
    },
  },
  beforeDestroy() {
    if (this.$refs.wrapper.children) {
      off(this.$refs.wrapper.children[0], 'click', this.showModal);
    }
  }
}
</script>

<style>

</style>
