<template>
  <div
      v-loading="loading"
      class="page"
  >
    <div class="page__head" style="margin-bottom: 0">
      <el-row type="flex" justify="space-between" align="bottom">
        <div class="page__head-left">
          <div class="title-edit">
            <h1 class="page__head-title">Проект Модели профессиональных компетенций</h1>


          </div>
        </div>
      </el-row>
    </div>
    <click-to-edit
      style="margin-bottom: 20px;width: 600px"
      v-model="mpc.name"
      :disabled="!$can('mpc-create-competence', mpc)"
      :word-limit="200"
      placeholder=" "
      @input="saveMpcName"
    ></click-to-edit>

    <div class="settings">
      <!--                <div>-->
      <!--                  <el-row>-->
      <!--                    <el-col :span="8">-->
      <!--                      <span class="form-label__title">Статус</span>-->
      <!--                      <button @click="setStatus($constants.mpc.statuses.STATUS_APPROVED_BY_ORDER)">Утверждены распоряжением</button>-->
      <!--                      <el-date-picker-->
      <!--                          style="width: 100%"-->
      <!--                          align="left"-->
      <!--                          placeholder="Выберите дату"-->
      <!--                          v-model="mpc.statement_date"-->
      <!--                          size="mini"-->
      <!--                          type="date"-->
      <!--                          format="dd.MM.yyyy"-->
      <!--                          value-format="timestamp"-->
      <!--                      >-->
      <!--                      </el-date-picker>-->
      <!--                    </el-col>-->
      <!--                  </el-row>-->


      <!--                </div>-->

      <div class="settings__left">
        <el-checkbox
            v-model="myAndSharedFilter"
            @change="getMpc"
            style="margin-right: 24px;"
        >
          Только мои компетенции
        </el-checkbox>

        <deleted-competences-modal
            v-if="$can('mpc-see-deleted-competences', mpc)"
            :mpc-id="mpc.id"
            @competence-restored="getMpc"
        >
          <el-button
              style="padding: 0"
              slot="reference"
              type="text"
              size="small"
              icon="fas fa-trash"
          >
            Журнал удаленных компетенций
          </el-button>
        </deleted-competences-modal>
      </div>

      <div class="settings__right">
        <el-button
          v-if="$can('mpc-export', mpc)"
            :loading="loadingDownloader"
            style="margin-left:40px"
            type="primary"
            plain
            @click="exportToExcel"
        >Выгрузить в Excel
        </el-button>
        <el-button
          v-if="$can('mpc-export', mpc)"
            :loading="loadingDownloader"
            style="margin-left:20px"
            type="primary"
            plain
            @click="exportToWord"
        >Выгрузить в Word
        </el-button>
        <el-button
            v-if="$can('mpc-create-competence', mpc)"
            style="margin-left:20px"
            type="primary"
            icon="fas fa-plus"
            @click="addSubmodel"
        >
          Добавить подмодель
        </el-button>

        <copy-mpcs-modal
          v-if="$can('mpc-copy-competence-from-another-mpc', mpc)"
            :mpc="mpc"
            @new-competencies-added="getMpc"
        >
          <el-button
              style="margin-left:20px"
              slot="reference"
              type="primary"
              icon="fas fa-plus"
          >
            Добавить из другой МПК
          </el-button>
        </copy-mpcs-modal>
      </div>
    </div>

    <el-row style="margin-bottom: 24px;">
      <div class="hint-div" style="display: flex; align-items: center;">
        <svg style="margin-right: 8px" width="24" height="24" viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path
              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
              stroke="#A3A6A7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M12 8V12" stroke="#A3A6A7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M12 16H12.01" stroke="#A3A6A7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

        При формировании индикаторов необходимо использовать конкретные и точные формулировки знаний и навыков.<br>

        Например: Порядок осмотра путевых машин, Правила технической эксплуатации скоростных поездов, Виды надзора и контроля за безопасностью труда,  Расследование и учет микротравм в ОАО «РЖД» и т. п.
      </div>
    </el-row>

    <competences-table
        v-if="!mpc.submodels.length"
        :mpc="mpc"
        :mpc_submodel_id="null"
        :competences="mpc.competences"
        @new-competence-added="getMpc"
        @competence-deleted="getMpc"
    ></competences-table>


    <el-collapse-custom
        v-if="mpc.submodels.length"
        v-model="activeNames"
        class="mpc-collapse-custom"
    >
      <el-collapse-item-custom
          name="0"
          v-if="mpc.competences.length"
          title="Компетенции вне подмоделей"
      >
        <competences-table
            :mpc="mpc"
            :mpc_submodel_id="null"
            :competences="mpc.competences"
            :submodels="mpc.submodels"
            @new-competence-added="getMpc"
            @competence-deleted="getMpc"
        ></competences-table>
      </el-collapse-item-custom>


      <el-collapse-item-custom
          :name="submodel.id"
          v-for="submodel in mpc.submodels"
          :key="submodel.id"
          :disabled="!submodel.id"
      >
        <template slot="title">
          <click-to-edit
              :disabled="!$can('mpc-create-competence', mpc)"
              :word-limit="200"
              v-model="submodel.name"
              @input="saveSubmodel(submodel)"
          ></click-to-edit>
          <div style="margin:4px 30px 0 0">
            {{ submodel.competences.length }}/{{ $constants.mpc.MAX_SUBMODEL_COMPETENCES }}
          </div>
          <el-popconfirm
              v-if="!submodel.competences.length && $can('mpc-create-competence', mpc)"
              confirm-button-text='Да'
              cancel-button-text='нет'
              icon="el-icon-question"
              title="Вы уверены, что хотите удалить подмодель?"
              @confirm="deleteSubmodel(submodel)"
          >
            <el-button
                slot="reference"
                style="margin-right:10px;"
                type="text"
                icon="fas fa-trash"
                :loading="loading"
            >
            </el-button>
          </el-popconfirm>
        </template>

        <competences-table
            :mpc="mpc"
            :mpc_submodel_id="submodel.id"
            :competences="submodel.competences"
            :submodels="mpc.submodels"
            @new-competence-added="getMpc"
            @competence-deleted="getMpc"
        ></competences-table>
      </el-collapse-item-custom>
    </el-collapse-custom>
  </div>
</template>

<script>


import PageHeading from "../components/PageHeading";
import ClickToEdit from "../components/ClickToEdit";
import EllipsisMenu from "../components/EllipsisMenu";
import CompetencesTable from "../components/mpc/CompetencesTable";
import ElCollapseCustom from "../components/mpc/collapse/collapse";
import ElCollapseItemCustom from "../components/mpc/collapse/collapse-item";
import {downloader} from "../mixins/downloader";
import DeletedCompetencesModal from "../components/mpc/DeletedCompetencesModal";
import CopyMpcsModal from "../components/mpc/CopyMpcsModal";

export default {
  name: 'MPC',
  mixins: [downloader],
  components: {
    DeletedCompetencesModal,
    ElCollapseItemCustom, ElCollapseCustom, CompetencesTable, EllipsisMenu, ClickToEdit, PageHeading, CopyMpcsModal
  },

  data() {
    return {
      loading: false,
      activeNames: [],

      search: null,
      myAndSharedFilter: false,

      mpc: {
        name: null,
        submodels: [],
        competences: [],
        sources: [],
        statement_date: null
      },

    }
  },
  computed: {},

  mounted() {
    this.getMpc();
  },
  methods: {
    getMpc() {
      this.loading = true;
      this.$api.mpc.getProjectMpc({
        project_id: this.$route.params.id,
        my_and_shared: this.myAndSharedFilter ? 1 : null,
      })
          .then(data => {
            this.mpc = data.mpc;

            let mpcIdToHighlight = this.$route.query.to_mpc;
            if (mpcIdToHighlight) {
              this.activeNames = this.mpc.submodels.map(s => s.id);
              this.activeNames.push('0');
            }
          })
          .finally(() => {
            this.loading = false;
            this.$store.dispatch('updateMenu');
          })
    },

    addSubmodel() {
      this.mpc.submodels.push({
        mpc_id: this.mpc.id,
        competences: []
      });
    },

    saveMpcName() {
      this.loading = true;
      this.$api.mpc.saveMpcName({
        mpc_id: this.mpc.id,
        name: this.mpc.name,
      })
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDuration,
              title: 'Сохранено',
              message: 'Имя успешно сохранено'
            });
          })
          .finally(() => {
            this.loading = false;
          })
    },

    saveSubmodel(submodel) {
      this.loading = true;
      this.$api.mpc.saveSubmodel(submodel)
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDuration,
              title: 'Сохранено',
              message: 'Подмодель успешно сохранена'
            });
            submodel.id = data.submodel.id;
            submodel.name = data.submodel.name;
          })
          .finally(() => {
            this.loading = false;
          })
    },


    deleteSubmodel(submodel) {
      if (!submodel.id) {
        this.getMpc();
        return;
      }
      this.loading = true;
      this.$api.mpc.deleteSubmodel({
        id: submodel.id
      })
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDuration,
              title: 'Удалено',
              message: 'Подмодель успешно удалена'
            });
          })
          .finally(() => {
            this.getMpc();
          })
    },
    setStatus(status) {
      if (!this.mpc.statement_date) {
        this.$notify.error({
          title: 'Внимание',
          message: 'Веберите дату'
        });
        return;
      }

      this.loading = true;

      this.$api.mpc.setStatus({
        mpc_id: this.mpc.id,
        status: status,
        statement_date: this.mpc.statement_date ? this.mpc.statement_date / 1000 : null,

      })
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDuration,
              title: 'Удалено',
              message: 'Статус успешно изменен'
            });
          })
          .finally(() => {
            this.loading = false;
          })
    },


    exportToExcel() {
      this.downloadFile(
        false,
          this.$api.mpc.exportMpcToExcel,
          {project_id: this.$route.params.id}
      );
    },

    exportToWord() {
      this.downloadFile(
        false,
          this.$api.mpc.exportMpcToWord,
          {project_id: this.$route.params.id}
      );
    }
  }
}

</script>

<style lang="scss">
@import "./src/assets/css/project-variables";

.settings {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  &__left,
  &__right {
    display: flex;
    align-items: center;
  }

  &__right {
    margin-left: auto;
  }
}

.MPC-table .no-hover:hover > td {
  //background-color: initial !important;
}

.MPC-table td {
  vertical-align: top !important;
}

.MPC-table .dndrop-draggable-wrapper {
  overflow: unset !important;

  > .el-row {
    padding-bottom: 16px;
  }
}

.MPC-table tr.el-table__row {
  height: 1px;
}

.MPC-table td.el-table__cell {
  height: inherit;
}

.MPC-table div.cell {
  height: 100%;
  //background-color: red;
}

.indicator-block {
  position: relative;
  border-radius: 6px;
  border: 1px solid $--color-white;
  padding: 8px 16px;
  background-color: $--color-white;
  cursor: pointer;

  .el-row {
    position: static;

    .edit-block__value {

      svg {
        position: absolute;
        top: 50%;
        right: 40px;
        margin-top: 0;
        transform: translateY(-50%);
      }
    }
  }
}

.indicator-block-drag-handle {
  position: absolute;
  top: 50%;
  left: -12px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: move;
  transform: translateY(-50%);
  opacity: 0;
  pointer-events: none;
  transition: all .4s;

  svg path {
    transition: stroke .4s;
  }

  &:hover {

    svg path {
      stroke: #246FD5;
    }
  }
}

.competence-indicators {

  .edit-block__value {
    width: 95%;
  }
}

.competence-block-drag-handle {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: move;

  svg path {
    transition: stroke .4s;
  }

  &:hover {

    svg path {
      stroke: #246FD5;
    }
  }
}

.indicator-block .delete-button {
  position: absolute;
  top: 50%;
  right: 6px;
  padding: 0;
  display: block;
  color: black;
  transform: translateY(-50%);
  opacity: 0;
  pointer-events: none;
  transition: all .4s;
}

.indicator-block:hover {

  .indicator-block-drag-handle,
  .delete-button {
    opacity: 1;
    pointer-events: all;
  }
}

.MPC-table.el-table {

  th.el-table__cell {
    padding: 16px 0;
    border-top: none;

    .cell {
      padding: 0 16px;
    }
  }

  td.el-table__cell {

    .cell {
      padding: 0 16px;
    }
  }

  .MPC-table__users {

    > div:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  .comments {
    padding-top: 24px;

    &__title {
      font-size: 18px;
      margin-bottom: 16px;
    }

    &__list {
      margin-bottom: 16px;
    }

    &__item {
      padding-bottom: 16px;
      margin-bottom: 16px;

      &-avatar {
        width: 40px !important;
        height: 40px !important;
      }

      &-text {
        margin-bottom: 4px;
      }

      &-delete {
        width: 16px;
        height: 16px;
      }

      &-name,
      &-date {
        font-size: 12px;

        + span {
          font-size: 12px;
        }
      }
    }

    .edit-block__value {

      > span {
        font-size: 14px;
      }
    }

    &__add {

      .el-input {
        margin-bottom: 8px;

        &__inner {
          padding: 8px 12px;
        }
      }

      .el-button {
        padding: 6px 12px;
        font-size: 12px;
        text-transform: none;
      }
    }
  }

  .sources {
    margin-bottom: 8px;
  }
}

.competence-indicators {
  padding-left: 12px;
  margin-bottom: 8px;

  .edit-block {
    width: 100%;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.mpc-collapse-custom {
  border-top: none;
  border-bottom: none;

  .el-collapse-item {
    margin-bottom: 16px;

    &__header {
      height: auto;
      min-height: 48px;
      padding: 16px;
      margin-bottom: 8px;
      line-height: 1.5;
      color: $--color-text-regular;
      border-radius: 6px;
      background-color: $--color-divider-3;
      border-bottom: none;
    }

    .collapse-plus-button {
      margin-right: 8px;
      color: $--color-primary;
    }

    .edit-block {
      width: 90%;
    }
  }

  @media only screen and (max-width: 1480px) {

    .el-collapse-item {
      margin-bottom: 8px;

      &__header {
        padding: 8px;
        margin-bottom: 4px;
        font-size: 14px;
      }

      .collapse-plus-button {
        margin-right: 4px;
      }

      .edit-block {
        width: 85%;
      }
    }
  }
}

</style>
