<template>
  <div style="margin-right: 24px;">
    <el-dialog
        title="Нормативные значения статистических показателей"
        :visible.sync="show"
        :close-on-click-modal="false"
        width="90%"
        top="2vh"
        append-to-body
    >
      <div class="el-table el-table--custom">
        <div class="el-table__header-wrapper">
          <table cellspacing="0" cellpadding="0" class="el-table__header" style="width: 100%">
            <thead class="has-gutter">
              <tr>
                <th class="is-leaf el-table__cell" style="width: 200px;"></th>
                <th class="is-leaf el-table__cell" style="width: 240px;">
                  <div class="cell">Статистические показатели</div>
                </th>
                <th class="is-leaf el-table__cell">
                  <div class="cell">Назначение статистического показателя</div>
                </th>
                <th class="is-leaf el-table__cell">
                  <div class="cell">Величина коэффициента статистического показателя</div>
                </th>
                <th class="is-leaf el-table__cell">
                  <div class="cell">Пояснения величины коэффициента статистического показателя</div>
                </th>
                <th class="is-leaf el-table__cell">
                  <div class="cell">Дальнейшие действия по работе с тестами</div>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr class="el-table__row">
                <td class="el-table__cell" rowspan="3" style="width: 200px; text-align: center;">
                  <div class="cell">Про весь тест</div>
                </td>
                <td class="el-table__cell" rowspan="3" style="width: 240px; text-align: center;">
                  <div class="cell">Надежность теста</div>
                </td>
                <td class="el-table__cell" rowspan="3" style="text-align: center;">
                  <div class="cell">
                    Характеризует качество теста, его постоянство,
                    внутреннюю согласованность, устойчивость и достоверность результатов,
                    полученных с помощью него
                  </div>
                </td>
                <td class="el-table__cell">
                  <div class="cell" style="color: #E21A1A;">< 0,7</div>
                </td>
                <td class="el-table__cell">
                  <div class="cell">Тест не надежен</div>
                </td>
                <td class="el-table__cell">
                  <div class="cell">Требуется пересмотреть большую часть тестовых заданий</div>
                </td>
              </tr>

              <tr>
                <td class="el-table__cell">
                  <div class="cell" style="color: #E18250">0,7 до 0,8</div>
                </td>
                <td class="el-table__cell">
                  <div class="cell">Тест имеет среднюю степень надежности</div>
                </td>
                <td class="el-table__cell">
                  <div class="cell">Требуется пересмотреть некоторые тестовые задания</div>
                </td>
              </tr>

              <tr>
                <td class="el-table__cell">
                  <div class="cell" style="color: #6DC077;">> 0,8</div>
                </td>
                <td class="el-table__cell">
                  <div class="cell">Тест надежен</div>
                </td>
                <td class="el-table__cell">
                  <div class="cell">Тест можно использовать для оценки работников</div>
                </td>
              </tr>

              <tr>
                <td rowspan="6" class="el-table__cell" style="width: 200px; text-align: center;">
                  <div class="cell">Про каждое тестовое задание (тестовый вопрос)</div>
                </td>
                <td rowspan="3" class="el-table__cell" style="width: 240px; text-align: center;">
                  <div class="cell">Дискриминативность тестового задания (вопроса)</div>
                </td>
                <td rowspan="3" class="el-table__cell" style="text-align: center;">
                  <div class="cell">Характеризует возможность выполнения тестового задания "слабой" и "сильной" группой оцениваемых</div>
                </td>
                <td class="el-table__cell">
                  <div class="cell" style="color: #E21A1A;">< 0,19</div>
                </td>
                <td class="el-table__cell">
                  <div class="cell">Задание некачественное</div>
                </td>
                <td class="el-table__cell">
                  <div class="cell">Тестовое задание необходимо заменить</div>
                </td>
              </tr>
              <tr>
                <td class="el-table__cell">
                  <div class="cell" style="color: #E18250">0,2 до 0,29</div>
                </td>
                <td class="el-table__cell">
                  <div class="cell">Задание недостаточно качественное</div>
                </td>
                <td class="el-table__cell">
                  <div class="cell">Тестовое задание необходимо скорректировать (сформулировать более четко и лаконично), либо заменить его</div>
                </td>
              </tr>
              <tr>
                <td class="el-table__cell">
                  <div class="cell" style="color: #6DC077;">> 0,3</div>
                </td>
                <td class="el-table__cell">
                  <div class="cell">Задание качественное</div>
                </td>
                <td class="el-table__cell">
                  <div class="cell">Тестовое задание можно использовать для оценки работников</div>
                </td>
              </tr>
              <tr>
                <td rowspan="3" class="el-table__cell" style="width: 240px; text-align: center;">
                  <div class="cell">
                    Трудность тестового задания (вопроса)
                  </div>
                </td>
                <td rowspan="3" class="el-table__cell" style="text-align: center;">
                  <div class="cell">Характеристика тестового задания, отражающая долю оцениваемых, которая справилась с заданием (в %)</div>
                </td>
                <td class="el-table__cell">
                  <div class="cell" style="color: #E21A1A;">0-19%</div>
                </td>
                <td class="el-table__cell">
                  <div class="cell">Задание очень легкое</div>
                </td>
                <td class="el-table__cell">
                  <div class="cell">Тестовое задание необходимо усложнить</div>
                </td>
              </tr>
              <tr>
                <td class="el-table__cell">
                  <div class="cell" style="color: #6DC077;">20-79%</div>
                </td>
                <td class="el-table__cell">
                  <div class="cell">Задание оптимальной сложности</div>
                </td>
                <td class="el-table__cell">
                  <div class="cell">Тестовое задание можно использовать для оценки работников</div>
                </td>
              </tr>
              <tr>
                <td class="el-table__cell">
                  <div class="cell" style="color: #E18250">> 80%</div>
                </td>
                <td class="el-table__cell">
                  <div class="cell">Задание очень сложное</div>
                </td>
                <td class="el-table__cell">
                  <div class="cell">Тестовое задание необходимо упростить</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="show = false">Закрыть</el-button>
      </span>
    </el-dialog>

    <span ref="wrapper">
      <slot name="reference"></slot>
    </span>
  </div>
</template>

<script>
import {off, on} from "element-ui/src/utils/dom";

export default {
  name: "NormativeValuesIndicators",
  props: {},
  data() {
    return {
      show: false,
      tableData: [{
        description: 'Про весь тест',
        statisticalIndicators: 'Надежность теста',
      }, {
        description: 'Про каждое тестовое задание (тестовый вопрос)',
        statisticalIndicators: 'Дискриминативность тестового задания (вопроса)',
      }],
    }
  },
  mounted() {
    if (this.$refs.wrapper.children) {
      on(this.$refs.wrapper.children[0], 'click', this.showDeleted);
    }
  },
  watch: {},
  methods: {
    showDeleted() {
      this.show = true;
    },
  },
  beforeDestroy() {
    if (this.$refs.wrapper.children) {
      off(this.$refs.wrapper.children[0], 'click', this.showDeleted);
    }
  }
}
</script>

<style lang="scss" scoped>

.el-table.el-table--custom {

  .el-table__header,
  .el-table__body,
  .el-table__footer {
    border-collapse: collapse;
  }

  td {
    vertical-align: middle;
    border: 1px solid #D3D3D3;
    background-color: transparent;

  }
}
</style>
