<template>
  <div>
    <el-dialog
      title="Перемещение вопроса"
      :visible.sync="show"
      :close-on-click-modal="false"
      width="95%"
      top="2vh"
      append-to-body
    >
      <el-row>
        <div>Выберите индикатор, в который будет перемещен вопрос:</div>

        <div v-for="section in testSections">
          <el-radio
            v-model="sectionId"
            :label="section.id"
          >
            {{section.name}}
          </el-radio>
        </div>

      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="show = false">Закрыть</el-button>
        <el-button size="small" type="primary" @click="moveQuestion" :loading="loading">Переместить</el-button>
      </span>
    </el-dialog>

    <span ref="wrapper">
      <slot name="reference"></slot>
    </span>
  </div>
</template>

<script>

import {off, on} from "element-ui/src/utils/dom";

export default {
  name: "move-question-modal",
  components: {},
  props: {
    questionId: {
      type: Number,
    },
    testSections:{
      type: Array
    }
  },

  data() {
    return {
      show: false,
      loading: false,
      sectionId: null,
    }
  },
  mounted() {
    if (this.$refs.wrapper.children) {
      on(this.$refs.wrapper.children[0], 'click', this.showModal);
    }
  },
  watch: {},
  methods: {
    showModal() {
      this.show = true;
      this.sectionId = null;
    },
    moveQuestion() {
      this.loading = true;
      this.$api.test.moveQuestion({
        question_id: this.questionId,
        section_id: this.sectionId,
      })
        .then(data => {
          this.$notify.success({
            duration: this.$constants.global.notifyDuration,
            title: 'Сохранено',
            message: 'Вопрос успешно перемещен'
          });
          this.show = false;
          this.$emit('question-moved');
        })
        .finally(() => {
          this.loading = false;
        })
    }
  },

  beforeDestroy() {
    if (this.$refs.wrapper.children) {
      off(this.$refs.wrapper.children[0], 'click', this.showModal);
    }
  }

}
</script>

<style scope>

</style>