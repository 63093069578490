<template>
  <el-popover
    v-model="visible"
      placement="bottom-end"
      :visible-arrow="false"
      popper-class="el-popper-table"
      trigger="click"
      width="auto"
      class="ellipsis-menu"
      @show="onShow"
      @hide="onHide"
  >
    <el-button
        slot="reference"
        style="width: 100%; text-align: right;"
        :class="{'selected': show}"
        size="mini"
        type="text"
        icon="fas fa-ellipsis-v"
    ></el-button>

    <div @click="visible = false" class="ellipsis-menu-slot">
      <slot></slot>
    </div>
  </el-popover>
</template>

<script>
export default {
  name: "ellipsis-menu",
  components: {},

  props: {},

  data() {
    return {
      show: false,
      visible: false,
    }
  },
  mounted() {

  },
  watch: {},
  methods: {
    onShow() {
      this.show = true;
    },
    onHide() {
      this.show = false;
    }
  },

}
</script>

<style lang="scss">
@import "@/assets/css/project-variables.scss";

.el-popper.el-popper-table {
  padding: 16px;
  margin-top: 4px;
  line-height: 1.5;
  color: $--color-text-regular-4;
  border: none;
  background: $--color-white;
  box-shadow: 1px 0 30px 7px rgba(20, 33, 64, 0.06);
  border-radius: 5px;

  .ellipsis-menu-slot {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .el-button {

    &--text {
      color: $--color-text-regular-4;

      i {
        font-size: 16px;
      }

      &:hover {
        color: $--color-primary;
      }
    }
  }

  @media only screen and (max-width: 1480px) {
    padding: 12px;

    .el-button {

      &--text {

        i {
          font-size: 14px;
        }
      }
    }
  }
}

</style>
