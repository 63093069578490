<template>
  <el-upload
    class="file-uploader"
    grab
    ref="upload"
    action=""
    :on-remove="handleDelete"
    :http-request="doUpload"
  >
    <i class="el-icon-plus avatar-uploader-icon"></i>
  </el-upload>

</template>

<script>


export default {
  name: "file-uploader",

  props: {
    value: {},
    method: {
      required: true,
      type: Function,
    },
    folder: {
      required: true,
      type: Object,
    },
    exts: {
      type:Array
    }
  },

  data() {
    return {}
  },
  mounted() {
  },
  watch: {},
  methods: {
    handleDelete() {
      this.$emit('input', null);
    },

    doUpload(params) {


      let file = params.file;
        // let  fileType = file.type;
        // let isImage = fileType.indexOf('image') !== -1;


      // let isLt2M = file.size / 1024 / 1024 < 2;
      // if (!isLt2M) {
      //   this.$notify.error({
      //     title: 'Ошибка',
      //     message: 'Размер файла не должен превышать 2 MB'
      //   });
      //   this.$refs.upload.uploadFiles = [];
      //   return;
      // }

      if(this.exts && this.exts.length){
        let ext = file.name.split('.');
        ext = ext[ext.length-1];
        console.log(ext);
        if(!this.exts.includes(ext)){
          this.$notify.error({
            title: 'Ошибка',
            message: 'Формат файла запрещен!'
          });
          this.$refs.upload.uploadFiles = [];
          return;
        }
      }

      let formData = new FormData();
      formData.append('file', file);
      formData.append('folder_id', this.folder.id);

      this.$refs.upload.uploadFiles[0].status = 'uploading';

      this.method(
        formData,
        {
          headers: {'Content-Type': 'multipart/form-data'},
          onUploadProgress: this.onProgress.bind(this)
        })
        .then((data) => {
          this.$refs.upload.uploadFiles[0].id = data.id;

          this.$emit('input', this.$refs.upload.uploadFiles[0]);
          this.$emit('uploaded');
        })
        .catch((err) => {
          this.$refs.upload.uploadFiles = [];
        });

    },
    onProgress(progressEvent) {
      this.$refs.upload.uploadFiles[0].status = 'uploading';
      this.$refs.upload.uploadFiles[0].percentage = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));

      if (this.$refs.upload.uploadFiles[0].percentage === 100) {
        this.$refs.upload.uploadFiles[0].status = 'success';
      }
    },
  },

}
</script>

<style>
.file-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.file-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>