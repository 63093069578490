<template>
  <el-table
      v-loading="loading"
      :data="questions"
      class="el-table--questions"
      @expand-change="getQuestionAnswers"
      style="width: calc(100% - 36px); margin-left: 20px;"
  >
    <el-table-column
        type="expand"
        width="24"
    >
      <template slot-scope="scope">
        <test-question-answers
            :answers="answers[scope.row.id]"
        >
        </test-question-answers>
      </template>

    </el-table-column>

    <el-table-column
        label="№ / Вопрос"
        class-name="el-table__cell--bottom"
        width="220"
    >
      <template slot-scope="scope">
        <el-link type="primary">
          {{
            scope.$index + startIndex
          }}

          .
          {{ scope.row.question }}
        </el-link>
      </template>
    </el-table-column>

    <el-table-column
        label="Тип"
        class-name="el-table__cell--bottom"
        width="130"
    >
      <template slot-scope="scope">
        {{ $constants.test.questionStatuesNames[scope.row.type] }}
      </template>
    </el-table-column>

    <el-table-column
        label="Ответило"
        class-name="el-table__cell--bottom"
        width="100"
    >
      <template slot-scope="scope">
        {{ scope.row.computed.count_users_answered }}
      </template>
    </el-table-column>

    <el-table-column
        label="Ответило правильно"
        class-name="el-table__cell--bottom"
        width="180"
    >
      <template slot-scope="scope">
        {{ scope.row.computed.count_right_answers }}
      </template>
    </el-table-column>

    <el-table-column
        label="%"
        class-name="el-table__cell--bottom"
        width="60"
    >
      <template slot-scope="scope">
        {{ scope.row.computed.count_users_answered_percent }}
      </template>
    </el-table-column>

    <el-table-column
        label="Дискриминативность"
        width="180"
    >
      <template slot-scope="scope">
        <span :class="getDiscriminativityClass(scope.row.computed.discriminativity)">{{ scope.row.computed.discriminativity }}</span>
      </template>
    </el-table-column>

    <el-table-column
        label="Трудность"
        class-name="el-table__cell--bottom"
        width="100"
    >
      <template slot-scope="scope">
        <span :class="getDifficultyClass(scope.row.computed.difficulty)">{{ scope.row.computed.difficulty }}</span>

      </template>
    </el-table-column>

    <el-table-column
        label="Комментарий к вопросу"
        class-name="el-table__cell--bottom"
        min-width="240"
    >
      <template slot-scope="scope">
        <el-row
          v-if="scope.row.check_list"
          v-for="comment in scope.row.check_list"
          :key="comment.id"
          style="margin-bottom: 10px;"
        >
          {{$constants.test.checklistResults[comment.result]}}
          <span v-if="comment.result===6">: {{ comment.comment }}</span>
        </el-row>

      </template>
    </el-table-column>

    <el-table-column
        label="Решение"
        class-name="el-table__cell--bottom"
        min-width="200"
    >
      <template slot-scope="scope">
        <el-row
            v-if="scope.row.solutions"
            v-for="solution in scope.row.solutions"
            :key="solution.id"
            style="margin-bottom: 10px;"
        >
          {{ solution.solution }}
        </el-row>

        <solution-dialog
          v-if="$can('test-add-solution', test)"
            :questionId="scope.row.id"
            :solutions="solutions[scope.row.id]"
            @update-solutions="getQuestionSolutions(scope.row.id)"
        >
          <el-button
              slot="reference"
              type="text"
              size="mini"
              icon="fas fa-plus"
          >
            Добавить решение
          </el-button>
        </solution-dialog>

      </template>
    </el-table-column>
    <el-table-column
        label="Источник"
        class-name="el-table__cell--bottom"
        min-width="120"
    >
      <template slot-scope="scope">
        {{scope.row.sources}}
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import SolutionDialog from "./SolutionDialog";
import TestQuestionAnswers from "../reportPilotResult/TestQuestionAnswers";

export default {
  name: "TestQuestions",
  components: {
    TestQuestionAnswers,
    SolutionDialog
  },
  props: {
    test: {
      type: Object,
    },
    sectionIndex: {
      type: Number
    },
    startIndex:{
      type:Number
    },
    questions: {
      type: Array,
      default: function () {
        return []
      }
    },
  },
  data() {
    return {
      loading: false,
      answers: {},
      solutions: {},
    }
  },
  methods: {
    getQuestionSolutions(questionId) {
      this.$api.report.getQuestionSolutions({
        question_id: questionId,
      })
          .then((data) => {
            this.$set(this.solutions, questionId, data.solutions);
            this.questions.filter(question => question.id === questionId)[0].solutions = data.solutions;
          })
          .finally(() => {
            this.loading = false;
          })
    },
    getQuestion(question) {
      this.loading = true;
      this.$api.report.getPilotResultQuestion({
        question_id: question.id
      })
          .then(data => {
            question = data.question;
          })
          .finally(() => {
            this.loading = false;
          })
    },
    getQuestionAnswers(question, expandedQuestions) {
      if (!expandedQuestions.find(q => q.id === question.id)) {
        this.answers[question.id] = [];
        return;
      }
      this.loading = true;
      this.$api.report.getPilotResultQuestionAnswers({
        question_id: question.id
      })
          .then(data => {
            this.$set(this.answers, question.id, data.answers);
          })
          .finally(() => {
            this.loading = false;
          })
    },
    getDiscriminativityClass(discriminativity) {
      if(discriminativity < 0.19 ) {
        return 'lower-parameter'
      }
      if(discriminativity >= 0.2 && discriminativity < 0.3) {
        return 'average-parameter'
      }
      return 'high-parameter'
    },
    getDifficultyClass(difficulty) {

      if(difficulty < 20 ) {
        return 'lower-parameter'
      }
      if(difficulty >= 20 && difficulty < 80) {
        return 'high-parameter'
      }
      return 'average-parameter'

    }
  }
}
</script>

<style lang="scss">
@import "@/assets/css/project-variables";

.el-table--questions {

  th.el-table__cell {
    vertical-align: bottom;
  }

  tr {
    transition: background-color .4s;

    &.expanded {
      background-color: $--color-divider-3;

      + tr td {
        background-color: $--color-divider-3;

        &:hover {
          background-color: $--color-divider-3 !important;
        }
      }
    }
  }

  td.el-table__expanded-cell {
    padding: 0;
  }

  .el-table__expand-icon.el-table__expand-icon--expanded {
    transform: none;
  }

  .el-table__expand-icon > .el-icon {
    margin-top: 4px;
    margin-left: 6px;
    transform: translate(-50%, -50%);
    transition: transform .4s;
  }

  .el-table__expand-icon.el-table__expand-icon--expanded > .el-icon {
    transform: translate(-50%, -50%) rotate(90deg);
  }
}

</style>
