<template>
    <el-select
        style="width:100%"
        :value="value"
        :size="size"
        filterable
        remote
        clearable
        reserve-keyword
        placeholder="Типовая ОЕ"
        :multiple="multiple"
        :remote-method="remoteMethod"
        :loading="loading"
        @change="selected"
        @clear="remoteMethod"
        @visible-change="$event ? remoteMethod() : null"
    >
        <el-option-custom
            v-for="item in options"
            :key="item.objectid+item.typeoe"
            :label="item.typeoe"
            :value="item.typeoe"
            :with-checkbox="multiple"
        >
        </el-option-custom>
    </el-select>

</template>

<script>


import ElOptionCustom from "@/components/custom/ElOptionCustom";
export default {
    name: "tsd-type-oe-select",
  components: {ElOptionCustom},
  props: {
        value: [],
        size:{
            type: String,
            default: ''
        },
      multiple:{
          type: Boolean
      }
    },

    data() {
        return {
            loading: false,
            options: [],
        }
    },
    mounted() {
        this.remoteMethod()
    },
    watch: {},
    computed: {
    },
    methods: {
        remoteMethod(query){
            this.loading = true;
            this.$api.tsd.searchTypeOe({
                search: query,
                page: 1
            })
                .then((data) => {
                    this.options = data.typeoes;
                })
                .finally(()=>{
                    this.loading = false;
                })
        },

        selected(value){
            this.$emit('input', value);
            this.$emit('selected', value)
        }
    },

}
</script>

<style scoped>

</style>