<template>
  <div class="login">
    <div class="login__left">
      <div class="login__logo">
        <img src="@/assets/img/login-logo-rzd.svg" alt="logo" class="img-responsive">
      </div>

      <div class="login__text">
        <h1 class="login__title">Цифровая платформа</h1>
        <p class="login__subtitle">по разработке систем профессиональных компетенций</p>
      </div>

      <div class="login__links">
        <a href="#">О платформе</a>
        <a href="#">Техническая поддержка</a>
      </div>
    </div>

    <div class="login__right">
      <div class="login__authorization">
        <div class="login__authorization-left">
          <div class="login__authorization-title">Вход</div>
          <div class="login__authorization-subtitle">Добро пожаловать в систему!</div>
        </div>

        <div class="login__authorization-right">
          <div class="login__authorization-logo">
            <img src="@/assets/img/logo.png" alt="logo" class="img-responsive">
          </div>

          <el-form
              class="login-form"
              :model="model"
              :rules="rules"
              ref="form"
              @submit.native.prevent="login"
          >
            <el-form-item prop="username">
              <label>
                <span><sup>*</sup>Логин</span>
                <el-input v-model="model.username" placeholder="Логин" prefix-icon="fas fa-user"></el-input>
              </label>
            </el-form-item>

            <el-form-item prop="password">
              <label>
                <span><sup>*</sup>Пароль</span>
                <el-input
                    v-model="model.password"
                    placeholder="Пароль"
                    type="password"
                    prefix-icon="fas fa-lock"
                ></el-input>
              </label>
            </el-form-item>

            <el-form-item>
              <el-button
                  :loading="loading"
                  class="login-button"
                  type="primary"
                  native-type="submit"
                  block
              >Войти
              </el-button>
            </el-form-item>
          </el-form>

          <router-link
              :to="{ name: 'Restore'}"
              class="login__authorization-link"
          >
            Не получается войти?
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'Login',
  components: {},

  data() {
    return {
      loading: false,

      model: {
        username: '',
        password: '',
      },
      rules: {},
    }
  },
  computed: {},

  mounted() {

  },
  methods: {
    login() {
      this.loading = true;
      this.$api.user.login({
        username: this.model.username,
        password: this.model.password,
      })
          .then(data => {
            localStorage.setItem('jwt', data.token);
            if (data.roles.find(r => r === 'pilot')) {
              this.$router.push({name: 'TestsList'})
            } else {
              this.$router.push({name: 'Projects'})
            }
            //this.card = data.fc;
          })
          .finally(() => {
            this.loading = false;
          })
    },

  }
}

</script>

<style lang="scss" scoped>
@import "@/assets/css/project-variables.scss";

.login {
  position: relative;
  width: 100%;
  height: calc(100vh);
  display: flex;

  &__left {
    flex-shrink: 0;
    width: 43%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 48px 40px;
    text-align: center;
  }

  &__right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../assets/img/login-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  &__logo {
    align-self: flex-start;
  }

  &__title {
    font-size: 30px;
    font-weight: 700;
    line-height: 1.5;
    color: $--color-black;
    margin: 0 0 8px;
  }

  &__subtitle {
    font-size: 24px;
    margin: 0;
    color: $--color-black;
  }

  &__links {
    width: 100%;
    display: flex;
    align-self: center;
    justify-content: space-between;

    a {
      font-size: 14px;
      line-height: 1.5;
      text-decoration: underline;
      color: $--color-black;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__authorization {
    display: flex;
    padding: 40px 32px;
    background: $--color-white;
    box-shadow: 1px 0 30px 7px rgba(20, 33, 64, 0.04);
    border-radius: 10px;

    &-left {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 32px 0 0;
      text-align: center;
      border-right: 1px solid $--color-divider-3;
    }

    &-right {
      display: flex;
      flex-direction: column;
      padding: 0 0 0 32px;
    }

    &-title {
      margin: 0 0 4px;
      font-size: 30px;
      color: $--color-black;
    }

    &-subtitle {
      font-size: 16px;
      color: $--color-black;
    }

    &-logo {
      width: 144px;
      margin: 0 0 32px auto;
    }

    .el-form {
      min-width: 378px;
      margin: 0 0 16px;

      &-item {
        margin: 0 0 24px;

        label {
          display: block;

          & > span {
            display: inline-block;
            margin: 0 0 8px;
            font-size: 14px;
            color: $--color-text-regular-4;

            sup {
              color: $--color-secondary-red;
              font-size: 14px;
              margin: 0 2px 0 0;
            }
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      .login-button {
        width: 100%;
      }
    }

    &-link {
      align-self: flex-end;
    }
  }
}

</style>
