<template>
  <div class="page" v-if="$can('menu-see-reports')">
    <div class="page__head">
      <el-row class="page__head-right">
        <el-col :span="20">
          <h1 class="page__head-title">
            {{ $route.meta.title }}
          </h1>
        </el-col>
        <el-col :span="4">
<!--          <el-button-->
<!--              class="el-button&#45;&#45;download"-->
<!--              type="primary"-->
<!--              plain-->
<!--          >-->
<!--            Выгрузить в excel-->
<!--          </el-button>-->
        </el-col>
      </el-row>

    </div>


    <el-row v-loading="loading">
      <!--  Фильтры  -->
      <el-row>
        <el-row type="flex" style="flex-wrap: wrap; margin-bottom: 24px;" :gutter="24">
          <el-col :span="6">
            <label class="form-label">
              <span class="form-label__title">Поиск</span>
              <el-input
                  style="width: 100%"
                  v-model="search"
                  prefix-icon="el-icon-search"
                  placeholder="По ФИО, подразделению и должности"
                  size="mini"
                  :disabled="!projectId"
                  clearable
                  @clear="getReport"
                  v-debounce:500="getReport"
              >
              </el-input>
            </label>
          </el-col>
          <el-col :span="4">
            <label class="form-label">
              <span class="form-label__title">Полигон ж.д</span>
              <polygon-zhd-select
                  size="mini"
                  multiple
                  v-model="polygonsZhd"
                  :disabled="!projectId"
                  @change="getReport"
              >
              </polygon-zhd-select>
            </label>
          </el-col>
          <el-col :span="5">
            <label class="form-label">
              <span class="form-label__title">Проект</span>
              <project-select
                  size="mini"
                  v-model="projectId"
                  filter-for-report
                  @change="getReport"
              >
              </project-select>
            </label>
          </el-col>
          <el-col :span="9" style="align-self: end; text-align: right">
            <el-button
                icon="el-icon-refresh"
                :disabled="isDisabled"
                @click="reset"
            >
              Сбросить
            </el-button>
          </el-col>
        </el-row>
      </el-row>

      <el-row v-if="parseInt(projectId)">
        <el-row v-if="report.projectPilot && report.pilots.length">
          <!-- Статистика по пилоту -->
          <el-row
              type="flex"
              justify="center"
              :gutter="40"
              style="margin:30px 0;"
          >
            <el-col>
              <div>Всего участников</div>
              <div>пилотной оценки СПК</div>
              <div><b>{{ report.pilots.length }} чел. / 100%</b></div>
            </el-col>
            <el-col>
              <div>Прошли пилотную</div>
              <div>оценку СПК</div>
              <div><b>{{ countUserFinishedTests }} чел. / {{ percentFinishedTests }}%</b></div>
            </el-col>
            <el-col>
              <div>Не прошли пилотную</div>
              <div>оценку СПК</div>
              <div><b>{{ report.pilots.length - countUserFinishedTests }} чел. / {{ (100 - percentFinishedTests).toFixed(2) }}%</b>
              </div>
            </el-col>
            <el-col>
              <div>До завершения</div>
              <div v-if="report.projectPilot"><b>{{ getDayToEnd() }} дн.</b></div>
            </el-col>
          </el-row>
          <el-row style="margin-bottom: 16px;">
            <el-col>Организатор пилотной оценки (секретарь):
              <span
                  v-for="secretary in report.secretaries_project_users">{{ secretary.full_name + '; ' }}</span>
            </el-col>
          </el-row>
          <el-row class="content">
            <el-row class="table-report">
              <el-table
                  class="el-table--report"
                  style="width: 100%;"
                  ref="reportTable"
                  :data="report.pilots"
              >

                <el-table-column
                    label="ФИО"
                    class-name="el-table__cell--bottom"
                    width="150"
                >
                  <template slot-scope="scope">
                    {{ scope.row.full_name }}
                  </template>
                </el-table-column>

                <el-table-column
                    label="Подразделение"
                    class-name="el-table__cell--bottom"
                    width="140"
                >
                  <template slot-scope="scope">
                    {{ scope.row.division }}
                  </template>
                </el-table-column>

                <el-table-column
                    label="Штатная должность"
                    class-name="el-table__cell--bottom"
                    width="170"
                >
                  <template slot-scope="scope">
                    {{ scope.row.position }}
                  </template>
                </el-table-column>

                <el-table-column
                    label="	Полигон ж.д."
                    class-name="el-table__cell--bottom"
                    width="120"
                >
                  <template slot-scope="scope">
                    {{ scope.row.polygon_zhd }}
                  </template>
                </el-table-column>

                <el-table-column
                    label="Статус"
                    class-name="el-table__cell--bottom"
                    width="120"

                    show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    {{
                      scope.row.finishedTests === scope.row.countTests ? $constants.reportMonitoring.pilotStatusesNames[1] : $constants.reportMonitoring.pilotStatusesNames[2]
                    }}
                  </template>
                </el-table-column>

                <el-table-column
                    label="Дата прохождения"
                    class-name="el-table__cell--bottom"
                    width="170"

                >
                  <template slot-scope="scope">
                    {{ scope.row.finishedTests === scope.row.countTests ? getFinishedDate(scope.row) : '' }}
                  </template>
                </el-table-column>

                <el-table-column
                    label="Количество пройденных тестов"
                    class-name="el-table__cell--bottom"
                    width="260"

                >
                  <template slot-scope="scope">
                    {{ scope.row.finishedTests }}/ {{ scope.row.countTests }}
                  </template>
                </el-table-column>

                <el-table-column
                    v-for="test in report.tests"
                    :key="test.id"
                    :label="test.name"
                    class-name="el-table__cell--bottom"
                    width="290"
                    align="center"
                >
                  <template slot-scope="scope">
                    <el-row v-if="getTestResult(test.id, scope.row.id) === 1"><i
                        class="report-icon el-icon-success"></i></el-row>
                    <el-row v-else-if="getTestResult(test.id, scope.row.id) === 2"><i
                        class="report-icon el-icon-error"></i></el-row>
                  </template>
                </el-table-column>

              </el-table>
            </el-row>


          </el-row>
        </el-row>
        <el-row v-else>Пилот отсутствует</el-row>
      </el-row>
      <div v-else>Выберите проект</div>
    </el-row>
  </div>
</template>

<script>
import PageHeading from "../../components/PageHeading";
import ClickToEdit from "../../components/ClickToEdit";
import TsdSelect from "@/components/selects/TsdSelect";
import ProjectSelect from "@/components/selects/ProjectSelect";
import PolygonZhdSelect from "../../components/selects/PolygonZhdSelect";
import {downloader} from "../../mixins/downloader";
import {sorter} from "../../mixins/sorter";


export default {
  name: 'ReportMonitoring',
  mixins: [downloader],
  components: {
    PolygonZhdSelect,
    PageHeading,
    ClickToEdit,
    TsdSelect,
    ProjectSelect

  },
  data() {
    return {
      loading: false,
      search: null,
      projectId: null,
      polygonsZhd: [],
      countUserFinishedTests: null,
      percentFinishedTests: null,
      report: {
        tests: [],
        pilots: [],
        secretaries_project_users: [],
      }
    }
  },
  computed: {
    isDisabled() {
      return !this.projectId &&
          !this.polygonsZhd.length &&
          !this.search
    },
  },
  mounted() {
    if (this.$route.query.project_id) {
      this.projectId = +this.$route.query.project_id
    }

    this.getReport()
  },
  methods: {
    getReport() {
      if (this.projectId) {
        this.countUserFinishedTests = null;
        this.percentFinishedTests = null;
        this.loading = true;
        this.$api.report.getMonitoringData({
          project_id: this.projectId,
          search: this.search,
          polygons_zhd: this.polygonsZhd,
        })
            .then((data) => {
              this.report.tests = data.tests;
              this.report.projectPilot = data.monitoringData.pilot;
              this.report.secretaries_project_users = data.monitoringData.secretaries_project_users;
              if (this.report.projectPilot) {
                this.report.pilots = this.expansionOfPilots(data.monitoringData.pilot_group)
                this.getPercentFinishedTests();
                this.getDayToEnd();
              }
            })
            .finally(() => {
              this.loading = false;
            })
      }
    },
    expansionOfPilots(pilots) {

      pilots.forEach(user => {

        user.countTests = user.tests.length;

        user.finishedTests = user.tests.filter(test => {
          return test.result
        }).length

        if (user.countTests === user.finishedTests) {
          this.countUserFinishedTests++
        }
      })

      return pilots;
    },
    getDayToEnd() {
      if (!this.report.projectPilot) {
        return 'Информация отсутствует'
      }

      if (this.report.projectPilot.date_end * 1000 < Date.now()) {
        return 0
      }

      return parseInt((this.report.projectPilot.date_end - Date.now() / 1000) / 60 / 60 / 24);
    },
    getPercentFinishedTests() {
      this.percentFinishedTests = (this.countUserFinishedTests * (100 / this.report.pilots.length)).toFixed(2);
    },
    getFinishedDate(user) {
      const userTestDates = user.tests.map(test => test.updated_at);
      if( !userTestDates.length ){
        return '';
      }
      let lastUpdatedDate = Math.max(...userTestDates);
      return new Date(lastUpdatedDate * 1000).toLocaleDateString()
    },
    getTestResult(testId, userId) {
      let user = this.report.pilots.filter(user => {
        return user.id === userId;
      })[0];

      let test = user.tests.filter(test => {
        return test.test_id === testId;
      })[0];

      if (!test) {
        return 3;
      }

      if (!test.result) {
        return 2;
      }
      return 1;

    },
    reset() {
      this.search = null;
      this.projectId = null;
      this.polygonsZhd = [];
    }
  }
}

</script>

<style lang="scss">

.report-icon {
  font-size: 30px;

  &.el-icon-success {
    color: #68b168;
  }

  &.el-icon-error {
    color: #e96666;
  }
}

.el-table.el-table--report {

  td {
    vertical-align: middle;
  }
}
</style>
