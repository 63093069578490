<template>
  <div>
    <page-heading
        :heading="test.name"
    >
      <span slot="help"></span>
      <span slot="right"></span>
    </page-heading>

    <div class="test">
      <el-row
          :gutter="24"
          type="flex"
      >
        <el-col :span="5" v-loading="loading">
          <el-row
              class="test__amount"
              type="flex"
          >
            <el-col>
              <h4>Всего {{ totalQuestionsCount }} вопросов: </h4>
            </el-col>

            <el-col>
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="16" cy="16" r="12" stroke="#767A7A" stroke-width="2"/>
                <path d="M16 8V16.25L22 19" stroke="#767A7A" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
              <span>{{ inProgressQuestionsCount }} в разработке</span>
            </el-col>

            <el-col>
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
                    stroke="#E18250" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path
                    d="M12.1201 11.9998C12.4336 11.1087 13.0523 10.3573 13.8667 9.87863C14.6811 9.39999 15.6386 9.22503 16.5697 9.38473C17.5007 9.54443 18.3452 10.0285 18.9536 10.7512C19.5619 11.4738 19.8949 12.3885 19.8934 13.3331C19.8934 15.9998 15.8935 17.3331 15.8935 17.3331"
                    stroke="#E18250" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16 22.667H16.0129" stroke="#E18250" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
              <span>{{ inNegotiationQuestionsCount }} на согласовании</span>
            </el-col>

            <el-col>
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="16" cy="16" r="12" stroke="#6DC077" stroke-width="2"/>
                <path d="M10.9997 16.3109L13.948 19.2592C14.3385 19.6497 14.9717 19.6497 15.3622 19.2592L21.966 12.6554"
                      stroke="#6DC077" stroke-width="2" stroke-linecap="round"/>
              </svg>
              <span>{{ agreedQuestionsCount }} согласованных</span>
            </el-col>

            <el-col>
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="16" cy="16" r="12" stroke="#D95954" stroke-width="2"/>
                <path d="M20.9998 21.001L10.999 11" stroke="#D95954" stroke-width="2" stroke-linecap="round"/>
                <path d="M10.9992 21.001L21 11" stroke="#D95954" stroke-width="2" stroke-linecap="round"/>
              </svg>
              <span>{{ rejectedQuestionsCount }} отклоненных</span>
            </el-col>
          </el-row>

          <el-collapse
              v-model="currentSection"
              class="player-section-collapse test__collapse"
              @change=""
          >
            <el-collapse-item
                v-for="(section, sectionIndex) in test.sections"
                :key="section.id"
                :title="section.name"
                :name="section.id"
            >
              <el-timeline v-if="section.questions.length" class="questions-timeline">
                <el-scrollbar :class="{ scroll: section.questions.length > 9 }">
                  <el-timeline-item
                      v-for="(question, questionIndex) in section.questions"
                      :icon="getTimelineIcon(question)"
                      :type="getTimelineIconType(question)"
                      size="large"
                      :key="question.id"
                  >
                    <el-button
                        class="select-question-button"
                        :class="{active: question.id===currentQuestion.id}"
                        type="text"
                        size="mini"
                        @click="questionSelected(question)"
                    >
                      Вопрос
                      {{
                        test.sections
                            .slice(0, sectionIndex)
                            .reduce((total, ch) => total += ch.questions.length, 0)
                        + questionIndex
                        + 1
                      }}.

                    </el-button>
                  </el-timeline-item>
                </el-scrollbar>
              </el-timeline>

              <el-button
                  v-if="$can('test-editor-create-question', test)"
                  style="padding: 0; margin-top: 16px;"
                  type="text"
                  size="small"
                  icon="fas fa-plus"
                  @click="addNewQuestion(section)"
              >
                Добавить вопрос
              </el-button>
            </el-collapse-item>
          </el-collapse>
        </el-col>

        <el-col :span="14" v-loading="loading">
          <el-card
              v-if="!!currentQuestion.section_id"
              class="test__content"
              shadow="never"
          >
            <el-form
                label-width="120px"
                label-position="left"
                :model="currentQuestion"
            >
              <div class="el-form__info">

                <el-form-item
                  v-if="currentQuestion.solutions && currentQuestion.solutions.length"
                  label="Решение эксперта:"
                >
                  <div v-for="solution in currentQuestion.solutions">
                    {{ solution.solution }}
                  </div>
                </el-form-item>

                <el-form-item label="Вопрос" prop="question">
                  <el-input
                      v-model="currentQuestion.question"
                      :disabled="!$can('test-editor-update-question', currentQuestion)"
                      type="textarea"
                      :autosize="{ minRows: 2 }"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Изображение">
                  <image-uploader
                      :disabled="!$can('test-editor-update-question', currentQuestion)"
                      v-model="currentQuestion.image"
                      :method="$api.test.uploadImage"
                      :files-list="[currentQuestion.image]"
                  ></image-uploader>
                </el-form-item>

                <el-form-item label="Тип ответа" prop="type">
                  <el-select
                      v-model="currentQuestion.type"
                      :disabled="!$can('test-editor-update-question', currentQuestion)"
                      @change="currentQuestion.answers.forEach(a => {a.is_right = false})"
                      style="margin-bottom: 24px;"
                  >
                    <el-option label="Одиночный выбор" :value="$constants.test.questionTypes.TYPE_SINGLE"></el-option>
                    <el-option label="Множественный выбор"
                               :value="$constants.test.questionTypes.TYPE_MULTIPLE"></el-option>
                  </el-select>
                  <p class="desc text-min" style="margin-bottom: 0;">
                    <span v-if="currentQuestion.type === $constants.test.questionTypes.TYPE_SINGLE">
                      Создайте минимум 4 варианта ответа
                    </span>
                    <span v-else>
                      Создайте минимум 5 вариантов ответов
                    </span>
                  </p>
                </el-form-item>
              </div>

              <h3 v-if="currentQuestion.answers.length" style="margin-bottom: 0; font-size: 24px; font-weight: 400;">
                Ответы</h3>

              <el-form-item
                  v-for="(answer, answerIndex) in currentQuestion.answers"
                  :key="answerIndex"
                  :label="'Ответ ' + (answerIndex+1)"
                  class="el-form-item--question"
              >
                <el-row type="flex" align="middle" style="margin-bottom: 12px;">
                  <el-input
                      v-model="answer.answer"
                      :disabled="!$can('test-editor-update-question', currentQuestion)"
                      type="textarea"
                      autosize
                  ></el-input>
                  <el-checkbox
                      v-model="answer.is_right"
                      style="margin-left: 10px"
                      :disabled="!$can('test-editor-update-question', currentQuestion)"
                      @change="rightAnswerChanged(answer)"
                  >
                    Это верный ответ
                  </el-checkbox>
                  <el-popconfirm
                      v-if="$can('test-editor-update-question', currentQuestion)"
                      confirm-button-text='Да'
                      cancel-button-text='нет'
                      icon="el-icon-question"
                      title="Вы уверены, что хотите удалить ответ?"
                      @confirm="deleteAnswer(answer)"
                  >
                    <el-button
                        style="margin-left: 10px; height: min-content"
                        slot="reference"
                        class="delete-button"
                        :loading="loading"
                        type="text"
                        icon="fas fa-trash"
                    ></el-button>
                  </el-popconfirm>
                </el-row>
                <image-uploader
                    :disabled="!$can('test-editor-update-question', currentQuestion)"
                    v-model="answer.image"
                    :method="$api.test.uploadImage"
                    :files-list="[answer.image]"
                ></image-uploader>
              </el-form-item>

              <el-button
                  v-if="$can('test-editor-update-question', currentQuestion)"
                  style="padding: 0"
                  type="text"
                  size="small"
                  icon="fas fa-plus"
                  @click="addNewAnswer"
              >
                Добавить ответ
              </el-button>
            </el-form>

            <el-row style="margin-top: 20px">
              <el-button
                  v-if="(
                              (currentQuestion.id && $can('test-editor-update-question', currentQuestion))
                              || (!currentQuestion.id && $can('test-editor-create-question', test))
                            )"
                  type="primary"
                  size="small"
                  @click="saveQuestion"
              >
                Сохранить
              </el-button>

              <move-question-modal
                  v-if="currentQuestion.id && $can('test-editor-move-question', currentQuestion)"
                  style="display: inline; margin-left: 10px"
                  :question-id="currentQuestion.id"
                  :test-sections="test.sections"
                  @question-moved="getTest(); questionSelected(currentQuestion)"
              >
                <el-button
                    slot="reference"
                    size="small"
                >
                  Переместить
                </el-button>
              </move-question-modal>

              <el-popconfirm
                  v-if="currentQuestion.id && $can('test-editor-delete-question', currentQuestion)"
                  confirm-button-text='Да'
                  cancel-button-text='нет'
                  icon="el-icon-question"
                  title="Вы уверены, что хотите удалить вопрос?"
                  @confirm="deleteQuestion"
              >
                <el-button
                    slot="reference"
                    style="margin-left: 10px"
                    size="small"
                    :loading="loading"
                    type="danger"
                >
                  Удалить вопрос
                </el-button>
              </el-popconfirm>
            </el-row>
          </el-card>
        </el-col>

        <el-col :span="5" v-loading="loading">
          <!--          <div class="test__btns">-->
          <!--            <el-button-->
          <!--                size="small"-->
          <!--                class="el-button&#45;&#45;download"-->
          <!--            >-->
          <!--              Выгрузить согласование теста-->
          <!--            </el-button>-->

          <!--            <el-button-->
          <!--                size="small"-->
          <!--                plain-->
          <!--            >-->
          <!--              Памятка по разработке-->
          <!--            </el-button>-->
          <!--          </div>-->

          <div class="test__analytics">
            <div class="test__info">
              <div class="test__info-title">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                      stroke="#A3A6A7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12 8V12" stroke="#A3A6A7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12 16H12.01" stroke="#A3A6A7" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round"/>
                </svg>
                <span>Название теста и индикаторов подтягиваются со страницы МПК и имеют идентичные формулировки.</span>
              </div>

              <ul class="test__info-list">
                <li>Если нужно удалить индикатор, нужно удалить необходимый индикатор в МПК и в тесте он тоже удалится.
                </li>
                <li>Если нужно изменить название теста/индикатора, то необходимо изменить их в МПК. В тесте они
                  изменятся
                  автоматически.
                </li>
                <li>Для согласования вопроса, необходимо заполнить все поля, в том числе и источники.</li>
              </ul>
            </div>
          </div>

          <div
              v-if="!!currentQuestion.section_id"
              class="test__author"
          >
            <div class="test__author-title">Автор вопроса</div>
            {{ currentQuestion.author ? currentQuestion.author.full_name : '' }}
            <add-users-dialog
                v-if="$can('test-editor-set-question-author', test)"
                singleSelect
                title-text="Выберите автора вопроса"
                :filter-tests="[this.test.id]"
                @users-selected="changeAuthor"
            >
              <el-button
                  slot="reference"
                  size="mini"
                  type="text"
              >
                Изменить автора
              </el-button>
            </add-users-dialog>
          </div>

          <div
              v-if="!!currentQuestion.section_id"
              class="test__source"
          >
            <div class="test__source-title">
              Источник
            </div>

            <el-input
                v-model="currentQuestion.sources"
                :disabled="!$can('test-editor-update-question', currentQuestion)"
                show-word-limit
                :maxlength="300"
                type="textarea"
                :autosize="{ minRows: 2 }"
            ></el-input>
          </div>

          <div
              v-if="!!currentQuestion.id"
              class="test__source"
          >
            <div class="test__source-title">Согласование</div>

            <div class="test__source-list">

              <agree-section
                :agreed-expert="!!currentQuestion.agreed_expert"
                :can-finish="!currentQuestion.agreed_expert && !!currentQuestion.can_finish"

                :agreed-expert-main="!!currentQuestion.agreed_expert_main"
                :can-agree-expert-main="!!currentQuestion.agreed_expert && !currentQuestion.agreed_expert_main  && !!currentQuestion.can_agree_main"

                :agreed-admin="!!currentQuestion.agreed_admin"
                :can-agree-admin="!!currentQuestion.agreed_expert_main && !currentQuestion.agreed_admin && !!currentQuestion.can_agree_admin"

                :can-reset-agree="!!currentQuestion.can_reset_agree"

                text-finish="Если Вы уверены, что завершили работу над формированием вопроса, отправьте его на согласование. Можете оставить комментарий:"
                text-agree="Вы согласовываете вопрос. Можете оставить комментарий:"
                text-reject="Вы отклоняете вопрос. Необходимо оставить комментарий:"
                text-reset="Вы отправляете вопрос на доработку. Необходимо оставить комментарий:"
                title-finish="Направить на согласование"
                title-agree="Согласование вопроса"
                title-reject="Отклонение вопроса"
                title-reset="Отправка на доработку"

                :method="$api.test.setQuestionAgreeStatus"
                :method-params="{question_id: currentQuestion.id}"

                @agreed="questionSelected(currentQuestion); getTest()"
              ></agree-section>

            </div>
            <test-question-agree-history-modal
                v-if="currentQuestion.id && $can('test-editor-see-question-agree-history', currentQuestion)"
                :method="$api.test.getQuestionAgreeHistory"
                :params="{  question_id: currentQuestion.id }"
            >
              <el-button
                  slot="reference"
                  type="text"
                  size="small"
                  icon="el-icon-list"
              >
                История согласования вопроса
              </el-button>

            </test-question-agree-history-modal>
          </div>

          <div class="test__source">
            <deleted-questions-modal
                v-if="$can('test-editor-see-deleted-questions', test)"
                :test-id="test.id"
                :test-sections="test.sections"
                @question-restored="getTest"
            >
              <el-button
                  slot="reference"
                  type="text"
                  size="small"
                  icon="el-icon-reading"
              >
                Журнал удаленных вопросов
              </el-button>
            </deleted-questions-modal>
          </div>

          <div class="test__history"
            v-if="!!currentQuestion.id"
          >
            <edit-history-modal
              title="История изменений вопроса"
              :method="$api.test.getQuestionEditHistory"
              :params="{ id: currentQuestion.id }"
              show-model-full-name
            >
              <el-button
                slot="reference"
                type="text"
                size="mini"
                icon="fas fa-history"
              >
                История изменений
              </el-button>
            </edit-history-modal>

          </div>

          <div class="test__source"
            v-show="!!currentQuestion.id"
          >
            <test-question-statistics
              :question-id="currentQuestion.id"
            >
            </test-question-statistics>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>

import PageHeading from "../../components/PageHeading";
import axios from "axios";
import FileUploader from "@/components/uploader/FileUploader";
import AddUsersDialog from "@/components/user/AddUsersDialog";
import ImageUploader from "@/components/uploader/ImageUploader";
import AgreeHistoryModal from "@/components/AgreeHistoryModal";
import TestQuestionAgreeHistoryModal from "@/components/test/TestQuestionAgreeHistoryModal";
import DeletedQuestionsModal from "@/components/test/DeletedQuestionsModal";
import MoveQuestionModal from "@/components/test/MoveQuestionModal";
import EditHistoryModal from "@/components/EditHistoryModal";
import AgreeSection from "@/components/AgreeSection";
import TestQuestionStatistics from "@/components/test/TestQuestionStatistics";

export default {
  name: 'TestEditor',
  components: {
    TestQuestionStatistics,
    AgreeSection,
    EditHistoryModal,
    MoveQuestionModal,
    DeletedQuestionsModal,
    TestQuestionAgreeHistoryModal,
    AgreeHistoryModal, ImageUploader, AddUsersDialog, FileUploader, PageHeading
  },
  data() {
    return {
      loading: false,
      currentSection: null,
      test: {
        id: null,
        name: null,
        sections: [],
      },
      currentQuestion: {
        id: null,
        section_id: null,
        type: this.$constants.test.questionTypes.TYPE_SINGLE,
        question: null,
        sources: null,
        image: {},
        answers: [],
      },
    }
  },
  computed: {
    totalQuestionsCount() {
      return this.test.sections
          .reduce((total, ch) => total += ch.questions.length, 0)
    },
    inProgressQuestionsCount() {
      return this.test.sections
          .reduce((total, ch) => total += ch.questions.filter(q => !q.agreed_expert && !q.is_rejected).length, 0)
    },
    inNegotiationQuestionsCount() {
      return this.test.sections
          .reduce((total, ch) => total += ch.questions.filter(q => q.agreed_expert && !q.agreed_admin).length, 0)
    },
    agreedQuestionsCount() {
      return this.test.sections
          .reduce((total, ch) => total += ch.questions.filter(q => q.agreed_admin).length, 0)
    },
    rejectedQuestionsCount() {
      return this.test.sections
          .reduce((total, ch) => total += ch.questions.filter(q => q.is_rejected).length, 0)
    },
  },
  watch: {
    currentSection: function (newVal) {
      this.currentQuestion.section_id = null;
    }
  },

  mounted() {
    this.getTest();
  },
  methods: {
    getTest() {
      this.loading = true;
      this.$api.test.getTestToEdit({
        id: this.$route.params.id,
      })
          .then(data => {
            this.test = data.test
          })
          .finally(() => {
            this.loading = false;
          })
    },

    questionSelected(question) {
      this.loading = true;
      this.currentQuestionAnswer = [];
      this.currentChecklists = [];
      this.$api.test.getQuestionToEdit({
        id: question.id,
      })
          .then(data => {
            this.currentQuestion = data.question
          })
          .finally(() => {
            this.loading = false;
          })
    },

    addNewQuestion(section) {
      this.currentQuestion.id = null;
      this.currentQuestion.section_id = section.id;
      this.currentQuestion.type = this.$constants.test.questionTypes.TYPE_SINGLE;
      this.currentQuestion.question = null;
      this.currentQuestion.sources = null;
      this.currentQuestion.image = null;
      this.currentQuestion.answers = [];
      this.currentQuestion.author = {};
    },
    addNewAnswer() {
      this.currentQuestion.answers.push({
        id: null,
        answer: null,
        is_right: false,
        image: null,
      })
    },

    rightAnswerChanged(answer) {
      if (!answer.is_right) {
        return;
      }

      if (this.currentQuestion.type !== this.$constants.test.questionTypes.TYPE_SINGLE) {
        return;
      }

      this.currentQuestion.answers.forEach(a => {
        if (a !== answer) {
          a.is_right = false;
        }
      })

    },

    deleteAnswer(answer) {
      this.currentQuestion.answers.splice(this.currentQuestion.answers.indexOf(answer), 1);
    },

    saveQuestion() {
      this.loading = true;
      this.$api.test.saveQuestion(this.currentQuestion)
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDuration,
              title: 'Сохранено',
              message: 'Вопрос успешно сохранен'
            });
            this.currentQuestion.id = data.question.id;
            this.getTest();
            this.questionSelected(this.currentQuestion);
          })
          .finally(() => {
            //this.loading = false;
          })
    },

    deleteQuestion() {
      this.loading = true;
      this.$api.test.deleteQuestion({
        id: this.currentQuestion.id,
      })
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDuration,
              title: 'Удалено',
              message: 'Вопрос успешно удален'
            });
            this.getTest();
            this.currentQuestion.section_id = null;
          })
          .finally(() => {
            this.loading = false;
          })
    },
    changeAuthor(newAuthor) {
      this.loading = true;
      this.$api.test.setQuestionAuthor({
        question_id: this.currentQuestion.id,
        author_id: newAuthor.id,
      })
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDuration,
              title: 'Изменено',
              message: 'Автор успешно изменен'
            });
            // this.currentQuestion.author = data.question.author;
            this.questionSelected(this.currentQuestion)
          })
          .finally(() => {
            this.loading = false;
          })
    },
    getTimelineIcon(question) {
      if (question.agreed_admin) {
        return 'el-icon-success';
      } else if (question.agreed_expert) {
        return 'el-icon-question';
      }

      if (!question.agreed_expert) {
        if (question.is_rejected) {
          return 'el-icon-error';
        }
        return 'el-icon-time';
      }
      return '';
    },
    getTimelineIconType(question) {
      if (question.agreed_admin) {
        return 'success';
      } else if (question.agreed_expert) {
        return 'warning';
      }
      if (question.is_rejected) {
        return 'danger';
      }
      return 'info';
    },
  }
}

</script>

<style lang="scss">
@import "./src/assets/css/project-variables";

.test {
  border-top: 1px solid $--color-divider;

  &__amount {
    flex-direction: column;
    padding-bottom: 32px;
    margin: 24px 0;
    border-bottom: 1px solid $--color-divider;

    h4 {
      margin-bottom: 0;
    }

    .el-col {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    svg {
      width: 32px;
      height: 32px;
      margin-right: 8px;
    }
  }

  &__collapse {
    border: none;

    .el-collapse-item {
      padding: 12px 16px;
      margin-bottom: 16px;
      border: 1px solid $--color-divider;
      border-radius: 6px;

      &__header {
        height: auto;
        font-size: 14px;
        border-bottom: none;

        i {
          flex-shrink: 0;
          padding-left: 8px;
          margin: 0 0 0 auto;
          font-size: 16px;
        }

        &.is-active {
          padding-bottom: 12px;
          border-bottom: 1px solid $--color-divider;
        }
      }

      &__wrap {
        border-bottom: none;
      }

      &__content {
        padding: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__content {
    padding: 24px 24px 32px;
    background-color: $--color-divider-4;
    border: none;

    .el-form {

      &-item {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 24px;
      }
    }

    .el-textarea.is-disabled .el-textarea__inner,
    .el-input.is-disabled .el-input__inner {
      color: $--color-text-regular;
    }
  }

  &__analytics {
    margin-top: 24px;
  }

  &__info {
    margin: 24px 0;
    padding: 24px 0;
    border-top: 1px solid $--color-divider;
    border-bottom: 1px solid $--color-divider;

    &-title {
      display: flex;
      margin-bottom: 8px;

      svg {
        width: 24px;
        height: 24px;
        flex-shrink: 0;
        margin-right: 8px;
      }

      span {
        font-size: 14px;
        font-weight: 700;
      }
    }

    &-list {
      padding-left: 16px;
      margin-bottom: 0;
      list-style: disc;

      li {
        font-size: 14px;
        margin-bottom: 4px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .questions-timeline {
    padding: 16px 0;
    border-bottom: 1px solid $--color-divider;

    .el-scrollbar {

      &__wrap {
        overflow: auto;
        margin-bottom: 0 !important;
      }

      &__bar {

        &.is-horizontal {
          display: none;
        }

        &.is-vertical {

          .el-scrollbar__thumb {
            background-color: $--color-primary;
          }
        }
      }

      &.scroll {
        height: 350px;
      }
    }

    .el-timeline-item {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      padding-bottom: 0;

      &__tail {
        display: none;
      }

      &__node {
        position: static;
        width: 18px;
        height: 18px;
        font-size: 18px;

        &--info {
          width: 20px;
          height: 20px;
        }
      }

      &__icon {
        font-size: 18px;
      }

      &__wrapper {
        position: static;
        padding-left: 12px;

        .select-question-button {
          font-size: 14px;
          line-height: 1.5;
          color: $--color-text-regular-4;

          &:hover {
            color: $--color-text-regular;
          }
        }

        .select-question-button.active {
          font-weight: bold;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .el-form {

    &__info {
      margin-bottom: 24px;
      border-bottom: 1px solid $--color-divider;
    }

    &-item {

      &__label {
        color: $--color-text-regular;
      }

      &__content {
        flex: 1;
        line-height: 1.5;
        margin-left: 0 !important;
      }

      &--question {
        padding: 8px 0;
        margin-bottom: 16px;
        border-bottom: 1px solid $--color-divider;

        &:first-child {
          border-top: 1px solid $--color-divider;
        }
      }
    }
  }

  .upload-demo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .el-upload {
      margin-bottom: 8px;

      &-list {

        &__item {
          height: 48px;
          display: flex;
          align-items: center;
          padding: 0 16px 0 0;
          margin: 0;
          background-color: $--color-divider-2;
          border: none;
          border-radius: 6px;

          &-thumbnail {
            width: 78px;
            height: 44px;
            float: none;
            display: block;
            margin: 0 8px 0 2px;
            object-fit: cover;
            object-position: center;
            border-radius: 4px;
          }

          &-name {
            display: flex;
            align-items: center;
            padding-left: 0;
            pointer-events: none;
            line-height: 1.5 !important;
          }

          &-status-label {
            display: none;
          }

          .el-icon-close {
            top: 50%;
            right: 12px;
            width: 24px;
            height: 24px;
            display: block;
            font-size: 24px;
            transform: translateY(-50%);

            &::before {
              content: '';
            }
          }
        }
      }

      &--picture-card {
        width: auto;
        height: auto;
        line-height: 1.5;
        border: none;

        .el-upload-list__item-actions {
          width: 78px;
        }
      }
    }
  }

  &__btns {
    padding-top: 24px;

    button.el-button {
      width: 100%;
      margin-left: 0;
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__author {
    margin-bottom: 16px;

    &-title {
      margin-bottom: 8px;
      font-size: 14px;
      color: $--color-text-regular-4;
    }
  }

  &__source {
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid $--color-divider-2;

    &-title {
      margin-bottom: 8px;
      font-size: 14px;
      color: $--color-text-regular-4;
    }

    &-list {
      margin-bottom: 24px;
    }

    &-item {
      position: relative;
      padding-left: 22px;
      margin-bottom: 8px;

      &::before {
        content: '';
        position: absolute;
        top: 4px;
        left: 0;
        width: 16px;
        height: 16px;
        background-image: url("../../assets/img/icons/icon-table-source.svg");
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-link {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__history {
    padding-bottom: 24px;
    margin-bottom: 32px;
    border-bottom: 1px solid $--color-divider-2;

    .el-button {
      margin-left: 0;
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__statistics {
    padding: 16px;
    border: 1px solid #D3D3D3;
    border-radius: 6px;

    .el-collapse-item__header {
      height: auto;
      font-size: 14px;
      line-height: 1.5;
      border-bottom: none;

      &.is-active {
        padding-bottom: 12px;
        margin-bottom: 16px;
        border-bottom: 1px solid $--color-divider-2;
      }
    }

    .el-collapse-item__wrap {
      border-bottom: none;
    }

    .el-collapse-item__content {
      padding-bottom: 0;
      font-size: 14px;
      line-height: 1.5;
    }

    &-list {
      margin-bottom: 0;

      &--red {
        padding-bottom: 16px;
        margin-bottom: 16px;
        border-bottom: 1px solid $--color-divider-2;

        .test__statistics-item__value {
          color: $--color-secondary-red;
        }
      }
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      &__title {
        flex: 1;
        padding-right: 24px;
        color: $--color-text-regular-4;
      }

      &__value {
        flex-shrink: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

</style>
