<template>
    <span>
        <el-dialog
            title="Журнал удаленных профилей"
            :visible.sync="show"
            :close-on-click-modal="false"
            width="95%"
            top="2vh"
            append-to-body
            destroy-on-close
        >
            <el-row>

                <el-table
                    style="width: 100%"
                    border
                    :data="profilingTsds"
                    size="mini"
                    v-loading="loading"
                >
                    <el-table-column label="Типовая ОЕ" width="240">
                        <template slot-scope="scope">
                          <el-tooltip placement="top" effect="light">
                            <div slot="content">{{ scope.row.tsd.tp
                              ? scope.row.tsd.tp.path + scope.row.tsd.tp.objectname
                              : '' }}</div>
                            <p class="text-ellipsis">{{ scope.row.tsd.tp
                              ? scope.row.tsd.tp.path + scope.row.tsd.tp.objectname
                              : '' }}</p>
                          </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="Типовое подразделение" width="360">
                        <template slot-scope="scope">
                            {{ scope.row.tsd.tp ? scope.row.tsd.tp.objectname : '' }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Типовая ШД (ИД)" width="160">
                        <template slot-scope="scope">
                            {{ scope.row.tsd.objectid }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Наименование типовой ШД" width="360">
                        <template slot-scope="scope">
                            {{ scope.row.tsd.objectname }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Уровень должности" width="200">
                        <template slot-scope="scope">
                            {{ $constants.tsd.dLevelNames[scope.row.tsd.t9902dlevel] }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Удалено">
                        <template slot-scope="scope">
                            <div>{{ scope.row.deleted_user.full_name }}</div>
                            {{ new Date(scope.row.deleted_at * 1000).toLocaleString() }}
                        </template>
                    </el-table-column>
                    <el-table-column width="40">
                        <template slot-scope="scope">
                            <ellipsis-menu

                            >
                                <el-popconfirm
                                    v-if="$can('profiling-restore-tsd', scope.row)"
                                    confirm-button-text='Да'
                                    cancel-button-text='нет'
                                    icon="el-icon-question"
                                    title="Вы уверены, что хотите вернуть профиль?"
                                    @confirm="restoreProfilingTsd(scope.row)"
                                >
                                    <el-button
                                        slot="reference"
                                        type="text"
                                        size="small"
                                        icon="fas fa-trash"
                                        :loading="loading"
                                    >
                                        Восстановить профиль ТШД
                                    </el-button>
                                </el-popconfirm>
                            </ellipsis-menu>
                        </template>
                    </el-table-column>


                </el-table>

              <paginator
                  :paginator.sync="paginator"
                  @page-changed="showDeleted"
              ></paginator>


              <!--                <paginator-->
              <!--                    :paginator.sync="paginator"-->
              <!--                    @page-changed="showDeleted"-->
              <!--                ></paginator>-->
            </el-row>

            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="show = false">Закрыть</el-button>
            </span>
        </el-dialog>

        <span ref="wrapper">
            <slot name="reference"></slot>
        </span>
    </span>
</template>

<script>

import {off, on} from "element-ui/src/utils/dom";
import Paginator from "./../Paginator";
import EllipsisMenu from "../EllipsisMenu";
import {paginator} from "@/mixins/paginator";

export default {
  name: "deleted-tsds-modal",
  mixins: [paginator],
  components: {EllipsisMenu, Paginator},
  props: {
    profilingId: {
      type: Number,
    }
  },

  data() {
    return {
      show: false,
      profilingTsds: [],
      loading: false,
    }
  },
  mounted() {
    if (this.$refs.wrapper.children) {
      on(this.$refs.wrapper.children[0], 'click', this.showDeleted);
    }
  },
  watch: {},
  methods: {
    showDeleted() {
      this.loading = true;
      this.profilingTsds = [];
      this.show = true;
      this.$api.profiling.getDeletedTsds({
        profiling_id: this.profilingId,
        expand: 'tsd.tp.path',
        [this.paginator.param]: this.paginator.page,
        [this.paginator.paramPerPage]: this.paginator.perPage,
      })
          .then(({data, paginator}) => {
            this.profilingTsds = data.profiling_tsds;
            this.paginatorFill(paginator);
          })
          .finally(() => {
            this.loading = false;
          })
    },
    restoreProfilingTsd(profilingTsd) {
      this.loading = true;
      this.$api.profiling.restoreTsd({
        profiling_id: this.profilingId,
        tsd_id: profilingTsd.tsd_id
      })
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDuration,
              title: 'Восстановлено',
              message: 'Элемент успешно восстановлен'
            });
            this.show = false;
            this.$emit('profiling-tsd-restored');
          })
          .finally(() => {
            this.loading = false;
          })
    },
  },

  beforeDestroy() {
    if (this.$refs.wrapper.children) {
      off(this.$refs.wrapper.children[0], 'click', this.showDeleted);
    }
  }

}
</script>

<style scope>

</style>
