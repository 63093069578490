<template>
    <div>
        <page-heading
            heading="Цепочка согласований"
        >
            <span slot="help"></span>
            <span slot="right">

            </span>
        </page-heading>



        <el-card>
            <div class="block" v-for="(element, index) in elements">
                <div class="inline">Этап согласования {{ index + 1 }}</div>
                <div class="inline" v-for="(parallelElement, parallelIndex) in element">
                    <el-select
                        style="width:300px;"
                        v-model="value[index+'_'+parallelIndex]" placeholder="Выберите сотрудника">
                        <el-option
                            v-for="item in options"
                            :key="item.id"
                            :label="item.full_name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                    <el-button
                        @click="deleteParallelElement(index, parallelIndex)"
                    >
                        Удалить
                    </el-button>
                </div>
                <div class="inline">
                    <el-button
                        icon="fas fa-plus"
                        @click="addParallelElement(element)">
                        Добавить сотрудника
                    </el-button>
                    <el-button
                        icon="fas fa-minus"
                        @click="deleteElement(index)">
                        Удалить этап
                    </el-button>
                </div>
                <div>
                    <i
                        style="margin-left: 100px;"
                        v-show="index + 1 < elements.length"
                        class="fas fa-arrow-down"></i>
                </div>
            </div>
            <div>
                <el-button
                    type="primary"
                    @click="addElement"
                >
                    Добавить этап
                </el-button>
            </div>

        </el-card>

    </div>
</template>

<script>


import PageHeading from "../components/PageHeading";
export default {
    name: 'Negotiation',
    components: { PageHeading  },

    data() {
        return {
            loading: false,
            elements: [],
            options: [],
            value:{},
        }
    },
    computed: { },

    mounted() {
        this.getUsers();
    },
    methods: {
        addElement() {
            this.elements.push([1]);
        },
        addParallelElement(element) {
            element.push(1);
        },
        deleteParallelElement(index, parallelIndex) {
            this.elements[index].splice(parallelIndex, 1);
        },
        deleteElement(index) {
            this.elements.splice(index, 1);
        },

        getUsers(){
            this.users = [];
            this.loading = true;
            this.$api.user.search({

            })
                .then(({data, paginator, sorter}) => {
                    this.options = data.users;
                })
                .finally(()=>{
                    this.loading = false;
                })
        },
    }
}

</script>

<style scoped>
.inline {
    /* border: 1px solid black; */
    width: 300px;
    margin: 20px;
    display: inline-block;
}
</style>