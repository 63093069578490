import requestSender from "./base/requestSender";

export default function(axiosInstance){
    return {
        getCommentsForEntity(payload) {
            return requestSender('get', 'comment/get-entity-comments', payload)
        },
        saveEntityComment(payload){
            return requestSender('post', 'comment/update-comment', payload);
        },
        addNewEntityComment(payload){
            return requestSender('post', 'comment/create-comment', payload);
        },
        deleteEntityComment(payload){
            return requestSender('post', 'comment/delete-comment', payload);
        },
    }
}