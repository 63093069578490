<template>
  <div>
    <el-row type="flex" class="row-bg" justify="space-between" style="margin-bottom:10px">
      <el-col align="right">
        <el-button
          v-if="$can('library-manage-folders')"
          type="primary"
          size="mini"
          icon="fas fa-upload"
          :disabled="!folder.id"
          @click="uploadModalShow"
        >
          Загрузить файл
        </el-button>
      </el-col>
    </el-row>
    <el-table
      size="mini"
      style="width: 100%"
      :data="files"
      :empty-text="folder.id ? 'В папке нет файлов' : 'Выберите папку'"
      :show-header="!!files.length"
      v-loading="loading || loadingDownloader"
      :row-key="(row) => row.id"
      @selection-change="$emit('selection-changed', $event)"
    >
      <el-table-column prop="original_name" label="Название"></el-table-column>

      <el-table-column label="Размер">
        <template slot-scope="scope">
          {{formatBytes(scope.row.size)}}
        </template>
      </el-table-column>
      <el-table-column label="Дата загрузки">
        <template slot-scope="scope">
          {{ new Date(scope.row.created_at * 1000).toLocaleDateString() }}
        </template>
      </el-table-column>
      <el-table-column width="80" align="right">
        <template slot-scope="scope">
          <ellipsis-menu>
            <el-popconfirm
              v-if="$can('library-delete-files')"
              confirm-button-text='Да'
              cancel-button-text='нет'
              icon="el-icon-question"
              title="Вы уверены, что хотите удалить файл?"
              @confirm="deleteFile(scope.row)"
            >
              <el-button
                slot="reference"
                type="text"
                size="small"
                icon="fas fa-trash"
                :loading="loading"
              >
                Удалить файл
              </el-button>
            </el-popconfirm>
            <el-button
              type="text"
              size="small"
              icon="fas fa-download"
              @click="download(scope.row)"
            >
              Скачать файл
            </el-button>
          </ellipsis-menu>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      title="Загрузка файла"
      destroy-on-close
      :visible.sync="uploadModal.show"
      width="40%"
    >
      <el-row>
        <file-uploader
          :method="$api.library.uploadFile"
          :folder="folder"
          :exts="['jpg','jpeg','png','mp4','xls','xlsx','csv','rtf','pdf','doc','docx']"
          @uploaded="uploadModal.show=false;getFolderFiles(folder)"
        >
        </file-uploader>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import FileUploader from "@/components/uploader/FileUploader";
import EllipsisMenu from "@/components/EllipsisMenu";
import {downloader} from "@/mixins/downloader";
export default {
  name: "library-files",
  components: {EllipsisMenu, FileUploader},
  mixins: [downloader],

  props: {
    folder: {
      type: Object
    },
  },

  data() {
    return {
      loading: false,
      files: [],


      uploadModal: {
        show: false,
        loading: false,
        file: null,
      },


    }
  },
  mounted() {},
  computed: { },
  watch: {
    folder: function(newVal, oldVal) {
      this.getFolderFiles(newVal);
    }
  },
  methods: {
    getFolderFiles(folder) {
      this.loading = true;
      this.$api.library.getFilesByFolder({
        folder_id: folder ? folder.id : null
      })
        .then(data => {
          this.files = data.files;
        })
        .finally(() => {
          this.loading = false;
        })
    },

    uploadModalShow() {
      this.uploadModal.show = true;
    },


    deleteFile(file) {
      this.loading = true;
      this.$api.library.deleteFile({
        folder_id: this.folder.id,
        file_id: file.id
      })
        .then(data => {
          this.getFolderFiles(this.folder);
        })
    },

    download(file){
      this.downloadFile(
        file.original_name,
        this.$api.library.downloadFile,
        { file_id: file.id }
      );
    },



    formatBytes(bytes, decimals = 0) {
      if (bytes === 0) return '0 Bytes';

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },


  }
}
</script>

<style lang="scss">
@import "@/assets/css/project-variables.scss";

.el-input__suffix .el-checkbox span.el-checkbox__label {
  font-size: 12px;
}
</style>