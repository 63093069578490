import requestSender from "./base/requestSender";

export default function(axiosInstance){
    return {
        searchTsds(payload){
            return requestSender('get', 'tsd/search-tsds', payload);
        },
        searchTypeOe(payload){
            return requestSender('get', 'tsd/search-type-oe', payload);
        },
        searchTpName(payload){
            return requestSender('get', 'tsd/search-tp-name', payload);
        },


        getTypeOeTree(payload){
            return requestSender('get', 'tsd/get-type-oe-tree', payload);
        },
        loadTsdsOfTpsToProject(payload){
            return requestSender('post', 'tsd/load-tsds-of-tps-to-project', payload);
        },


    }
}