import Vue from 'vue'
import VueRouter from 'vue-router'
import Rbac from '../views/Rbac'
import Users from '../views/Users'
import Divisions from '../views/Divisions'
import Projects from '../views/project/Projects'
import Project from '../views/project/Project'
import ProjectUsers from '../views/project/ProjectUsers'
import Fc from '../views/FC'
import Mpc from '../views/MPC'
import Schedule from '../views/Schedule'

import Login from "../views/auth/Login";
import Profile from "../views/auth/Profile";
import Negotiation from "../views/Negotiation";
import Profiling from "../views/Profiling";
import TestPlayer from "../views/test/TestPlayer";
import Pilot from "../views/Pilot";
import TestsList from "../views/test/TestsList";
import TestsDevelopment from "@/views/test/TestsDevelopment";
import TestEditor from "@/views/test/TestEditor";
import Library from "@/views/Library";
import ReportMonitoring from "@/views/report/ReportMonitoring";
import ReportPilotResult from "@/views/report/ReportPilotResult";
import Introduction from "@/views/Introduction";
import NotFound from "@/views/404";
import Restore from "@/views/auth/Restore";
import RestoreStepTwo from "@/views/auth/RestoreStepTwo";
import TestPreview from "@/views/test/TestPreview.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/projects'
    },
    {
        path: '/rbac',
        name: 'Rbac',
        component: Rbac,
        meta: {title: 'Rbac', requiresAuth: true}
    },
    {
        path: '/divisions',
        name: 'Divisions',
        component: Divisions,
        meta: {title: 'Подразделения', requiresAuth: true}
    },
    {
        path: '/users',
        name: 'Users',
        component: Users,
        meta: {title: 'Пользователи', requiresAuth: true}
    },
    {
        path: '/projects',
        name: 'Projects',
        component: Projects,
        meta: {title: 'Проекты', requiresAuth: true},
    },


    {
        path: '/project/:id(\\d+)',
        name: 'Project',
        component: Project,
        meta: {title: 'Проект', requiresAuth: true}
    },
    {
        path: '/project/:id(\\d+)/users',
        name: 'ProjectUsers',
        component: ProjectUsers,
        meta: {title: 'Состав рабочей группы', requiresAuth: true}
    },
    {
        path: '/project/:id(\\d+)/schedule',
        name: 'Schedule',
        component: Schedule,
        meta: {title: 'План-график', requiresAuth: true}
    },
    {
        path: '/project/:id(\\d+)/fc',
        name: 'Fc',
        component: Fc,
        meta: {title: 'Функциональная карта', requiresAuth: true}
    },
    {
        path: '/project/:id(\\d+)/mpc',
        name: 'Mpc',
        component: Mpc,
        meta: {title: 'МПК', requiresAuth: true}
    },
    {
        path: '/project/:id(\\d+)/tests_development',
        name: 'Tests_development',
        component: TestsDevelopment,
        meta: {title: 'Разработка тестов', requiresAuth: true}
    },
    {
        path: '/project/:id(\\d+)/profiling',
        name: 'Profiling',
        component: Profiling,
        meta: {title: 'Профилирование', requiresAuth: true}
    },
    // {
    //     path: '/project/:id(\\d+)/pilot',
    //     name: 'Pilot',
    //     component: Pilot,
    //     meta: {title: 'Пилотная оценка СПК', requiresAuth: true}
    // },
    {
        path: '/project/:id(\\d+)/pilot',
        name: 'Pilot',
        component: Pilot,
        meta: {title: 'Пилотная оценка', requiresAuth: true}
    },
    {
        path: '/project/:id(\\d+)/introduction',
        name: 'Introduction',
        component: Introduction,
        meta: {title: 'Внедрение СПК', requiresAuth: true}
    },
    {
        path: '/negotiation',
        name: 'Negotiation',
        component: Negotiation,
        meta: {title: 'Согласование', requiresAuth: true}
    },


    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {title: 'Логин', layout: 'login-layout'}
    },
    {
        path: '/restore',
        name: 'Restore',
        component: Restore,
        meta: {title: 'Восстановление пароля', layout: 'empty-layout'}
    },
    {
        path: '/restore/:token([\\w\\-\\+\\=]+)',
        name: 'RestoreStepTwo',
        component: RestoreStepTwo,
        meta: {title: 'Восстановление пароля', layout: 'empty-layout'}
    },
    {
        path: '/profile/:id(\\d+)',
        name: 'Profile',
        component: Profile,
        meta: {title: 'Профиль', requiresAuth: true}
    },
    {
        path: '/report/report_monitoring',
        name: 'ReportMonitoring',
        component: ReportMonitoring,
        meta: {title: 'Мониторинг пилота', requiresAuth: true}
    },
    {
        path: '/report/report_pilot_result',
        name: 'ReportPilotResult',
        component: ReportPilotResult,
        meta: {title: 'Результаты пилотной оценки', requiresAuth: true}
    },





    {
        path: '/assignments',
        name: 'TestsList',
        component: TestsList,
        meta: {title: 'Мои тесты', requiresAuth: true}
    },

    {
        path: '/test/:id(\\d+)/edit',
        name: 'TestEditor',
        component: TestEditor,
        meta: {title: 'Редактирование теста', requiresAuth: true}
    },
    {
        path: '/test/:id(\\d+)/play',
        name: 'TestPlayer',
        component: TestPlayer,
        meta: {title: 'Тест', requiresAuth: true}
    },
    {
        path: '/test/:id(\\d+)/preview',
        name: 'TestPreview',
        component: TestPreview,
        meta: {title: 'Тест превью', requiresAuth: true}
    },


    {
        path: '/library',
        name: 'Library',
        component: Library,
        meta: {title: 'Библиотека', requiresAuth: true},
    },



    { path: '/404', name: '404', component: NotFound },
    { path: '*', redirect: '/404' },

]


const router = new VueRouter({
    mode: 'history',
    // base: process.env.BASE_URL,
    base: process.env.NODE_ENV === 'production'
        // ? '/performance/'
        ? '/'
        : '/',
    routes
})



router.beforeEach((to, from, next) => {


    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
    } else if (previousNearestWithMeta) {
        document.title = previousNearestWithMeta.meta.title;
    }

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));






    if(to.matched.some(r => r.meta.requiresAuth)) {

        if (localStorage.getItem('jwt') == null) {
            next({
                path: '/login',
                params: { nextUrl: to.fullPath }
            })
        }
    }






    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
        });

        // We use this to track which meta tags we create so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
    })
        // Add the meta tags to the document head.
        .forEach(tag => document.head.appendChild(tag));


    next();
});

export default router
