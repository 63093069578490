import requestSender from "./base/requestSender";

export default function(axiosInstance){
    return {
        getProjectProfiling(payload){
            return requestSender('get', 'profiling/get-project-profiling', payload);
        },
        saveProfilingName(payload){
            return requestSender('post', 'profiling/save-profiling-name', payload);
        },
        deleteTsd(payload){
            return requestSender('post', 'profiling/delete-tsd', payload);
        },
        getDeletedTsds(payload){
            return requestSender('get', 'profiling/get-deleted-tsds', payload);
        },
        restoreTsd(payload){
            return requestSender('post', 'profiling/restore-tsd', payload);
        },

        saveCompetenceRate(payload){
            return requestSender('post', 'profiling/save-competence-rate', payload);
        },



        uploadToe(payload, options){
            return requestSender('post', 'profiling/upload-toe', payload, options);
        },


        exportToExcel(payload, options){
            return requestSender('post', 'profiling/get-project-profiling', payload, options);
        },
        exportAgreeHistoryToExcel(payload, options){
            return requestSender('post', 'profiling/export-agree-history-to-excel', payload, options);
        },



        getCompetenceSharedUsers(payload){
            return requestSender('get', 'profiling/get-competence-shared-users', payload);
        },
        addNewCompetenceSharedUsers(payload){
            return requestSender('post', 'profiling/add-new-competence-shared-users', payload);
        },
        deleteCompetenceSharedUser(payload){
            return requestSender('post', 'profiling/delete-competence-shared-user', payload);
        },


        getTsdTpSharedUsers(payload){
            return requestSender('get', 'profiling/get-tsd-tp-shared-users', payload);
        },
        addNewTsdTpSharedUsers(payload){
            return requestSender('post', 'profiling/add-new-tsd-tp-shared-users', payload);
        },
        deleteTsdTpSharedUser(payload){
            return requestSender('post', 'profiling/delete-tsd-tp-shared-user', payload);
        },
        setTsdTpMainSharedUser(payload){
            return requestSender('post', 'profiling/set-tsd-tp-main-shared-user', payload);
        },

        setTsdAgreeStatus(payload){
            return requestSender('post', 'profiling/set-tsd-agree-status', payload);
        },
        getAgreeHistory(payload){
            return requestSender('get', 'profiling/get-agree-history', payload);
        },

        startProfiling(payload){
            return requestSender('post', 'profiling/start-profiling', payload);
        },



    }
}