<template>
  <div
    class="page"
    v-if="$can('menu-see-users')"
  >
    <page-heading
        heading="Пользователи"
    >
      <div slot="help"></div>

      <div slot="right" class="page__head-right">
        <import-users-dialog
          v-if="$can('users-import')"
        >
          <el-button
              slot="reference"
              icon="el-icon-document-add"
          >
            Импорт из файла
          </el-button>
        </import-users-dialog>

        <el-button
            v-if="$can('users-create')"
            type="primary"
            icon="el-icon-plus"
            @click="editUserDialogShow({role:{}})"
        >
          Добавить пользователя
        </el-button>
      </div>
    </page-heading>

    <el-row :gutter="24" style="margin-bottom: 32px;" type="flex">
      <el-col :span="4">
        <div class="form-label">
          <span class="form-label__title">Поиск</span>
          <el-input
              v-model="search"
              prefix-icon="el-icon-search"
              placeholder="ФИО или подразделение пользователя"
              size="mini"
              clearable
              @clear="getUsers"
              v-debounce:500="getUsers"
          >
          </el-input>
        </div>
      </el-col>

      <el-col :span="4">
        <div class="form-label">
          <span class="form-label__title">Роль в системе</span>
          <role-select
              size="mini"
              v-model="role"
              :multiple="true"
              @change="getUsers"
          ></role-select>
        </div>
      </el-col>

      <el-col :span="4">
        <div class="form-label">
          <span class="form-label__title">Проект</span>
          <project-select
              size="mini"
              v-model="projectIds"
              :multiple="true"
              @change="getUsers"
          ></project-select>
        </div>
      </el-col>

      <el-col :span="4">
        <div class="form-label">
          <span class="form-label__title">Статус активности</span>
          <user-status-select
              size="mini"
              v-model="active"
              @change="getUsers"
          ></user-status-select>
        </div>
      </el-col>

      <el-col :span="8" style="align-self: end; text-align: right">
        <el-button
            icon="el-icon-refresh"
            :disabled="isDisabled"
            @click="reset"
        >
          Сбросить
        </el-button>
      </el-col>
    </el-row>

    <el-table
        v-loading="loading"
        style="width: 100%"
        height="50vh"
        :row-class-name="getRowClass"
        :data="users"
        @sort-change="sortChanged($event, getUsers)"
    >

      <el-table-column prop="full_name" label="ФИО" :sortable="setIsSortable('full_name')">
        <template slot-scope="scope">
          <el-link
              type="primary"
              :disabled="!$can('users-update')"
              @click="editUserDialogShow(scope.row)"
          >
            {{scope.row.full_name}}
          </el-link>
        </template>
      </el-table-column>
      <el-table-column prop="division" label="Подразделение" :sortable="setIsSortable('division')"></el-table-column>
      <el-table-column prop="position" label="Должность" :sortable="setIsSortable('position')"></el-table-column>
      <el-table-column label="Активные проекты">
        <template slot-scope="scope">
          <div
              v-for="project in scope.row.projects"
          >
            {{project.name}}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="role.description" label="Роль" :sortable="setIsSortable('')"></el-table-column>
      <!--                <el-table-column label="Телефоны">-->
      <!--                    <template slot-scope="scope">-->
      <!--                        {{ scope.row.phone_mobile ? scope.row.phone_mobile + ';' : '' }}-->
      <!--                        {{ scope.row.phone_work ? scope.row.phone_work + ';' : '' }}-->
      <!--                        {{ scope.row.phone_internal ? scope.row.phone_internal + ';' : '' }}-->
      <!--                    </template>-->
      <!--                </el-table-column>-->
      <!--                <el-table-column prop="created_at" label="Дата регистрации" :sortable="setIsSortable('created_at')">-->
      <!--                    <template slot-scope="scope">-->
      <!--                        {{ scope.row.created_at ? new Date(scope.row.created_at * 1000).toLocaleDateString() : '' }}-->
      <!--                    </template>-->
      <!--                </el-table-column>-->
      <!--                <el-table-column prop="date_work_end" label="Дата деактивации" :sortable="setIsSortable('date_work_end')">-->
      <!--                    <template slot-scope="scope">-->
      <!--                        {{ scope.row.date_work_end ? new Date(scope.row.date_work_end * 1000).toLocaleDateString() : '' }}-->
      <!--                    </template>-->
      <!--                </el-table-column>-->
      <el-table-column width="40" align="right" class-name="el-table__cell--ellipsis">
        <template slot-scope="scope">
          <ellipsis-menu>
            <el-button
                style="margin-bottom: 16px"
                v-if="$can('users-update')"
                type="text"
                size="small"
                icon="el-icon-edit"
                @click="editUserDialogShow(scope.row)"
            >
              Редактировать
            </el-button>

            <el-popconfirm
                v-if="!scope.row.active && $can('users-activate')"
                confirm-button-text='Да' cancel-button-text='нет' icon="el-icon-question"
                title="Вы уверены, что хотите активировать пользователя?"
                @confirm="setActiveUser(scope.row, 1)"
            >
              <el-button
                  style="margin-bottom: 16px"
                  slot="reference"
                  type="text"
                  size="small"
                  icon="fas fa-user"
                  :loading="loading"
              >
                Активировать пользователя
              </el-button>
            </el-popconfirm>

            <el-popconfirm
                v-if="scope.row.active && $can('users-deactivate')"
                confirm-button-text='Да' cancel-button-text='нет' icon="el-icon-question"
                title="Вы уверены, что хотите деактивировать пользователя?"
                @confirm="setActiveUser(scope.row, 0)"
            >
              <el-button
                  slot="reference"
                  type="text"
                  size="small"
                  icon="el-icon-user"
                  :loading="loading"
              >
                Деактивировать пользователя
              </el-button>
            </el-popconfirm>

            <el-popconfirm
                v-if="$can('users-delete')"
                confirm-button-text='Да'
                cancel-button-text='нет'
                icon="el-icon-question"
                title="Вы уверены, что хотите удалить пользователя?"
                @confirm="deleteUser(scope.row)"
            >
              <el-button
                  slot="reference"
                  type="text"
                  size="small"
                  icon="el-icon-delete"
                  :loading="loading"
              >
                Удалить пользователя
              </el-button>
            </el-popconfirm>
          </ellipsis-menu>
        </template>
      </el-table-column>
    </el-table>

    <paginator
        :paginator.sync="paginator"
        @page-changed="getUsers(false)"
    ></paginator>

    <edit-user-modal
        :show.sync="editUserDialog.show"
        :user="editUserDialog.userToEdit"
        @saved="getUsers(false)"
    ></edit-user-modal>
  </div>
</template>

<script>
import PageHeading from "../components/PageHeading";
import Paginator from "../components/Paginator";
import EditUserModal from "../components/user/EditUserModal";
import DivisionSelect from "../components/selects/DivisionSelect";
import ImportUsersDialog from "../components/user/ImportUsersDialog";
import {sorter} from "../mixins/sorter";
import {paginator} from "../mixins/paginator";
import EllipsisMenu from "../components/EllipsisMenu";
import RoleSelect from "../components/selects/RoleSelect";
import ProjectSelect from "../components/selects/ProjectSelect";
import UserStatusSelect from "../components/selects/UserStatusSelect";
import {mapGetters} from "vuex";

export default {
  name: 'Users',
  mixins: [sorter, paginator],
  components: {
    UserStatusSelect,
    RoleSelect, ProjectSelect, EllipsisMenu, ImportUsersDialog, DivisionSelect, EditUserModal, Paginator, PageHeading
  },

  data() {
    return {
      loading: false,
      users: [],
      search: null,
      role: [],
      projectIds: [],
      active: 1,
      editUserDialog: {
        show: false,
        userToEdit: {}
      }
    }
  },
  computed: {
    ...mapGetters(['user']),
    isDisabled(){
      return !this.search && !this.role.length && !this.projectIds.length && this.active === 1;
    }
  },

  mounted() {
    this.getUsers();
  },
  methods: {
    getUsers(resetPage=true) {
      if( resetPage ){
        this.paginator.page = 1;
      }
      this.users = [];
      this.loading = true;
      this.$api.user.search({
        search: this.search,
        role: this.role,
        hide_role: ['pilot'],
        project_ids: this.projectIds,
        active: this.active,
        expand: 'projects',

        [this.sorter.param]: this.sorter.sort,
        [this.paginator.param]: this.paginator.page,
        [this.paginator.paramPerPage]: this.paginator.perPage,
      })
          .then(({data, paginator, sorter}) => {
            this.sorterFill(sorter);
            this.paginatorFill(paginator);
            this.users = data.users;
          })
          .finally(() => {
            this.loading = false;
          })
    },
    editUserDialogShow(user) {
      this.editUserDialog.userToEdit = user;
      this.editUserDialog.show = true;
    },
    deleteUser(user) {
      this.loading = true;

      this.$api.user.delete({
        id: user.id
      })
          .then(data => {
            this.$notify.success({
duration: this.$constants.global.notifyDuration,
              title: 'Удалено',
              message: 'Пользователь успешно удален'
            });
          })
          .finally(() => {
            this.getUsers();
          })
    },
    setActiveUser(user, status) {
      this.loading = true;

      this.$api.user.setActive({
        id: user.id,
        active: status
      })
          .then(data => {
            this.$notify.success({
duration: this.$constants.global.notifyDuration,
              title: 'Сохранено',
              message: 'Статус успешно сохранен'
            });
          })
          .finally(() => {
            this.getUsers();
          })
    },

    getRowClass(data) {
      if (!data.row.active) {
        return 'user-row-inactive';
      }
      return '';
    },
    reset() {
          this.search = null;
          this.role = [];
          this.projectIds = [];
          this.active = 1;
          this.getUsers();
    }
  }
}

</script>

<style lang="scss">

.user-row-inactive {
  color: #cecece;
}

.page {

  &__head {

    &-right {
      display: flex;
      align-items: center;
    }
  }
}

.ellipsis-menu-slot {

  > span:not(:last-of-type) {
    margin-bottom: 16px;
  }
}

</style>
