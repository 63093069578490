import requestSender from "./base/requestSender";

export default function(axiosInstance){
    return {
        upload(payload){
            return requestSender('post', 'file/upload', payload);
        },
        download(payload){
            return requestSender('get', 'file/download', payload);
        },

    }
}