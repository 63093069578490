<template>
  <div>
    <div class="page__head">
      <el-row type="flex" justify="space-between" align="top">
        <div class="page__head-left">
          <div class="title-edit">
            <h1 class="page__head-title">Внедрение СПК</h1>

            <click-to-edit
                v-model="project.introduction.name"
                :disabled="!$can('project-introduction-edit', project.permissions)"
                :word-limit="200"
                @input="saveName"
            ></click-to-edit>
          </div>
        </div>

        <div class="page__head-right">
          <upload-to-asutr-modal
            v-if="$can('project-introduction-edit', project.permissions)"
            :project="project"
          >
            <el-button
              :disabled="!isUploadActive"
              style="margin-left:20px"
              slot="reference"
              type="primary"
            >
              Выгрузить в ЕК АСУТР
            </el-button>
          </upload-to-asutr-modal>
        </div>
      </el-row>
    </div>

    <el-row style="margin-bottom: 20px">
      <el-col :span="12">
        <el-row type="flex" align="middle">
          <el-col :span="5">Номер распоряжения</el-col>
          <el-col :span="12">
            <click-to-edit
                class="edit-block--no-svg"
                v-model="project.introduction.statement"
                :disabled="!$can('project-introduction-edit', project.permissions)"
                @input="saveStatement"
            ></click-to-edit>
          </el-col>
        </el-row>


      </el-col>
    </el-row>

    <el-row>
      <el-col :span="24">
        <el-table
            v-loading="loading"
            :data="projectElements"
            style="width: 100%"
        >
          <el-table-column>
            <template slot-scope="scope">
              {{ project[scope.row].in_project_name }}
            </template>
          </el-table-column>

          <el-table-column label="Статус">
            <template slot-scope="scope">
              {{
                ($constants.statusesMap[scope.row] ? ($constants.statusesMap[scope.row][project[scope.row].status]) : null)
              }}

              <el-row
                  v-if="scope.row=='introduction' && canSetDate"
                  type="flex"
              >
                <el-col :span="5">Дата внедрения:</el-col>
                <el-col :span="8">
                  <click-to-edit
                      style="display: inline"
                      :placeholder="$can('project-introduction-edit', project.permissions) ? 'Нажмите для редактирования' : ' '"
                      v-model="project[scope.row].date_introduction"
                      :disabled="!$can('project-introduction-edit', project.permissions)"
                      transform-timestamp
                      type="date-picker"
                      @input="saveDateIntroduction"
                  ></click-to-edit>
                </el-col>
              </el-row>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ClickToEdit from "@/components/ClickToEdit";
import CopyMpcsModal from "@/components/mpc/CopyMpcsModal.vue";
import UploadToAsutrModal from "@/components/introduction/UploadToAsutrModal.vue";

export default {
  name: 'Introduction',
  components: {UploadToAsutrModal, CopyMpcsModal, ClickToEdit},
  data() {
    return {
      loading: false,
      project: {
        id: null,

        mpc: {id: null, in_project_name: null, status: null, date_start: null, date_end: null},
        profiling: {id: null, in_project_name: null, status: null, date_start: null, date_end: null},
        tests_development: {id: null, in_project_name: null, status: null, date_start: null, date_end: null},
        introduction: {
          id: null,
          in_project_name: null,
          status: null,
          name: null,
          date_start: null,
          date_end: null,
          statement: null,
          date_introduction: null
        },
      },
      projectElements: ['mpc', 'profiling', 'tests_development', 'introduction'],
    }
  },
  computed: {
    isUploadActive(){
      return this.project.mpc.status >= this.$constants.mpc.statuses.STATUS_APPROVED
        && this.project.profiling.status >= this.$constants.profiling.statuses.STATUS_APPROVED;
    },
  },
  mounted() {
    this.getProject();
  },
  methods: {
    getProject() {
      this.loading = true;
      this.$api.project.get({
        id: this.$route.params.id,
        expand: 'permissions'
      })
          .then(data => {
            this.project = data.project
          })
          .finally(() => {
            this.loading = false;
          })
    },
    saveName() {
      this.loading = true;
      this.$api.introduction.saveName({
        id: this.project.introduction.id,
        name: this.project.introduction.name
      })
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDuration,
              title: 'Сохранено',
              message: 'Имя сохранено'
            });
          })
          .finally(() => {
            this.loading = false;
          })
    },

    saveStatement() {
      this.loading = true;
      this.$api.introduction.saveStatement({
        id: this.project.introduction.id,
        statement: this.project.introduction.statement
      })
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDuration,
              title: 'Сохранено',
              message: 'Номер распоряжения сохранен'
            });
          })
          .finally(() => {
            this.loading = false;
          })
    },


    canSetDate() {
      return true;
      //this.project.mpc.status == this.$constants.mpc.statuses.
    },

    saveDateIntroduction() {
      this.loading = true;
      this.$api.introduction.saveDateIntroduction({
        id: this.project.introduction.id,
        date_introduction: this.project.introduction.date_introduction
      })
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDuration,
              title: 'Сохранено',
              message: 'Дата сохранена'
            });
          })
          .finally(() => {
            this.$store.dispatch('updateMenu');
            this.getProject();
          })
    },

  }
}

</script>
