<template>
  <div>
    <el-dialog
      title="Журнал удаленных вопросов"
      :visible.sync="show"
      :close-on-click-modal="false"
      width="95%"
      top="2vh"
      append-to-body
    >
      <el-row>

        <el-table
          class="MPC-table"
          row-class-name="no-hover"
          empty-text="Нет удаленных вопросов"
          style="width: 100%"
          border
          :data="questions"

          v-loading="loading"
        >
          <el-table-column label="Индикатор">
            <template slot-scope="scope">
              {{ scope.row.section ? scope.row.section.name : '' }}
            </template>
          </el-table-column>
          <el-table-column label="Вопрос">
            <template slot-scope="scope">
              {{ scope.row.question }}
            </template>
          </el-table-column>
          <el-table-column label="Автор вопроса">
            <template slot-scope="scope">
              {{ scope.row.author ? scope.row.author.full_name : '' }}
            </template>
          </el-table-column>
          <el-table-column label="Удалено">
            <template slot-scope="scope">
              <div>{{ scope.row.deleted_user.full_name }}</div>
              {{ new Date(scope.row.deleted_at * 1000).toLocaleString() }}
            </template>
          </el-table-column>
          <el-table-column width="40">
            <template slot-scope="scope">
              <ellipsis-menu

              >
                <el-button
                  type="text"
                  size="small"
                  @click="showRestoreModal(scope.row)"
                >
                  Восстановить вопрос
                </el-button>
              </ellipsis-menu>
            </template>
          </el-table-column>
        </el-table>

        <paginator
          :paginator.sync="paginator"
          @page-changed="showDeleted"
        ></paginator>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="show = false">Закрыть</el-button>
      </span>
    </el-dialog>



    <el-dialog
      title="Восстановление вопроса"
      :visible.sync="restoreModal.show"
      :close-on-click-modal="false"
      width="80%"
      top="2vh"
      append-to-body
    >
      <el-row>
        <div>Выберите индикатор, в который будет восстановлен вопрос:</div>

        <div v-for="section in testSections">
          <el-radio
            v-model="restoreModal.sectionId"
            :label="section.id"
          >
            {{section.name}}
          </el-radio>
        </div>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="restoreModal.show = false">Отменить</el-button>
        <el-button size="small" type="primary" @click="restoreQuestion" :loading="loading" :disabled="!restoreModal.sectionId">Восстановить</el-button>
      </span>
    </el-dialog>



    <span ref="wrapper">
      <slot name="reference"></slot>
    </span>
  </div>
</template>

<script>

import {off, on} from "element-ui/src/utils/dom";
import Paginator from "./../Paginator";
import EllipsisMenu from "../EllipsisMenu";
import {paginator} from "@/mixins/paginator";

export default {
  name: "deleted-questions-modal",
  mixins: [paginator],
  components: {EllipsisMenu, Paginator},
  props: {
    testId: {
      type: Number,
    },
    testSections:{
      type: Array
    }
  },

  data() {
    return {
      show: false,
      questions: [],
      loading: false,
      restoreModal: {
        show: false,
        questionId: null,
        sectionId: null,
      }
    }
  },
  mounted() {
    if (this.$refs.wrapper.children) {
      on(this.$refs.wrapper.children[0], 'click', this.showDeleted);
    }
  },
  watch: {},
  methods: {
    showDeleted() {
      this.loading = true;
      this.questions = [];
      this.show = true;
      this.$api.test.getDeletedQuestions({
        test_id: this.testId,
        [this.paginator.param]: this.paginator.page,
        [this.paginator.paramPerPage]: this.paginator.perPage,
      })
        .then(({data, paginator}) => {
          this.questions = data.questions;
          this.paginatorFill(paginator);
        })
        .finally(() => {
          this.loading = false;
        })
    },
    showRestoreModal(question){
      this.restoreModal.questionId = question.id;
      this.restoreModal.indicatorId = null;
      this.restoreModal.show = true;
    },
    restoreQuestion() {
      this.loading = true;
      this.$api.test.restoreQuestion({
        question_id: this.restoreModal.questionId,
        section_id: this.restoreModal.sectionId,
      })
        // .then(({data, paginator}) => {
        .then(data => {
          this.$notify.success({
            duration: this.$constants.global.notifyDuration,
            title: 'Восстановлено',
            message: 'Вопрос успешно восстановлен'
          });
          this.restoreModal.show = false;
          this.show = false;
          this.$emit('question-restored');
        })
        .finally(() => {
          this.loading = false;
        })
    }
  },

  beforeDestroy() {
    if (this.$refs.wrapper.children) {
      off(this.$refs.wrapper.children[0], 'click', this.showDeleted);
    }
  }

}
</script>

<style scope>

</style>