<template>
  <div class="page projects">
    <page-heading heading="Проекты">
      <span slot="help"></span>

      <div slot="right" class="page__head-right">
        <el-button
            v-if="$can('project-create')"
            type="primary"
            @click="editProjectModalShow({})"
        >
          Добавить проект
        </el-button>
      </div>
    </page-heading>

    <div class="page__content">
      <el-row :gutter="24" style="margin-bottom: 24px;" type="flex">
        <el-col :span="4">
          <div class="form-label">
            <span class="form-label__title">Поиск</span>

            <el-input
                style="width: 100%"
                v-model="search"
                prefix-icon="el-icon-search"
                placeholder="Введите текст..."
                size="mini"
                clearable
                @clear="getProjects"
                v-debounce:500="getProjects"
            >
            </el-input>
          </div>
        </el-col>

        <el-col :span="4">
          <div class="form-label">
            <span class="form-label__title">Подразделение</span>

            <division-select
                style="width: 100%"
                size="mini"
                v-model="divisions"
                :multiple="true"
                @selected="getProjects"
            ></division-select>
          </div>
        </el-col>

        <el-col :span="4">
          <div class="form-label">
            <span class="form-label__title">Тип работ</span>

            <el-select
                style="width: 100%"
                v-model="type"
                size="mini"
                clearable
                placeholder="Выберите из списка"
                @change="getProjects"
            >
              <el-option label="Все" value=""></el-option>
              <el-option :label="$constants.project.typesNames[1]" :value="1"></el-option>
              <el-option :label="$constants.project.typesNames[2]" :value="2"></el-option>
            </el-select>
          </div>
        </el-col>

        <el-col :span="4">
          <div class="form-label">
            <span class="form-label__title">Статус проекта СПК</span>

            <el-select
                style="width: 100%"
                v-model="statuses"
                :multiple="true"
                size="mini"
                clearable
                placeholder="Выберите из списка"
                @change="getProjects"
            >
              <el-option-custom with-checkbox :label="$constants.project.statusesNames[1]" :value="1"></el-option-custom>
              <el-option-custom with-checkbox :label="$constants.project.statusesNames[2]" :value="2"></el-option-custom>
              <el-option-custom with-checkbox :label="$constants.project.statusesNames[3]" :value="3"></el-option-custom>
              <el-option-custom with-checkbox :label="$constants.project.statusesNames[4]" :value="4"></el-option-custom>
            </el-select>
          </div>
        </el-col>

        <el-col :span="8" style="align-self: end; text-align: right">
          <el-button
              icon="el-icon-refresh"
              :disabled="isDisabled"
              @click="reset"
          >
            Сбросить
          </el-button>
        </el-col>
      </el-row>

      <transition name="fade">
        <el-row :gutter="24" style="margin-bottom: 24px;" v-if="extraOptions">
          <el-col :span="4">
            <div class="form-label">
              <span class="form-label__title">Дата начала (с)</span>

              <el-date-picker
                :picker-options="{firstDayOfWeek: 1}"
                  style="width: 100%"
                  v-model="date_start_from"
                  align="left"
                  placeholder="Выберите дату"
                  size="mini"
                  type="date"
                  format="dd.MM.yyyy"
                  value-format="timestamp"
                  :editable="false"
                  @change="getProjects"
              >
              </el-date-picker>
            </div>
          </el-col>

          <el-col :span="4">
            <div class="form-label">
              <span class="form-label__title">Дата начала (по)</span>

              <el-date-picker
                :picker-options="{firstDayOfWeek: 1}"
                  style="width: 100%"
                  v-model="date_start_to"
                  placeholder="Выберите дату"
                  size="mini"
                  type="date"
                  format="dd.MM.yyyy"
                  value-format="timestamp"
                  :editable="false"
                  @change="getProjects"
              >
              </el-date-picker>
            </div>
          </el-col>

          <el-col :span="4">
            <div class="form-label">
              <span class="form-label__title">Дата конца (с)</span>

              <el-date-picker
                :picker-options="{firstDayOfWeek: 1}"
                  style="width: 100%"
                  v-model="date_end_from"
                  placeholder="Выберите дату"
                  size="mini"
                  type="date"
                  format="dd.MM.yyyy"
                  value-format="timestamp"
                  :editable="false"
                  @change="getProjects"
              >
              </el-date-picker>
            </div>
          </el-col>

          <el-col :span="4">
            <div class="form-label">
              <span class="form-label__title">Дата конца (по)</span>

              <el-date-picker
                :picker-options="{firstDayOfWeek: 1}"
                  style="width: 100%"
                  v-model="date_end_to"
                  placeholder="Выберите дату"
                  size="mini"
                  type="date"
                  format="dd.MM.yyyy"
                  value-format="timestamp"
                  :editable="false"
                  @change="getProjects"
              >
              </el-date-picker>
            </div>
          </el-col>
        </el-row>
      </transition>

      <el-row :gutter="24" style="margin-bottom: 24px;">
        <el-col :span="4">
          <el-button
              type="text"
              size="small"
              icon="el-icon-arrow-down"
              class="toggle-btn"
              @click="extraOptions = !extraOptions"
              :class="{ active: extraOptions }"
          >
            Дополнительные параметры
          </el-button>
        </el-col>

        <el-col :span="4" style="align-self: end">
          <el-switch
              v-model="myFilter"
              active-text="Мои проекты"
              @change="getProjects"
          >
          </el-switch>
        </el-col>
        <el-col :span="4" style="align-self: end">
          <deleted-projects-modal
            v-if="$can('project-see-deleted')"
            @project-restored="getProjects"
          >
            <el-button
              slot="reference"
              type="text"
              size="small"
              icon="fas fa-trash"
            >
              Журнал удаленных проектов
            </el-button>
          </deleted-projects-modal>
        </el-col>
      </el-row>

      <el-table
          v-loading="loading"
          style="width: 100%"
          :height="extraOptions ? '32vh' : '42vh'"
          :data="projects"
          @sort-change="sortChanged($event, getProjects)"
      >
        <el-table-column
            prop="name"
            label="Проект"
            :sortable="setIsSortable('name')"
        >
          <template slot-scope="scope">
              <span
                  class="el-link"
                  @click="$router.push({ name: 'Project', params: { id: scope.row.id } })"
              >
                {{ scope.row.name }}
              </span>
          </template>
        </el-table-column>

        <el-table-column
            prop="division.name"
            label="Подразделение"
            :sortable="setIsSortable('division.name')"
            show-overflow-tooltip
        >
        </el-table-column>

        <el-table-column
            width="200"
            prop="date_start"
            label="Дата начала"
            :sortable="setIsSortable('date_start')"
            show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.date_start ? new Date(scope.row.date_start * 1000).toLocaleDateString() : '' }}
          </template>
        </el-table-column>

        <el-table-column
            width="200"
            prop="date_end"
            label="Дата завершения"
            :sortable="setIsSortable('date_end')"
            show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.date_end ? new Date(scope.row.date_end * 1000).toLocaleDateString() : '' }}
          </template>
        </el-table-column>

        <el-table-column
            width="200"
            prop="type"
            label="Тип работ"
            :sortable="setIsSortable('type')"
            show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.type ? $constants.project.typesNames[scope.row.type] : '' }}
          </template>
        </el-table-column>

        <el-table-column
            width="200"
            prop="computed_status"
            label="Статус проекта СПК"
            :sortable="setIsSortable('computed_status')"
            show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ $constants.project.statusesNames[scope.row.computed_status] }}
          </template>
        </el-table-column>

        <el-table-column
            label="Методолог"
            show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.admins ? scope.row.admins.map(a => a.full_name).join() : '' }}
          </template>
        </el-table-column>

        <el-table-column width="40" align="right">
          <template slot-scope="scope">
            <ellipsis-menu>
              <el-button
                  v-if="$can('project-update')"
                  style="margin-bottom: 16px"
                  type="text"
                  size="small"
                  icon="el-icon-chat-square"
                  @click="editProjectModalShow(scope.row)"
              >
                Редактировать
              </el-button>

              <el-popconfirm
                  v-if="$can('project-delete', scope.row.permissions)"
                  confirm-button-text='Да'
                  cancel-button-text='нет'
                  icon="el-icon-question"
                  title="Вы уверены, что хотите удалить проект?"
                  @confirm="deleteProject(scope.row)"
              >
                <el-button
                    class="el-button--trash"
                    slot="reference"
                    type="text"
                    size="small"
                    icon="el-icon-delete"
                    :loading="loading"
                >
                  Удалить проект
                </el-button>
              </el-popconfirm>
            </ellipsis-menu>
          </template>
        </el-table-column>
      </el-table>

      <paginator
          :paginator.sync="paginator"
          @page-changed="getProjects(false)"
      ></paginator>
    </div>

    <edit-project-modal
        v-if="$can('project-create') || $can('project-update')"
        :show.sync="editProjectDialog.show"
        :project="editProjectDialog.projectToEdit"
        @saved="getProjects"
    ></edit-project-modal>
  </div>
</template>

<script>
import PageHeading from "../../components/PageHeading";
import Paginator from "../../components/Paginator";
import DivisionStatusSelect from "../../components/selects/DivisionStatusSelect";
import EditProjectModal from "../../components/project/EditProjectModal";
import {sorter} from "../../mixins/sorter";
import {paginator} from "../../mixins/paginator";
import DivisionSelect from "../../components/selects/DivisionSelect";
import EllipsisMenu from "../../components/EllipsisMenu";
import ElOptionCustom from "@/components/custom/ElOptionCustom";
import DeletedProjectsModal from "@/components/project/DeletedProjectsModal";

export default {
  name: 'Users',
  mixins: [sorter, paginator],
  components: {
    DeletedProjectsModal,
    ElOptionCustom,
    EllipsisMenu, DivisionSelect, EditProjectModal, DivisionStatusSelect, Paginator, PageHeading},
  data() {
    return {
      loading: false,
      projects: [],
      search: null,
      division: null,
      divisions: [],
      type: null,
      statuses: [],
      date_start_from: null,
      date_start_to: null,
      date_end_from: null,
      date_end_to: null,
      myFilter: false,
      paginator: {page: 1},
      editProjectDialog: {
        show: false,
        projectToEdit: {}
      },
      extraOptions: false,
    }
  },
  computed: {
    isDisabled() {
      return !this.search &&
             !this.divisions.length &&
             !this.type &&
             !this.statuses.length &&
             this.myFilter &&
             !this.date_start_from &&
             !this.date_start_to &&
             !this.date_end_from &&
             !this.date_end_to
    }
  },
  mounted() {
    this.getProjects();
  },
  methods: {
    getProjects(resetPage=true) {
      this.loading = true;
      if( resetPage ){
        this.paginator.page = 1;
      }
      this.$api.project.search({
        search: this.search,
        divisions: this.divisions,
        type: this.type,
        statuses: this.statuses,
        my: this.myFilter ? 1 : 0,
        date_start_from: this.date_start_from ? this.date_start_from / 1000 : null,
        date_start_to: this.date_start_to ? this.date_start_to / 1000 : null,
        date_end_from: this.date_end_from ? this.date_end_from / 1000 : null,
        date_end_to: this.date_end_to ? this.date_end_to / 1000 : null,
        expand: 'permissions',

        [this.sorter.param]: this.sorter.sort,
        [this.paginator.param]: this.paginator.page,
        [this.paginator.paramPerPage]: this.paginator.perPage,
      })
        .then(({data, paginator, sorter}) => {
          this.sorterFill(sorter);
          this.paginatorFill(paginator);
          this.projects = data.projects;
        })
        .finally(() => {
          this.loading = false;
        })
    },
    editProjectModalShow(project) {
      this.editProjectDialog.projectToEdit = project
      this.editProjectDialog.show = true;
    },
    deleteProject(project) {
      this.loading = true;
      this.$api.project.delete({
        id: project.id
      })
        .then(data => {
          this.$notify.success({
            duration: this.$constants.global.notifyDuration,
            title: 'Удалено',
            message: 'Проект успешно удален'
          });
        })
        .finally(() => {
          this.getProjects();
        })
    },
    reset() {
      this.division = null;
      this.statuses = [];
      this.search = null;
      this.divisions = [];
      this.type = null;
      this.myFilter = false;
      this.date_end_from = null;
      this.date_end_to = null;
      this.date_start_from = null;
      this.date_start_to = null;
      this.getProjects();
    }
  }
}

</script>

<style lang="scss" scoped>
@import "@/assets/css/project-variables.scss";

.el-link {
  white-space: normal;
  color: $--color-primary;
  transition: all .4s;

  &:hover {
    text-decoration: underline;
  }
}
</style>
