<template>
  <div>
    <el-dialog
        :title="title"
        :visible.sync="show"
        :close-on-click-modal="false"
        :width="width"
        top="2vh"
        append-to-body
    >
      <el-row
          v-loading="loading"
      >
        <el-timeline>
          <el-timeline-item
              v-for="element in elements"
              :key="element.id"
              :timestamp="new Date(element.created_at * 1000).toLocaleString()"
              color="#3D8BF4"
              placement="top"
          >
            <span v-if="element.author">{{ element.author.role.description }} {{ element.author.full_name }} ({{ element.author.position }})</span>
            <span v-else>Удаленный пользователь</span>

            <span v-if="element.stage == $constants.mpc.competenceAgreeStages.STAGE_EXPERT">
              завершил(а) работу над компетенцией
            </span>
            <span v-if="element.stage != $constants.mpc.competenceAgreeStages.STAGE_EXPERT">
              <span v-if="element.agreed">
                  согласовал(а) компетенцию
              </span>
              <span v-if="!element.agreed && !element.reset">
                  отклонил(а) компетенцию
              </span>
              <span v-if="!element.agreed && element.reset">
                  отправил(а) на доработку компетенцию
              </span>
            </span>
            "{{ element.competence.name }}"
            <div v-if="element.comment">"{{ element.comment }}"</div>
          </el-timeline-item>
        </el-timeline>

        <paginator
            :paginator.sync="paginator"
            @page-changed="showHistory"
        ></paginator>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="show = false">Закрыть</el-button>
      </span>
    </el-dialog>

    <span ref="wrapper">
      <slot name="reference"></slot>
    </span>
  </div>
</template>

<script>

import {off, on} from "element-ui/src/utils/dom";
import Paginator from "./Paginator";

export default {
  name: "agree-history-modal",
  components: {Paginator},
  props: {
    method: Function,
    params: Object,
    title: {
      type: String,
      default: 'История согласований'
    },
    width: {
      type: String,
      default: '60%'
    },
  },

  data() {
    return {
      show: false,
      elements: [],
      loading: false,
      paginator: {page: 1},
    }
  },
  mounted() {
    if (this.$refs.wrapper.children) {
      on(this.$refs.wrapper.children[0], 'click', this.showHistory);
    }
  },
  watch: {},
  methods: {
    showHistory() {
      this.loading = true;
      this.elements = [];
      this.show = true;
      this.params.page = this.paginator.page;
      this.method(this.params)
          .then(({data, paginator}) => {
            this.elements = data.history;
            this.paginator = paginator;
          })
          .finally(() => {
            this.loading = false;
          })
    }
  },

  beforeDestroy() {
    if (this.$refs.wrapper.children) {
      off(this.$refs.wrapper.children[0], 'click', this.showHistory);
    }
    this.paginator.page = 1;
  }

}
</script>

<style scope>

</style>