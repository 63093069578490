import Vue from "vue";

const Constants = {
    global: {
        notifyDuration: 800,
    },


    rbac: {
        roles: {
            USER: 'user',
            HR_ADMIN: 'hr_admin'
        }
    },

    fc: {
        elementTypes:{
            TYPE_AREA:              1,
            TYPE_GENERAL_FUNCTION:  2,
            TYPE_FUNCTION:          3,
            TYPE_EXPERIENCE:        4,
            TYPE_SKILL:             5,
            TYPE_SOURCE:            6,

            // parent_prop_name: {
            //     1: 'fc_id',
            //     2: 'fc_area_id',
            //     3: 'fc_general_function_id',
            //     4: 'fc_function_id',
            //     5: 'fc_function_id',
            //     6: 'fc_function_id',
            // }
        },
        statuses: {
            STATUS_PLANNED: 1,
            STATUS_ACTIVE:  2,
            STATUS_PAUSED:  3,
            STATUS_FINISHED:4
        },
        statusesNames: {
            1: 'Работа запланирована',
            2: 'В работе',
            3: 'Работа приостановлена',
            4: 'Работа завершена',
        },
    },

    project:{
        types: {
            TYPE_DEVELOPMENT:   1,
            TYPE_ACTUALIZATION: 2
        },
        typesNames: {
            1: 'Разработка СПК',
            2: 'Актуализация СПК',
        },


        statuses: {
            STATUS_PLANNED: 1,
            STATUS_ACTIVE:  2,
            STATUS_PAUSED:  3,
            STATUS_FINISHED:4
        },
        statusesNames: {
            1: 'Запланирован',
            2: 'Активный',
            3: 'Приостановлен',
            4: 'Завершен',
        }
    },

    projectSchedule: {
        statuses: {
            STATUS_PLANNED: 1,
            STATUS_ACTIVE:  2,
            STATUS_PAUSED:  3,
            STATUS_FINISHED:4
        },
        statusesNames: {
            1: 'Работа запланирована',
            2: 'В работе',
            3: 'Работа приостановлена',
            4: 'Работа завершена',
        }
    },

    mpc: {
        MAX_SUBMODEL_COMPETENCES: 35,

        competenceAgreeStages:{
            STAGE_EXPERT:      1,
            STAGE_EXPERT_MAIN: 2,
            STAGE_ADMIN:       3,
            STAGE_PROFILING:   4,
        },
        statuses: {
            STATUS_PLANNED:               1,
            STATUS_PAUSED:                2,
            STATUS_ACTIVE:                3,
            STATUS_FOR_APPROVAL:          4,
            STATUS_APPROVED:              5,
            STATUS_ADJUSTMENT:            6,
            STATUS_APPROVED_BY_STATEMENT: 7,
            STATUS_TRANSFERRED:           8,
        },
        statusesNames: {
            1: 'Работа запланирована',
            2: 'Работа приостановлена',
            3: 'В работе',
            4: 'На согласовании',
            5: 'Согласовано',
            6: 'Корректировка',
            7: 'Утверждена распоряжением',
            8: 'Переданы в ЕК АСУТР',
        },
    },

    tsd: {
        dLevelNames:{
            1: 'Стратегический',
            2: 'Тактический',
            3: 'Операционный',
            4: 'Исполнительский',
        }
    },

    profiling: {
        tsdAgreeStages:{
            STAGE_EXPERT: 1,
            STAGE_EXPERT_MAIN: 2,
            STAGE_ADMIN: 3,
        },
        statuses: {
            STATUS_PLANNED:                 1,
            STATUS_PAUSED:                  2,
            STATUS_PREPARING_FOR_PROFILING: 3,
            STATUS_PROFILING:               4,
            STATUS_FOR_APPROVAL:            5,
            STATUS_APPROVED:                6,
            STATUS_ADJUSTMENT:              7,
            STATUS_APPROVED_BY_STATEMENT:   8,
            STATUS_TRANSFERRED:             9,
        },
        statusesNames: {
            1: 'Работа запланирована',
            2: 'Работа приостановлена',
            3: 'Подготовка к профилированию',
            4: 'Идет профилирование',
            5: 'На согласовании',
            6: 'Согласовано',
            7: 'Корректировка',
            8: 'Утверждены распоряжением',
            9: 'Переданы в ЕК АСУТР',
        },
    },

    pilotAssessment: {
        statuses:{
            STATUS_PLANNED:  1,
            STATUS_PAUSED:   2,
            STATUS_ACTIVE:   3,
            STATUS_FINISHED: 4,
        },
        statusesNames: {
            1: 'Работа запланирована',
            2: 'Работа приостановлена',
            3: 'Идет пилотная оценка СПК',
            4: 'Работа завершена',
        },
    },

    test: {
        questionTypes: {
            TYPE_SINGLE: 1,
            TYPE_MULTIPLE: 2,
        },

        checklistResults:{
            1: 'Не соответствует функциям моей должности',
            2: 'Неверный правильный вариант ответа',
            3: 'Использован недействующий нормативный документ',
            4: 'Вопрос трудно воспринимается',
            5: 'Опечатка или орфографическая ошибка',
            6: 'Другое',
        },

        questionAgreeStages:{
            STAGE_EXPERT: 1,
            STAGE_EXPERT_MAIN: 2,
            STAGE_ADMIN: 3,
        },

        questionStatuesNames: {
            1:'Единственный выбор',
            2:'Множественный выбор',
        },

        testStatuses: {
            STATUS_PLANNED: 1,
            STATUS_ACTIVE: 2,
            STATUS_FOR_APPROVAL: 3,
            STATUS_AGREED_FOR_PILOT: 4,
            STATUS_AGREED_AFTER_PILOT: 5,
        },
        testStatusesNames: {
            1: 'Работа запланирована',
            2: 'В работе',
            3: 'На согласовании',
            4: 'Согласовано для пилотной оценки',
            5: 'Согласованы все вопросы теста после пилота',
        },
    },

    testsDevelopment: {
        statuses: {
            STATUS_PLANNED:        1,
            STATUS_PAUSED:         2,
            STATUS_ACTIVE:         3,
            STATUS_FOR_APPROVAL:   4,
            AGREED_FOR_PILOT:      5,
            EXPERTISE_IN_PROGRESS: 6,
            AGREED_AFTER_PILOT:    7,
            STATUS_TRANSFERRED:    8,
        },
        statusesNames: {
            1: 'Работа запланирована',
            2: 'Работа приостановлена',
            3: 'В работе',
            4: 'На согласовании',
            5: 'Согласовано для пилотной оценки',
            6: 'Идет экспертиза',
            7: 'Согласованы для ЕК АСУТР',
            8: 'Переданы в ЕК АСУТР',
        },
        checklistResults:{
            1: 'оставить без изменений',
            2: 'скорректировать формулировку вопроса',
            3: 'скорректировать вариант ответа (усложнить)',
            4: 'скорректировать вариант ответа (упростить)',
            5: 'скорректировать ответы (устранить дубляжи)',
            6: 'скорректировать в соответствии с актуальной нормативной документацией',
            7: 'заменить вопрос (не соответствует индикатору)',
            8: 'Другое',
        },
    },

    reportMonitoring: {
        testsStatuses:{
            FINISHED: 1,
            NOT_FINISHED: 2,
            NOT_ASSIGNED: 3,
        },
        testsStatusesNames: {
            1: '+',
            2: '-',
            3: 'Не назначен',
        },
        pilotStatuses: {
            FINISHED: 1,
            NOT_FINISHED: 2,
        },
        pilotStatusesNames: {
            1: 'Пройдено',
            2: 'Не пройдено',
        }
    },

    division: {
        statuses:{
            DISABLED: 1,
            ACTIVE: 2,
        },
        statusesNames: {
            1: 'Неактивно',
            2: 'Активно',
        },
        spk: {
            statuses:{
                IMPLEMENTED: 1,
                NOT_IMPLEMENTED: 2,
            },
            statusesNames: {
                1: 'Внедрена СПК',
                2: 'Не внедрена СПК',
            },
        },
    },

    introduction: {
        statuses:{
            NOT_IMPLEMENTED   : 1,
            IMPLEMENTED       : 2,

            NOT_ACTUALIZED    : 11,
            ACTUALIZED        : 12,
        },
        statusesNames: {
            1: 'Не внедрена СПК',
            2: 'Внедрена СПК',

            11: 'Не актуализирована',
            12: 'Актуализирована',
        },
    },

    comment: {
        entityTypes: {
            FC        : 1,
            MPC       : 2,
            PROFILING : 3,
        }
    },


    get statusesMap() {
        return {
            schedule: this.projectSchedule.statusesNames,
            fc: this.fc.statusesNames,
            mpc: this.mpc.statusesNames,
            profiling: this.profiling.statusesNames,
            tests_development: this.testsDevelopment.statusesNames,
            pilot: this.pilotAssessment.statusesNames,
            introduction: this.introduction.statusesNames,
        }
    },
}

export default {
    install(Vue) {
        Vue.prototype.$constants = Constants;
    }
}
