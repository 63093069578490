<template>
  <div>
    <page-heading
        heading="Подразделения"
    >
      <span slot="help"></span>
      <span slot="right">
        <el-button
            v-if="$can('division-create')"
            type="primary"
            size="small"
            @click="editDivisionModalShow({})"
        >
            Добавить подразделение
        </el-button>
      </span>
    </page-heading>

    <el-row :gutter="20" style="margin-bottom: 10px;">
      <el-col :span="7">
        <el-input
            v-model="search"
            prefix-icon="el-icon-search"
            placeholder="Поиск подразделения"
            size="mini"
            clearable
            @clear="getDivisions"
            v-debounce:500="getDivisions"
        >
        </el-input>
      </el-col>
      <el-col :span="6">
        <division-status-select
            v-model="status"
            @change="getDivisions"
        ></division-status-select>
      </el-col>
      <el-col :span="6">
        <division-spk-status-select
            v-model="spkStatus"
            @change="getDivisions"
        ></division-spk-status-select>
      </el-col>
      <el-col :span="5" style="align-self: end; text-align: right; margin-left: auto;">
        <el-button
            icon="el-icon-refresh"
            :disabled="isDisabled"
            @click="reset"
        >
          Сбросить
        </el-button>
      </el-col>
    </el-row>

    <el-table
        class="divisions-table"
        v-loading="loading"
        style="width: 100%"
        height="54vh"
        :data="divisions"
        :row-class-name="tableRowClassName"
        @sort-change="sortChanged($event, getDivisions)"
    >
      <el-table-column prop="name" label="Подразделение" :sortable="setIsSortable('name')">
        <template slot-scope="scope">
          <el-link
              type="primary"
              :disabled="!$can('division-update')"
              @click="editDivisionModalShow(scope.row)"
          >
            {{ scope.row.name }}
          </el-link>
        </template>
      </el-table-column>
      <el-table-column
          prop="created_at"
          label="Заведено на платформу"
          :sortable="setIsSortable('created_at')"
      >
        <template slot-scope="scope">
          {{ scope.row.created_at ? new Date(scope.row.created_at * 1000).toLocaleDateString() : '' }}
        </template>
      </el-table-column>

      <el-table-column
          prop="action_end"
          label="Неактивно с"
          :sortable="setIsSortable('action_end')"
      >
        <template slot-scope="scope">
          {{ scope.row.action_end ? new Date(scope.row.action_end * 1000).toLocaleDateString() : '' }}
        </template>
      </el-table-column>

      <el-table-column
          prop="spkStatus"
          label="Статус внедрения СПК"
      >
        <template slot-scope="scope">
            {{$constants.division.spk.statusesNames[scope.row.spkStatus]}}
        </template>
      </el-table-column>

      <el-table-column width="40" align="right">
        <template slot-scope="scope">
          <ellipsis-menu>
            <el-button
                v-if="$can('division-update')"
                type="text"
                size="small"
                icon="el-icon-edit"
                @click="editDivisionModalShow(scope.row)"
            >
              Редактировать
            </el-button>

            <el-popconfirm
                v-if="$can('division-delete')"
                confirm-button-text='Да'
                cancel-button-text='нет'
                icon="el-icon-question"
                title="Вы уверены, что хотите удалить подразделение?"
                @confirm="deleteDivision(scope.row)"
            >
              <el-button
                  slot="reference"
                  type="text"
                  size="small"
                  icon="el-icon-delete"
                  :loading="loading"
              >
                Удалить подразделение
              </el-button>
            </el-popconfirm>
          </ellipsis-menu>
        </template>
      </el-table-column>
    </el-table>

    <paginator
        :paginator.sync="paginator"
        @page-changed="getDivisions(false)"
    ></paginator>

    <edit-division-modal
        :show.sync="editDivisionDialog.show"
        :division="editDivisionDialog.divisionToEdit"
        @saved="getDivisions(false)"
    ></edit-division-modal>
  </div>
</template>

<script>


import PageHeading from "../components/PageHeading";
import Paginator from "../components/Paginator";
import DivisionStatusSelect from "../components/selects/DivisionStatusSelect";
import DivisionSpkStatusSelect from "../components/selects/DivisionSpkStatusSelect";
import EditDivisionModal from "../components/division/EditDivisionModal";
import {sorter} from "../mixins/sorter";
import {paginator} from "../mixins/paginator";
import EllipsisMenu from "../components/EllipsisMenu";

export default {
  name: 'Divisions',
  mixins: [sorter, paginator],
  components: {EllipsisMenu, EditDivisionModal, DivisionStatusSelect, Paginator, PageHeading, DivisionSpkStatusSelect},
  data() {
    return {
      loading: false,
      divisions: [],


      search: null,
      status: null,
      spkStatus: null,

      editDivisionDialog: {
        show: false,
        divisionToEdit: {}
      }
    }
  },
  computed: {
    isDisabled() {
      return !this.search &&
             !this.status &&
             !this.spkStatus
    }
  },

  mounted() {
    this.getDivisions();
  },
  methods: {
    getDivisions(resetPage=true) {
      if( resetPage ){
        this.paginator.page = 1;
      }
      this.loading = true;
      this.$api.division.search({
        search: this.search,
        status: this.status,
        status_spk: this.spkStatus,

        [this.sorter.param]: this.sorter.sort,
        [this.paginator.param]: this.paginator.page,
        [this.paginator.paramPerPage]: this.paginator.perPage,
      })
          .then(({data, paginator, sorter}) => {
            this.sorterFill(sorter);
            this.paginatorFill(paginator);
            this.divisions = data.divisions;
          })
          .finally(() => {
            this.loading = false;
          })
    },

    editDivisionModalShow(division) {
      this.editDivisionDialog.divisionToEdit = division
      this.editDivisionDialog.show = true;
    },

    deleteDivision(division) {
      this.loading = true;

      this.$api.division.delete({
        id: division.id
      })
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDuration,
              title: 'Удалено',
              message: 'Подразделение успешно удалено'
            });
          })
          .finally(() => {
            this.getDivisions();
          })
    },
    tableRowClassName({row}) {
      return row.action_end ? 'deactivated' : '';
    },
    reset() {
      this.search    = null;
      this.status    = null;
      this.spkStatus = null;
      this.getDivisions();

    }
  }
}

</script>

<style lang="scss" >
.el-table {
    &.divisions-table {
      tr {
        &.deactivated {
          td {
            > * {
              color: #b5b5b5;
            }
            a {
              color: #b5b5b5;
              &:hover {
                &:after{
                  border-color: #b5b5b5;
                }
              }
            }
          }
        }
      }
    }
  }
</style>
