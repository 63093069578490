// Import router if you are using the middleware on Vue Router
// import router from "../router";
import store from "../store";
import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api'; // Ensure this is installed
Vue.use(VueCompositionAPI); // VueCompositionAPI must be used as plugin before any function, otherwise see your console if warning/errpr
import { computed } from '@vue/composition-api'; // import computed function
import { createAcl, defineAclRules } from 'vue-simple-acl/dist/vue-simple-acl.vue2';

const user = computed(() => store.getters.user);
const rules = () => defineAclRules((setRule) => {

    // setRule('kpi-manage-folder', (user, folder) => folder.canManage);


    setRule('rbac-see', (user) =>  user.value.permissions.includes('rbac.see'));
    setRule('rbac-manage', (user) =>  user.value.permissions.includes('rbac.manage'));

    setRule('users-see', (user) =>  user.value.permissions.includes('user.see'));
    setRule('users-create', (user) =>  user.value.permissions.includes('user.create'));
    setRule('users-update', (user) =>  user.value.permissions.includes('user.update'));
    setRule('users-delete', (user) =>  user.value.permissions.includes('user.delete'));
    setRule('users-activate', (user) =>  user.value.permissions.includes('user.activate'));
    setRule('users-deactivate', (user) =>  user.value.permissions.includes('user.deactivate'));
    setRule('users-reset-password', (user) =>  user.value.permissions.includes('user.reset_password'));
    setRule('users-set-pilot-password', (user) =>  user.value.permissions.includes('user.set_pilot_password'));
    setRule('users-import', (user) =>  user.value.permissions.includes('user.import'));

    setRule('division-see', (user) =>  user.value.permissions.includes('division.see'));
    setRule('division-create', (user) =>  user.value.permissions.includes('division.create'));
    setRule('division-update', (user) =>  user.value.permissions.includes('division.update'));
    setRule('division-delete', (user) =>  user.value.permissions.includes('division.delete'));


    setRule('project-see', (user) =>  user.value.permissions.includes('project.see'));
    setRule('project-see-deleted', (user) =>  user.value.permissions.includes('project.see_deleted'));
    setRule('project-create', (user) =>  user.value.permissions.includes('project.create'));
    setRule('project-update', (user) =>  user.value.permissions.includes('project.update'));
    setRule('project-delete', (user, project) => {
        return project && project.can_delete;
    });
    setRule('project-see-workgroup', (user) =>  user.value.permissions.includes('project.see_workgroup'));
    setRule('project-add-to-workgroup', (user, project) => {
        return user.value.permissions.includes('project.add_to_workgroup') || (project && project.can_add_users);
    });
    setRule('project-delete-from-workgroup', (user, project) => {
        return user.value.permissions.includes('project.delete_from_workgroup') || (project && project.can_delete_users);
    });
    setRule('project-set-tsd', (user, project) => {
        return user.value.permissions.includes('project.set_tsd') || (project && project.can_set_tsd);
    });
    setRule('project-set-status', (user, project) => {
        return user.value.permissions.includes('project.set_status') || (project && project.can_set_status);
    });
    setRule('project-set-element-name', (user) => {
        return user.value.permissions.includes('project.create');
    });
    setRule('project-introduction-edit', (user, project) => {
        return user.value.permissions.includes('introduction.edit') || (project && project.can_introduction_edit);
    });


    setRule('schedule-update', (user, schedule) => {
        return user.value.permissions.includes('schedule.update') || (schedule && schedule.can_update);
    });
    setRule('schedule-export', (user, schedule) => {
        return user.value.permissions.includes('schedule.export') || (schedule && schedule.can_export);
    });
    setRule('schedule-set-status', (user, schedule) => {
        return user.value.permissions.includes('schedule.set_status') || (schedule && schedule.can_set_status);
    });



    setRule('fc-export', (user, fc) => {
        return user.value.permissions.includes('fc.export') || (fc && fc.can_export);
    });
    setRule('fc-create-area', (user, fc) => {
        return user.value.permissions.includes('fc.create_area') || (fc && fc.can_create_area);
    });
    setRule('fc-update-area', (user, element) => {
        return user.value.permissions.includes('fc.update_area') || (element && element.can_update);
    });
    setRule('fc-set-area-user', (user, area) => {
        return user.value.permissions.includes('fc.set_area_user') || (area && area.can_set_user);
    });
    setRule('fc-see-deleted-areas', (user, area) => {
        return user.value.permissions.includes('fc.see_deleted_areas') || (area && area.can_see_deleted_areas);
    });
    setRule('fc-restore-deleted-areas', (user, area) => {
        return user.value.permissions.includes('fc.restore_deleted_areas') || (area && area.can_restore);
    });
    setRule('fc-see-area-history', (user, area) => {
        return user.value.permissions.includes('fc.see_area_history') || (area && area.can_see_history);
    });
    setRule('fc-copy-area-to-mpc', (user, area) => {
        return user.value.permissions.includes('fc.copy_area_to_mpc') || (area && area.can_copy_to_mpc);
    });


    setRule('mpc-export', (user, mpc) => {
        return user.value.permissions.includes('mpc.export') || (mpc && mpc.can_export);
    });
    setRule('mpc-copy-competence-from-another-mpc', (user, mpc) => {
        return user.value.permissions.includes('mpc.copy_competence_from_another_mpc') || (mpc && mpc.can_copy_competence_from_another_mpc);
    });
    setRule('mpc-create-competence', (user, mpc) => {
        return user.value.permissions.includes('mpc.create_competence') || (mpc && mpc.can_create_competence);
    });
    setRule('mpc-update-competence', (user, competence) => {
        return competence && competence.can_update;
    });
    setRule('mpc-delete-competence', (user, competence) => {
        return competence && competence.can_delete;
    });
    setRule('mpc-restore-competence', (user, competence) => {
        return competence && competence.can_restore;
    });
    setRule('mpc-move-competence', (user, competence) => {
        return competence && competence.can_move;
    });
    setRule('mpc-see-deleted-competences', (user, area) => {
        return user.value.permissions.includes('mpc.see_deleted_competences') || (area && area.can_see_deleted_competences);
    });
    setRule('mpc-set-competence-user', (user, competence) => {
        return user.value.permissions.includes('mpc.set_competence_user') || (competence && competence.can_set_user);
    });
    setRule('mpc-set-competence-main-user', (user, competence) => {
        return user.value.permissions.includes('mpc.set_competence_main_user') || (competence && competence.can_set_main_user);
    });
    setRule('mpc-see-competence-history', (user, competence) => {
        return user.value.permissions.includes('mpc.see_competence_history') || (competence && competence.can_see_history);
    });
    setRule('mpc-see-competence-agree-history', (user, competence) => {
        return user.value.permissions.includes('mpc.see_competence_agree_history') || (competence && competence.can_see_agree_history);
    });



    setRule('profiling-export', (user, profilingTsd) => {
        return user.value.permissions.includes('profiling.export') || (profilingTsd && profilingTsd.can_export);
    });
    setRule('profiling-delete-tsd', (user, profilingTsd) => {
        return user.value.permissions.includes('profiling.delete_tsd') || (profilingTsd && profilingTsd.can_delete);
    });
    setRule('profiling-restore-tsd', (user, profilingTsd) => {
        return user.value.permissions.includes('profiling.restore_tsd') || (profilingTsd && profilingTsd.can_restore);
    });
    setRule('profiling-see-deleted-tsds', (user, profiling) => {
        return user.value.permissions.includes('profiling.see_deleted_tsds') || (profiling && profiling.can_see_deleted_tsds);
    });
    setRule('profiling-start', (user, profiling) => {
        return profiling && profiling.can_start_profiling;
    });
    setRule('profiling-see-tsd-agree-history', (user, profilingTsd) => {
        return user.value.permissions.includes('profiling.see_std_agree_history') || (profilingTsd && profilingTsd.can_see_agree_history);
    });
    setRule('profiling-set-tsd-user', (user, profilingTsd) => {
        return user.value.permissions.includes('profiling.set_tsd_user') || (profilingTsd && profilingTsd.can_set_user);
    });
    setRule('profiling-set-tsd-main-user', (user, profilingTsd) => {
        return user.value.permissions.includes('profiling.set_tsd_main_user') || (profilingTsd && profilingTsd.can_set_main_user);
    });
    setRule('profiling-set-competence-user', (user, competence) => {
        return user.value.permissions.includes('profiling.set_competence_user') || (competence && competence.can_set_profiling_user);
    });
    setRule('profiling-import-tos', (user, profiling) => {
        return profiling && profiling.can_import_tos;
    });


    setRule('test-development-set-test-user', (user, test) => {
        return user.value.permissions.includes('test_development.set_test_user') || (test && test.can_set_user);
    });
    setRule('test-development-set-test-main-user', (user, test) => {
        return user.value.permissions.includes('test_development.set_test_main_user') || (test && test.can_set_main_user);
    });
    setRule('test-development-download-scorm', (user, test) => {
        return user.value.permissions.includes('test_development.download_scorm') || (test && test.can_download_scorm);
    });
    setRule('test-development-preview', (user, test) => {
        return user.value.permissions.includes('test_development.preview') || (test && test.can_preview);
    });

    setRule('test-editor-create-question', (user, test) => {
        return user.value.permissions.includes('test_editor.create_question') || (test && test.can_create_question);
    });
    setRule('test-editor-update-question', (user, question) => {
        return question && (!question.id || question.can_update);
    });
    setRule('test-editor-delete-question', (user, question) => {
        return user.value.permissions.includes('test_editor.delete_question') || (question && question.can_delete);
    });
    setRule('test-editor-move-question', (user, question) => {
        return user.value.permissions.includes('test_editor.move_question') || (question && question.can_move);
    });
    setRule('test-editor-set-question-author', (user, test) => {
        return user.value.permissions.includes('test_editor.set_question_author') || (test && test.can_set_author);
    });
    setRule('test-editor-see-question-agree-history', (user, question) => {
        return user.value.permissions.includes('test_editor.see_question_agree_history') || (question && question.can_see_agree_history);
    });
    setRule('test-editor-see-deleted-questions', (user, test) => {
        return user.value.permissions.includes('test_editor.see_deleted_questions') || (test && test.can_see_deleted_questions);
    });
    setRule('test-export-pilot-report', (user, test) => {
        return user.value.permissions.includes('report.export_pilot') || (test && test.can_export_pilot_report);
    });
    setRule('test-add-solution', (user, test) => {
        return user.value.permissions.includes('report.add_solution_pilot') || (test && test.can_add_solution);
    });


    setRule('pilot.start', (user, pilot) => checkObjectPermission(pilot, 'pilot.start') );




    setRule('library-see', (user) =>  user.value.permissions.includes('library.see'));
    setRule('library-manage-folders', (user) =>  user.value.permissions.includes('library.manage_folders'));
    setRule('library-delete-files', (user) =>  user.value.permissions.includes('library.delete_files'));

    setRule('assignments-see', (user) =>  user.value.roles && user.value.roles.includes('pilot'));

    setRule('comment-update', (user, comment) => {
        return user.value.permissions.includes('comment.update') || (comment && comment.can_update);
    });
    setRule('comment-delete', (user, comment) => {
        return user.value.permissions.includes('comment.delete') || (comment && comment.can_delete);
    });


    setRule('menu-see-rbac', (user) =>  user.value.permissions.includes('menu.see_rbac'));
    setRule('menu-see-divisions', (user) =>  user.value.permissions.includes('menu.see_divisions'));
    setRule('menu-see-users', (user) =>  user.value.permissions.includes('menu.see_users'));
    setRule('menu-see-projects', (user) =>  user.value.permissions.includes('menu.see_projects'));
    setRule('menu-see-library', (user) =>  user.value.permissions.includes('menu.see_library'));
    setRule('menu-see-reports', (user) =>  user.value.permissions.includes('menu.see_reports'));
    setRule('menu-see-assignments', (user) =>  user.value.permissions.includes('menu.see_assignments'));

});

const checkObjectPermission = (object, permission) => {
    if( !object || !object.permissions || !object.permissions[permission] ){
        return false;
    }
    return object.permissions[permission].can;
}

const simpleAcl = createAcl({
    user,
    rules,
    // router
});

export default simpleAcl;